import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { BillingCodeCustomerComponent } from './infratrac/billingcodecustomer/billingcodecustomer.component';
import { BillingCodeImportComponent } from './infratrac/billingcode-import/billingcode-import.component';
import { BillingCodeInfratechComponent } from './infratrac/billingcodeinfratech-table/billingcodeinfratech.component';
import { BillingUnitTypeComponent } from './infratrac/billingunittype-table/billingunittype.component';
import { CustomerComponent } from './infratrac/customer-form/customer.component';
import { CustomerInvoiceComponent } from './infratrac/customerinvoices-table/customerinvoice.component';
import { CustomerGroupInvoicesComponent } from './infratrac/customergroupinvoices/customergroupinvoices.component';
import { CustomerGroupInvoiceComponent } from './infratrac/customergroupinvoicing/customergroupinvoice.component';
import { CrewComponent } from './infratrac/crew-form/crew.component';
import { CrewMemberComponent } from './infratrac/crewmembers/crewmember.component';
import { ContractChildComponent } from './infratrac/contract-details/contract.component';
import { ContractFormComponent } from './infratrac/contract-form/contract-form.component';
import { ContractOverviewComponent } from './infratrac/contract-overview/contractoverview.component';
import { DfrComponent } from './infratrac/dfr/dfr.component';
import { DfrBillableProductionComponent } from './infratrac/dfrbillableproduction/dfrbillableproduction.component';
import { DfrLocateTicketComponent } from './infratrac/dfrlocatetickets/dfrlocateticket.component';
import { DfrProductionDelaysComponent } from './infratrac/dfrProductionDelays/dfrproductiondelays.component';
import { DfrPayRollHoursTableComponent } from './infratrac/dfrpayrollhours/dfrpayrollhour.component';
import { DfrEquipmentUsageComponent } from './infratrac/dfrequipmentusage/dfrequipmentusage.component';
import { DfrSystemDownTimeComponent } from './infratrac/dfrsystemdowntime/dfrsystemdowntime.component';
import { OfficeLocationsTableComponent } from './infratrac/officelocation-table/officelocationtable.component';
import { PayRollHoursTableComponent } from './infratrac/payroll-table/payroll-table.component';
import { EmployeeComponent } from './infratrac/employee-form/employee.component';
import { EmployeeImportComponent } from './infratrac/employeeimport/employee-import.component';
import { EquipmentComponent } from './infratrac/equipment/equipment.component';
import { VendorComponent } from './infratrac/vendor-form/vendor.component';
import { EmployeeDetailsComponent } from './infratrac/employee-details/employee-details.component';
import { ValidationDemo } from './infratrac/validationdemo/validationdemo.component';
import { CrewTypeComponent } from './infratrac/crewtypes-form/crewtype.component';
import { SubContractorComponent } from './infratrac/subcontractor-form/subcontractor.component';
import { LinemanToolTableComponent } from './infratrac/linemantool-table/linemantooltable.component';
import { WoSearchComponent } from './infratrac/wo-search/wosearch.component';
import { WoTableComponent } from './infratrac/wo-table/wotable.component';
import { RpCrewComponent } from './infratrac/rpcrew-list/rpcrew.component';
import { ReportViewerComponent } from './infratrac/telerik-reportviewer/reportviewer.component';
import { ReportPageComponent } from './infratrac/reportpage/reportpage.component';
import { ReportPageViewerComponent } from './infratrac/reportviewer/reportviewer.component';
import { TaskTableComponent } from './infratrac/task-table/tasktable.component';
import { SupervisorComponent } from './infratrac/supervisor-table/supervisor.component';
import { VendorTableComponent } from './infratrac/vendor-dfrlist/vendortable.component';
import { BlobComponent } from './infratrac/blob-uploader/blob.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { ExcelImportComponent } from './infratrac/excel-importer/excelimport.component';
import { WoInvoiceComponent } from './infratrac/invoicing/woinvoice.component';
import { UnbilledTableComponent } from './infratrac/unbilled/unbilledtable.component';
import { GroupInvoicePaymentComponent } from './infratrac/invoicegrouppayment/grouppayment.component';
import { EstimatedUnitComponent } from './infratrac/estimatedunits/estimatedunit.component';
import { PositionComponent } from './infratrac/position/position.component';
import { PositionMapperComponent } from './infratrac/positionmapper/positionmapper.component';
import { ProjectComponent } from './infratrac/projects/project.component';
import { TrainingComponent } from './infratrac/training/training.component';
import { PowerBiComponent } from './infratrac/powerbi/powerbi.component';
import { EditPersonComponent } from './infratrac/persondemo/person-edit.component';
import { DynamicControlComponent } from './infratrac/dynamic-controls/dynamic-control.component';
import { WoContractUpdateComponent } from './infratrac/wocontractupdate-import/wocontractupdate-import.component';

export const routes: Routes = [
    { path: '', component: DashboardDemoComponent, canActivate: [AuthenticationGuard] },
    //{ path: 'sample', component: SampleDemoComponent },
    //{ path: 'forms', component: FormsDemoComponent },
    //{ path: 'data', component: DataDemoComponent },
    //{ path: 'panels', component: PanelsDemoComponent },
    //{ path: 'overlays', component: OverlaysDemoComponent },
    //{ path: 'menus', component: MenusDemoComponent },
    //{ path: 'messages', component: MessagesDemoComponent },
    //{ path: 'misc', component: MiscDemoComponent },
    //{ path: 'empty', component: EmptyDemoComponent },
    //{ path: 'charts', component: ChartsDemoComponent },
    //{ path: 'file', component: FileDemoComponent },
    //{ path: 'documentation', component: DocumentationComponent },
    { path: 'customer', component: CustomerComponent, canActivate: [AuthenticationGuard] },
    { path: 'customerinvoices', component: CustomerInvoiceComponent, canActivate: [AuthenticationGuard] },

    { path: 'crew', component: CrewComponent, canActivate: [AuthenticationGuard] },
    { path: 'contract', component: ContractChildComponent, canActivate: [AuthenticationGuard] },
    { path: 'contractform', component: ContractFormComponent, canActivate: [AuthenticationGuard] },
    { path: 'employee', component: EmployeeComponent, canActivate: [AuthenticationGuard] },
    { path: 'employeedetails', component: EmployeeDetailsComponent, canActivate: [AuthenticationGuard] },
    //{ path: 'validation', component: ValidationDemo },
    { path: 'crewtype', component: CrewTypeComponent, canActivate: [AuthenticationGuard] },
    { path: 'subcontractor', component: SubContractorComponent, canActivate: [AuthenticationGuard] },
    { path: 'payrolltable', component: PayRollHoursTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'linemantooltable', component: LinemanToolTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'billingunittypes', component: BillingUnitTypeComponent, canActivate: [AuthenticationGuard] },
    { path: 'billingcodesinfratech', component: BillingCodeInfratechComponent, canActivate: [AuthenticationGuard] },
    { path: 'billingcodescustomer', component: BillingCodeCustomerComponent, canActivate: [AuthenticationGuard] },
    { path: 'contractoverview', component: ContractOverviewComponent, canActivate: [AuthenticationGuard] },
    { path: 'contractoverview/:intContractId/:intCustomerId', component: ContractOverviewComponent, canActivate: [AuthenticationGuard] },
    { path: 'wotable', component: WoTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'wosearch', component: WoSearchComponent, canActivate: [AuthenticationGuard] },
    { path: 'reportviewer', component: ReportViewerComponent, canActivate: [AuthenticationGuard] },
    { path: 'reportpage', component: ReportPageComponent, canActivate: [AuthenticationGuard] },
    { path: 'reportpageviewer', component: ReportPageViewerComponent, canActivate: [AuthenticationGuard] },
    { path: 'tasktable', component: TaskTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'supervisors', component: SupervisorComponent, canActivate: [AuthenticationGuard] },
    { path: 'vendor', component: VendorComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfr', component: DfrComponent, canActivate: [AuthenticationGuard] },   
    { path: 'rpcrews', component: RpCrewComponent, canActivate: [AuthenticationGuard] },
    { path: 'vendordfr', component: VendorTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'officelocation', component: OfficeLocationsTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'crewmembers', component: CrewMemberComponent, canActivate: [AuthenticationGuard] },
    { path: 'file-upload', component: BlobComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrbp', component: DfrBillableProductionComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrpayrollhour', component: DfrPayRollHoursTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrproductiondelay', component: DfrProductionDelaysComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrsystemdownime', component: DfrSystemDownTimeComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrlocateticket', component: DfrLocateTicketComponent, canActivate: [AuthenticationGuard] },
    { path: 'dfrequipmentusage', component: DfrEquipmentUsageComponent, canActivate: [AuthenticationGuard] },
    { path: 'excelimport', component: ExcelImportComponent, canActivate: [AuthenticationGuard] },
    { path: 'bcimport', component: BillingCodeImportComponent, canActivate: [AuthenticationGuard] },
    { path: 'woinvoicing', component: WoInvoiceComponent, canActivate: [AuthenticationGuard] },
    { path: 'unbilled', component: UnbilledTableComponent, canActivate: [AuthenticationGuard] },
    { path: 'groupInvoice', component: CustomerGroupInvoiceComponent, canActivate: [AuthenticationGuard] },
    { path: 'groupInvoices', component: CustomerGroupInvoicesComponent, canActivate: [AuthenticationGuard] },
    { path: 'groupinvoicepayment', component: GroupInvoicePaymentComponent, canActivate: [AuthenticationGuard] },
    { path: 'equipment', component: EquipmentComponent, canActivate: [AuthenticationGuard] },
    { path: 'estimatedunits', component: EstimatedUnitComponent, canActivate: [AuthenticationGuard] },
    { path: 'positions', component: PositionComponent, canActivate: [AuthenticationGuard] },
    { path: 'positionmapper', component: PositionMapperComponent, canActivate: [AuthenticationGuard] },
    { path: 'projects', component: ProjectComponent, canActivate: [AuthenticationGuard] },
    { path: 'training', component: TrainingComponent, canActivate: [AuthenticationGuard] },
    { path: 'powerbi', component: PowerBiComponent, canActivate: [AuthenticationGuard] },
    { path: 'employeeimport', component: EmployeeImportComponent, canActivate: [AuthenticationGuard] },
    { path: 'personedit', component: EditPersonComponent, canActivate: [AuthenticationGuard] },
    { path: 'dynamiccontrols', component: DynamicControlComponent, canActivate: [AuthenticationGuard] },

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
