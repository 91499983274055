import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DailyFieldReport } from '../domain/dailyfieldreport';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DailyFieldReportService {
    dfr: Observable<DailyFieldReport[]>;
    newdfr: DailyFieldReport;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('DFRService');
    }
    baseUrl: string = 'api/DailyFieldReports/';
    errorUrl: string = 'http://localhost:5243/api/Customers/';

    getDFRs(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDFRs', []))

            );
    }

    getDFRbyWoId(int): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'woid/' + int)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDFRbyWOId', []))

            );
    }

    getDFRbyCrewId(int): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'crewid/' + int)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDFRbyCrewId', []))

            );
    }

    getDFRbyDfrId(int): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'dfrid/' + int)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDFRbyDfrId', []))

            );
    }

    getDFRbyForemanId(int): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'foremanid/' + int)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDFRbyforemanId', []))

            );
    }

    getUnapprovedDfrs(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'unapproveddfrs/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getUnapporvedDfrs', []))

            );
    }

    getRevenueYTD(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'revenueYTD/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRevenueYTD', []))

            );
    }

    getRevenueMTD(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'revenueMTD/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRevenueMTD', []))

            );
    }

    getRevenueWTD(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'revenueWTD/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRevenueWTD', []))

            );
    }

    getRevenueDaily(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'revenueDaily/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRevenueDaily', []))

            );
    }

    getInvoiceRequest(): Observable<DailyFieldReport[]> {
        return this.http.get<DailyFieldReport[]>(this.baseUrl + 'billrequest/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getBillRequest', []))

            );
    }

    addDFR(dfr: DailyFieldReport): Observable<DailyFieldReport> {
        return this.http.post<DailyFieldReport>(this.baseUrl, dfr)
            .pipe(
                catchError(this.handleError('addDFR', dfr))
            );
    }

    updateDFR(id, dfr: DailyFieldReport): Observable<DailyFieldReport> {
        return this.http.put(this.baseUrl + id, dfr)
            .pipe(
                tap(_ => console.log(`updated DFR id=${id}`)),
                catchError(this.handleError('updateDFR', dfr))
            );
    }

    deleteDfr(id): Observable<DailyFieldReport> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted DFR id=${id}`)),
                catchError(this.handleError('DeletedDFR'))
            );
    }
}
