import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import { SelectItem } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { TaskService } from '../service/taskservice';
import { Task } from '../domain/task';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
@Component({
    selector: 'task-table',
    templateUrl: './tasktable.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class TaskTableComponent implements OnInit {
    wo: Job = {};

    wos: Job[];

    task: Task = {};

    completedTask: Task = {};

    tasks: Task[];

    crews: Crew[];

    crew: Crew = {};

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    closeConfirm: boolean = false;

    selectedTask: string;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private taskService: TaskService, private crewService: CrewService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.selectedWoId = curVal;
                this.getTaskbyWoId(this.selectedWoId);
                //console.log(this.selectedWoId);
            }

            if (propName === 'selectedASupervisor') {
                //this.onRowSelect(curVal);
                this.selectedASupervisor = curVal;
                //console.log(this.selectedASupervisor);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl('', Validators.required),
            'taskowner': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
        });

        this.getCrews();

        this.getEmployeesbyStatus();

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.taskName = [
            { label: 'Investigation', value: 'Investigation' },
            { label: 'Locates One-Call', value: 'Locates One-Call' },
            { label: 'Locates Private', value: 'Locates Private' },
            { label: 'Placement', value: 'Placement' },
            { label: 'Craft-Work', value: 'Craft-Work' },
            { label: 'Clean-up', value: 'Clean-up' },
            { label: 'Sent For Approval', value: 'Sent For Approval' },
            { label: 'Approval', value: 'Approval' },
            { label: 'Billing', value: 'Billing' },
            { label: 'All Field Work', value: 'All Field Work' },

        ];

        this.taskStatus = [
            { label: 'UnAssigned', value: 'UnAssigned' },
            { label: 'Open', value: 'Open' },
            { label: 'Inprocess', value: 'Inprocess' },
            { label: 'Incomplete', value: 'Incomplete' },
            { label: 'On Hold', value: 'On Hold' },
            { label: 'Resolved ', value: 'Resolved' },
            { label: 'Complete', value: 'Complete' },
            { label: 'Advanced', value: 'Advanaced' },

        ];

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'strTaskName', header: 'Task' },
            { field: 'strStatus', header: 'Task Status' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'supervisor', header: 'Supervisor' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];
    }

    onSelect(value) {
        //const value = event.target.value;
        this.dateReceived = value;
        //console.log(element.getAttribute('formControlName'));
        this.task.dtmStartDate = this._dateService.toString(this.dateReceived);
    }

    getTaskbyWoId(id): void {
        this.taskService.getTaskbyJobId(id)
            .subscribe(tasks => {
                this.tasks = tasks;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getCrews(): void {
        this.crewService.getCrewsNoLegacy()
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    addTask(task): void {
        if (!task) { return; }
        this.taskService.addTask(this.task)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    this.getTaskbyWoId(this.selectedWoId);
                    this.showSuccessViaToast();
                });
    }

    updateTask(id, task: Task): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        this.unitName = this.task.strTaskName;
        //console.log(this.billingunittype);
        //this.taskService.updateTask(this.intUnitId, this.task)
        this.taskService.updateTask(id, task)

            .subscribe(data => {
                //console.log(data);
                this.getTaskbyWoId(this.selectedWoId);
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newTask = true;
        this.wo = {};
        this.displayDialog = true;
        this.userform.controls['task'].enable();
        this.userform.controls['crew'].enable();
        this.userform.controls['foreman'].enable();
    }

    clone(c: Task): Task {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.task = {};
        this.userform.controls['task'].enable();
        this.userform.controls['crew'].enable();
        this.userform.controls['foreman'].enable();
        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intTaskId;

        this.newTask = false;
        this.task = this.clone(rowdata);

        //this.insertASupertoWoSuper(this.wo.intSupervisor);

        //console.log(this.selectedASupervisor);
        this.displayDialog = true;
        //this.userform.get('supervisor').setValue(this.task.supervisor);
        this.userform.get('crew').setValue(this.task.intCrewId);
        this.userform.get('foreman').setValue(this.task.intForeman);
        this.userform.get('task').setValue(this.task.strTaskName);
        this.userform.get('taskstatus').setValue(this.task.strStatus);
        this.userform.get('tasknote').setValue(this.task.strTaskDes);
        this.userform.get('taskowner').setValue(this.task.intTaskOwner);
        if (this.task.dtmStartDate !== null) {
            this.userform.get('datestart').setValue(new Date(this.task.dtmStartDate));
        }

        if (this.task.strStatus == 'In-Process') {
            this.userform.get('taskstatus').setValue('Inprocess');
        } else if (this.task.strStatus == 'InProcess') {
            this.userform.get('taskstatus').setValue(this.task.strStatus);
        }

        this.userform.controls['task'].disable();

        if (this.task.intCrewId != null) {
            this.userform.controls['crew'].disable();
        }

        if (this.task.intForeman != null) {
            this.userform.controls['foreman'].disable();
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;

        this.task.intCrewId = this.userform.get('crew').value;
        this.task.intForeman = this.userform.get('foreman').value;
        this.task.strTaskName = this.userform.get('task').value;
        this.task.strStatus = this.userform.get('taskstatus').value;
        this.task.strTaskDes = this.userform.get('tasknote').value;
        this.task.intTaskOwner = this.userform.get('taskowner').value;
        this.task.dtmStartDate = this.userform.get('datestart').value;

        if (this.newTask) {
            this.task.intJobId = this.selectedWoId;
            this.task.intSupervisorId = this.selectedASupervisor;
            this.addTask(this.task);
        } else {
            this.updateTask(this.task.intTaskId, this.task);
        }
        this.displayDialog = false;
        this.task = {};
        this.userform.controls['task'].enable();
        this.userform.controls['crew'].enable();
        this.userform.controls['foreman'].enable();
        this.userform.reset();
    }

    filterWoSupervisor(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intReportingSupervisorId == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    filterSupervisorId(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intSupervisorKey == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    onChangeTask(event) {
        this.selectedTask = event.value;
        this.checkDuplicateTaskforCrew();

        //let tasks = [...this.tasks];
        ////this.crewName = this.crew.strCrewId;
        //let existItem = tasks.filter(item => item.strTaskName === event.value && item.intCrewId == this.crewId);
        //console.log(event.value);
        //console.log(this.crewId);
        //if (existItem.length >= 1) {
        //    this.service.add({
        //        key: 'e',
        //        sticky: true,
        //        severity: 'warn',
        //        summary: this.crewNumber + ' already has ' + event.value + ' assigned to them.',
        //        detail: 'Click Ok to close?'
        //    });
        //}
    }

    onChangeCrew(event) {
        this.crewId = event.value;

        this.crew = this.crews.find(c => c.intCrewKey == this.crewId);

        this.foremanId = this.crew.intForeman;

        this.userform.get('foreman').setValue(this.foremanId);

        this.crewNumber = this.crew.strCrewId;

       
        let tasks = [...this.tasks];
        //this.crewName = this.crew.strCrewId;
        let existItem = tasks.filter(item => item.strStatus != 'Complete' && this.crew.intCrewKey == item.intCrewId);
        if (existItem.length >= 1) {
            //this.service.add(
            //    { key: 'tst', severity: 'warn', summary: this.crewNumber + ' has a task open that needs to be completed before adding a new task:', detail: this.task.strTaskName });
            this.service.add({
                key: 'd',
                sticky: true,
                severity: 'warn',
                summary: this.crewNumber + ' has open Task(s). They need to be Completed before adding a new Task:',
                detail: 'Mark All Open Task for ' + this.crewNumber + ' Completed to proceed?'
            });
        } else {
            //this.updateTaskNameBasedonCrew();
        }

        //this.updateTaskNameBasedonCrew();
    }

    onConfirm() {
        this.service.clear('c');
        this.checkDuplicateTaskforCrew();
        //if (this.taskDropdown === 'Craft-Work') {
        //    this.userform.get('task').setValue(this.taskDropdown);
        //    this.userform.get('taskstatus').setValue('Inprocess');
        //    this.service.clear();
        //} else if (this.taskDropdown === 'Placement') {
        //    this.userform.get('task').setValue(this.taskDropdown);
        //    this.userform.get('taskstatus').setValue('Inprocess');
        //    this.service.clear();
        //}
    }

    onConfirmTask() {
        this.service.clear('d');
        //this.service.add(
        //    { key: 'tst', severity: 'warn', summary: 'Task Complete', detail: this.task.strTaskName });
        this.closeConfirm = true;
        let tasks = [...this.tasks];
        //this.crewName = this.crew.strCrewId;
        let existItem = tasks.filter(item => item.strStatus != 'Complete' && this.crew.intCrewKey == item.intCrewId);
        tasks.forEach(t => {
            if (t.intCrewId == this.crew.intCrewKey && t.strStatus != 'Complete') {
                // //console.log(t.intTaskId);
                t.strStatus = 'Complete';
                //this.completedTask.intTaskId = t.intTaskId;
                // this.completedTask.intSupervisorId = this.selectedASupervisor;
                // this.completedTask.intJobId = this.task.intJobId;
                this.updateTask(t.intTaskId, t);
            }
        });
    }

    onReject() {
        this.service.clear('c');
        this.service.clear('d');
    }

    onRejectTask() {
        this.service.clear('d');
        this.displayDialog = false;
        this.submitted = false;
        this.userform.reset();
        this.task = {};
    }

    onRejectDuplicate() {
        this.service.clear('e');
        this.userform.get('task').setValue(null);

        //this.displayDialog = false;
        //this.submitted = false;
        //this.userform.reset();
        //this.task = {};
    }

    updateTaskNameBasedonCrew() {
        let tasks = [...this.tasks];
        //this.crewName = this.crew.strCrewId;
        let existItem = tasks.filter(item => item.strTaskName === this.selectedTask && item.intCrewId == this.crewId);
        if (existItem.length >= 1) {
            this.service.add({
                key: 'e',
                sticky: true,
                severity: 'warn',
                summary: this.crewNumber + ' already has ' + this.selectedTask + ' assigned to them.',
                detail: 'Click Ok to close?'
            });
        } else {
            if (this.crewNumber.startsWith("5")) {
                //this.userform.get('task').setValue('Craft-Work');
                if (this.task.strTaskName !== 'Craft-Work') {
                    this.taskDropdown = 'Craft-Work';
                    this.service.clear();
                    this.service.add({
                        key: 'c',
                        sticky: true,
                        severity: 'warn',
                        summary: 'Change Task to Craft-Work for 50xx crew type?',
                        detail: 'Confirm to proceed'
                    });
                }
            } else if (this.crewNumber.startsWith("7")) {
                this.taskDropdown = 'Placement';
                this.service.clear();
                this.service.add({
                    key: 'c',
                    sticky: true,
                    severity: 'warn',
                    summary: 'Change Task to Placement for 70xx crew type?',
                    detail: 'Confirm to proceed'
                });
            }
        }
    }

    checkDuplicateTaskforCrew() {
        let tasks = [...this.tasks];
        //this.crewName = this.crew.strCrewId;
        let existItem = tasks.filter(item => item.strTaskName === this.selectedTask && item.intCrewId == this.crewId);
        //console.log(event.value);
        //console.log(this.crewId);
        if (existItem.length >= 1) {
            this.service.add({
                key: 'e',
                sticky: true,
                severity: 'warn',
                summary: this.crewNumber + ' already has ' + this.selectedTask + ' assigned to them.',
                detail: 'Click Ok to close.'
            });
        } else {
            if (this.taskDropdown === 'Craft-Work') {
                this.userform.get('task').setValue(this.taskDropdown);
                this.userform.get('taskstatus').setValue('Inprocess');
                this.service.clear();
            } else if (this.taskDropdown === 'Placement') {
                this.userform.get('task').setValue(this.taskDropdown);
                this.userform.get('taskstatus').setValue('Inprocess');
                this.service.clear();
            }
        }
    }
}
