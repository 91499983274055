import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Dropdown } from 'primeng/primeng';
import { DynamicControl } from '../domain/dynamiccontrol';
import { DynamicControlsService } from '../service/dynamiccontrolservice';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DateService } from '../../_services/date.service';
import { JsonParsePipe } from '../../_pipes/jsonparse.pipe';
@Component({
    selector: 'dynamic-form',
    templateUrl: 'dynamic-form.component.html',
    styles: [
        `
    .error { color: red; }
    `
    ],
    providers: [JsonParsePipe]
})
export class DynamicFormComponent implements OnInit {
    @Input() dataObject;
    @Input() contractId;
    @Input() woId;
    @Input() tabIndex;
    @Input() newWoInserted;
    @Output() controlChanged: EventEmitter<number> = new EventEmitter();
    @Output() canDisplay: EventEmitter<boolean> = new EventEmitter();
    form: FormGroup;
    objectProps;
    neededArray;
    obj: object = null;
    myArr = [];
    newValues = [];

    addSelectValue: boolean = false;
    filterData;
    dynamiccontrol: DynamicControl = {};
    dynamiccontrols: DynamicControl[] = [];
    productForm: FormGroup;
    displayDialog: boolean = false;
    displayAddDialog: boolean = false;
    formItems: FormArray;
    controltypes: any = [];
    canRender: boolean = false;
    @Input() canAddControl: boolean = false;
    @Input() canEditControl: boolean = false;
    @Input() displayButtons: boolean = true;
    @Input() employeeId;

    constructor(private dynamicControlService: DynamicControlsService, private service: MessageService, public snackbar: MatSnackBar, private router: Router, private _dateService: DateService, private fb: FormBuilder, private jsonparse: JsonParsePipe, private route: ActivatedRoute) {

        this.productForm = this.fb.group({
            name: '',
            quantities: this.fb.array([]),
        });

        this.controltypes = [
            { label: 'select', value: 'select' },
            { label: 'calendar', value: 'calendar' },
            { label: 'text', value: 'text' },
            { label: 'radio', value: 'radio' },
            { label: 'textarea', value: 'textarea' },
            //{ label: 'checkbox', value: 'checkbox' }
        ];


    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);
            //console.log('reloaded');
            //console.log(propName);
            //console.log(change.currentValue);



            if (propName === 'dataObject') {
                if (typeof Object.keys(this.dataObject) !== 'undefined' && Object.keys(this.dataObject).length > 0) {
                    this.dataObject = [];
                    //this.onRowSelect(curVal);
                    this.dataObject = JSON.parse(curVal);
                   
                    //sort Objects so they show up in same order everytime
                    //this.dataObject = this.dataObject.sort((a, b) => a.controlName.localeCompare(b.controlName));
                    //check for Nulls in Old Control Names
                    this.dataObject = this.dataObject.sort((a, b) => a.controName !== null ? a : a.controlName.localeCompare(b.controlName !== null ? b : b.controlName));
                    //this.dataObject=this.dataObject.sort((a, b) => ((a || {}).controlName || "").localeCompare((b || {}).controlName || ""));
                    //If the value is string

                    //data.sort((a, b) => a.status.localeCompare(b.status));
                    //If the value is int

                    //data.sort((a, b) => a.status === b.status ? -1 : 0);
                    //console.log(this.dataObject);
                    //this.getProductionbyWoId(this.selectedWoId);
                    //console.log(((Object.keys(change.currentValue)["options"])));

                    //this.dataObject.forEach(function iter(a) {
                    //    if (Array.isArray(a.options)) {
                    //        a.options.forEach(iter);
                    //        return;
                    //    }
                    //    console.log(a.value);
                    //});

                    this.createForm();
                    
                    //if (curVal != prevVal) {
                    //    this.createForm();
                    //    //console.log(((Object(this.dataObject)["options"])));
                    //}
                }

            }

            if (propName === 'newWoInserted') {
                //console.log(curVal);
                if (curVal === 'true') {
                    this.woInsertedHandler(true);
                } else if (curVal==='false') {
                    this.woUpdateHandler();
                }
            }


        }

        //this.createForm();

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {

        this.form = new FormGroup({});

        //if (this.newWoInserted) {
        //    console.log('Hello');
        //    //this.uploadSuccess.subscribe(data => {
        //    //    // Do something in the childComponent after parent emits the event.
        //    //});
        //}

      
        //console.log(this.dataObject);
        //if (typeof Object.keys(this.dataObject) !== 'undefined' && Object.keys(this.dataObject).length > 0) {
        //    // You have an array
        //    console.log('Has Keys');
        //    // remap the API to be suitable for iterating over it
        //    this.objectProps =
        //        Object.keys(this.dataObject)
        //            .map(prop => {
        //                return Object.assign({}, { key: prop }, this.dataObject[prop]);
        //            });
        //    //console.log(this.objectProps);
        //    // setup the form
        //    const formGroup = {};
        //    for (let prop of Object.keys(this.dataObject)) {
        //        formGroup[prop] = new FormControl(this.dataObject[prop].strValue || '', this.mapValidators(this.dataObject[prop].validation));

        //    }

        //    this.form = new FormGroup(formGroup);
        //} else {
        //    console.log('no Keys');
        //}



    }


    reload() {
        //this.canDisplay.emit(false);
        this.controlChanged.emit(this.tabIndex);
        //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //this.router.onSameUrlNavigation = 'reload';
        //this.router.navigate(['./'], { relativeTo: this.route });
        //this.router.navigate(['./'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        //this.countChanged.emit(4)

        //this.router.navigate(['./'], { skipLocationChange: true }).then(() => {
        //    this.router.navigate(['DynamicFormComponent']);
        //});
    }

    woInsertedHandler(event) {
        //console.log(event);
        if (this.woId) {
            Object.keys(this.form.controls).forEach(key => {
                //console.log(key);
                //console.log(this.dataObject);

                let newValue = this.form.get(key).value;

                // Retrieve item and assign ref to updatedItem
                //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
                //console.log(updatedItem);
                // Modify object property
                //updatedItem.aProp = ds.aProp

                const allowed = [key];



                const filtered = Object.keys(this.dataObject)
                    .filter(key => allowed.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.dataObject[key];
                        //console.log(obj[key]);
                        //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
                        //console.log(this.cloneControl(obj));
                        this.dynamiccontrol = this.cloneControl(obj[key]);
                        this.dynamiccontrol.intJobId = this.woId;
                        delete this.dynamiccontrol.intControlId;
                        if (this.dynamiccontrol.strValue != newValue) {
                            this.dynamiccontrol.strValue = newValue;
                        }
                        if (this.dynamiccontrol.intJobId != 0) {
                            this.addWoControls(this.dynamiccontrol);
                        }
                        //let updatedItem = this.dataObject.find((element) => { return element.id === obj.id })
                        //console.log(updatedItem);
                        //console.log(this.dynamiccontrol);
                        //this.dynamiccontrols.push(obj);
                        //filteredControls.push(obj);
                        //if (this.dynamiccontrol.strValue != newValue) {
                        //    this.dynamiccontrol.strValue = newValue;
                        //    //console.log(this.dynamiccontrol);
                        //    this.updateControl('control');
                        //}
                        return obj;
                    }, {});


            });

            this.showSuccessViaToast('Custom Controls Added', 'Added');
        

        }
    }

    woUpdateHandler() {
        //console.log('Do Something Update');
        //console.log('Submit')
        //console.log(form.value);
        const filteredControls = [];
        Object.keys(this.form.controls).forEach(key => {
            //console.log(key);
            //console.log(this.dataObject);

            let newValue = this.form.get(key).value;

            // Retrieve item and assign ref to updatedItem
            //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
            //console.log(updatedItem);
            // Modify object property
            //updatedItem.aProp = ds.aProp

            const allowed = [key];



            const filtered = Object.keys(this.dataObject)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.dataObject[key];
                    //console.log(obj[key]);
                    //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
                    //console.log(this.cloneControl(obj));
                    this.dynamiccontrol = this.cloneControl(obj[key]);
                    //let updatedItem = this.dataObject.find((element) => { return element.id === obj.id })
                    //console.log(updatedItem);
                    //console.log(this.dynamiccontrol);
                    //this.dynamiccontrols.push(obj);
                    //filteredControls.push(obj);
                    if (this.dynamiccontrol.strValue != newValue && this.dynamiccontrol.intJobId !=0) {
                        this.dynamiccontrol.strValue = newValue;
                        //console.log(this.dynamiccontrol);
                        this.updateControl('control');
                    }
                    return obj;
                }, {});


        });

        this.reload();
    }
    

    convertToJSON(product: any) {
        //console.log(product);
        if (this.canRender) {
             //console.log(product);
            return JSON.parse((product));
        }

    }

    convertToDate(date) {
        if (date != null || date == '1900-01-01T05:00:00') {
            var jsonData =  new Date(date) ;
            //console.log(JSON.stringify(jsonData));
            return (JSON.stringify(jsonData));
        }
    }

    setSelectedPartner(dropdown: Dropdown, label: any, val: any) {
        if (val != '')
            dropdown.updateSelectedOption(val);
    }

    onChange(event) {
        //console.log('event :' + event);
        //let newGame: Game = { name: "NEW GAME" };

        //this.games.push(newGame);

        //acc.options = this.games;
        //console.log(event);
    }

    onTypeChange(event) {
        //console.log(event.value);
        //let controlType = event.value;
        //if (controlType = 'text') {
        //    this.dynamiccontrol.controlName = 'txt' + this.dynamiccontrol.label;
        //    console.log(this.dynamiccontrol.controlName = 'txt' + this.dynamiccontrol.label);
        //}

    }

    displayOptions(event) {
        this.addSelectValue = true;
        this.quantities().clear();
        this.productForm.reset();
        //this.addTestQuantity();
        this.displayDialog = true;
        //console.log(event);
        let formId = event
        //Load Controls Values        
            const allowed = [formId];
            const filtered = Object.keys(this.dataObject)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.dataObject[key];
                    
                                       
                    let controlValues = this.cloneControl(obj[key]);
                    this.dynamiccontrol = this.cloneControl(obj[key]);
                    //console.log(this.dynamiccontrol);
                    this.formItems = (Object(controlValues)["options"]);
                    this.myArr = [];
                    this.myArr = JSON.parse((Object(controlValues)["options"]));
                    let controlName = Object(controlValues)["label"];
                    //console.log(Object(controlValues)["label"]);
                    this.productForm.controls['name'].setValue(controlName);
                   
                    if (this.myArr && this.myArr.length) {
                        this.myArr.forEach((element, index, array) => {
                            this.addTestQuantity(element.label, element.value);
                            //console.log(element.label); // 100, 200, 300
                            //console.log(element.value); // 100, 200, 300
                            //console.log(index); // 0, 1, 2
                            //console.log(array); // same myArray object 3 times
                        });
                    }

                   
                    return obj;
                }, {});       

    }

    addInputBoxValue(event) {
        console.log(event.value);
        //this.partners.push({ label: record.description, value: record.organizationId });


    }

    ChangePaxType(event, dd: Dropdown) {
        console.log(dd.selectedOption.label) // this is your selected item label
    }



    private createForm() {
        //this.form = new FormGroup({});
        //console.log('Form Created')
        if (typeof Object.keys(this.dataObject) !== 'undefined' && Object.keys(this.dataObject).length > 0) {
            // You have an array
            //console.log('Has Keys');
            //console.log(this.dataObject);
           
            // remap the API to be suitable for iterating over it
             //console.log('Remap API')
            this.objectProps =
                (Object.keys(this.dataObject))
                .map(prop => {
                    //console.log(Object.assign({}, { key: this.dataObject[prop].label }, this.dataObject[prop]));
                        return Object.assign({}, { key: prop }, this.dataObject[prop]);
                    //return Object.assign({}, { key: this.dataObject[prop].label }, this.dataObject[prop]);

                       
                });
             //console.log('Remap API Done')
            //console.log(this.objectProps);

            // setup the form
            const formGroup = {};
            for (let prop of (Object.keys(this.dataObject))) {
                formGroup[prop] = new FormControl(this.dataObject[prop].strValue || '', this.mapValidators(this.dataObject[prop].validation));
                
            }          
            //console.log(formGroup);
            this.form = new FormGroup(formGroup);
            this.canRender = true;
            this.canDisplay.emit(true);
            //console.log(formGroup);
        } else {
            //console.log('no Keys');
            //this.canDisplay.emit(false);
        }


    }


    private mapValidators(validators) {
        const formValidators = [];

        if (validators) {
            for (const validation of Object.keys(validators)) {
                if (validation === 'required') {
                    formValidators.push(Validators.required);
                } else if (validation === 'min') {
                    formValidators.push(Validators.min(validators[validation]));
                }
            }
        }

        return formValidators;
    }



    onSubmit(form) {
        //console.log('Submit')
        //console.log(form.value);
        const filteredControls = [];
        Object.keys(this.form.controls).forEach(key => {
            //console.log(key);
            //console.log(this.dataObject);
           
            let newValue = this.form.get(key).value;

            // Retrieve item and assign ref to updatedItem
            //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
            //console.log(updatedItem);
            // Modify object property
            //updatedItem.aProp = ds.aProp

            const allowed = [key];

            

            const filtered = Object.keys(this.dataObject)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.dataObject[key];
                    //console.log(obj[key]);
                    //let updatedItem = this.dataObject.find((element) => { return element.id === this.dataObject.id })
                    //console.log(this.cloneControl(obj));
                    this.dynamiccontrol = this.cloneControl(obj[key]);
                    //let updatedItem = this.dataObject.find((element) => { return element.id === obj.id })
                    //console.log(updatedItem);
                    //console.log(this.dynamiccontrol);
                    //this.dynamiccontrols.push(obj);
                    //filteredControls.push(obj);
                    if (this.dynamiccontrol.strValue != newValue) {
                    this.dynamiccontrol.strValue = newValue;
                    //console.log(this.dynamiccontrol);
                        this.updateControl('control');
                        }
                    return obj;
                }, {});
           
            
        });

        //let flat = [].concat.apply([], filteredControls);
        //console.log(filtered);
        //console.log(flat);
        //flat.forEach(element => console.log(this.cloneControl(element)));
        //let updatedItem = items.find((element) => { return element.id === item.id })
        //Object.keys(flat).forEach(val => {
        //    let key = val;
        //    let value = flat[val];
        //    console.log(`${key} : ${value}`);
        //});
    }

    cloneControl(c: DynamicControl): DynamicControl {
        let control = {};
        for (let prop in c) {
            control[prop] = c[prop];
        }
        return control;
    }

    updateControl(updateform): void {
        const epochNow = (new Date).getTime();
        //let crews = [...this.crews];
        //crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.dynamiccontrol.mDateUpdated = epochNow;
        this.dynamiccontrol.editedBy = this.employeeId.toString();
        this.dynamiccontrol.infratracVersion = "12.0"
        let fieldUpdated = this.dynamiccontrol.label;
        let valueUpdated = this.dynamiccontrol.strValue;
        
        this.dynamicControlService.updateControl(this.dynamiccontrol.intControlId, this.dynamiccontrol)

            .subscribe(data => {
                if (updateform === 'control') {
                    this.showSuccessViaToast(fieldUpdated + ' changed to ' + valueUpdated, 'Field Updated');
                } else {
                    this.showSuccessViaToast('Control Values', 'Updated');
                }


                //this.reload();


                //console.log(data);
                //this.ngOnInit();
                //this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    quantities(): FormArray {
        return this.productForm.get("quantities") as FormArray
    }

    getControlName(): FormArray {
        return this.productForm.get("name") as FormArray
    }

    newQuantity(): FormGroup {
        return this.fb.group({
            label: '',
            value: '',
        })
    }

    testQuantity(label, value): FormGroup {
        return this.fb.group({
            label:label,
            value: value,
        })
    }

    loadOptions(array): FormGroup {
        console.log(array)
        return this.fb.group({
            array

        })
    }

    addTestQuantity(label, value) {
        this.quantities().push(this.testQuantity(label, value));
    }

    addQuantity() {
        this.quantities().push(this.newQuantity());
    }

    removeQuantity(value, i: number) {
        //console.log(value);
        this.showValueViaToast('Removed: Click Save to Change', value);
        //var arrayControl = this.quantities().get('value') as FormArray;
        //(<FormArray>this.quantities().get('value')).at(i).get('value')
        //console.log((<FormArray>this.quantities().get('value')).at(i).get('label'));
        //var item = arrayControl.at(i);
        //console.log(item);
        this.quantities().removeAt(i);
        
    }

   


    onDialogSubmit() {
        this.displayDialog = false;
        //console.log(this.productForm.value);
        //console.log(JSON.stringify(this.productForm.value));
        //console.log(this.quantities());
        //console.log(Object(this.getControlName())["name"]);
        //console.log(JSON.stringify(Object(this.quantities())["value"]));
        this.dynamiccontrol.options = JSON.stringify(Object(this.quantities())["value"]);
        this.dynamiccontrol.label = this.productForm.get('name').value;
        this.updateControl('values');
        //this.reload();
        //this.ngOnInit();
        //(Object(controlValues)["options"]);
    }

    dialogCancel() {
        this.displayDialog = false;
        this.quantities().clear();
        this.productForm.reset();

    }

    showSuccessViaToast(message, summary) {
        this.service.add({ key: 'tst', severity: 'success', summary: summary, detail: message  });
    }

    showValueViaToast(message, summary) {
        this.service.add({ key: 'tst', severity: 'success', summary: summary, detail: message });
    }

    showDialogToAdd() {
        this.dynamiccontrol = {};
        this.displayAddDialog = true;

    }

    addCancel() {
        this.displayAddDialog = false;
        this.dynamiccontrol = {};
    }

    onAddSave() {
        const epochNow = (new Date).getTime();
        //console.log(epochNow);
        this.dynamiccontrol.intContractId = this.contractId;
        this.dynamiccontrol.intJobId = 0;
        this.dynamiccontrol.infratracVersion = "12.0";
        this.dynamiccontrol.createdBy = this.employeeId.toString();
        //this.dynamiccontrol.mDateCreated = epochNow;
        this.dynamiccontrol.mDateUpdated = epochNow;
        this.dynamiccontrol.controlName = (this.dynamiccontrol.type + this.dynamiccontrol.label.replace(/\s/g, ""));
        //if (this.dynamiccontrol.type === 'textarea') {
        //    //add z so it shows up at the bottom of the form for spacing. 
        //    this.dynamiccontrol.controlName = ('z'+ this.dynamiccontrol.type + this.dynamiccontrol.label.replace(/\s/g, ""));

        //} else {

        //    this.dynamiccontrol.controlName = (this.dynamiccontrol.type + this.dynamiccontrol.label.replace(/\s/g, ""));
        //}
        //console.log(this.dynamiccontrol);
        //console.log(this.cloneControl(this.dynamiccontrol));
       
        this.addControl();
        this.displayAddDialog = false;
        

    }

    addControl(): void {
        
        this.dynamicControlService.addDynamicControl(this.dynamiccontrol)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    this.showSuccessViaToast('Control Added', 'Added');
                    this.reload();
                });
    }

    addWoControls(control): void {

        this.dynamicControlService.addDynamicControl(control)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    //this.showSuccessViaToast('Control Added', 'Added');
                    //this.reload();
                });
        //this.showSuccessViaToast('Custom Controls Added', 'Added');

    }

    //findDuplicates() {
    //    const newArr: AttachmentForm[] = [];
    //    this.attachmentForms.forEach((item) => {
    //        if (this.attachmentForms.filter(i => i.name === item.name).length > 1) {
    //            item.isDuplicate = true;
    //        } else {
    //            item.isDuplicate = false;
    //        }
    //        newArr.push(item);
    //    });
    //    this.attachmentForms = newArr;
    //}
   
}
