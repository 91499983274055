import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer, EventEmitter, Output } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { PayRollHour } from '../domain/payrollhour';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { DatePipe, formatDate } from '@angular/common';
@Component({
    selector: 'dfrpayroll-table',
    templateUrl: './dfrpayrollhour.component.html',

    providers: [MessageService]
})
export class DfrPayRollHoursTableComponent implements OnInit {
    payrollHours: PayRollHour[];

    payrollHour: PayRollHour = {};

    selectedPayroll: PayRollHour;

    employees: Employee[];

    payrollcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() insertedDfrId: string;

    @Input() selectedWoId: string;

    @Input() selectedDfrDate: string;

    @Input() selectedCrewNumber: string;

    @Input() updatePayrollDates: boolean;

    yearFilter: number;

    yearTimeout: any;

    showDelete: boolean = false;

    newPayRoll: boolean = false;

    displayDialog: boolean = false;

    perdiem: boolean = false;

    crewNumber: string;

    dfrDate: string;

    payrollId; string;

    @ViewChild('hours', { static: false }) hours: ElementRef;

    pipe = new DatePipe('en-US');

    deletedHours: number;

    deletedName: string;

    crewHours: number = 0;

    vacHours: number = 0;

    ptoHours: number = 0;

    holHours: number = 0;

    dfrId: string;

    @Output() dfrCrewHours = new EventEmitter<number>();

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private payRollHourService: PayRollHourService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private employeeService: EmployeeService,
        private renderer: Renderer) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(propName + curVal);
            //console.log(prevVal);

            if (propName === 'insertedDfrId') {
                this.insertedDfrId = curVal;
                if (this.insertedDfrId)
                    this.dfrId = JSON.parse(this.insertedDfrId);
                this.insertedDfrId = this.dfrId
                //console.log(this.selectedContractId);
                if (this.insertedDfrId) {
                    this.getEmployeeHoursbyDFR(this.insertedDfrId);
                    //this.addHours();
                }
            }
            if (propName === 'selectedDfrDate') {
                this.selectedDfrDate = curVal;
                //this.onRowSelect(curVal);
                this.selectedDfrDate = curVal;
                this.dfrDate = this.selectedDfrDate;
                if (this.updatePayrollDates) {
                    this.updateDate(this.selectedDfrDate);
                }
                //this.updateDate(this.selectedDfrDate);
                //console.log(this.updatePayrollDates);
                ////this.dfrDate = this._dateService.toString(JSON.parse(this.dfrDate));
                //this.selectedDfrId = '99108';
                //this.getEmployeeHoursbyDFR(this.selectedDfrId);
            }
            if (propName === 'selectedCrewNumber') {
                //this.onRowSelect(curVal);
                this.crewNumber = this.selectedCrewNumber;
                //this.selectedDfrId = '99108';
                //this.getEmployeeHoursbyDFR(this.selectedDfrId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.payrollcols = [
            { field: 'employeeName', header: 'Employee', width: '15%' },
            //{ field: 'dtDate', header: 'Date', width: '9%' },
            //{ field: 'intReportId', header: 'Dfr', width: '10%' },
            //{ field: 'intCrew', header: 'Crew', width: '9%' },
            { field: 'intHoursWorked', header: 'Reg', width: '7%' },
            { field: 'intHoursVac', header: 'Vac', width: '7%' },
            { field: 'intHoursPto', header: 'Pto', width: '7%' },
            { field: 'intHoursHol', header: 'Hol', width: '7%' },
            { field: 'ysnPerDiem', header: 'PD', width: '7%' },
            { field: 'strComments', header: 'Comments', width: '40%' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];

        this.getEmployeesbyStatus();
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getEmployeeHours(id): void {
        this.payRollHourService.getEmployeePayRollHours(id)
            .subscribe(employeehours => this.payrollHours = employeehours);
    }

    getEmployeeHoursbyDFR(id): void {
        this.payRollHourService.getEmployeePayRollHoursbyDfr(id)
            .subscribe(employeehours => {
                this.payrollHours = employeehours
                this.addHours(false);
            });
       
    }

    addPayrollHour(payroll, dfrid): void {
        if (!payroll) { return; }
        this.payRollHourService.addPayrollHour(payroll)
            .subscribe(
                data => {
                    //console.log(data);
                    let message = data.intHoursWorked + ': Hours ';
                    this.getEmployeeHoursbyDFR(dfrid);
                    this.showSuccessViaToast(message);
                });
    }

    updatePayrollHour(payrollid, payroll, dfrid, showtoast:boolean): void {
        if (!payroll) { return; }
        this.payRollHourService.updatePayrollHour(payrollid, payroll)
            .subscribe(
                data => {
                    this.getEmployeeHoursbyDFR(dfrid);
                    if (showtoast) {
                        this.showSuccessUpdateViaToast();
                    }
                });
    }

    deletePayroll(id): void {
        this.payRollHourService.deletePayroll(id)
            .subscribe();
        this.showdeletedPayroll(this.deletedHours, this.deletedName);
    }

    showSuccessViaToast(message) {
        this.service.add({ severity: 'success', summary: 'Hours Added', detail: message });
    }

    showSuccessUpdateViaToast() {
        
        this.service.add({ severity: 'success', summary: 'Hours Updated', detail: "Success: Total Crew Hours " + this.crewHours });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    onRowSelect(event) {
        this.showDelete = true;
        this.newPayRoll = false;
        this.payrollHour = this.clonePayroll(event.data);
        this.payrollId = this.payrollHour.intWorkId;

        this.deletedHours = this.payrollHour.intHoursWorked;
        //this.deletedName = this.payrollHour.employeeName;
        if (this.payrollHour.ysnPerDiem == -1) {
            this.perdiem = true
        } else {
            this.perdiem = false
        }
        this.displayDialog = true;
        //this.billableProduction = this.cloneProduction(event.data);
        //this.intProductionId = this.billableProduction.intProductionId
        //this.deletedBc = this.billableProduction.strCustomerBillingCode;
        //this.deletedBcDescription = this.billableProduction.strBcdescription;
        //this.deletedQty = this.billableProduction.intQty;
    }

    clonePayroll(p: PayRollHour): PayRollHour {
        let payroll = {};
        for (let prop in p) {
            payroll[prop] = p[prop];
        }
        return payroll;
    }

    showDialogToAdd() {
        this.newPayRoll = true;
        this.perdiem = false;
        this.payrollHour = {};
        this.payrollHour.intHoursHol = 0;
        this.payrollHour.intHoursNpd = 0;
        this.payrollHour.intHoursPto = 0;
        this.payrollHour.intHoursVac = 0;
        this.payrollHour.intHoursWorked = 0;

        this.showDelete = false;
        this.displayDialog = true;
    }

    onChange(event) {
        if (event) {
            this.payrollHour.ysnPerDiem = -1
        } else {
            this.payrollHour.ysnPerDiem = 0
        }
    }

    save() {
        const epochNow = (new Date).getTime();
        if (this.payrollHour.strEmployee) {
            //console.log(this.selectedCrewNumber);
            this.payrollHour.intCrew = this.selectedCrewNumber;
            this.payrollHour.intReportId = this.insertedDfrId;
            this.payrollHour.dtDate = JSON.parse(this.selectedDfrDate);
            //this.crewHours += this.payrollHour.intHoursWorked;
            //this.vacHours += this.payrollHour.intHoursVac;
            //this.ptoHours += this.payrollHour.intHoursPto;
            //this.holHours += this.payrollHour.intHoursHol;
            let payrollhours = [...this.payrollHours];
            //console.log(this.billableProduction);
            if (this.newPayRoll) {
                //console.log(this.payrollHour);
                payrollhours.push(this.payrollHour);
                this.addPayrollHour(this.payrollHour, this.insertedDfrId);
            }
            else {
                payrollhours[this.payrollHours.indexOf(this.selectedPayroll)] = this.payrollHour;
                this.payrollHour.mDateUpdated = epochNow;
                this.updatePayrollHour(this.payrollId, this.payrollHour, this.insertedDfrId, true);
            }
            this.payrollHours = payrollhours;
            this.payrollHour = {};
            this.perdiem = false;
            this.displayDialog = false;
            this.addHours(true);
        } else {
            this.showdNeedEmployee();
        }
    }

    showdNeedEmployee() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Need Employee', detail: 'Select Employee' });
    }

    onChangeEmployee(event) {
        //console.log(event.value);
        //console.log(this.payrollHour.strEmployee);
        this.payrollHour.intHoursWorked = "";
        setTimeout(() => this.hours.nativeElement.focus());
    }

    delete() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Payroll Hours?',
            detail: 'Are you Sure'
        });
    }

    onConfirm() {
        this.service.clear('d');
        //this.crewHours = this.payrollHour.intHoursWorked;
        //this.crewHours -= this.deletedHours;
        //this.vacHours -= this.payrollHour.intHoursVac;
        //this.ptoHours -= this.payrollHour.intHoursPto;
        //this.holHours -= this.payrollHour.intHoursHol;
        let payrollhours = [...this.payrollHours];
        let index = this.payrollHours.indexOf(this.selectedPayroll);
        //console.log(this.payrollId);
        this.deletePayroll(this.payrollId);
        this.payrollHours = this.payrollHours.filter((val, i) => i != index);
        this.addHours(true);
        this.payrollHour = {};
        this.perdiem = false;
        this.displayDialog = false;
    }

    onReject() {
        this.service.clear('d');
    }

    showdeletedPayroll(hours, name) {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Payroll Hours Deleted', detail: 'Deleted Hours: ' + hours });
    }

    addHours(updateDfr: boolean) {
        let myArray = this.payrollHours
        this.crewHours = 0;
        this.vacHours = 0;
        this.ptoHours = 0;
        this.holHours = 0;
        for (let i = 0; i < myArray.length; i++) {
            this.crewHours += myArray[i].intHoursWorked;
            this.vacHours += myArray[i].intHoursVac;
            this.ptoHours += myArray[i].intHoursPto;
            this.holHours += myArray[i].intHoursHol;
            //console.log(myArray[i].intHoursWorked);
        }
        if (updateDfr)
        this.dfrCrewHours.emit(this.crewHours);
    }

    updateDate(changeddate) {
        const format = 'MM/dd/yyyy';
        const myDate = '2019-06-29';
        const locale = 'en-US';
        if (changeddate) {
            let date = JSON.parse(changeddate)
            if (Array.isArray(this.payrollHours) && (this.payrollHours.length)) {
                let payrollhours = [...this.payrollHours];
                

                for (let i = 0; i < payrollhours.length; i++) {
                    if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                    //    console.log(formatDate(payrollhours[i].dtDate, format, locale));
                    //console.log(formatDate(date, format, locale));

                    payrollhours[i].dtDate = date;                    
                  
                    this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);
                   
                }
            }
        }
    
    }
}
