import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { FileUploadModule } from 'primeng/fileupload';
import { BlobStorageService } from '../service/blobuploaderservice';
import { JobService } from '../service/jobservice';
import { Job } from '../domain/job'
import { BlobStorage } from '../domain/blobstorage'

declare var require: any;

@Component({
    selector: 'blob-uploader',
    templateUrl: './blob.component.html',
    styleUrls: ['./blob.component.css']
})
export class BlobComponent implements OnInit {
    constructor(private http: HttpClient, private blobstorageService: BlobStorageService, private jobService: JobService) { }

    files: string[] = [];

    blobFiles: BlobStorage[] = [];

    blobFile: BlobStorage = {};

    fileToUpload: FormData;

    fileUpload: any;

    fileUpoadInitiated: boolean;

    fileDownloadInitiated: boolean;

    cols: any[];

    wo: Job = {};

    wos: Job[] = [];

    @Input() selectedWoId: string = "";

    filenames: Job[];

    intJobId: string;

    private baseUrl = 'http://localhost:64983/api/blobstorage';

    fileIndex: number;

    fileName: string;

    selectedDocument: any[];

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                this.files = [];
                this.selectedWoId = curVal;
                this.intJobId = JSON.parse(this.selectedWoId);
                //console.log(this.intJobId);
                if (this.intJobId) {
                    this.getWOImages(this.intJobId);
                }
            }
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit(): void {
        //this.showBlobs();

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'intReportNo', header: 'DFR #' },
            { field: 'dtmWorkDate', header: 'DFR Date' },
            { field: 'strJob', header: 'WO#' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'taskname', header: 'Task' },
            { field: 'selUtilityHit', header: 'Utility Hit' },
            { field: 'selPersonalInjury', header: 'Personal Injury' },
            { field: 'selVehicleAccident', header: 'Vehicle Accident' },
            { field: 'ysnApproved', header: 'Approved' },
            { field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];
    }

    updateWo(): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        //this.unitName = this.wo.strJob;
        //console.log(this.billingunittype);
        this.jobService.updateJob(this.selectedWoId, this.wo)

            .subscribe(data => {
                this.getWOImages(this.intJobId);
                //this.showSuccessUpdateViaToast();
                //this.filterWoString(this.strJob);
                //this.ngOnInit();
                //this.handleError("err:Test");
            });
        //this.filterWoString(this.selectedWo);
    }

    showBlobs(): void {
        this.blobstorageService.showBlobs()
            .subscribe(result => {
                this.files = result;
            }, error => console.error(error));
    }

    insertBlob(file): void {
        console.log(file)
        this.blobstorageService.onUploadFiles(file)
            .subscribe((response: any) => {
                this.fileUpoadInitiated = false;
                this.fileUpload = '';
                if (response == true) {
                    //this.showBlobs();
                    this.wo.strImagePath = this.wo.strImagePath + "*" + this.fileName;
                    this.updateWo();
                    this.blobFiles = [];
                    this.wo = {};
                }
                else {
                    alert('Error occured!');
                    this.fileUpoadInitiated = false;
                }
            },
                err => console.log(err),
            );
    }

    getWOImages(woId): void {
        this.blobFiles = [];

        if (woId) {
            this.jobService.getbyWoId(woId)
                .subscribe(wos => {
                    this.wos = wos;
                    //console.log(wos)

                    if (this.wos.length > 0) {
                        this.wo = wos.find(w => w.intJobId == woId);
                        //If WO has files load them.
                        if (this.wo.strImagePath !== null) {
                            this.filenames = this.wo.strImagePath.split('*');
                            //console.log(this.wo.strImagePath);
                            this.filenames.forEach((file, index) => {
                                if (index === (this.filenames.length - 1)) {
                                    //console.log("Last callback call at index " + file + " with value " + index);
                                }
                                if (file.toString().split('_')[1]) {
                                    //console.log(file.toString().split('_')[1]);
                                    this.files.push(file.toString());
                                    //console.log(file.toString().split('_')[0]);
                                    this.blobFiles.push({
                                        index: index,
                                        id: file.toString().split('_')[0],
                                        fileName: file.toString().split('_')[1],
                                        filePath: file.toString(),
                                    });
                                    //console.log(this.blobFiles);
                                    const maxValueOfY = Math.max(...this.blobFiles.map(o => o.id), 0);
                                    this.fileIndex = maxValueOfY + 1;
                                } else {
                                    this.fileIndex = 0;
                                }
                            })
                        }
                    } else {
                        //alert('length 0');
                    }
                });
        }
    }

    addFile() {
        if (!this.fileUpoadInitiated) {
            document.getElementById('fileUpload').click();
        }
    }
    handleFileInput(files: any) {
        let formData: FormData = new FormData();
        let newFileName = ""
        var validFilename = /^[a-z0-9.@()-\s]+\.[^.]+$/i.test(files[0].name);
        //console.log(files[0].name);
        if (validFilename) {
            if (this.fileIndex !== 0) {
                newFileName = this.fileIndex + '_' + files[0].name;
            } else {
                newFileName = this.intJobId + '500001_' + files[0].name;
            }

            //var validFilename = /^[a-z0-9.@()-]+\.[^.]+$/i.test(newFileName);
            //console.log(validFilename);
            this.fileName = newFileName;
            formData.append("asset", files[0], newFileName);
            this.fileToUpload = formData;
            //console.log(formData);
            this.onUploadFiles();
        } else {
            alert('Invalid File Name');
        }
    }

    onUploadFiles() {
        if (this.fileUpoadInitiated) {
            return;
        }
        this.fileUpoadInitiated = true;
        if (this.fileToUpload == undefined) {
            this.fileUpoadInitiated = false;
            return false;
        }
        else {
            this.insertBlob(this.fileToUpload);


            //return this.blobstorageService.onUploadFiles(this.fileToUpload)
            //    .subscribe((response: any) => {
            //        this.fileUpoadInitiated = false;
            //        this.fileUpload = '';
            //        if (response == true) {
            //            //this.showBlobs();
            //            this.wo.strImagePath = this.wo.strImagePath + "*" + this.fileName;
            //            this.updateWo();
            //            this.blobFiles = [];
            //            this.wo = {};
            //        }
            //        else {
            //            alert('Error occured!');
            //            this.fileUpoadInitiated = false;
            //        }
            //    },
            //        err => console.log(err),
            //    );
        }
    }
    downloadFile(file) {
        //console.log(file.filePath.toString());
        //console.log(file.fileName);
        this.fileDownloadInitiated = true;
        this.blobstorageService.downloadFile(file.filePath.toString())
            .subscribe((result: any) => {
                if (result.type != 'text/plain') {
                    var blob = new Blob([result]);
                    let saveAs = require('file-saver');
                    let fileName = file.fileName;

                    //var url = window.URL.createObjectURL(result.data);
                    //window.open(url);

                    //window.open(url, '_blank');

                    saveAs(blob, fileName);
                    this.fileDownloadInitiated = false;
                }
                else {
                    this.fileDownloadInitiated = false;
                    alert('File not found in Blob!');
                }
            }
            );
    }
    deleteFile(file): void {
        var del = confirm('Are you sure want to delete this file');
        if (!del) return;
        //console.log(file.fileName);
        let index = file.index
        this.filenames = this.filenames.filter((val, i) => i != index);
        //console.log(this.filenames);
        let updatedfiles = "";
        this.filenames.forEach((file, delindex) => {
            if (delindex === (this.filenames.length - 1)) {
                updatedfiles += file
            } else {
                updatedfiles += file + "*";
            }
        })
        this.wo.strImagePath = updatedfiles;
        this.blobstorageService.deleteFile(file.id + '_' + file.fileName)
            .subscribe(result => {
                if (result != null) {
                    this.updateWo();
                }
            }, error => console.error(error));
    }
}
