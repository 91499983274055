import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Employee } from '../domain/employee';
import { Configuration } from '../../app.constants';
import { Observable, throwError, of } from 'rxjs/';
import { catchError, map, tap, retry, filter } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class EmployeeService {
    employee: Observable<Employee[]>;
    newemployee: Employee;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('EmployeeService');
    }
    baseUrl: string = 'api/Employees/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCrews', []))

            );
    }

    getEmployeesbyID(id): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.baseUrl + 'employee/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEmployeebyId', []))

            );
    }

    getEmployeesbyEmail(email): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.baseUrl + 'employeeemail/' + email)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEmployeeEmail', []))

            );
    }

    getEmployeesbyStatus(status): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.baseUrl + 'activefilter/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getActiveStatus', []))

            );
    }

    getVendorbyStatus(status): Observable<Employee[]> {
        return this.http.get<Employee[]>(this.baseUrl + 'activevendorfilter/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getActiveStatusVendor', []))

            );
    }

    addEmployee(employee: Employee): Observable<Employee> {
        return this.http.post<Employee>(this.baseUrl, employee)
            .pipe(
                catchError(this.handleError('addCrew', employee))
            );
    }

    updateEmployee(id, employee: Employee): Observable<Employee> {
        return this.http.put(this.baseUrl + id, employee)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateEmployee', employee))
            );
    }

    /* GET heroes whose name contains search term */
    searchEmployee(term: string): Observable<Employee[]> {
        // Add safe, URL encoded search parameter if there is a search term
        //const options = term ?
        //    { params: new HttpParams().set('strEmployeeID', term) } : {};

        const params = new HttpParams()
            .set('ysnActive', 'Active');

        return this.http.get<Employee[]>(this.baseUrl, { params })
            .pipe(
                catchError(this.handleError<Employee[]>('searchEmployee', []))
            );
    }

    searchEmployees(term: string): Observable<Employee[]> {
        if (!term.trim()) {
            // if not search term, return empty hero array.
            return of([]);
        }
        return this.http.get<Employee[]>(`${this.baseUrl}?ysnActive=${term}`).pipe(
            tap(_ => console.log(`found heroes matching "${term}"`)),
            catchError(this.handleError<Employee[]>('searchHeroes', []))
        );
    }

    //filter(): Observable<Employee[]> {
    //    return this.http.get<Employee[]>(this.baseUrl)
    //        .pipe(
    //            filter(response => response.code === 200));
    //}
}
