import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import { SelectItem, Calendar } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { TaskService } from '../service/taskservice';
import { Task } from '../domain/task';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { DailyFieldReport } from '../domain/dailyfieldreport'
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';
import { DatePipe, formatDate } from '@angular/common';
import { FilterUtils } from "primeng/api";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission'
import { CustomerInvoiceService } from '../service/customerinvoiceservice';
import { CustomerInvoice } from '../domain/customerinvoice'
import { CustomerService } from '../service/customerservice';
import { Customer } from '../domain/customer'
import { async } from 'q';




@Component({
    selector: 'unbilled-table',
    templateUrl: './unbilledtable.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class UnbilledTableComponent implements OnInit {
    @ViewChild('dt', { static: false }) private _table: Table;

    @ViewChild('calendar', { static: false }) calendarFrom: Calendar;

    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    unbilledProduction: BillableProduction = {};

    unbilledProductions: BillableProduction[];

    customer: Customer = {};

    customers: Customer[];

    selectedUnbilled: BillableProduction[];

    wo: Job = {};

    wos: Job[];

    task: Task = {};

    tasks: Task[];

    crews: Crew[];

    crew: Crew = {};

    customerInvoice: CustomerInvoice = {};

    customerInvoices: CustomerInvoice[];

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    @Input() selectedWONum: string = "";

    @Input() deletedInvoiceNum: number;

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    invoiceDetails: boolean = false;

    selectedColumns: any[];

    rangeDates: Date;

    dateFilters: any;

    userEmail: string;

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    userId: string;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    invoiceDate: Date;

    sumBillableProductions: number;

    newInvoiceNo: any;

    finalBill: boolean;

    @Output() invoiceCreated = new EventEmitter<boolean>();

    disableInvoiceBtn: boolean = true;

    progressBar: string = 'determinate';

    displayReport: boolean = false;

    selectedReportName: string;

    canRequestInvoice: boolean = false;

    canInvoice: boolean = false;

    isAdmin: boolean = false;

    showInvoiceButton: boolean = false;

    selectedDfrId: string = null;

    value: number = 0;

    showbillableprogress: boolean = false;

    showInvoiceprogress: boolean = false;

    currentInvoiceNum: string;

    msgs2: Message[] = [];

    invoiceAmount: number = 0;

    dfrList: any = [];

    isDEC: boolean = false;




    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private taskService: TaskService, private crewService: CrewService,
        private dfrService: DailyFieldReportService, private billableproductionService: BillableProductionService, private adalSvc: MsAdalAngular6Service,
        private userpermissionservice: UserPermissionService, private activatedroute: ActivatedRoute, private customerInvoiceService: CustomerInvoiceService,
        private customerService: CustomerService) {

        this.userEmail = this.adalSvc.LoggedInUserEmail;
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(this.userEmail);
            //this.getEmployeesbyEmail();
        });

        this.getEmployeesbyEmail();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);


                if (curVal) {
                    this.selectedWoId = curVal;
                    this.getUnBilledProductionbyWoId(this.selectedWoId);
                    this.getMaxInvoiceNo(this.selectedWoId);
                    this.getCustomersById(this.selectedCustomerId);
                    this.getWobyId(this.selectedWoId);
                   
                }
                //this.getBillableProductionbyWoId(this.selectedWoId);
                //console.log(this.selectedWoId);
            }



            if (propName === 'deletedInvoiceNum') {
                if (curVal == '1') {
                    this.getUnBilledProductionbyWoId(this.selectedWoId);
                    this.getMaxInvoiceNo(this.selectedWoId);
                    this.msgs2 = [];

                    //this.getBillableProductionbyWoId(this.selectedWoId);
                    //this.unbillProduction();
                    //console.log(curVal);
                }
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl(''),
            'taskowner': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
        });

        //this.getCrews();

        //this.getEmployeesbyStatus();

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'fakeBill', header: 'Bill', display: true, width: '6%' },
            { field: 'dfrdate', header: 'Date', display: true, width: '10%' },
            { field: 'intReportId', header: 'DFR #', display: true, width: '10%' },
            { field: 'crew', header: 'Crew', display: true, width: '8%' },
            { field: 'intQty', header: 'Qty', display: true, width: '8%' },
            { field: 'bcode', header: 'BC', display: true, width: '10%' },
            { field: 'bcdescription', header: 'Description', display: true, width: '30%' },
            { field: 'curBcPrice', header: 'Extended', display: true, width: '10%' },
            { field: 'strConstPt1', header: 'CP1', display: true, width: '10%' },
            { field: 'strConstPt2', header: 'CP2', display: true, width: '10%' },
            { field: 'strDetails', header: 'Comments', display: true, width: '10%' },
            { field: 'strStatus', header: 'Status', display: true, width: '9%' },
            { field: 'ysnAdjustment', header: 'Adj', display: true, width: '7%' },
            //{ field: 'button', header: 'Bill', display: true, width: '6%' },



        ];

        var _self = this;

        this.selectedColumns = this.cols;

        FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
            // get the from/start value
            var s = _self.rangeDates[0].getTime();
            //console.log(s);
            var e;
            // the to/end value might not be set
            // use the from/start date and add 1 day
            // or the to/end date and add 1 day
            if (_self.rangeDates[1]) {
                e = _self.rangeDates[1].getTime() + 86400000;
            } else {
                e = s + 86400000;
            }

            var dateValue = new Date(value);
            //console.log(e)
            // compare it to the actual values
            return dateValue.getTime() >= s && dateValue.getTime() < e;
        }

        this.invoiceDate = new Date();
    }

    getWobyId(id): void {
        this.jobService.getbyWoId(id)
            .subscribe(wos => {
                this.wos = wos;
                this.wo = this.wos.find(bp => bp.intJobId == id );
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.wo);
            });
    }

    addCustomerInvoice(invoice): void {
        if (!invoice) { return; }
        this.customerInvoiceService.addCustomerInvoice(this.customerInvoice)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                //this.showSuccessViaToast();
                this.invoiceCreated.emit(true);
                this.getMaxInvoiceNo(this.selectedWoId);
                //this.ngOnInit();
            });
    }

    async addCustomerInvoicePromise(invoice): Promise<any> {
        var result = await this.customerInvoiceService.addCustomerInvoice(this.customerInvoice).toPromise();
        this.invoiceCreated.emit(true);
        this.getMaxInvoiceNo(this.selectedWoId);
        return result;
    }

    getCustomersById(id): void {
        this.customerService.getCustomerbyId(id)
            .subscribe(customers => {
                this.customer = customers.find(c => c.intCustomerKey == id);
                //console.log(this.customer.strPaymentTerms);
            });
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
                //console.log(this.userpermission);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;
                //console.log(this.userpermissions);
                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;


                } else {
                    this.isAdmin = false;


                }

                if (this.userpermission.productionBillingInvoice == 1 && this.userpermission.customerBillingInvoice != 1) {
                    this.canRequestInvoice = true;
                } else {
                    this.canRequestInvoice = false;
                }

                if (this.userpermission.customerBillingInvoice == 1 || this.userpermission.intAdmin == 1) {
                    this.canInvoice = true;

                } else {
                    this.canInvoice = false;

                }


            });



    }


    getUnBilledProductionbyWoId(id): void {
        this.billableproductionService.getUnBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.unbilledProductions = bp;
                this.selectedUnbilled = this.unbilledProductions.filter(bp => bp.strStatus == 2);
                if (this.selectedUnbilled.length > 0) {
                    if (this.selectedCustomerId == '318') {

                        this.isDEC=true
                    }
                    this.disableInvoiceBtn = false;
                    this.addTotal();
                }
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });

    }

    getBillableProductionbyWoId(id): void {
        this.billableproductionService.getBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                this.billableProductions = this.billableProductions.filter(bp => bp.strInvoiceNum == this.deletedInvoiceNum);
                //this.unbillProduction();
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.billableProductions);
            });
    }

    getMaxInvoiceNo(id): void {
        this.billableproductionService.getMaxInvoiceNo(id)
            .subscribe(maxinvno => {
                this.newInvoiceNo = maxinvno

            });

    }

    updateUnbilled(id, billableproduction, showtoast: boolean): void {
        //let crews = [...this.crews];
        //crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.billableproductionService.updateBillableProduction(id, billableproduction)

            .subscribe(data => {
                if (showtoast) { }



            });

    }

    async updateUnbilledPromise(id, billableproduction, showtoast: boolean): Promise<any> {
        var result = await this.billableproductionService.updateBillableProduction(id, billableproduction).toPromise();
        if (showtoast) { }
        return result;
    }




    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newTask = true;
        this.wo = {};
        this.displayDialog = true;
    }

    clone(c: Task): Task {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.wo = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Invoice Created', detail: this.currentInvoiceNum + '=$' + (Math.floor(this.sumBillableProductions *100)/100)});
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Marked Bill', detail: '' });
    }

    showSuccessBillRequestViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Request for Invoice has been sent', detail: '' });
    }

    addMessages() {
        this.msgs2= [
            { severity: 'success', summary: 'Invoice Created', detail: this.currentInvoiceNum + ' Amount: ' +  this.formatter.format(this.invoiceAmount) },
            //{ severity: 'info', summary: 'Info', detail: 'Message Content' },
            //{ severity: 'warn', summary: 'Warning', detail: 'Message Content' },
            //{ severity: 'error', summary: 'Error', detail: 'Message Content' }
        ];
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }



    onConfirm() {
        this.service.clear('c');
        if (this.taskDropdown === 'Craft-Work') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        } else if (this.taskDropdown === 'Placement') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        }
    }

    onReject() {
        this.service.clear('c');
    }

    async invoice() {
        if (this.selectedUnbilled.length > 0) {
            this.showInvoiceprogress = true;
            //this.showbillableprogress = false;
            //this.value = 0;
            this.disableInvoiceBtn = true;
            this.progressBar = 'indeterminate'
            const invoice = await this.invoiceUnbilled();
            const invoiceUnbilled = await this.createInvoice();
            //await invoiceUnbilled;
            await invoice;
            // If invoicing issues uncomment one line below
            //await new Promise(resolve => setTimeout(resolve, 1000)); // 3 sec
            //await invoice;
            await invoiceUnbilled;
            //console.log(this.currentInvoiceNum);
            this.getUnBilledProductionbyWoId(this.selectedWoId)
            this.progressBar = 'determinate'
            //this.disableInvoiceBtn = false;            
            //this.showSuccessViaToast();
            //this.addMessages();
            this.msgs2.push({
                severity: 'success',
                summary: 'Invoice Created',
                detail: this.currentInvoiceNum + ' Invoice Amount: ' + this.formatter.format(this.invoiceAmount)
            });
            this.sumBillableProductions = 0;
            this.disableInvoiceBtn = true;
            this.showInvoiceprogress = false;
            this.showbillableprogress = false;
            //console.log(this.selectedCustomerId);
            //if (this.selectedCustomerId == '318') {
            //    this.isDEC = true;
            //} else {
            //    this.isDEC = false;
            //}
            //this.value=0
            //this.invoiceCreated.emit(true);
            //this._table.reset;
        } else {
            //console.log('Nothing Selected')

        }
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    async createInvoice() {
        let paymentterms
        let invoicedue
        let invoicedate
        if (this.customer.strPaymentTerms) {
            paymentterms = this.customer.strPaymentTerms;
        } else {
            paymentterms = 0
        }

        if (this.invoiceDate) {
            invoicedate = this.invoiceDate;
            invoicedue = new Date(new Date().setDate(this.invoiceDate.getDate() + paymentterms));
        } else {
            invoicedue = new Date(new Date().setDate(new Date().getDate() + paymentterms));
            invoicedate = new Date(new Date().setDate(new Date().getDate()));
        }
        let newinvoicenum = this.newInvoiceNo.toString();

        //await new Promise(resolve => setTimeout(resolve, 900)); // .8 sec

        //console.log(newinvoicenum);
        if (newinvoicenum) {
            newinvoicenum = parseInt(this.newInvoiceNo) + 1;

        } else {
            newinvoicenum = parseInt("000") + 1;
        }

        this.currentInvoiceNum = this.wo.strJob + '-' + this.padLeft(newinvoicenum.toString(), '0', 3);

        let invoice = this.customerInvoice;
        //invoice.curInvoiceTotal = this.sumBillableProductions
        invoice.curInvoiceTotal = this.invoiceAmount;
        invoice.dtmInvoiceDate = this._dateService.toString(invoicedate);
        invoice.strInvoiceNo = this.padLeft(newinvoicenum.toString(), '0', 3);
        invoice.intJobNum = this.selectedWoId;
        invoice.intCustomerId = this.selectedCustomerId;
        invoice.intWoid = this.selectedWONum;
        invoice.dtmInvoiceDue = this._dateService.toString(invoicedue);
        if (this.finalBill) {
            invoice.ynFinal = -1;
        } else {
            invoice.ynFinal = 0;
        }

        const wocontrols = await this.addCustomerInvoicePromise(invoice);

        //await new Promise(resolve => setTimeout(resolve, 2000)); // 2 sec

        //this.addCustomerInvoice(invoice);


        //this.getUnBilledProductionbyWoId(this.selectedWoId)
        //this.invoiceCreated.emit(true);
        //this._table.reset;
    }



    async invoiceUnbilled() {
        if (this.selectedUnbilled.length > 0) {
            //this.getMaxInvoiceNo(this.selectedWoId);
            this.invoiceCreated.emit(false);
            let paymentterms
            if (this.customer.strPaymentTerms) {
                paymentterms = this.customer.strPaymentTerms;
            } else {
                paymentterms = 0
            }

            let invoicedue = new Date(new Date().setDate(new Date().getDate() + paymentterms));
            let newinvoicenum = this.newInvoiceNo.toString();

            //console.log(newinvoicenum);
            if (newinvoicenum) {
                newinvoicenum = parseInt(this.newInvoiceNo) + 1;

            } else {
                newinvoicenum = parseInt("000") + 1;
            }

            //console.log(parseInt(this.newInvoiceNo) + 1);
            //console.log(this.padLeft(newinvoicenum.toString(), '0', 3));  // '000123')
            //console.log((String(0).repeat(3 - parseInt(this.newInvoiceNo) + 1).length) + this.newInvoiceNo));
            let selectedinvoiceamount = this.sumBillableProductions;
            let invoicenum = this.newInvoiceNo
            let invoiceamount = 0;
            //this.dfrList = [];
            //this.value = 100;

            if (Array.isArray(this.selectedUnbilled) && (this.selectedUnbilled.length)) {
                let unbilled = [...this.selectedUnbilled];

                for (let i = 0; i < unbilled.length; i++) {
                    //if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                    //console.log(formatDate(payrollhours[i].dtDate, format, locale));
                    //console.log(formatDate(date, format, locale));
                    let data = unbilled[i];
                    this.selectedUnbilled.find(b => b.intProductionId == data.intProductionId).strStatus = 3;
                    this.selectedUnbilled.find(b => b.intProductionId == data.intProductionId).dtmInvoiceDate = this._dateService.toString(this.invoiceDate);
                    this.selectedUnbilled.find(b => b.intProductionId == data.intProductionId).strInvoiceNum = this.padLeft(newinvoicenum.toString(), '0', 3);
                    invoiceamount += data.curBcprice * data.intQty
                    this.addDFR(data.intReportId)
                    //this.updateUnbilled(unbilled[i].intProductionId, data, true);
                    //console.log(this.value);
                    this.value = 100 - (Math.round((i / this.selectedUnbilled.length) * 100));
                    if (this.value <= 1) {
                        this.value = 0;
                        //this.service.add({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
                        //clearInterval(interval);
                    }


                    let result = await this.updateUnbilledPromise(unbilled[i].intProductionId, data, true);

                    //await new Promise(resolve => setTimeout(resolve, 900)); // 2 sec






                    //this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);

                }

                //console.log(Math.round(invoiceamount));
                //console.log(Math.round(this.sumBillableProductions));
                this.invoiceAmount = invoiceamount;
                if (invoiceamount != selectedinvoiceamount) {

                    this.msgs2.push({
                        severity: 'error',
                        summary: 'Invoice Amount Mismatch',
                        detail: this.formatter.format(invoiceamount) + '<>' + this.formatter.format(this.sumBillableProductions)
                    });
                        //this.msgs2 [
                        //    //{ severity: 'success', summary: 'Success', detail: 'Message Content' },
                        //    //{ severity: 'info', summary: 'Info', detail: 'Message Content' },
                        //    //{ severity: 'warn', summary: 'Warning', detail: 'Message Content' },
                        //    { severity: 'error', summary: 'Invoice Mismatch-Invoice Amount does not match selected bill amount', detail: this.formatter.format(invoiceamount) + '<>' + this.formatter.format(this.sumBillableProductions) }
                        //];
                   
                    //this.service.add({ key: 'tst', severity: 'error', summary: 'Invoice Mismatch', detail: '$' + Math.round(invoiceamount) + '<>' + '$' + Math.round(this.sumBillableProductions) });
                }
            }


        }
    }



    padLeft(text: string, padChar: string, size: number): string {
        return (String(padChar).repeat(size) + text).substr((size * -1), size);
    }

    onSelectionChange(selection: BillableProduction[]) {
        if (this.selectedCustomerId == '318') {
            this.isDEC = true;
        } else {

            this.isDEC = false;
        }
        for (let i = selection.length - 1; i >= 0; i--) {
            let data = selection[i];
            this.unbilledProductions.find(b => b.intProductionId == data.intProductionId).strStatus = 2;

            //selection.splice(i, 1);
            //console.log(data);
        }
        //console.log(selection);
    }

    onDateSelect(event, dt) {

        dt.filter(this.rangeDates, 'dfrdate', 'dateRangeFilter');

    }


    async updatePanel() {
        this.disableInvoiceBtn = false;
        this.progressBar = 'determinate';
        this.addTotal();
    }


    async updateAllUnbilledSelection(control) {
        if (this.selectedCustomerId == '318') {
            this.isDEC = true;
        } else {

            this.isDEC = false;
        }
        this.showbillableprogress = true;
        this.value = 0;
        this.disableInvoiceBtn = true;
        //console.log(control.checked);
        //console.log(row.intProductionId);
        //this.progressBar = 'indeterminate';
        let items = this.unbilledProductions;
        if (control.checked) {

            this.disableInvoiceBtn = true;
            for (let i = this.unbilledProductions.length - 1; i >= 0; i--) {
                let data = this.unbilledProductions[i];
                this.unbilledProductions.find(b => b.intProductionId == data.intProductionId).strStatus = 2;
                let result = await this.updateUnbilledPromise(data.intProductionId, data, false);
                this.value = Math.round((i / this.unbilledProductions.length - 1) * 100) * -1;
                if (this.value >= 99) {
                    this.value = 100;
                    //this.service.add({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
                    //clearInterval(interval);
                }
                //console.log(i);
            }


        } else {
            //this.value = 0;
            this.disableInvoiceBtn = true;
            this.isDEC = false;
            for (let i = this.unbilledProductions.length - 1; i >= 0; i--) {
                let data = this.unbilledProductions[i];
                this.unbilledProductions.find(b => b.intProductionId == data.intProductionId).strStatus = 0;
                let result = await this.updateUnbilledPromise(data.intProductionId, data, false);
                this.value = Math.round((i / this.unbilledProductions.length) * 100);
                if (this.value <= 0) {
                    this.value = 0;
                    //this.service.add({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
                    //clearInterval(interval);
                }
                //await result;
                //selection.splice(i, 1);
                //console.log(data);
                //this.disableInvoiceBtn = false;

            }
            //this.progressBar ='determinate'
            //this.disableInvoiceBtn = false;
        }

        if (this.selectedUnbilled.length > 0) {
            this.disableInvoiceBtn = false;
        } else {
            this.disableInvoiceBtn = true;
        }

        //await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
        //this.progressBar = 'determinate';
        //this.disableInvoiceBtn = false;
        this.addTotal();

    }

    removeItem(value) {

        if (this.dfrList.includes(value)) {          //checking weather array contain the id
           this.dfrList.splice(this.dfrList.indexOf(value), 1);  //deleting   //adding to array because value doesnt exists
        } else {
            //this.dfrList.splice(this.dfrList.indexOf(id), 1);  //deleting
        }
        console.log(this.dfrList);
    }

    addItem(value) {

        if (this.dfrList.indexOf(value) === -1) {
            this.dfrList.push(value);
            console.log(this.dfrList);
        }
    }

    addDFR(id) {
        if (!this.dfrList.includes(id)) {          //checking weather array contain the id
            this.dfrList.push(id);               //adding to array because value doesnt exists
        } else {
            //this.dfrList.splice(this.dfrList.indexOf(id), 1);  //deleting
        }
        //console.log(this.dfrList);
    }




    updateUnbilledSelection(row, control) {
        this.disableInvoiceBtn = true;
        //console.log(control.checked);
        //console.log(row.intProductionId);

        if (control.checked) {
            this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 2;
            this.updateUnbilled(row.intProductionId, row, true);
            this.addTotal();
          //this.addDFR(row.intReportId);
            //this.dfrList.push(row.intReportId);
            //this.addItem(row.intReportId);
        } else {
            this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 0;
            this.updateUnbilled(row.intProductionId, row, true);
            this.addTotal();
            //this.removeItem(row.intReportId);
        }

        

        //const result = Array.from(this.dfrList.reduce((m, t) => m.set(t.name, t), new Map()).values());
        //this.dfrList = this.dfrList.filter((el, i, a) => i === a.indexOf(el))
        //console.log(result);
        if (this.selectedUnbilled.length > 0) {
            this.disableInvoiceBtn = false;
            if (this.selectedCustomerId == '318') {
                this.isDEC = true;
            }
        } else {
            this.isDEC = false;
            this.disableInvoiceBtn = true;
        }

    }

    onClick() {
        this._table.reset();
        this.rangeDates = null;
        this.invoiceDate = new Date(new Date().setDate(new Date().getDate()));

    }

    addTotal() {
        //this.showbillableprogress = false;
        let myArray = this.selectedUnbilled

        this.sumBillableProductions = 0;
        if (myArray.length > 0) {
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i].intQty && myArray[i].curBcprice) {
                    this.sumBillableProductions += ((myArray[i].intQty) * (myArray[i].curBcprice));

                }
            }
        }
        //this.costTotal.emit(this.sumBillableProductions);
    }

    finalClick(event) {
        this.finalBill = true;
    }

    notFinalClick(event) {
        this.finalBill = null;
    }

    showPreview() {
        this.displayReport = true;
        this.selectedReportName = "Infratrac/Invoice Preview.trdp"
    }

    requestInvoice() {

    }

    deleteInvoice() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Invoice?',
            //detail: this.deletedQty + ' of ' + this.deletedBc + ' : ' + this.deletedBcDescription
        });

    }

    printDFR(rowdata) {
        this.displayReport = true;
        this.selectedDfrId = rowdata.intReportId;
        this.selectedReportName = "DFRs/Infratech DFR no prices.trdp";
        //console.log(rowdata.intReportId);
    }

    printDECTimesheet(event) {
        this.dfrList = []
       //IF DEC then create a list of DFR#'s to push to the DEC Timesheet Report. 
        if (this.selectedCustomerId == '318') {
            this.isDEC = true;

            //Loop through the selected unbilled and add the DFRID for the report. Will not push duplicates DFR#'s
            if (Array.isArray(this.selectedUnbilled) && (this.selectedUnbilled.length)) {
                let unbilled = [...this.selectedUnbilled];

                for (let i = 0; i < unbilled.length; i++) {
                    let data = unbilled[i];

                    this.addDFR(data.intReportId)
                    


                }
               

                }
        } else {
            this.isDEC = false;
        }
        this.displayReport = true;
        this.selectedDfrId = this.dfrList;
        this.selectedReportName = "DEC Reports/DEC Timesheet.trdp";
        //console.log(rowdata.intReportId);
    }
}
