import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { RepMaintActivity } from '../domain/repmaintactivity';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class RepMaintActivityService {
    crewtype: Observable<RepMaintActivity[]>;
    newcrewtype: RepMaintActivity ;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('RepMainActivity');
    }
    baseUrl: string = 'api/RepMaintActivities/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getRepairActivities(): Observable<RepMaintActivity[]> {
        return this.http.get<RepMaintActivity[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRepairActivities', []))

            );
    }

    addRepair(repair: RepMaintActivity): Observable<RepMaintActivity> {
        return this.http.post<RepMaintActivity>(this.baseUrl, repair)
            .pipe(
                catchError(this.handleError('addRepair', repair))
            );
    }

    updateRepair(id, repair: RepMaintActivity): Observable<RepMaintActivity> {
        return this.http.put(this.baseUrl + id, repair)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateRepMaintVendor', repair))
            );
    }

    getRepairsbyEquipmentId(id): Observable<RepMaintActivity[]> {
        return this.http.get<RepMaintActivity[]>(this.baseUrl + 'equipmentid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRepairsbyEquipmentId', []))

            );
    }

    /* GET heroes whose name contains search term */
    //searchCrews(term: string): Observable<Crew[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('crew', term) } : {};

    //    return this.http.get<Crew[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Crew[]>('searchCrew', []))
    //        );
    //}
}
