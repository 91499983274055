import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { SelectItem } from 'primeng/primeng';
import { Table } from 'primeng/table';

@Component({
    selector: 'rpcrewlist',
    templateUrl: './rpcrew.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class RpCrewComponent implements OnInit {
    crews: Crew[];

    crew: Crew = {};

    cols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    submitted: boolean;

    @Input() selectedRpId: string = "";

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    displayReport: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private crewService: CrewService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedRpId') {
                //this.onRowSelect(curVal);
                this.selectedRpId = curVal;
                this.getCrews(this.selectedRpId);
                //console.log('fired');
            }
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'intCrewKey', header: 'Crew ID' },
            { field: 'strCrewId', header: 'Crew #' },
            { field: 'strArea', header: 'Area' },
            { field: 'crewtype', header: 'Crew Type' }
        ];
    }

    getCrews(id): void {
        this.crewService.getRpCrews(id)
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Updated', detail: this.unitName });
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onReject() {
        this.service.clear('c');
    }

    printDFR() {
        this.displayReport = true;
    }
}
