import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer, EventEmitter, Output } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { FileUploadModule } from 'primeng/fileupload';
import { Job } from '../domain/job'
import { JobService } from '../service/jobservice';

@Component({
    selector: 'wocontractupdate-import',
    templateUrl: './wocontractupdate-import.component.html',

    providers: [MessageService]

})
export class WoContractUpdateComponent implements OnInit {

    arrayBuffer: any;
    file: File;
    uploadedFiles: any[] = [];
    import: any[];
    uploadedFile: File;
    value: number = 0;

    wo: Job = {};

    wos: Job[];
    

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,       
        private fb: FormBuilder,
        private _dateService: DateService,        
        private renderer: Renderer,
        private jobService: JobService
        ) {
    }

   

    ngOnInit() {
        
    }

    updateWo(): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        //this.unitName = this.wo.strJob;
        //console.log(this.billingunittype);
        this.jobService.updateJob(this.wo.intJobId, this.wo)

            .subscribe(data => {
                //console.log(data);
                //this.showSuccessUpdateViaToast();
               
                //this.ngOnInit();
                //this.handleError("err:Test");
            });
        //this.filterWoString(this.selectedWo);
    }

    async updateWoContractInfo(control): Promise<any> {
        var result = await this.jobService.patchJobContract(control.intJobId, control).toPromise();
        return result;
    }
   

   async Upload() {
        let count = 0
        if (Array.isArray(this.import) && (this.import.length)) {
            let sqlimport = [...this.import];
            
            for (const imp of this.import) {
                this.wo.intJobId = imp['intJobID'];
                //this.wo.strJob = imp['strJob'];
                //this.wo.strJobName = imp['strJobName'];
                this.wo.intContractKey = imp['newContractKey'];
                this.wo.strContractNo = imp['newstrContractNo'];
                //this.wo.dtmReceived = imp['dtmReceived'];
                //this.wo.intCustomerKey = imp['intCustomerKey'];
                //this.wo.intOfficeLocation = imp['intOfficeLocation'];
                //this.employee.dtmHireDate = imp['dtmHireDate'];
                //this.wo.strContractNo = imp['newstrContractNo'];
               
                await this.updateWoContractInfo(this.wo);
                count++;
                //await new Promise(resolve => setTimeout(resolve, 800)); // .2 sec
                //this.value = Math.round(count * 100 / sqlimport.length) + 1;
                let interval = setInterval(() => {
                    //console.log(this.value);
                    this.value = this.value + Math.round((count/sqlimport.length) * 100);
                    if (this.value >= 100) {
                        this.value = 100;
                        //this.service.add({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
                        clearInterval(interval);
                    }
                }, 500);
                //console.log(this.billingcode);
              
                //if (this.value >= 99) {
                //    this.value = 100;
                //    this.service.add({ severity: 'info', summary: 'File Uploaded', detail: 'Process Completed' });
                //}
               
            }
            this.service.add({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
            
        }
       
        //let fileReader = new FileReader();
        //fileReader.onload = (e) => {
        //    this.arrayBuffer = fileReader.result;
        //    var data = new Uint8Array(this.arrayBuffer);
        //    var arr = new Array();
        //    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //    var bstr = arr.join("");
        //    var workbook = XLSX.read(bstr, { type: "binary" });
        //    var first_sheet_name = workbook.SheetNames[0];
        //    var worksheet = workbook.Sheets[first_sheet_name];
        //    //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
        //    this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        //}
        //fileReader.readAsArrayBuffer(this.file);
    }

    incomingfile(event) {
        this.value = 0;
        this.import = [];
         this.file = event.files[0];        

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];           
            this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    onRemove() {
        this.import = [];
    }

    onClear() {
        this.import = [];
    }

   

    
}
