import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer, EventEmitter, Output } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { FileUploadModule } from 'primeng/fileupload';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
@Component({
    selector: 'billingcode-import',
    templateUrl: './billingcode-import.component.html',

    providers: [MessageService]

})
export class BillingCodeImportComponent implements OnInit {

    arrayBuffer: any;
    file: File;
    uploadedFiles: any[] = [];
    import: any[];
    uploadedFile: File;
    value: number = 0;

    billingcodes: BillingCodeCustomer[];

    billingcode: BillingCodeCustomer = {};
    

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,       
        private fb: FormBuilder,
        private _dateService: DateService,        
        private renderer: Renderer,
        private billingcodeservice: BillingCodesCustomerService
        ) {
    }

   

    ngOnInit() {
        
    }

    addCustomerBillingCode(billingcode): void {
        if (!billingcode) { return; }
        this.billingcodeservice.addBillingCodeCustomer(this.billingcode)
            .subscribe(data => {
                //this.showSuccessViaToast();
                //customers.push(this.customer);
                
                //this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    async uploadBillingCodes(bc) {
        const uploadbc = this.addCustomerBillingCode(bc);
        await uploadbc;
        return;
    }

   async Upload() {
        var count = 0
        if (Array.isArray(this.import) && (this.import.length)) {
            let sqlimport = [...this.import];

            for (const imp of this.import) {
                this.billingcode.strCustomerBillingCode = imp['strCustomerBillingCode'];
                this.billingcode.strCustomerProductDesc = imp['strCustomerProductDesc'];
                this.billingcode.curUnitRate = imp['curUnitRate'];
                this.billingcode.intInfratechBillingCode = imp['intInfratechBillingCode'];
                this.billingcode.curSubUnitRate = imp['curSubUnitRate'];
                this.billingcode.curSub2UnitRate = imp['curSub2UnitRate'];
                this.billingcode.intCustomer= imp['intCustomer'];
                this.billingcode.intContractKey= imp['intContractKey'];
                this.billingcode.ysnActive = -1;
                //this.billingcode.dtmCreateDate = this._dateService.toString(new Date(new Date().setDate(new Date().getDate())));
                count += 1
                //this.addCustomerBillingCode(this.billingcode);
                this.uploadBillingCodes(this.billingcode);
                await new Promise(resolve => setTimeout(resolve, 800)); // .2 sec
                this.value = Math.round(count /sqlimport.length)*100;
                //console.log(this.billingcode);
                //console.log(i);
                if (this.value >= 99) {
                    this.value = 100;
                    
                }
               
            }

            this.service.add({ severity: 'info', summary: 'File Uploaded', detail: 'Process Completed' });
        }
       
        //let fileReader = new FileReader();
        //fileReader.onload = (e) => {
        //    this.arrayBuffer = fileReader.result;
        //    var data = new Uint8Array(this.arrayBuffer);
        //    var arr = new Array();
        //    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //    var bstr = arr.join("");
        //    var workbook = XLSX.read(bstr, { type: "binary" });
        //    var first_sheet_name = workbook.SheetNames[0];
        //    var worksheet = workbook.Sheets[first_sheet_name];
        //    //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
        //    this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        //}
        //fileReader.readAsArrayBuffer(this.file);
    }

    incomingfile(event) {
        this.value = 0;
        this.import = [];
         this.file = event.files[0];        

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];           
            this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    onRemove() {
        this.import = [];
    }

    onClear() {
        this.import = [];
    }

   

    
}
