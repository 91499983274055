import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';

@Component({
    selector: 'app-employee-child',
    templateUrl: './employee-details.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class EmployeeDetailsComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    employees: Employee[];

    employee: Employee = {};

    cols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedEmployee: Employee;

    activeFilter: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    selectedActive: string = "-1";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    selectedCustomerId: string = "";
    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private employeeService: EmployeeService, private positionService: PositionService) {
    }

    ngOnInit() {
        this.getPositions();

        this.getEmployees();

        //this.getActiveEmployees();

        this.active = [
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.cols = [
            { field: 'strEmployeeId', header: 'Employee ID' },
            { field: 'strLastName', header: 'Last Name' },
            { field: 'strFirstName', header: 'First Name' },
            { field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'dtmTermDate', header: 'Term Date' },
            { field: 'intPosition', header: 'Position' },
            { field: 'strCrew', header: 'Crew' },
            { field: 'ysnActive', header: 'Active' },
        ];
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployee('Active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    //showDialogToAddEmployee() {
    //    this.newContract = true;
    //    this.employee = {};
    //    this.employee.strEmployeeID = this.selectedEmployee.strEmployeeID;
    //    this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
    //    this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
    //    this.displayContractDialog = true;
    //}

    //onRowUnselect() {
    //    this.displayContract = false;
    //}

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    onCancelContract() {
        this.displayEmployeeDialog = false;
    }

    //showSuccessViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    //}

    //showSuccessUpdateViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    //}

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }
}
