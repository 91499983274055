import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { OfficeLocationService } from '../service/officelocationservice';
import { OfficeLocation } from '../domain/officelocation';

@Component({
    selector: 'officelocation-table',
    templateUrl: './officelocationtable.component.html',

    providers: [MessageService]
})
export class OfficeLocationsTableComponent implements OnInit {

    cols: any[];

    contactType: any[];

    officeLocations: OfficeLocation[];

    officeLocation: OfficeLocation = {};

    selectedLocation: OfficeLocation;
        
    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedCustomerId: string = "";

    yearFilter: number;

    yearTimeout: any;

    displayDialog: boolean = false;

    newLocation: boolean = false;

    locationId: string;

    locationName: string;

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private officeLocationService: OfficeLocationService,
        private fb: FormBuilder,
        private _dateService: DateService) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedCustomerId') {
                //this.onRowSelect(curVal);
                this.selectedCustomerId = curVal;
                this.getOfficeLocation(this.selectedCustomerId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {


        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];


        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];



        this.cols = [
            { field: 'strLocation', header: 'Location' },
            { field: 'strContactFirstName', header: 'First Name' },
            { field: 'strContactLastName', header: 'Last Name' },
            //{ field: 'intLocationId', header: 'Id' },
            //{ field: 'strContactType', header: 'Contact Type' },
            //{ field: 'strAddress1', header: 'Address1' },
            //{ field: 'strAddress2', header: 'Address2' },
            //{ field: 'strCity', header: 'City' },
            //{ field: 'strState', header: 'State' },
            //{ field: 'strZip', header: 'Zip' },
            //{ field: 'strPhone', header: 'Phone' },
            //{ field: 'strEmail', header: 'Email' },
        ];

        this.contactType = [
            { label: 'Accounting', value: '1' },
            { label: 'Sales', value: '2' },
            { label: 'Engineering', value: '3' },
            { label: 'Executive', value: '4' },
            { label: 'Other', value: '5' },
           
        ];


    }

    getOfficeLocation(id): void {
        this.officeLocationService.getOfficeLocationsbyCustomer(id)
            .subscribe(locations => this.officeLocations = locations);
    }


    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }



    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    edit(rowdata) {
        this.locationId = rowdata.intLocationId;    

        this.newLocation = false;

        this.officeLocation = this.clone(rowdata);

        this.displayDialog = true;
       
    }

    clone(c: OfficeLocation): OfficeLocation {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.officeLocation = {};
    }

    save() {
        let officeLocations = [...this.officeLocations];
        this.locationName = this.officeLocation.strLocation;
       this.officeLocation.intCustomerAutoKey = this.selectedCustomerId;
        if (this.newLocation) {
            
            this.addLocation(officeLocations);
            officeLocations.push(this.officeLocation);          
        }
        else {
            this.updateOfficeLocation();
           
        }
        this.officeLocations = officeLocations;
        this.officeLocation = null;
        this.displayDialog = false;
    }

    updateOfficeLocation(): void {
        let officeLocations = [...this.officeLocations];
        officeLocations[this.officeLocations.indexOf(this.selectedLocation)] = this.officeLocation;
        this.locationName = this.officeLocation.strLocation;
        this.officeLocationService.updateOfficeLocation(this.officeLocation.intLocationId, this.officeLocation)   

            .subscribe(data => {
                //console.log(data);
                this.showSuccessUpdateViaToast();
                //this.ngOnInit();
                this.getOfficeLocation(this.selectedCustomerId);

                //this.handleError("err:Test");
            });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Location Updated', detail: this.locationName });
    }

    addLocation(location): void {
        if (!location) { return; }
        this.officeLocationService.addOfficeLocation(this.officeLocation)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Location Added', detail: this.locationName });
    }

    showDialogToAdd() {
        this.newLocation = true;
        this.officeLocation = {};
        this.displayDialog = true;
    }
}
