import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { PayRollHour } from '../domain/payrollhour';

@Component({
    selector: 'payroll-table',
    templateUrl: './payroll-table.component.html',
   
    providers: [MessageService]
})
export class PayRollHoursTableComponent implements OnInit {
   
    payrollHours: PayRollHour[];

    payrollcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedEmployeeId: string = "";

    yearFilter: number;

    yearTimeout: any;

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private payRollHourService: PayRollHourService,
        private fb: FormBuilder,
        private _dateService: DateService) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEmployeeId') {
                //this.onRowSelect(curVal);
                this.selectedEmployeeId = curVal;
                this.getEmployeeHours(this.selectedEmployeeId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
       
      
        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

      
       this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

       
       
        this.payrollcols = [
            { field: 'dtDate', header: 'Date' },
            { field: 'intReportId', header: 'DFR#' },
            { field: 'intCrew', header: 'Crew' },
            { field: 'intHoursWorked', header: 'Hours' },
            { field: 'intHoursVac', header: 'Vacation' },
            { field: 'intHoursPto', header: 'PTO' },
            { field: 'intHoursHol', header: 'Hol' },
            { field: 'ysnPerDiem', header: 'PerDiem' },
            { field: 'isTabletRow', header: 'Tablet Entry' },
            { field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];

        
    }
   
    getEmployeeHours(id): void {
        this.payRollHourService.getEmployeePayRollHours(id)
            .subscribe(employeehours => this.payrollHours = employeehours);
    }
    

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

   

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }
}
