import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DetailedEstimatedUnit } from '../domain/detailedestimatedunits';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DetailedEstimatedUnitService {
    detailunit: Observable<DetailedEstimatedUnit[]>;
    newestimatedunit: DetailedEstimatedUnit;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('DetailEstimatedUnitService');
    }
    baseUrl: string = 'api/DetailedEstimatedUnits/';
    errorUrl: string = 'http://localhost:5243/api/DetailedEstimatedUnits/';

    getDetailEstimatedUnit(): Observable<DetailedEstimatedUnit[]> {
        return this.http.get<DetailedEstimatedUnit[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDetailEstimatedUnit', []))

            );
    }

    //getEquipmentbyStatus(status): Observable<EstimatedUnit[]> {
    //    return this.http.get<EstimatedUnit[]>(this.baseUrl + 'activefilter/' + status)

    //        //.pipe(
    //        //    map((e: any[]) => e.json()),
    //        //    catchError((e: Response) => throwError(e))
    //        //);
    //        .pipe(
    //            retry(3), // retry a failed request up to 3 times
    //            catchError(this.handleError('getActiveStatus', []))

    //        );
    //}

    addDetailEstimatedUnit(unit: DetailedEstimatedUnit): Observable<DetailedEstimatedUnit> {
        return this.http.post<DetailedEstimatedUnit>(this.baseUrl, unit)
            .pipe(
                catchError(this.handleError('addDetailEstimtatedUnit', unit))
            );
    }

    updateDetailEstimatedUnit(id, estimatedunit: DetailedEstimatedUnit): Observable<DetailedEstimatedUnit> {
        return this.http.put(this.baseUrl + id, estimatedunit)
            .pipe(
                tap(_ => console.log(`updated Estimated Unit id=${id}`)),
                catchError(this.handleError('updateEstimatedUnit', estimatedunit))
            );
    }

    deleteDetailEstimatedUnit(id): Observable<DetailedEstimatedUnit> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Estimated Unit id=${id}`)),
                catchError(this.handleError('Detail Estimated Unit'))
            );
    }

    getDetailEstimatedUnitId(id): Observable<DetailedEstimatedUnit[]> {
        return this.http.get<DetailedEstimatedUnit[]>(this.baseUrl + 'unitid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnitbyID', []))

            );
    }

    getEstimatedUnitsbyJobId(id): Observable<DetailedEstimatedUnit[]> {
        return this.http.get<DetailedEstimatedUnit[]>(this.baseUrl + 'jobid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnitbyJobID', []))

            );
    }

    getEstimatedUnitsbyUnitId(id): Observable<DetailedEstimatedUnit[]> {
        return this.http.get<DetailedEstimatedUnit[]>(this.baseUrl + 'unit/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnitbyUnitID', []))

            );
    }
}
