import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { fsclyrwks } from '../domain/fsclyrwks';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class FsclyrwksService {
    crew: Observable<fsclyrwks[]>;
    newcrew: fsclyrwks;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('fsclyrwks');
    }
    baseUrl: string = 'api/fsclyrwks/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getfiscaldates(): Observable<fsclyrwks[]> {
        return this.http.get<fsclyrwks[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getfiscaldates', []))

            );
    }  

    

    getfiscalwkbydate(id): Observable<fsclyrwks[]> {
        return this.http.get<fsclyrwks[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getfsclyrwksbyid', []))

            );
    }

  

    addfiscalperiod(wk: fsclyrwks): Observable<fsclyrwks> {
        return this.http.post<fsclyrwks>(this.baseUrl, wk)
            .pipe(
                catchError(this.handleError('addfiscalperiod', wk))
            );
    }

    updatefiscalperiod(id, wk: fsclyrwks): Observable<fsclyrwks> {
        return this.http.put(this.baseUrl + id, wk)
            .pipe(
                tap(_ => console.log(`updated fiscalperiod=${id}`)),
                catchError(this.handleError('updateFiscalPeriod', wk))
            );
    }

  
}
