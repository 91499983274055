import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class BlobStorageService {
   
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('BlobUploaderService');
    }
    baseUrl: string = 'api/BlobStorage/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    showBlobs() {
        return this.http.get<string[]>(this.baseUrl + 'listfiles')
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getListFiles', []))

            );
    }

    
    onUploadFiles(file) {
       
         return this.http.post(this.baseUrl + 'insertfile', file)
             .pipe(
                 retry(3), // retry a failed request up to 3 times
                 catchError(this.handleError('UploadFiles', []))

             );

        }


    downloadFile(fileName: string) {
        return this.http.get(this.baseUrl + 'downloadfile/' + fileName, { responseType: "blob" })
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('downloadFiles', []))

        );

    }

    deleteFile(fileName: string) {        
     return this.http.get(this.baseUrl + 'deletefile/' + fileName)
        .pipe(
            retry(3), // retry a failed request up to 3 times
            catchError(this.handleError('getListFiles', []))

    );
    }
}
