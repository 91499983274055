import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position'
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
@Component({
    selector: 'position-table',
    templateUrl: './position.component.html'
   ,
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class PositionComponent implements OnInit {
    position: Position = {};

    positions: Position[];

   

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedPosition: Position;

    newPosition: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    category: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    canEdit: boolean = false;

    canAdd: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private positionService: PositionService, private fb: FormBuilder) {
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'position': new FormControl('', Validators.required),
            'category': new FormControl('', Validators.required),
            'fieldPosition': new FormControl('', Validators.required),
            'rate': new FormControl('', ),
        });
        this.getPositions();

        

        //this.employees.sort(this.dynamicSort("strLastname"));

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.category = [
            { label: 'Select One', value: null },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
            { label: 'D', value: 'D' },
            { label: 'Z', value: 'Z' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intPositionId', header: 'ID' },
            { field: 'strPosition', header: 'Position' },
            { field: 'strCategory', header: 'Category' },
            { field: 'ysnFieldPosition', header: 'Field Position' },
            { field: 'hourlyRate', header: 'Avg Rate' },

        ];
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => {
                this.positions = positions;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    
    addPosition(position): void {
        if (!position) { return; }
        this.positionService.addPosition(this.position)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updatePosition(): void {
        //let positions = [...this.positions];
        //positions[this.positions.indexOf(this.selectedPosition)] = this.position;
        //this.unitName = this.position.strPosition;
        //console.log(this.billingunittype);
        this.positionService.updatePosition(this.intUnitId, this.position)

            .subscribe(data => {
                //console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //let itemIndex = this.positions.findIndex(item => item.intPositionId== this.position.intPositionId);
                //this.positions[itemIndex] = this.position;
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newPosition = true;
        this.position = {};
        this.displayDialog = true;
    }

    cloneBillingCode(c: Position): Position {
        let bcode = {};
        for (let prop in c) {
            bcode[prop] = c[prop];
        }
        return bcode;
    }

    cancel() {
        this.displayDialog = false;
        this.position = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Position Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Position Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intPositionId;
        this.newPosition = false;
        this.position = this.cloneBillingCode(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
        this.userform.get('position').setValue(this.position.strPosition);
        this.userform.get('category').setValue(this.position.strCategory);
        this.userform.get('fieldPosition').setValue(this.position.ysnFieldPosition);
        this.userform.get('rate').setValue(this.position.hourlyRate);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.position.strPosition= this.userform.get('position').value;
        this.position.strCategory = this.userform.get('category').value;
        this.position.ysnFieldPosition = this.userform.get('fieldPosition').value;
        this.position.hourlyRate = this.userform.get('rate').value;

        let positions = [...this.positions];

        this.unitName = this.position.strPosition;
        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newPosition) {
            this.addPosition(this.position);
            positions.push(this.position);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updatePosition();
            //positions[this.positions.indexOf(this.selectedPosition)] = this.position;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }
        //this.positions = positions;
        this.position = {};
        this.submitted = false;
        this.userform.reset();
        this.displayDialog = false;
    }
}
