import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Equipment } from '../domain/equipment';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class EquipmentService {
    delay: Observable<Equipment[]>;
    newequipment: Equipment;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('EquipmentService');
    }
    baseUrl: string = 'api/Equipments/';
    errorUrl: string = 'http://localhost:5243/api/Equipments/';

    getEquipments(): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEquipments', []))

            );
    }

    getEquipmentbyStatus(status): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(this.baseUrl + 'activefilter/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getActiveStatus', []))

            );
    }

  

    addEquipment(equipment: Equipment): Observable<Equipment> {
        return this.http.post<Equipment>(this.baseUrl, equipment)
            .pipe(
                catchError(this.handleError('addEquipment', equipment))
            );
    }

    updateEquipment(id, equipment: Equipment): Observable<Equipment> {
        return this.http.put(this.baseUrl + id, equipment)
            .pipe(
                tap(_ => console.log(`updated Equipment id=${id}`)),
                catchError(this.handleError('updateEquipment', equipment))
            );
    }

    deleteEquipment(id): Observable<Equipment> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted System Delay id=${id}`)),
                catchError(this.handleError('DeletedProductinDelay'))
            );
    }

    getActiveEquipmentId(): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(this.baseUrl + 'activeequipment/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDfrbyID', []))

            );
    }
}
