import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer, EventEmitter, Output } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem, ConfirmationService, Message } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { FileUploadModule } from 'primeng/fileupload';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { FsclyrwksService } from '../service/fsclyrwksservice';
import { fsclyrwks } from '../domain/fsclyrwks';
@Component({
    selector: 'excel-import',
    templateUrl: './excelimport.component.html',

    providers: [MessageService, ConfirmationService]

})
export class ExcelImportComponent implements OnInit {

    arrayBuffer: any;
    file: File;
    uploadedFiles: any[] = [];
    import: any[];
    uploadedFile: File;
    value: number = 0;

    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolPurchase: LinemanToolPurchase = {};

    fiscalPeriod: fsclyrwks = {};

    fiscalPeriods: fsclyrwks[];

    toolbankDate: Date;

    msgs: Message[] = [];

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,       
        private fb: FormBuilder,
        private _dateService: DateService,        
        private renderer: Renderer,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private fiscalwkyrService: FsclyrwksService,
        private confirmationService: ConfirmationService

        ) {
    }

   

    ngOnInit() {
        this.toolbankDate = this.getNextDayOfWeek(new Date(new Date().setDate(new Date().getDate())), 0);
    }

    /**
     * Returns the date of the next day. If today is friday and we are asking for next friday the friday of the next week is returned.
     * @param dayOfWeek 0:Su,1:Mo,2:Tu,3:We,4:Th,5:Fr,6:Sa
     */
    getNextDayOfWeek(date, dayOfWeek) {
    var resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (-7 + dayOfWeek - date.getDay() - 1) % 7 + 1);
    return resultDate;

    }

    confirmToolBankUpload() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
                this.Upload();
            },
            reject: () => {
                this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
            }
        });
    }


    addToolPurchase(toolpurchase): void {
        if (!toolpurchase) { return; }
        this.linemanToolPurchaseService.addToolPurchase(this.linemanToolPurchase)
            .subscribe(data => {
                //this.showSuccessViaToast();
                //customers.push(this.customer);
                
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    uploadImport(tooldeposits) {
        if (!tooldeposits) { return; }
        this.linemanToolPurchaseService.uploadImport(this.linemanToolPurchases)
            .subscribe(data => {
                //this.showSuccessViaToast();
                //customers.push(this.customer);

                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    addFiscalWeek(week): void {
        if (!week) { return; }
        this.fiscalwkyrService.addfiscalperiod(week)
            .subscribe(data => {
                //this.showSuccessViaToast();
                //customers.push(this.customer);

                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    async uploadFile(fiscalweek) {
        const uploadfile = this.addFiscalWeek(fiscalweek);
        await uploadfile;
        return;
    }

    async uploadToolBankFile(line) {
        const uploadfile = this.addToolPurchase(line);
        await uploadfile;
        return;
    }

    async UploadFiscalPeriods() {
        if (Array.isArray(this.import) && (this.import.length)) {
            let sqlimport = [...this.import];
            var count = 0

            for (const imp of this.import) {
                this.fiscalPeriod.fsclyr = imp['Year'];
                this.fiscalPeriod.fsclmthwknbr = imp['Week_No'];
                this.fiscalPeriod.wkbgndt = imp['sql_startdate'];
                this.fiscalPeriod.wkenddt = imp['sql_enddate'];
                this.fiscalPeriod.fsclprdnbr = imp['Month'];
                this.fiscalPeriod.fsclyrwknbr = imp['fscl_week'];
                //this.linemanToolPurchase.dtmDate = this._dateService.toString(new Date(new Date().setDate(new Date().getDate())));
                count += 1
                this.uploadFile(this.fiscalPeriod);
                await new Promise(resolve => setTimeout(resolve, 200)); // .2 sec
                //this.addFiscalWeek(this.fiscalPeriod);
                this.value = Math.round(count / sqlimport.length) * 100;
                //console.log(this.value);
                //console.log(i);
                if (this.value >= 100) {
                    this.value = 100;
                    this.service.add({ severity: 'info', summary: 'File Uploaded', detail: 'Process Completed' });
                }

            }

            


        }
    }

    async  Upload() {
        
        let toolbankdate
        if (this.toolbankDate) {
            toolbankdate = this.toolbankDate;
            
        } else {
            
            toolbankdate = new Date(new Date().setDate(new Date().getDate()));
        }

        if (Array.isArray(this.import) && (this.import.length)) {
            let sqlimport = [...this.import];
            var count = 0

            for (const imp of this.import) {
                this.linemanToolPurchase.intEmployee = imp['Employee Id'];
                this.linemanToolPurchase.curDeposit = imp['PST Total Record Tools Amount'];
                this.linemanToolPurchase.intTxtype = '1';
                this.linemanToolPurchase.dtmDate = this._dateService.toString(new Date(toolbankdate));
                count += 1
                //this.addToolPurchase(this.linemanToolPurchase);
                this.uploadToolBankFile(this.linemanToolPurchase);
                await new Promise(resolve => setTimeout(resolve, 800)); // .5 sec
                this.value = Math.round(count / sqlimport.length) * 100;
                //console.log(this.value);
                //console.log(i);
                if (this.value >= 100) {
                    this.value = 100;
                    //this.service.add({ severity: 'info', summary: 'File Uploaded', detail: 'Process Completed' });
                }
               
            }
            this.msgs = [{ severity: 'info', summary: 'File Uploaded', detail: 'Process Complete' }];
            //this.onClear();
            
        }
       
        //let fileReader = new FileReader();
        //fileReader.onload = (e) => {
        //    this.arrayBuffer = fileReader.result;
        //    var data = new Uint8Array(this.arrayBuffer);
        //    var arr = new Array();
        //    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //    var bstr = arr.join("");
        //    var workbook = XLSX.read(bstr, { type: "binary" });
        //    var first_sheet_name = workbook.SheetNames[0];
        //    var worksheet = workbook.Sheets[first_sheet_name];
        //    //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
        //    this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        //}
        //fileReader.readAsArrayBuffer(this.file);
    }

    incomingfile(event) {
        this.value = 0;
        this.import = [];
         this.file = event.files[0];        

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];           
            this.import = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    onRemove() {
        this.import = [];
    }

    onClear() {
        this.import = [];
    }

   

    
}
