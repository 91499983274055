import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Proficiency } from '../domain/proficiency';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ProficiencyService {
    positions: Observable<Proficiency[]>;
    newproficiency: Proficiency;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ProficiencyService');
    }
    baseUrl: string = 'api/Proficiencies/';
    errorUrl: string = 'http://localhost:5243/api/Positions/';

    getProficiencies(): Observable<Proficiency[]> {
        return this.http.get<Proficiency[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getPosition', []))

            );
    }

    addProficiency(proficiency: Proficiency): Observable<Proficiency> {
        return this.http.post<Proficiency>(this.baseUrl, proficiency)
            .pipe(
                catchError(this.handleError('addPosition', proficiency))
            );
    }

    updateProficiency(id, proficiency: Proficiency): Observable<Proficiency> {
        return this.http.put(this.baseUrl + id, proficiency)
            .pipe(
                tap(_ => console.log(`updated position id=${id}`)),
                catchError(this.handleError('updatePosition', proficiency))
            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
