import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CustomerGroupInvoice } from '../domain/customergroupinvoice ';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CustomerGroupInvoiceService {
    customerInvoice: Observable<CustomerGroupInvoice[]>;
    newCustomerInvoice: CustomerGroupInvoice;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CustomerGroupInvoice');
    }
    baseUrl: string = 'api/CustomerGroupInvoices/';
    errorUrl: string = 'http://localhost:5243/api/CustomerGroupInvoices/';

    getCustomerInvoices(): Observable<CustomerGroupInvoice[]> {
        return this.http.get<CustomerGroupInvoice[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCustomerGroupInvoices', []))

            );
    }

    getGroupInvoicesbyId(id): Observable<CustomerGroupInvoice[]> {
        return this.http.get<CustomerGroupInvoice[]>(this.baseUrl + 'getGroupInvoicesbyId/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getWoInvoices', []))

            );
    }

    getGroupInvoicesbyCustomerId(id): Observable<CustomerGroupInvoice[]> {
        return this.http.get<CustomerGroupInvoice[]>(this.baseUrl + 'getGroupInvoicesbyCustomerId/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('Get Group Invoiced by Cust Id', []))

            );
    }

    addCustomerInvoice(customerInvoice: CustomerGroupInvoice): Observable<CustomerGroupInvoice> {
        return this.http.post<CustomerGroupInvoice>(this.baseUrl, customerInvoice)
            .pipe(
                catchError(this.handleError('addCustomerInvoice', customerInvoice))
            );
    }

    updateCustomerInvoice(id, customerInvoice: CustomerGroupInvoice): Observable<CustomerGroupInvoice> {
        return this.http.put(this.baseUrl + id, customerInvoice)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateCustomer', customerInvoice))
            );
    }

    deleteCustomerInvoice(id): Observable<CustomerGroupInvoice> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Invoice id=${id}`)),
                catchError(this.handleError('Delete Customer Invoice'))
            );
    }
}
