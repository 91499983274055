import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { EmployeeCounseling } from '../domain/employeecounseling';
import { ProgressiveIncident } from '../domain/progressiveincident';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { EmployeeCounselingService } from '../service/employeecounselingservice';
import { ProgressiveIncidentService } from '../service/progressiveincidentservice';
import { PrimeTemplate } from 'primeng/primeng';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';



@Component({
    selector: 'employeecounseling-table',
    templateUrl: './employeecounseling.component.html',

    providers: [MessageService]
})
export class EmployeeCounselingComponent implements OnInit {
    employeeCounselings: EmployeeCounseling[];

    employeeCounseling: EmployeeCounseling = {};

    progressiveIncidents: ProgressiveIncident[];

    progressiveIncident: ProgressiveIncident = {};

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    cols: any[];

    txType: SelectItem[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedEmployeeId: string = "";

    yearFilter: number;

    yearTimeout: any;

    displayDialog: boolean = false;

    displayAddDialog: boolean = false;

    displayAddItemDialog: boolean = false;

    transactionDate: Date;

    newEmployeeCounseling: boolean = false;

    //isDeposit: boolean = true;

    private searchTerms = new Subject<string>();

    amount: number;

    formattedAmount: string;

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    displayReport: boolean = false;
   

    selectedReportName: string = null;



    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private _dateService: DateService,
        private employeeCounselingService: EmployeeCounselingService,
        private progressiveIncidentService: ProgressiveIncidentService,       
        private currencyPipe: CurrencyPipe,
        private supervisorService: SupervisorService,
        private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {

        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEmployeeId') {
                //this.onRowSelect(curVal);
                this.selectedEmployeeId = curVal;
                this.getEmployeeProgressiveCounselings(this.selectedEmployeeId)
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.getProgressiveIncidents();

        this.getSupervisorsList();
        //this.getRepMaintItems();

        

        //this.getActiveEmployees();

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.txType = [
            { label: 'Select One', value: null },
            { label: 'Warning', value: 'Warning' },
            { label: 'Suspension', value: 'Suspension' },
            //{ label: 'Probation', value: 'Probation' },
            //{ label: 'Seperation', value: 'Dismissal' },
            //{ label: 'Final', value: 'Final' },
            { label: 'Dismissal', value: 'Dismissal' },
            //{ label: 'Counseling', value: 'Counseling' },
            //{ label: 'Warning/Suspension', value: 'Warning/Suspension' },
            //{ label: 'Other', value: 'Other' },

        ];

        this.cols = [
            //{ field: 'EmployeeID', header: 'intEmployee' },
            { field: 'incidentDate', header: 'Date' },
            { field: 'actionTaken', header: 'Action' },
            { field: 'type', header: 'Incident Type' },
            //{ field: 'maintVendor', header: 'Description' },
            { field: 'supervisorRemarks', header: 'Remarks' },
            //{ field: 'curPurchaseOrderAmnt', header: 'PO Amount' },
            //{ field: 'strInvoiceNo', header: 'Invoice #' },
            //{ field: 'curInvoiceAmnt', header: 'Invoice Amount' },
            //{ field: 'curDeposit', header: 'Deposit Amount' },
            //{ field: 'strAdditionalComments', header: 'Comments' },

            //{ field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.employeeCounselingEdit == 1 || this.isAdmin) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.employeeCounselingAdd == 1 || this.isAdmin) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                
            });
    }

    getSupervisorsList(): void {
        this.supervisorService.getSupervisorsList()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('name'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEmployeeProgressiveCounselings(id): void {
        this.employeeCounselingService.getCounselingbyEmployeeId(id)
            .subscribe(counselings => this.employeeCounselings = counselings);
    }

    addProgressiveCounseling(item): void {
        if (!item) { return; }
        this.employeeCounselingService.addCounseling(this.employeeCounseling)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getEmployeeProgressiveCounselings(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addProgressiveIncident(incident): void {
        if (!incident) { return; }
        this.progressiveIncidentService.addProgressiveIncident(this.progressiveIncident)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getProgressiveIncidents();
                //this.getLinemanToolPurchases(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    getProgressiveIncidents(): void {
        this.progressiveIncidentService.getProgressiveIncidents()
            .subscribe(items => this.progressiveIncidents = items);

    }

   


    updateEmployeeCounseling(): void {
        //let contracts = [...this.contracts];
        //contracts[this.contracts.indexOf(this.selectedContract)] = this.customer;
        //this.contractName = this.customer.strCustomerName;
        this.employeeCounselingService.updateCounseling(this.employeeCounseling.progressiveCounselingId, this.employeeCounseling)

            .subscribe(data => {
                this.showSuccessUpdateViaToast();
                //this.showSuccessUpdateViaToast();
                this.getEmployeeProgressiveCounselings(this.selectedEmployeeId);
                //this.ngOnInit();

                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

   

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    showDialogToAdd() {
        this.employeeCounseling = {};
        this.employeeCounseling.employeeId = this.selectedEmployeeId;
        this.employeeCounseling.incidentDate = new Date(Date.now());
        //this.repairActivity.intTypeDesc = '1';
        this.newEmployeeCounseling = true;
        this.displayDialog = true;
    }

    showDialogToAddVendor() {
       
        this.displayAddDialog = true;
    }

    showDialogToAddItem() {

        this.displayAddItemDialog = true;
    }

    onSelect(value) {
        //const value = event.target.value;
        this.transactionDate = value;
        //console.log(this.transactionDate);
        this.employeeCounseling.incidentDate = new Date( this._dateService.toString(this.transactionDate));
    }

    onChange(event) {
        //console.log(event.value);
        //if (event.value == '1') {
        //    this.isDeposit = true;
        //} else if (event.value == '2') {
        //    this.isDeposit = false;
        //} else {
        //    this.isDeposit = null;
        //}
    }

    onCancel() {
        this.displayDialog = false;
        //this.isDeposit = true;
        this.employeeCounseling = {};
    }

    //onCancelAddVendor() {
    //    this.displayAddDialog = false;
    //    //this.isDeposit = false;
    //    this.repMaintVendor = {};
    //}

    //onCancelAddItem() {
    //    this.displayAddItemDialog = false;
    //    //this.isDeposit = false;
    //    this.repMaintItem = {};
    //}

    //onNewItemSave() {
    //    let items = [...this.repMaintItems];
    //    //this.contractName = this.contract.strContractName;
    //    //this.showSuccessUpdateViaToast();

    //    this.addRepMaintItem(this.repMaintItem);
    //    items.push(this.repMaintItem);

    //    //this.customerService.addCustomer(this.customer)
    //    //    .subscribe(data => {
    //    //        customers.push(this.customer);
    //    //        console.log(data);
    //    //        this.ngOnInit();
    //    //        this.showSuccessViaToast();
    //    //    });



    //    this.repMaintItems = items;
    //    this.repMaintItem = {};
    //    this.displayAddItemDialog = false;
    //    //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    //}

    //onNewVendorSave() {
    //    let vendors = [...this.repMaintVendors];
    //    //this.contractName = this.contract.strContractName;
    //    //this.showSuccessUpdateViaToast();
      
    //        this.addRepMainVendor(vendors);
    //        vendors.push(this.repMaintVendor);

    //        //this.customerService.addCustomer(this.customer)
    //        //    .subscribe(data => {
    //        //        customers.push(this.customer);
    //        //        console.log(data);
    //        //        this.ngOnInit();
    //        //        this.showSuccessViaToast();
    //        //    });
        
       

    //    this.repMaintVendors = vendors;
    //    this.repMaintVendor = {};
    //    this.displayAddDialog = false;
    //    //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    //}

    onSave() {
        let incident = [...this.employeeCounselings];
        const epochNow = (new Date).getTime();
        //console.log(epochNow);

        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
        if (this.newEmployeeCounseling) {
            this.addProgressiveCounseling(incident);
            incident.push(this.employeeCounseling);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            //this.repairActivity.mDateUpdated = epochNow;
            this.updateEmployeeCounseling();

            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.employeeCounselings = incident;
        this.employeeCounseling = {};
        this.displayDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    editPurchase(rowdata) {
        this.newEmployeeCounseling = false;
        this.employeeCounseling = this.clone(rowdata);
        this.employeeCounseling.actionTaken = this.employeeCounseling.actionTaken.trim();
        this.employeeCounseling.incidentDate= new Date(this.employeeCounseling.incidentDate);
        //if (this.linemanToolPurchase.intTxtype == '1') {
        //    this.isDeposit = true;
        //} else if (this.linemanToolPurchase.intTxtype == '2') {
        //    this.isDeposit = false;
        //} else {
        //    this.isDeposit = null;
        //}
        this.displayDialog = true;
    }

    clone(c: EmployeeCounseling): EmployeeCounseling {
        let purchase = {};
        for (let prop in c) {
            purchase[prop] = c[prop];
        }
        return purchase;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Employee Counseling Added', detail: 'Added' });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Employee Counseling Updated', detail: 'Updated' });
    }

    transformAmount(element) {
        this.amount = element.target.value.replace(/[$,]/g, "");
        this.formattedAmount = this.currencyPipe.transform(this.amount, '$')

        element.target.value = this.formattedAmount;
        //console.log(element.target)
        //if (element.target.name === "poamount") {
        //    this.repairActivity.curPurchaseOrderAmnt = this.amount
        //} else if (element.target.name === "invoiceamnt") {
        //    this.repairActivity.curInvoiceAmnt = this.amount

        //}
    }

    removeCurrencyPipeFormat(formatedNumber) {
        return formatedNumber.replace(/[$,]/g, "")
    }

    validateOnlyNumbers(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }

    printEmployeeCounseling() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;
        this.selectedReportName = "Employee/Employee Counseling by Employee Id.trdp";
        //console.log(this.selectedDfrId);
    }
}
