import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingUnitTypeService } from '../service/billingunittypeservice';
import { BillingUnitType } from '../domain/billingunittype'
import { BillingCodeInfratech } from '../domain/billingcodeinfratech'
import { BillingCodesInfratechService } from '../service/billingcodesinfratechservice';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule, ControlValueAccessor  } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'billingcode-table',
    templateUrl: './billingcodecustomer.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService, CurrencyPipe]
})
export class BillingCodeCustomerComponent implements OnInit {
    billingunittype: BillingUnitType = {};

    billingunittypes: BillingUnitType[];

    billingCodeInfratech: BillingCodeInfratech = {};

    billingCodesInfratech: BillingCodeInfratech[];

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    billingCode: string;

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedBillingUnitType: BillingUnitType;

    selectedBillingCode: BillingCodeCustomer;

    newBillingUnitType: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() canEditBillingCodes: boolean = false;

    amt: boolean;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private billingUnitTypeService: BillingUnitTypeService, private fb: FormBuilder,
        private billingCodeInfratechService: BillingCodesInfratechService, private billingCodeCustomerService: BillingCodesCustomerService, private currencyPipe: CurrencyPipe) {
    }



    ngOnInit() {
        this.userform = this.fb.group({
            'unittype': new FormControl('', Validators.required),
            'unitdescription': new FormControl('', Validators.required),
            'active': new FormControl('', Validators.required),
            'unit': new FormControl('', Validators.required),
            'unitprice': new FormControl('', { validators: Validators.required, updateOn: 'blur' }),
            'subprice': new FormControl('', {  updateOn: 'blur' }),
            'sub2price': new FormControl('', { updateOn: 'blur' }),
        });

       

        //this.userform.valueChanges.subscribe(val => {
        //    if (typeof val.unitprice === 'string') {
        //        const maskedVal = this.currencyPipe.transform(val.unitprice.replace(/[$,]/g, ""), '$');
        //        if (val.amount !== maskedVal) {
        //            this.userform.patchValue({ unitprice: maskedVal });
        //        }
        //    }
        //});

        //this.userform.valueChanges.subscribe(form => {
        //    if (form.unitprice) {
        //        this.userform.patchValue({
        //            unitprice: this.currencyPipe.transform(form.unitprice.replace(/\D/g, '').replace(/^0+/, ''), 'USD','symbol')
        //        }, { emitEvent: false });

        //    }
        //});
        this.getBillingUnitTypes();

        this.getBillingCodeInfratech();

        this.getBillingCodesCustomerbyContract(this.selectedContractId);

        //this.employees.sort(this.dynamicSort("strLastname"));

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'strCustomerBillingCode', header: 'Billing Code' },
            { field: 'strCustomerProductDesc', header: 'Billing Description' },
            { field: 'curUnitRate', header: 'Rate' },
            { field: 'curSubUnitRate', header: 'Sub Price' },
            { field: 'curSub2UnitRate', header: 'Sub2 Price' },
            //{ field: 'intInfratechBillingCode', header: 'Infratech Code' },
            { field: 'ysnActive', header: 'Active' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];
    }

    public get eoInfo() {
        if (this.userform) {
            return this.userform.controls;
        }
    }

    public transformAmount(element, name) {
        if (name == "unitprice") {
            var amountRawValue = this.userform.value.unitprice;
            if (!amountRawValue) {
                return;
            }
            var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
            //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
            let formattedAmount = amountDigitValue;
            element.target.value = formattedAmount;
            this.userform.get('unitprice').setValue(formattedAmount);
            if (this.userform.get('unitprice').status === 'VALID') {
                this.amt = false;
            }
            else {
                this.amt = true;
                var thenum = this.userform.value.unitprice.replace(/^\D+/g, '');
                //console.log(thenum)
            }
        }
        if (name == "subprice") {
            var amountRawValue = this.userform.value.subprice;
            if (!amountRawValue) {
                return;
            }
            var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
            //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
            let formattedAmount = amountDigitValue;
            element.target.value = formattedAmount;
            this.userform.get('subprice').setValue(formattedAmount);
            if (this.userform.get('subprice').status === 'VALID') {
                this.amt = false;
            }
            else {
                this.amt = true;
                var thenum = this.userform.value.subprice.replace(/^\D+/g, '');
                //console.log(thenum)
            }
        }
        if (name == "sub2price") {
            var amountRawValue = this.userform.value.sub2price;
            if (!amountRawValue) {
                return;
            }
            var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
            //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
            let formattedAmount = amountDigitValue;
            element.target.value = formattedAmount;
            this.userform.get('sub2price').setValue(formattedAmount);
            if (this.userform.get('sub2price').status === 'VALID') {
                this.amt = false;
            }
            else {
                this.amt = true;
                var thenum = this.userform.value.sub2price.replace(/^\D+/g, '');
                //console.log(thenum)
            }
        }
    }

    getBillingUnitTypes(): void {
        this.billingUnitTypeService.getBillingUnitTypes()
            .subscribe(billingunittypes => {
                this.billingunittypes = billingunittypes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getBillingCodeInfratech(): void {
        this.billingCodeInfratechService.getBillingCodesInfratech()
            .subscribe(billingcodes => {
                this.billingCodesInfratech = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getBillingCodesCustomerbyContract(id): void {
        this.billingCodeCustomerService.getBillingCodesbyContract(id)
            .subscribe(billingcodes => {
                this.billingCodesCustomer = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getBillingCodesCustomerbyCustomer(id): void {
        this.billingCodeCustomerService.getBillingCodesbyCustomer(id)
            .subscribe(billingcodes => {
                this.billingCodesCustomer = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    addBillingCodeCustomer(billingcode): void {
        if (!billingcode) { return; }
        this.billingCodeCustomerService.addBillingCodeCustomer(this.billingCodeCustomer)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updateBillingCodeCustomer(): void {
        let billingcodes = [...this.billingCodesCustomer];
        billingcodes[this.billingCodesCustomer.indexOf(this.selectedBillingCode)] = this.billingCodeCustomer;
        this.unitName = this.billingCodeInfratech.strInfratechCode;
        //console.log(this.billingunittype);
        this.billingCodeCustomerService.updateBillingCodeCustomer(this.intUnitId, this.billingCodeCustomer)

            .subscribe(data => {
                //console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newBillingUnitType = true;
        this.billingCodeCustomer = {};
        this.displayDialog = true;
    }

    cloneBillingCode(c: BillingCodeCustomer): BillingCodeCustomer {
        let bcode = {};
        for (let prop in c) {
            bcode[prop] = c[prop];
        }
        return bcode;
    }

    cancel() {
        this.billingCodeCustomer = {};
        this.userform.reset();
        this.displayDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Billing Code Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Billing Code Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intCustomerBillingCodesKey;
        this.newBillingUnitType = false;
        this.billingCodeCustomer = this.cloneBillingCode(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
        this.userform.get('unittype').setValue(this.billingCodeCustomer.strCustomerBillingCode);
        this.userform.get('unitdescription').setValue(this.billingCodeCustomer.strCustomerProductDesc);
        this.userform.get('active').setValue(this.billingCodeCustomer.ysnActive);
        this.userform.get('unit').setValue(this.billingCodeCustomer.intInfratechBillingCode);
        this.userform.get('unitprice').setValue(this.billingCodeCustomer.curUnitRate);
        this.userform.get('subprice').setValue(this.billingCodeCustomer.curSubUnitRate);
        this.userform.get('sub2price').setValue(this.billingCodeCustomer.curSub2UnitRate);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        let billingcode = [...this.billingCodesCustomer];
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.billingCodeCustomer.strCustomerBillingCode = this.userform.get('unittype').value;
        this.billingCodeCustomer.strCustomerProductDesc = this.userform.get('unitdescription').value;
        this.billingCodeCustomer.ysnActive = this.userform.get('active').value;
        this.billingCodeCustomer.intInfratechBillingCode = this.userform.get('unit').value;
        this.billingCodeCustomer.curUnitRate = this.userform.get('unitprice').value;
        this.billingCodeCustomer.curSubUnitRate = this.userform.get('subprice').value;
        this.billingCodeCustomer.curSub2UnitRate = this.userform.get('sub2price').value;
        this.billingCodeCustomer.intContractKey = this.selectedContractId;
        this.billingCodeCustomer.intCustomer = this.selectedCustomerId;

        this.billingCode = this.billingCodeCustomer.strCustomerBillingCode;
        let existItem = this.billingCodesCustomer.filter(item => item.strCustomerBillingCode === this.billingCodeCustomer.strCustomerBillingCode);
        if (this.newBillingUnitType) {
            if (existItem.length >= 1) {
                this.service.add(
                    { key: 'tst', severity: 'warn', summary: 'Duplicate Billing Code', detail: this.billingCode });
            } else {
                this.addBillingCodeCustomer(this.billingCodeCustomer);
                billingcode.push(this.billingCodeCustomer);
               
                this.submitted = false;
                this.userform.reset();
                this.displayDialog = false;
                this.billingCodeCustomer = {};
            }
            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            //console.log(this.billingCodeCustomer);
            this.updateBillingCodeCustomer();
            this.submitted = false;
            this.userform.reset();
            this.displayDialog = false;
            this.billingCodeCustomer = {};
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

       
    }

  
}
