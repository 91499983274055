import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { CrewMemberService } from '../service/crewmembersservice';
import { CrewMember } from '../domain/crewmembers';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { CrewComponent } from '../crew-form/crew.component';

@Component({
    selector: 'crewmembers',
    templateUrl: './crewmember.component.html',

    providers: [MessageService]
})
export class CrewMemberComponent implements OnInit {

    cols: any[];

    contactType: any[];

    crewmembers: CrewMember[];

    crewmember: CrewMember = {};

    employees: Employee[];

    employee: Employee = {};

    sourceEmployees: Employee[];

    targetEmployees: Employee[];

    selectedCrewMember: CrewMember;

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedCrewId: string = "";

    yearFilter: number;

    yearTimeout: any;

    displayDialog: boolean = false;

    newCrewMember: boolean = false;

    crewMemberId: string;

    crewName: string;

    selectedEmployees: any[];

    selectedCrewMembers: any[];

    selectedCrewMemberId: string;

    selectedMember: any[];

    showDeleteToast: boolean = false;

    clonedSourceEmployees: Employee[];

    

   

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private crewmemberservice: CrewMemberService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private employeeService: EmployeeService) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);
            this.selectedCrewMembers = null;
            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedCrewId') {
                //this.onRowSelect(curVal);
                this.selectedCrewId = curVal;
                this.getCrewMembersbyCrew(this.selectedCrewId);
                this.crewmembers = [];
                //console.log(this.selectedCrewId);                            
            }
           
        }
                
    }

    ngOnInit() {        

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];


        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];



        this.cols = [
            { field: 'strLastName', header: 'Last Name' },
            { field: 'strFirstName', header: 'First Name'},
            { field: 'position', header: 'Position' },
            
        ];

        this.contactType = [
            { label: 'Accounting', value: '1' },
            { label: 'Sales', value: '2' },
            { label: 'Engineering', value: '3' },
            { label: 'Executive', value: '4' },
            { label: 'Other', value: '5' },

        ];

        this.getEmployeesbyStatus();

        this.targetEmployees = [];


    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.sourceEmployees = employees.sort(this.dynamicSort('strLastName')));
    }

    getEmployeeById(id): void {
        if (id) {            
            this.employeeService.getEmployeesbyID(id)
                .subscribe(employees => {
                    this.employees = employees;
                    if (this.crewmembers.length > 0) {
                        this.employee = employees.find(e => e.strEmployeeId == id);
                        this.selectedCrewMembers.push(this.employee);

                    }

                });
        }

      
    }  

    getCrewMembersbyCrew(id): void {
        if (id) {
            this.crewmemberservice.getCrewMemberbyCrewId(id)
                .subscribe(crewmembers => {
                   this.crewmembers = crewmembers;
                    if (this.crewmembers.length > 0) {
                        this.crewmember = crewmembers.find(cm => cm.strCrewId == this.selectedCrewId);
                        this.selectedCrewMemberId = this.crewmember.intCrewMemberId;
                        this.selectedEmployees = this.crewmember.strCrewMember.split('#');
                        this.selectedCrewMembers = this.pushCrewMembers(this.selectedEmployees)
                        this.newCrewMember = false;
                    } else {
                        this.newCrewMember = true;
                    }
                });
        } 
        

       
        //console.log(this.selectedCrewMemberId);
    }

   

    edit(rowdata) {
        this.crewMemberId = rowdata.intCrewMemberId;

        //this.newCrewMember = false;

        this.crewmember = this.clone(rowdata);

        this.displayDialog = true;

        this.selectedEmployees = this.crewmember.strCrewMember.split('#');

       
       

    }

    clone(c: CrewMember): CrewMember {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.crewmember = {};
        this.targetEmployees = [];
    }


    updateCrewMember(): void {
        let crewmembers = [...this.crewmembers];
        crewmembers[this.crewmembers.indexOf(this.selectedCrewMember)] = this.crewmember;        
        this.crewmemberservice.updateCrewMember(this.selectedCrewMemberId, this.crewmember)       
            .subscribe(data => {
                if (this.showDeleteToast) {
                    this.showDeletedCrewMemberToast();
                    
                } else {
                    this.showSuccessUpdateViaToast()
                }
                this.getCrewMembersbyCrew(this.selectedCrewId);

            });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Members Update', detail: '' });
    }

    showDeletedCrewMemberToast() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Crew Member Deleted', detail: '' });
    }

    addCrewMembers(crewmember): void {
        if (!crewmember) { return; }
        //console.log(this.crewmember);
        this.crewmemberservice.addCrewMember(this.crewmember)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessUpdateViaToast();
                this.getCrewMembersbyCrew(this.selectedCrewId);
            });
        
        //console.log(this.selectedCrewId);
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Location Added', detail: 'Updated' });
    }

    showDialogToAdd() {
        this.clonedSourceEmployees = this.sourceEmployees;
        //this.getEmployeesbyStatus();
        this.targetEmployees = [];
        //this.newCrewMember = true;
        this.addExistingCrewMemberstoPicklist()
        //this.crewmember = {};
        this.displayDialog = true;
        this.showDeleteToast = false;
    }

   pushCrewMembers(filteredemployees: any[]) {
       
       let filtered: any[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            this.getEmployeeById(employee);
            
        }
        return filtered;
    }

   
    delete(row) {
        this.showDeleteToast = true;
        let index = this.selectedCrewMembers.indexOf(row);
        this.selectedCrewMembers = this.selectedCrewMembers.filter((val, i) => i != index);
        //console.log(this.selectedCrewMembers);
       
        //console.log(this.crewmembers);
        let updatedcrew="";
        this.selectedCrewMembers.forEach(member => {
            //console.log(member.strEmployeeId);            
            updatedcrew += member.strEmployeeId + "#";
            //console.log(updatedcrew)
        })
       
        this.crewmembers.find(c => c.strCrewId==this.selectedCrewId).strCrewMember = updatedcrew
        //console.log(this.crewmembers);
        this.updateCrewMember();
    }

    addExistingCrewMemberstoPicklist() {
        //console.log(this.selectedCrewMembers.length);
        if (this.selectedCrewMembers) {
            let updatedcrew = "";
            this.newCrewMember = false;
            this.selectedCrewMembers.forEach(member => {
                this.targetEmployees.push(member);
                
            })
        }
        else {
            //this.showSuccessViaToast();
            this.newCrewMember = true;
        }
    }

    save() {
        //let crewmembers = [...this.crewmembers];
        let targetedemployees = [...this.targetEmployees];
        let newcrewmembers = "";
        targetedemployees.forEach(employee => {
            newcrewmembers += employee.strEmployeeId + "#";
            //console.log(newcrewmembers);
        })
       
        //console.log(this.newCrewMember);
        if (this.newCrewMember) {
            this.crewmember = {};
            this.crewmember.strCrewId = this.selectedCrewId;
            this.crewmember.strCrewMember = newcrewmembers;
            this.crewmember.dtmDateCreated = new Date();
            this.addCrewMembers(this.crewmember);
            
            }
        else {
            //console.log(this.crewmember);
            this.crewmembers.find(c => c.strCrewId == this.selectedCrewId).strCrewMember = newcrewmembers;
            this.updateCrewMember();
                
        }
        //console.log(this.selectedCrewId);
     
       
        this.displayDialog = false;
        this.targetEmployees = [];
    }

    onRowSelect(event) {
       
        this.selectedMember = this.cloneMember(event.data);
        //this.displayDialog = true;
    }

    cloneMember(c: any): any {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

}

