import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectItem, MenuItem, AutoCompleteModule, AutoComplete } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { DialogService } from 'primeng/api';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { invoicepayments  } from '../domain/invoicepayments'
import { InvoicePaymentsServices } from '../service/invoicepayments';
@Component({
    encapsulation: ViewEncapsulation.None,

    selector: 'invoice-payments',
    templateUrl: './invoicepaymentlist.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class InvoicePaymentListComponent implements OnInit {

    payments: invoicepayments[];

    payment: invoicepayments = {};

    intJobId: number;

    invoiceNumber: string;

    sumofPayments: number;

    invoiceTotal: number;





    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private fb: FormBuilder, private paymentservice: InvoicePaymentsServices,
        private _dateService: DateService, private currencyPipe: CurrencyPipe, public ref: DynamicDialogRef, public config: DynamicDialogConfig ) {
    }

    ngOnInit() {
        //console.log(this.config.data.invoicenum);
        this.invoiceTotal = this.config.data.invoiceamount;
        this.getInvoicePayments(this.config.data.id, this.config.data.invoicenum);
    }


    getInvoicePayments(id, invoicenum): void {
        this.paymentservice.getInvoicePaymentbyInvoiceNum(id, invoicenum)
            .subscribe(payments => {
                this.payments = payments;
                this.addTotal();
            });
    }

    deletePaymentbyId(id): void {
        this.paymentservice.deleteInvoicePayment(id)
            .subscribe();
        this.addTotal();
    }

    addTotal() {
        let myArray = this.payments

        this.sumofPayments = 0;
       
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].paymentAmount) {
                this.sumofPayments += (myArray[i].paymentAmount);
                
            }
        }

        //this.costTotal.emit(this.sumBillableProductions);
    }

    deletePayment(payment: invoicepayments) {
        this.deletePaymentbyId(payment.paymentId);
        this.ref.close(payment);
    }

    selectPayment(payment: invoicepayments) {
        this.ref.close(payment);
    }
}
