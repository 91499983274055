import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import {  SelectItem } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { TaskService } from '../service/taskservice';
import { Task } from '../domain/task';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { DailyFieldReport } from '../domain/dailyfieldreport'
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';

@Component({
    selector: 'bp-table',
    templateUrl: './bptable.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class BpTableComponent implements OnInit {
    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    wo: Job = {};

    wos: Job[];

    task: Task = {};

    tasks: Task[];

    crews: Crew[];

    crew: Crew = {};

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    invoiceDetails: boolean = false;

    selectedColumns: any[];

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private taskService: TaskService, private crewService: CrewService,
        private dfrService: DailyFieldReportService, private billableproductionService: BillableProductionService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.selectedWoId = curVal;
                this.getProductionbyWoId(this.selectedWoId);
                //console.log(this.selectedWoId);
            }

            if (propName === 'selectedASupervisor') {
                //this.onRowSelect(curVal);
                this.selectedASupervisor = curVal;
                //console.log(this.selectedASupervisor);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl(''),
            'taskowner': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
        });

        this.getCrews();

        this.getEmployeesbyStatus();

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'dfrdate', header: 'DFR Date', display: true, width: '8%' },
            { field: 'intReportId', header: 'DFR #', display: true, width: '7%' },
            { field: 'crew', header: 'Crew', display: true, width: '5%' },
            { field: 'bcode', header: 'BC', display: true, width: '10%' },
            { field: 'bcdescription', header: 'Description', display: true, width: '33%' },
            { field: 'intQty', header: 'Qty', display: true, width: '8%' },
            { field: 'strConstPt1', header: 'CPoint 1', display: true, width: '10%' },
            { field: 'strConstPt2', header: 'CPoint 2', display: true, width: '10%' },
            { field: 'strDetails', header: 'Comments', display: true, width: '9%' },
            { field: 'strStatus', header: 'Status', display: this.invoiceDetails, width: '0%' },
            { field: 'strInvoiceNum', header: 'Invoice #', display: this.invoiceDetails, width: '0%' },
            { field: 'dtmInvoiceDate', header: 'Invoice Date', display: this.invoiceDetails, width: '0%' },

        ];

        this.selectedColumns = this.cols;
    }

    onSelect(value) {
        //const value = event.target.value;
        this.dateReceived = value;
        //console.log(element.getAttribute('formControlName'));
        this.task.dtmStartDate = this._dateService.toString(this.dateReceived);
    }

    getProductionbyWoId(id): void {
        this.billableproductionService.getBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getCrews(): void {
        this.crewService.getCrewsNoLegacy()
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    addTask(task): void {
        if (!task) { return; }
        this.taskService.addTask(this.task)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    this.getProductionbyWoId(this.selectedWoId);
                    this.showSuccessViaToast();
                });
    }

    updateTask(): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        this.unitName = this.task.strTaskName;
        //console.log(this.billingunittype);
        this.taskService.updateTask(this.intUnitId, this.task)

            .subscribe(data => {
                //console.log(data);
                this.getProductionbyWoId(this.selectedWoId);
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newTask = true;
        this.wo = {};
        this.displayDialog = true;
    }

    clone(c: Task): Task {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.wo = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intTaskId;

        this.newTask = false;
        this.task = this.clone(rowdata);

        //this.insertASupertoWoSuper(this.wo.intSupervisor);

        //console.log(this.selectedASupervisor);
        this.displayDialog = true;
        //this.userform.get('supervisor').setValue(this.task.supervisor);
        this.userform.get('crew').setValue(this.task.intCrewId);
        this.userform.get('foreman').setValue(this.task.intForeman);
        this.userform.get('task').setValue(this.task.strTaskName);
        this.userform.get('taskstatus').setValue(this.task.strStatus);
        this.userform.get('tasknote').setValue(this.task.strTaskDes);
        this.userform.get('taskowner').setValue(this.task.intTaskOwner);
        if (this.task.dtmStartDate !== null) {
            this.userform.get('datestart').setValue(new Date(this.task.dtmStartDate));
        }

        if (this.task.strStatus == 'In-Process') {
            this.userform.get('taskstatus').setValue('Inprocess');
        } else if (this.task.strStatus == 'InProcess') {
            this.userform.get('taskstatus').setValue(this.task.strStatus);
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;

        this.task.intCrewId = this.userform.get('crew').value;
        this.task.intForeman = this.userform.get('foreman').value;
        this.task.strTaskName = this.userform.get('task').value;
        this.task.strStatus = this.userform.get('taskstatus').value;
        this.task.strTaskDes = this.userform.get('tasknote').value;
        this.task.intTaskOwner = this.userform.get('taskowner').value;
        this.task.dtmStartDate = this.userform.get('datestart').value;

        if (this.newTask) {
            this.task.intJobId = this.selectedWoId;
            this.task.intSupervisorId = this.selectedASupervisor;
            this.addTask(this.task);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateTask();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.displayDialog = false;
        this.submitted = false;
        this.userform.reset();
        this.task = {};
    }

    filterWoSupervisor(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intReportingSupervisorId == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    filterSupervisorId(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intSupervisorKey == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    onChangeCrew(event) {
        this.crewId = event.value;

        this.crew = this.crews.find(c => c.intCrewKey == this.crewId);

        this.foremanId = this.crew.intForeman;

        this.userform.get('foreman').setValue(this.foremanId);

        this.crewNumber = this.crew.strCrewId;

        if (this.crewNumber.startsWith("5")) {
            //this.userform.get('task').setValue('Craft-Work');
            if (this.task.strTaskName !== 'Craft-Work') {
                this.taskDropdown = 'Craft-Work';
                this.service.clear();
                this.service.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Change Task to Craft-Work for 50xx crew type?', detail: 'Confirm to proceed' });
            }
        } else if (this.crewNumber.startsWith("7")) {
            this.taskDropdown = 'Placement';
            this.service.clear();
            this.service.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Change Task to Placement for 70xx crew type?', detail: 'Confirm to proceed' });
        }
    }

    onConfirm() {
        this.service.clear('c');
        if (this.taskDropdown === 'Craft-Work') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        } else if (this.taskDropdown === 'Placement') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        }
    }

    onReject() {
        this.service.clear('c');
    }

    handleChange(e) {
        let isChecked = e.checked;
        if (isChecked == true) {
            this.invoiceDetails = true;
            this.cols = [
                { field: 'dfrdate', header: 'DFR Date', display: true, width: '8%' },
                { field: 'intReportId', header: 'DFR #', display: true, width: '7%' },
                { field: 'crew', header: 'Crew', display: true, width: '5%' },
                { field: 'bcode', header: 'BC', display: true, width: '9%' },
                { field: 'bcdescription', header: 'Description', display: true, width: '15%' },
                { field: 'intQty', header: 'Qty', display: true, width: '7%' },
                { field: 'strConstPt1', header: 'CPoint 1', display: true, width: '9%' },
                { field: 'strConstPt2', header: 'CPoint 2', display: true, width: '9%' },
                { field: 'strDetails', header: 'Comments', display: true, width: '10%' },
                { field: 'strStatus', header: 'Status', display: this.invoiceDetails, width: '6%' },
                { field: 'strInvoiceNum', header: 'Invoice #', display: this.invoiceDetails, width: '8%' },
                { field: 'dtmInvoiceDate', header: 'Invoice Date', display: this.invoiceDetails, width: '8%' },

            ];
        } else {
            this.invoiceDetails = false;
            this.cols = [
                //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
                { field: 'dfrdate', header: 'DFR Date', display: true, width: '8%' },
                { field: 'intReportId', header: 'DFR #', display: true, width: '7%' },
                { field: 'crew', header: 'Crew', display: true, width: '5%' },
                { field: 'bcode', header: 'BC', display: true, width: '10%' },
                { field: 'bcdescription', header: 'Description', display: true, width: '33%' },
                { field: 'intQty', header: 'Qty', display: true, width: '8%' },
                { field: 'strConstPt1', header: 'CPoint 1', display: true, width: '10%' },
                { field: 'strConstPt2', header: 'CPoint 2', display: true, width: '10%' },
                { field: 'strDetails', header: 'Comments', display: true, width: '9%' },
                { field: 'strStatus', header: 'Status', display: this.invoiceDetails, width: '0%' },
                { field: 'strInvoiceNum', header: 'Invoice #', display: this.invoiceDetails, width: '0%' },
                { field: 'dtmInvoiceDate', header: 'Invoice Date', display: this.invoiceDetails, width: '0%' },

            ];
        }
    }
}
