import { Component, OnInit, ViewEncapsulation, ViewChild, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { SubContractorService } from '../service/subcontractorservice';
import { SubContractor } from '../domain/subcontractor';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
    templateUrl: './subcontractor.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class SubContractorComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    employees: Employee[];

    employee: Employee = {};

    subcontractors: SubContractor[];

    subcontractor: SubContractor = {};

    selectedSubContractor: SubContractor[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    cols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedEmployee: Employee;

    activeFilter: SelectItem[];

    perdiem: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    newEmployee: boolean;

    newSubcontractor: boolean;

    selectedActive: string = "Active";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    selectedEmployeeId: string = "";

    selectedRPId: string = "";

    userform: FormGroup;

    submitted: boolean;

    description: string;

    hireDate: Date;

    termDate: Date;

    rehireDate: Date;

    umbExpDate: Date;

    orientationDate: Date;

    benefits: SelectItem[];

    w9status: SelectItem[];

    minoritystatus: SelectItem[];

    viewRate: boolean = false;

    employeeName: string;

    newEmployeeId: string;

    subEmpty: boolean;

    displayEmployeeDetails: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    newAuth: boolean = false;

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private subcontractorService: SubContractorService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'firstname': new FormControl('', Validators.required),
            'lastname': new FormControl('', Validators.required),
            'rpname': new FormControl('', Validators.required),
            'notes': new FormControl(''),
            'active': new FormControl('', Validators.required),
            'position': new FormControl(''),
            'taxid': new FormControl('', Validators.required),
            'gldate': new FormControl(''),
            'wcdate': new FormControl(''),
            'benefit': new FormControl(''),
            'aidate': new FormControl(''),
            'email': new FormControl(''),
            'crew': new FormControl(''),
            'payrate': new FormControl(''),
            'orientationdone': new FormControl(''),
            'startdate': new FormControl(''),
            'fax': new FormControl(''),
            'sapvendor': new FormControl(''),
            'w9status': new FormControl(''),
            'minority': new FormControl(''),
            'customers': new FormControl(''),
            'address1': new FormControl(''),
            'city': new FormControl(''),
            'state': new FormControl('', Validators.maxLength(2)),
            'zip': new FormControl(''),
            'phone': new FormControl(''),
            'terms': new FormControl(''),
            'umbdate': new FormControl(''),
        });

        this.getSubContractors();

        this.getPositions();

        if (this.selectedActive == 'Active') {
            this.getEmployeesbyStatus('Active');
        } else if (this.selectedActive == 'InActive') {
            this.getEmployeesbyStatus('InActive');
        } else {
            this.getEmployees();
        }

        //this.getActiveEmployees();

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.benefits = [
            { label: 'Z', value: 5 },
        ];

        this.w9status = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.minoritystatus = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.perdiem = [
            { label: '$0', value: null },
            { label: '$10', value: '1' },
            { label: '$20', value: '2' },
            { label: '$30', value: '3' },
        ];

        this.cols = [
            { field: 'strEmployeeId', header: 'RP ID' },
            { field: 'strLastName', header: 'RP Name' },
            { field: 'strFirstName', header: 'Class' },
            { field: 'dtmHireDate', header: 'Hire Date' },
            { field: 'intPosition', header: 'Position' },
            { field: 'strCrew', header: 'Crew' },
            { field: 'ysnActive', header: 'Active' },
        ];
    }

    onRowUnselect() {
        //this.payrollHours = [];
        //this.linemanToolPurchases = [];
        this.displayEmployeeDetails = false;
    }

    onRowSelect(event) {
        this.selectedSubContractor = this.subcontractors;
        var cont = this.selectedSubContractor;
        if (event.data.strEmployeeId) {
            this.selectedEmployeeId = event.data.strEmployeeId;
            cont = cont.filter(v => v.intEmpId == this.selectedEmployeeId);

            this.selectedSubContractor = cont;
            this.displayEmployeeDetails = true;
        }
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getEmployeeAuth(id): void {
        this.userauthservice.getAuthId(id)
            .subscribe(auths => {
                this.userauths = auths

                this.userauth = auths.find(e => e.fkEmpId == id);
                //console.log(this.userauth);
                //console.log(this.userauths);
                if (this.userauth) {
                    this.userform.get('username').setValue(this.userauth.userId);
                    this.userform.get('password').setValue(this.userauth.password);
                    this.newAuth = false;
                } else {
                    this.newAuth = true;
                }
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.rpEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.rpCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }



            });
    }

    onSelect(value) {
        this.hireDate = value;
        this.subcontractor.insuranceDtForGl = this._dateService.toString(this.hireDate);
    }

    onTermSelect(value) {
        this.termDate = value;
        this.subcontractor.insuranceDtForWc = this._dateService.toString(this.termDate);
    }

    onRehireSelect(value) {
        this.rehireDate = value;
        this.subcontractor.insuranceDtForAi = this._dateService.toString(this.rehireDate);
    }

    onUmbSelect(value) {
        this.umbExpDate = value;
        this.subcontractor.umbExpirationDt = this._dateService.toString(this.umbExpDate);
    }

    onOrientSelect(value) {
        this.orientationDate = value;
        this.subcontractor.startDate = this._dateService.toString(this.orientationDate);
    }

    cancel() {
        this.displayEmployeeDialog = false;
        this.employee = {};
        this.userform.reset();
        this.subcontractor = {};
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.employee.strLastName = this.userform.get('lastname').value;
        this.employee.strFirstName = this.userform.get('firstname').value;
        this.employee.txtNotes = this.userform.get('notes').value;
        this.employee.ysnActive = this.userform.get('active').value;
        this.employee.intPosition = this.userform.get('position').value;
        //this.employee.strPerDiemType = this.userform.get('perdiem').value;
        this.employee.intBenefits = this.userform.get('benefit').value;
        this.employee.strEmail = this.userform.get('email').value;
        this.employee.strCrew = this.userform.get('crew').value;
        this.employee.intPayRate = this.userform.get('payrate').value;
        this.employee.sapvendorNum = this.userform.get('sapvendor').value;
        this.employee.address1 = this.userform.get('address1').value;
        this.employee.city = this.userform.get('city').value;
        this.employee.state = this.userform.get('state').value;
        this.employee.zipCode = this.userform.get('zip').value;
        if (this.userform.get('phone').value) {
            //console.log(this.userform.get('phone').value);
            this.employee.phone = this.userform.get('phone').value.replace(/\D/g, '');
        } else {
            this.employee.phone = null;
        }
        this.subcontractor.strCompanyName = this.userform.get('rpname').value;
        this.subcontractor.strTaxNumberId = this.userform.get('taxid').value;
        this.subcontractor.insuranceDtForGl = this.userform.get('gldate').value;
        this.subcontractor.insuranceDtForWc = this.userform.get('wcdate').value;
        this.subcontractor.insuranceDtForAi = this.userform.get('aidate').value;
        this.subcontractor.umbExpirationDt = this.userform.get('umbdate').value;
        this.subcontractor.startDate = this.userform.get('startdate').value;
        this.subcontractor.paymentTerms = this.userform.get('terms').value;
        this.subcontractor.intW9status = this.userform.get('w9status').value;
        this.subcontractor.ysnMinorityStatus = this.userform.get('minority').value;
        this.subcontractor.strCustomerTheyWorkedFor = this.userform.get('customers').value;

        if ((this.newEmployee == true) && (this.newSubcontractor == true)) {
            this.subcontractor.CreationDate = this._dateService.toString(Date.now());
            this.employee.creationDate = this._dateService.toString(Date.now());
            this.addEmployee(this.employee);
            //console.log("Add Employee - Add Sub");
        } else if ((this.newEmployee == false) && (this.newSubcontractor == true)) {
            this.subcontractor.CreationDate = this._dateService.toString(Date.now());
            this.employee.lastEditDate = this._dateService.toString(Date.now());

            this.updateEmployee();
            this.addSubContrator(this.subcontractor);
        } else {
            this.subcontractor.LastEditDate = this._dateService.toString(Date.now());
            this.employee.lastEditDate = this._dateService.toString(Date.now());
            this.updateEmployee();
            this.updateSubcontractor();
        }

        this.displayEmployeeDialog = false;
        this.employee = {};
        this.submitted = false;
        this.userform.reset();
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.filter(e => e.intPosition == "25"));
    }

    getEmployeesbyStatus(status): void {
        this.employeeService.getEmployeesbyStatus(status)
            .subscribe(employees => this.employees = employees.filter(e => e.intPosition == "25"));
    }

    getSubContractors(): void {
        this.subcontractorService.getSubContractors()
            .subscribe(subs => {
                this.subcontractors = subs;
            });
    }

    addEmployee(employee): void {
        if (!employee) {
            return;
        }
        this.employeeService.addEmployee(this.employee)
            .subscribe(data => {
                //Get the Key from insert from tblEmployee for tblSubcontractor Employee Key
                this.subcontractor.intEmpId = data.strEmployeeId;
                this.addSubContrator(this.subcontractor);
            });
    }

    addSubContrator(sub): void {
        if (!sub) {
            return;
        }
        this.subcontractorService.addSubContractor(this.subcontractor)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    updateEmployee(): void {
        let employees = [...this.employees];
        employees[this.employees.indexOf(this.selectedEmployee)] = this.employee;
        this.employeeName = this.employee.strLastName;
        this.employeeService.updateEmployee(this.selectedEmployeeId, this.employee)
            .subscribe(data => {
                //console.log(data);
                this.ngOnInit();
            });
    }

    updateSubcontractor(): void {
        this.employeeName = this.employee.strLastName;
        this.subcontractorService.updateSubContractor(this.subcontractor.id, this.subcontractor)
            .subscribe(data => {
                //console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'RP Added', detail: this.employeeName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'RP Updated', detail: this.employeeName });
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployees("Active")
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAddEmployee() {
        this.newEmployee = true;
        this.newSubcontractor = true;
        this.employee = {};
        this.subcontractor = {};
        this.userform.reset();
        this.userform.get('position').setValue(25);
        this.userform.get('benefit').setValue(5);
        this.userform.get('firstname').setValue('Sub');
        this.userform.get('active').setValue('Active');
        this.userform.get('position').disable();
        this.userform.get('benefit').disable();
        this.userform.get('firstname').disable();
        this.displayEmployeeDialog = true;
    }

    editEmployee(rowdata) {
        this.selectedEmployeeId = rowdata.strEmployeeId;
        this.newEmployee = false;
        this.employee = this.cloneEmployee(rowdata);
        this.subcontractor = this.subcontractors.find(sub => sub.intEmpId === this.selectedEmployeeId);
        this.subEmpty = this.isEmpty(this.subcontractor);
        //console.log(this.employee);
        if (this.subEmpty == true) {
            //this.selectedRPId = this.subcontractor.id;
            this.newSubcontractor = true;
            //console.log("sub null");
        } else {
            this.newSubcontractor = false;
            //console.log("sub not null");
        }

        this.subcontractor = this.cloneSub(this.subcontractor);
        this.subcontractor.intEmpId = this.selectedEmployeeId;
        this.displayEmployeeDialog = true;
        this.userform.get('active').setValue(this.employee.ysnActive);
        this.userform.get('position').setValue(this.employee.intPosition);
        this.userform.get('firstname').setValue(this.employee.strFirstName);
        this.userform.get('lastname').setValue(this.employee.strLastName);
        if (this.subEmpty == false) {
            if (this.subcontractor.insuranceDtForGl !== null) {
                this.userform.get('gldate').setValue(new Date(this.subcontractor.insuranceDtForGl));
            }
            if (this.subcontractor.insuranceDtForWc !== null) {
                this.userform.get('wcdate').setValue(new Date(this.subcontractor.insuranceDtForWc));
            }
            if (this.subcontractor.insuranceDtForAi !== null) {
                this.userform.get('aidate').setValue(new Date(this.subcontractor.insuranceDtForAi));
            }
            if (this.subcontractor.startDate !== null) {
                this.userform.get('startdate').setValue(new Date(this.subcontractor.startDate));
            }
            if (this.subcontractor.umbExpirationDt !== null) {
                this.userform.get('umbdate').setValue(new Date(this.subcontractor.umbExpirationDt));
            }
        }

        this.userform.get('benefit').setValue(this.employee.intBenefits);

        this.userform.get('sapvendor').setValue(this.employee.sapvendorNum);
        this.userform.get('email').setValue(this.employee.strEmail);
        this.userform.get('crew').setValue(this.employee.strCrew);
        this.userform.get('payrate').setValue(this.employee.intPayRate);
        this.userform.get('orientationdone').setValue(this.employee.ysnOrientation);
        this.userform.get('address1').setValue(this.employee.address1);
        this.userform.get('city').setValue(this.employee.city);
        this.userform.get('state').setValue(this.employee.state);
        this.userform.get('zip').setValue(this.employee.zipCode);
        this.userform.get('phone').setValue(this.employee.phone);

        this.userform.get('rpname').setValue(this.subcontractor.strCompanyName);
        this.userform.get('taxid').setValue(this.subcontractor.strTaxNumberId);
        this.userform.get('fax').setValue(this.subcontractor.strFax);
        this.userform.get('w9status').setValue(this.subcontractor.intW9status);
        this.userform.get('minority').setValue(this.subcontractor.ysnMinorityStatus);
        this.userform.get('customers').setValue(this.subcontractor.strCustomerTheyWorkedFor);
        this.userform.get('notes').setValue(this.employee.txtNotes);
        this.userform.get('terms').setValue(this.subcontractor.paymentTerms);
        this.userform.get('position').disable();
        this.userform.get('benefit').disable();
        this.userform.get('firstname').disable();
    }

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    cloneSub(c: SubContractor): SubContractor {
        let sub = {};
        for (let prop in c) {
            sub[prop] = c[prop];
        }
        return sub;
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    areNullOrUndefined(arr) {
        for (var i = 0; i < arr.length; i++) {
            var itm = arr[i];
            if (itm === null || itm === undefined) {
                return true;
            }
        }
        return false;
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getEmployees();
        } else {
            this.getEmployeesbyStatus(event.value);
        }

        this.selectedActive = event.value;
    }

    setFocus(elm: HTMLInputElement) {
        setTimeout(() => {
            elm.focus();
        },
            200);
    }
}
