import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EquipmentUsage } from '../domain/equipmentusage';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { Equipment } from '../domain/equipment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class EquipmentUsageService {
    delay: Observable<EquipmentUsage[]>;
    newequipment: EquipmentUsage;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ProductionDelayService');
    }
    baseUrl: string = 'api/EquipmentUsages/';
    errorUrl: string = 'http://localhost:5243/api/EquipmentUsage/';

    getEquipmentUsages(): Observable<EquipmentUsage[]> {
        return this.http.get<EquipmentUsage[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEquipmentUsage', []))

            );
    }

    addEquipmentUsage(equipment: EquipmentUsage): Observable<EquipmentUsage> {
        return this.http.post<EquipmentUsage>(this.baseUrl, equipment)
            .pipe(
                catchError(this.handleError('addEquipmentUsage', equipment))
            );
    }

    updateEquipmentUsage(id, equipment: EquipmentUsage): Observable<EquipmentUsage> {
        return this.http.put(this.baseUrl + id, equipment)
            .pipe(
                tap(_ => console.log(`updated Equipment id=${id}`)),
                catchError(this.handleError('updateEquipmentUsage', equipment))
            );
    }

    deleteEquipmentUsage(id): Observable<EquipmentUsage> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Equipment Usage id=${id}`)),
                catchError(this.handleError('Delete Equipment Usage'))
            );
    }

    getEquipmentUsagebyId(id): Observable<Equipment[]> {
        return this.http.get<EquipmentUsage[]>(this.baseUrl + 'dfrid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDfrbyID', []))

            );
    }
}
