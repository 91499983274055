import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { RepMaintVendor } from '../domain/repmaintvendors';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class RepMaintVendorService {
    crewtype: Observable<RepMaintVendor[]>;
    newcrewtype: RepMaintVendor ;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrewTypeService');
    }
    baseUrl: string = 'api/RepMaintVendors/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getRepairVendors(): Observable<RepMaintVendor[]> {
        return this.http.get<RepMaintVendor[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRepairVendors', []))

            );
    }

    addRepairVendor(repairvendor: RepMaintVendor): Observable<RepMaintVendor> {
        return this.http.post<RepMaintVendor>(this.baseUrl, repairvendor)
            .pipe(
                catchError(this.handleError('addRepMaintVendor', repairvendor))
            );
    }

    updateRepairVendor(id, repairvendor: RepMaintVendor): Observable<RepMaintVendor> {
        return this.http.put(this.baseUrl + id, repairvendor)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateRepMaintVendor', repairvendor))
            );
    }

    /* GET heroes whose name contains search term */
    //searchCrews(term: string): Observable<Crew[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('crew', term) } : {};

    //    return this.http.get<Crew[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Crew[]>('searchCrew', []))
    //        );
    //}
}
