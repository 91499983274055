import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EquipmentService } from '../service/equipmentservice';
import { Equipment } from '../domain/equipment'
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { MenuItem, Message } from 'primeng/components/common/api';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { DatePipe } from '@angular/common';
import { DateService } from '../../_services/date.service';

@Component({
    templateUrl: './equipment.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class EquipmentComponent implements OnInit {
    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    crew: Crew = {};

    crews: Crew[];

    equipment: Equipment = {};

    equipments: Equipment[];

    duplicateCrew: boolean;

    employees: Employee[];

    supervisors: Supervisor[];

    cols: any[];

    ownership: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    displayCrewDetails: boolean;

    selectedEquipment: Equipment;

    newEquipment: boolean;

    activeFilter: SelectItem[];

    gpsTracking: SelectItem[];

    newEquipmentId = "";

    filter: any = {};

    displayEquipmentDetails: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    filteredCrews: Crew[];

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    filteredNoLegacyCrews: Crew[];

    selectedActive: number = -1;

    selectedCrewId: string;

    msgs: Message[] = [];

    canEdit: boolean = false;

    canAdd: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    expDate: Date;

    items: MenuItem[];

    capCost: number;

    appraisalCost: number;



    constructor(private crewService: CrewService, private service: MessageService, public snackbar: MatSnackBar, private route: Router,
        private equipmentService: EquipmentService, private employeeService: EmployeeService, private supervisorService: SupervisorService,
        private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service, private _dateService: DateService,) {

        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    ngOnInit() {
        //this.getCrewsNoLegacy();

        this.getEmployees();

        if (this.selectedActive == -1) {
            this.getEquipmentbyStatus(-1);
        } else if (this.selectedActive == 0) {
            this.getEquipmentbyStatus(0);
        } else {
            this.getEquipment();
        }

        this.items = [

            {
                label: 'Maint Records', icon: 'pi pi-print', command: () => {
                    //this.printQtrToolBankBalance();
                }
            },
            //{
            //    label: 'Tool Bank Balance', icon: 'pi pi-print', command: () => {
            //        this.printToolBankBalance();
            //    }
            //},
            //{
            //    label: 'Employee Counseling', icon: 'pi pi-print', command: () => {
            //        this.printEmployeeCounseling();
            //    }
            //},
        ];

        this.gpsTracking = [

            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ownership = [
            { label: 'Owned', value: 'Owned' },
            { label: 'Financed', value: 'Financed' },
            { label: 'Operating Lease', value: 'Operating Lease' },
            { label: 'Owned-PNC', value: 'Owned-PNC' },
            { label: 'Rental', value: 'Rental' },
           
            ]

        this.cols = [
            { field: 'newEquipNo', header: 'Equipment No' },
            { field: 'strDescription', header: 'Description' },
            { field: 'area', header: 'Area' },
            { field: 'strModel', header: 'Model' },
            { field: 'titleState', header: 'St' },
            { field: 'strRegistraionExp', header: 'Expires' },
            { field: 'strVin', header: 'VIN' },
            { field: 'strLicensePlate', header: 'License' },
            { field: 'lastCrewReported', header: 'Crew' },
            { field: 'lastDateReported', header: 'Date' },

            //{ field: 'intForeman', header: 'Foreman' },
            { field: 'ysnActive', header: 'Active' }
        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;
                
                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                //console.log(this.userpermission.crewEdit);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.equipmentEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.equipmentAdd == 1 || this.userpermission.intAdmin == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                //console.log(this.userpermission.equipmentEdit);
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    

    getEquipmentbyStatus(status): void {
        this.equipmentService.getEquipmentbyStatus(status)
            .subscribe(equipments => this.equipments=equipments);
        //console.log(this.equipments);
    }

    getActiveSupervisors(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('strAreaNumber'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    filterCrewSingle(event) {
        let query = event.query;
        this.crewService.getCrews()
            .subscribe(crews => {
                this.filteredCrews = this.filterActiveCrew(query, crews);
            });
    }

    filterActiveCrew(query, crews: any[]): any[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        for (let i = 0; i < crews.length; i++) {
            let crew = crews[i];
            if (crew.ysnActive == -1) {
                filtered.push(crew);
            }
        }
        return filtered;
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    filterNoLegacyCrews(filteredcrews: Crew[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Crew[] = [];
        for (let i = 0; i < filteredcrews.length; i++) {
            let crew = filteredcrews[i];
            if (crew.intCrewKey > '515') {
                filtered.push(crew);
            }
        }
        return filtered;
    }

    getEquipment(): void {
        this.equipmentService.getEquipments()
            .subscribe(equipments => {
                this.equipments = equipments.sort(this.dynamicSort('newEquipNo'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
            });
    }

   

    addEquipment(equipment): void {
        if (!equipment) { return; }
        this.equipmentService.addEquipment(this.equipment)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updateEquipment(id, equipment): void {
        //let equipments = [...this.equipments];
        //crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.equipmentService.updateEquipment(id, equipment)

            .subscribe(data => {
                //console.log(data);
                //this.ngOnInit();
                this.showSuccessUpdateViaToast();
                if (this.selectedActive == -1) {
                    this.getEquipmentbyStatus(-1);
                } else if (this.selectedActive == 0) {
                    this.getEquipmentbyStatus(0);
                } else {
                    this.getEquipment();
                }
                //this.handleError("err:Test");
            });
    }

    checkDuplicate(crew): void {
        this.crewService.duplicateCrew(crew)
            .subscribe(crews => {
                this.crews = crews;
                //console.log(this.wos.length);
                if (this.crews.length >= 1) {
                    this.duplicateCrew = true;
                    this.service.add({
                        severity: 'error',
                        summary: 'Duplicate Crew #',
                        detail: this.crew.strCrewId + ' already exist in the system'
                    });
                } else {
                    this.duplicateCrew = false;
                }
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newEquipment = true;
        this.equipment = {};
        this.displayDialog = true;
        //console.log(this.crew);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
    }

    onSave() {
        let equipments = [...this.equipments];
        this.newEquipmentId = this.equipment.newEquipNo;
        let oldequipmentno = this.equipment.newEquipNo;
        if (oldequipmentno.length > 10) oldequipmentno = oldequipmentno.substring(0, 10);
        //let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        if (this.newEquipment) {
            this.equipment.strEquipmentNo = oldequipmentno;
            if (typeof (this.equipment.purchaseCapCost) == 'undefined') {

            }
            else {
                this.equipment.purchaseCapCost = Number(this.equipment.purchaseCapCost.replace(/[$,]/g, ""));
            }
            if (typeof (this.equipment.appraisalValue) == 'undefined') {

            }
                else {
                this.equipment.appraisalValue = Number(this.equipment.appraisalValue.replace(/[$,]/g, ""));
            }
                this.addEquipment(this.equipment);
                equipments.push(this.equipment);
            
        }
        else {

            if (typeof (this.equipment.purchaseCapCost) == 'undefined') {

            }
            else {
                this.equipment.purchaseCapCost = Number(this.equipment.purchaseCapCost.replace(/[$,]/g, ""));
            }
            if (typeof (this.equipment.appraisalValue) == 'undefined') {

            }
            else {
                this.equipment.appraisalValue = Number(this.equipment.appraisalValue.replace(/[$,]/g, ""));
            }
            //if (this.equipment.purchaseCapCost.length>0) {
            //    this.equipment.purchaseCapCost = Number(this.equipment.purchaseCapCost.replace(/[$,]/g, ""));
            //}
            //if (this.equipment.appraisalValue.length>0 ) {
            //    this.equipment.appraisalValue = Number(this.equipment.appraisalValue.replace(/[$,]/g, ""));
            //}
            //this.equipment.purchaseCapCost = Number(this.equipment.purchaseCapCost.replace(/[$,]/g, ""));
            //this.equipment.appraisalValue = Number(this.equipment.appraisalValue.replace(/[$,]/g, ""));
            this.updateEquipment(this.equipment.intEquipmentKey, this.equipment);
          
            equipments.push(this.equipment);
        }

       
        this.displayDialog = false;
        this.equipments = equipments;
        //this.equipment = {};
      
    }

    cloneCrew(c: Equipment): Equipment {
        let equipment = {};
        for (let prop in c) {
            equipment[prop] = c[prop];
        }
        return equipment;
    }

    cancel() {
        this.displayDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Equipment Added', detail: this.newEquipmentId });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Equipment Updated', detail: this.newEquipmentId });
    }

    editRow(rowdata) {
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);

        this.newEquipment = false;
        this.equipment = this.cloneCrew(rowdata);
        //Format Cap Cost 
        var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.equipment.purchaseCapCost);
        this.equipment.purchaseCapCost = String(uy);
        var av = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.equipment.appraisalValue);
        this.equipment.appraisalValue = String(av);
       
        //console.log(uy);
        this.displayDialog = true;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onLoad() {
        var acrews = this.crews;
        if (this.filter.id) {
            acrews = acrews.filter(v => v.YsnActive == '-1');
        }
        this.crews = acrews;
    }

    filterCountry(query, countries: any[]): any[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        for (let i = 0; i < countries.length; i++) {
            let country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }
        return filtered;
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getEquipment();
        } else {
            this.getEquipmentbyStatus(event.value);
        }

        this.selectedActive = event.value;
        //console.log(event.value);
    }

    onRowUnselect() {
        this.displayEquipmentDetails = false;
    }

    onRowSelect(event) {
        this.displayEquipmentDetails = true;
        //console.log(event.data.intCrewKey);
        this.selectedEquipment = event.data.intEquipmentKey;
    }

    onBlur() {
        this.msgs = [];
        this.checkDuplicate(this.crew.strCrewId);
        //console.log(this.userform.get('wo').value);
    }

    onSelect(value) {
        //const date = value;
        this.expDate = value;
        //console.log(element.getAttribute('formControlName'));
        this.equipment.strRegistraionExp = this._dateService.toString(value);
        //console.log(this.equipment.strRegistraionExp)
    }

    onSelectMilHrsDate(value) {
        this.equipment.milHrDate = this._dateService.toString(value);
    }

    onSelectAcquiredDate(value) {
        this.equipment.dtmAcquistionDate = this._dateService.toString(value);
    }

    onSelectExpDate(value) {
        this.equipment.expirationDate = this._dateService.toString(value);
    }

    onSelectInvDate(value) {
        this.equipment.lastInventoryDate = this._dateService.toString(value);
    }

    onSelectAppDate(value) {
        this.equipment.appraisalDate = this._dateService.toString(value);
    }

    onSelectDisposedDate(value) {
        this.equipment.disposedDate = this._dateService.toString(value);
    }

    checkChange(value) {
        if (value) {
            this.equipment.ysnTitleReceived = 1;

        } else {
            this.equipment.ysnTitleReceived = 0;
        }
    }

     public transformAmount(element, name) {
         if (name == "capcost") {
             //console.log(element.target.value);
            var amountRawValue = element.target.value;
            if (!amountRawValue) {
                return;
            }
           
            var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
            //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
            let formattedAmount = amountDigitValue;
            element.target.value = formattedAmount;
            this.equipment.purchaseCapCost=(formattedAmount);
           
        }
        //if (name == "subprice") {
        //    var amountRawValue = this.userform.value.subprice;
        //    if (!amountRawValue) {
        //        return;
        //    }
        //    var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
        //    //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
        //    let formattedAmount = amountDigitValue;
        //    element.target.value = formattedAmount;
        //    this.userform.get('subprice').setValue(formattedAmount);
        //    if (this.userform.get('subprice').status === 'VALID') {
        //        this.amt = false;
        //    }
        //    else {
        //        this.amt = true;
        //        var thenum = this.userform.value.subprice.replace(/^\D+/g, '');
        //        console.log(thenum)
        //    }
        //}
        //if (name == "sub2price") {
        //    var amountRawValue = this.userform.value.sub2price;
        //    if (!amountRawValue) {
        //        return;
        //    }
        //    var amountDigitValue = Number(amountRawValue.replace(/[$,]/g, ""));
        //    //let formattedAmount = this.currencyPipe.transform(amountDigitValue, 'USD');
        //    let formattedAmount = amountDigitValue;
        //    element.target.value = formattedAmount;
        //    this.userform.get('sub2price').setValue(formattedAmount);
        //    if (this.userform.get('sub2price').status === 'VALID') {
        //        this.amt = false;
        //    }
        //    else {
        //        this.amt = true;
        //        var thenum = this.userform.value.sub2price.replace(/^\D+/g, '');
        //        console.log(thenum)
        //    }
        //}
    }

    formatCurrency_CapCostValue(event) {
        var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
        this.capCost = event.target.value;
        this.capCost= Number(uy.replace(/[$,]/g, ""));
    }

    formatCurrency_AppraisalCostValue(event) {
        var uy = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(event.target.value);
        this.appraisalCost = event.target.value;
        this.appraisalCost = Number(uy.replace(/[$,]/g, ""));
    }
   
}
