import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ProficiencyActivity } from '../domain/proficiencyactivity';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ProficiencyActivityService {
    positions: Observable<ProficiencyActivity[]>;
    newproficiency: ProficiencyActivity;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ProficiencyActivityService');
    }
    baseUrl: string = 'api/ProficienciesActivities/';
    errorUrl: string = 'http://localhost:5243/api/ProficienciesActivities/';

    getProficiencyActivities(): Observable<ProficiencyActivity[]> {
        return this.http.get<ProficiencyActivity[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getProficienciesActivities', []))

            );
    }

    addProficiencyActivity(proficiency: ProficiencyActivity): Observable<ProficiencyActivity> {
        return this.http.post<ProficiencyActivity>(this.baseUrl, proficiency)
            .pipe(
                catchError(this.handleError('addProficiencyActivity', proficiency))
            );
    }

    updateProficiencyActivity(id, proficiency: ProficiencyActivity): Observable<ProficiencyActivity> {
        return this.http.put(this.baseUrl + id, proficiency)
            .pipe(
                tap(_ => console.log(`updated proficiencyactivities id=${id}`)),
                catchError(this.handleError('UpdatePositionActivities', proficiency))
            );
    }

    getProficiencyActivitybyEmployeeId(id): Observable<ProficiencyActivity[]> {
        return this.http.get<ProficiencyActivity[]>(this.baseUrl + 'employee/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getProficiencyActivitiesbyEmployeebyId', []))

            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
