import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import { SelectItem, OverlayPanel } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { TaskDelay } from '../domain/taskdelay';
import { SupervisorService } from '../service/supervisorservice';
import { TaskService } from '../service/taskservice';
import { TaskDelayServices} from '../service/taskdelayservice'
import { Task } from '../domain/task';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { DailyFieldReport } from '../domain/dailyfieldreport'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission'
import { Table } from 'primeng/table';
import { FilterUtils } from "primeng/api";
@Component({
    selector: 'dfr-entry',
    templateUrl: './dfr.component.html',
    styles: [

        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    .old-car {
            background-color: #1CA979 !important;
            color: #ffffff !important;
        }
    `
    ],
    providers: [MessageService, DatePipe]
})
export class DfrComponent implements OnInit {
    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    unApprovedDfr: DailyFieldReport = {};

    unApprovedDfrs: DailyFieldReport[];

    wo: Job = {};

    wos: Job[];

    task: Task = {};

    tasks: Task[];

    selectedTask: Task = {};

    crews: Crew[];

    crew: Crew = {};

    taskDelay: TaskDelay = {};

    taskDelays: TaskDelay[];

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    selectedForeman: Employee = {};

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    cols: any[];

    dfrsunapproved: any[];

    taskcols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    incidentform: FormGroup;

    submitted: boolean;

    dfrSubmitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    jobName: string = "";

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    wofilter: any;

    intWoId: string = null;

    intContractId: string;

    types: SelectItem[];

    taskStatustypes: SelectItem[];

    displayHitDetails: boolean = false;

    collapsed: boolean = true;

    minDate: Date;

    maxDate: Date;

    taskform: FormGroup;

    selectedTaskName: string;

    addedTask: Task;

    isReadOnly: boolean = false;

    addedTaskId: string;

    showCrewDl: boolean = false;

    showForemanDl: boolean = false;

    checked1: boolean;

    isProofed: boolean=false;

    checked3: boolean;

    updateUnAssignedTask: boolean = false;

    taskStartDate: string;

    dfrDate: string;

    dfrSubmitButtonDisabled: boolean = false;

    insertedDfrId: string;

    selectedCrewId: string;

    crewHours: number;

    items: MenuItem[];

    searchDfrNum: string;

    searchForDfr: boolean = false;

    collapseDetail: boolean = false;

    showFilterWO: boolean = true;

    approved: string = 'Approve';

    approvedButtonClass: string = "ui-button-warning";

    dfrApproved: boolean = false;

    clickedApprove: boolean = false;

    userEmail: string;

    userId: string;

    showDelete: boolean = false;

    showApprove: boolean = false;

    isAdmin: boolean;

    canDateOverRide: boolean = false;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    canApprove: boolean = false;

    viewPrices: boolean = false;

    dateDisabled: boolean = false;

    crewType: string;

    displayReport: boolean = false;

    selectedDfrId: string = null;

    selectedReportName: string = null;

    dfrId: string = null;

    prevDfrDate: string;

    showProof: boolean = false;

    interval: any;

    isTabletEntry: boolean = false;

    payrollDate: string;

    updatePayrollDates: boolean = false;

    hideFilterDateCol: boolean = false;

    rangeDates: Date;
    filters: any;

    selectedDateFilter: any = null;

    selectedAreaFilter: string = null;
   
    @ViewChild('dt', { static: false }) private _table: Table;

    @ViewChild('area', { static: false }) private _areainput: Input;

    hasMultiplier: boolean = false;

    displayOnHold: boolean = false;

    taskDelaysList: any[];

    newTaskDelay: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private taskService: TaskService, private crewService: CrewService,
        private dfrService: DailyFieldReportService, private adalSvc: MsAdalAngular6Service, private userpermissionservice: UserPermissionService, private activatedroute: ActivatedRoute, private datePipe: DatePipe, private taskDelayService: TaskDelayServices) {
        //console.log(this.adalSvc.userInfo);
        this.userEmail = this.adalSvc.LoggedInUserEmail;
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(this.userEmail);
            //this.getEmployeesbyEmail();
        });

        this.getEmployeesbyEmail();


    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.selectedWoId = curVal;
                this.getDfrbyWoId(this.selectedWoId);
            }

            if (propName === 'selectedASupervisor') {
                //this.onRowSelect(curVal);
                this.selectedASupervisor = curVal;
            }
        }
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    ngAfterViewInit() {
        if (null === this.selectedDateFilter) {
            this.selectedDateFilter = null;
        } else {
            this.filterDateSession(this.selectedDateFilter);
        }

        if (null === this.selectedAreaFilter || this.selectedAreaFilter=='') {
            this.selectedAreaFilter = '';
        } else {
            this.filterAreaSession(this.selectedAreaFilter);
        }

        //console.log(localStorage.getItem("filterArea"));
        //this.selectedAreaFilter = (localStorage.getItem("filterArea"));
    }



    ngOnInit() {

        this.interval = setInterval(() => {
            this.getUnapprovedDfrs();
        }, 240000);         

   
       
        
        FilterUtils['myCustomFilter'] = (value: Date, filter:Date): boolean => {
            return value == filter;
        }

        FilterUtils['custom'] = (value, filter): boolean => {
            //console.log(value.toString());
            //console.log(filter);
            if (filter === undefined || filter === null || filter === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            for (let i = 0; i < filter.length; i++) {
                if (filter[i] === value) {
                    return value;
                }
            }

            return false;
        }

        FilterUtils['numberInBetween'] = (value: number, filter: [number, number]) => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value >= filter[0] && value <= filter[1];
        };
        
   
       
        this.userform = this.fb.group({
            'dfrdate': new FormControl('', Validators.required),
            'wo': new FormControl(''),
            'jobname': new FormControl(''),
            'area': new FormControl(''),
            'task': new FormControl('', Validators.required),
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'supervisor': new FormControl(''),
            'contract': new FormControl(''),
            'comments': new FormControl(''),
            'taskstatus': new FormControl('', Validators.required),
            'utilityhit': new FormControl(''),
            'pi': new FormControl(''),
            'va': new FormControl(''),
            'hittype': new FormControl(''),
            'hitreportno': new FormControl(''),
            'proofed': new FormControl(''),
            'subsurf': new FormControl(''),
            'traffic': new FormControl(''),
            'access': new FormControl(''),
            'restricted': new FormControl(''),
            'wkndnght': new FormControl(''),
            'estftg': new FormControl(''),
            updateOn: 'blur'
        });

        this.taskform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl('', Validators.required),
            'taskowner': new FormControl(''),
        });

        this.userform
            .controls["dfrdate"]
            .valueChanges
            .subscribe(selectedValue => {
                //console.log('New Value: ', selectedValue);                   // New value of Type;
                //console.log('Old Value: ', this.userform.value['dfrdate']);   // Old value of Type; Avoid using this.filterForm.get('type').value - This will give you the current/latest value.
                this.prevDfrDate=this.userform.value['dfrdate']
            });

        this.items = [
            {
                label: 'Update & New', icon: 'pi pi-refresh', command: () => {
                    this.updateandNewDfrbutton();
                }
            },
            {
                label: 'Print DFR', icon: 'pi pi-print', command: () => {
                    this.printDFR();
                }
            },

            //{
            //    label: 'Delete', icon: 'pi pi-times', command: () => {
            //        //this.delete();
            //    }
            //},
            //{ label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io' },
            //{ label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup'] }
        ];



        //this.getEmployeesbyEmail();

        this.getCrewsbyStatus();

        this.getEmployeesbyStatus();

        this.getActiveSupervisorsList();

        this.getUnapprovedDfrs();

        if (this.activatedroute.snapshot.queryParamMap.get('id')) {
            this.insertedDfrId = this.activatedroute.snapshot.queryParamMap.get('id');
            this.searchDfrNum = this.insertedDfrId;
            //this.onRouteDfrId(this.insertedDfrId);
            this.onClickSearch(this.insertedDfrId);
            
        }

      
        if (!this.selectedWo == null) {
            this.filterWoString(this.selectedWo);
        };

        if (!this.searchForDfr)
            this.getDfrDate();

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [

            { field: 'intReportNo', header: 'DFR #' },
            { field: 'dtmWorkDate', header: 'DFR Date' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'taskname', header: 'Task' },
            { field: 'selUtilityHit', header: 'Utility Hit' },
            { field: 'selPersonalInjury', header: 'Personal Injury' },
            { field: 'selVehicleAccident', header: 'Vehicle Accident' },
            { field: 'ysnApproved', header: 'Approved' },
            { field: 'isTabletRow', header: 'Tablet Entry' },

        ];

        

        this.dfrsunapproved = [

            { field: 'intReportNo', header: 'DFR #', display: true },
            { field: 'strAreaNumber', header: 'Area', display: true },
            { field: 'dtmWorkDate', header: 'DFR Date', display: true },
            { field: 'crew', header: 'Crew', display: true },
            { field: 'foreman', header: 'Foreman', display: true },
            { field: 'taskname', header: 'Task', display: true },
            { field: 'strJob', header: 'WO#', display: true },
            { field: 'strJobName', header: 'Job Name', display: true },
            //{ field: 'ysnApproved', header: 'Approved' },
            { field: 'isTabletRow', header: 'Tablet Entry', display: true },
            { field: 'mDateCreated', header: 'Date Entered', display: true },
            //{ field: 'filterDate', header: 'filterDate', display: false },

        ];

        this.taskcols = [

            { field: 'strTaskName', header: 'Task' },
            { field: 'strStatus', header: 'Task Status' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },

        ];

        this.taskName = [
            { label: 'Investigation', value: 'Investigation' },
            { label: 'Locates One-Call', value: 'Locates One-Call' },
            { label: 'Locates Private', value: 'Locates Private' },
            { label: 'Placement', value: 'Placement' },
            { label: 'Craft-Work', value: 'Craft-Work' },
            { label: 'Clean-up', value: 'Clean-up' },
            { label: 'Sent For Approval', value: 'Sent For Approval' },
            { label: 'Approval', value: 'Approval' },
            { label: 'Billing', value: 'Billing' },
            { label: 'All Field Work', value: 'All Field Work' },

        ];

        this.taskStatus = [
            { label: 'UnAssigned', value: 'UnAssigned' },
            { label: 'Open', value: 'Open' },
            { label: 'Inprocess', value: 'Inprocess' },
            { label: 'Incomplete', value: 'Incomplete' },
            { label: 'On Hold', value: 'On Hold' },
            { label: 'Resolved ', value: 'Resolved' },
            { label: 'Complete', value: 'Complete' },
            { label: 'Advanced', value: 'Advanaced' },

        ];

        this.taskDelaysList = [
            { label: 'None', value: 'None' },
            { label: 'Locates', value: 'Locates' },
            { label: 'Permit', value: 'Permit' },
            { label: 'Material', value: 'Material' },
            { label: 'Property Owner', value: 'Property Owener' },
            { label: 'Need Further Approval', value: 'Need Further Approval' },
            { label: 'Customer', value: 'Customer' },
            { label: 'Material Not Reconciled', value: 'Material Not Reconciled' },
            { label: 'Administrative', value: 'Administrative' },
            { label: 'MOT', value: 'MOT' },
            { label: 'Easement', value: 'Easement' },
            { label: 'Existing Equipment', value: 'Existing Equipment' },
            { label: 'Cutover/Switching', value: 'Cutover/Switching' },
            { label: 'Other', value: 'Other' },

        ];

        this.types = [];
        this.types.push({ label: 'Marked', value: '1' });
        this.types.push({ label: 'MMarked', value: '2' });
        this.types.push({ label: 'UnMarked', value: '3' });

        this.taskStatustypes = [];
        this.taskStatustypes.push({ label: 'Complete', value: 'Complete' });
        //this.taskStatustypes.push({ label: 'InProcess', value: 'InProcess' });
        this.taskStatustypes.push({ label: 'In-Process', value: 'In-Process' });
        this.taskStatustypes.push({ label: 'On Hold', value: 'On Hold' });

      
      
          
        this.minDate = new Date(new Date().setDate(new Date().getDate() - 5));
        this.maxDate = new Date();

        const currTime = new Date().getTime();
        const currDateTimeMinusDay = (new Date().setTime(new Date().getTime() - 1))

        this.filters = [];
        this.filters.push({ label: 'All days', value: null });
        this.filters.push({ label: 'Today', value: [this.createFilterDate(currTime)] });
        this.filters.push({ label: 'Yesterday', value: [this.createFilterDate(new Date(new Date().setDate(new Date().getDate() - 1)))] });
        this.filters.push({ label: 'Last 3 days', value: Array(...new Array(3)).map((item, index) => this.createFilterDate(new Date(new Date().setDate(new Date().getDate() - 1) - index * 1000 * 60 * 60 * 24))) });
        this.filters.push({ label: 'Last 7 days', value: Array(...new Array(7)).map((item, index) => this.createFilterDate(new Date(new Date().setDate(new Date().getDate() - 1) - index * 1000 * 60 * 60 * 24))) });

        //console.log(Array(...new Array(3)).map((item, index) => this.createFilterDate(new Date(new Date().setDate(new Date().getDate() - 1) - index * 1000 * 60 * 60 * 24))));

        this.selectedDateFilter = JSON.parse(localStorage.getItem("filterDate"));

        this.selectedAreaFilter = localStorage.getItem("filterArea");

        //this.selectedDateFilter = [this.createFilterDate(new Date(new Date().setDate(new Date().getDate() - 1)))];

        //if (null === this.selectedDateFilter) {
        //    this.selectedDateFilter = null;
        //} else {
        //    this.filterDateSession(this.selectedDateFilter);
        //} 
            
        
    }

    filterDateSession(dates) {       
       
        this._table.filter(dates,'filterDate', 'custom');
    }

    filterAreaSession(area) {
        this._table.filter(area, 'strAreaNumber', 'contains');
    }

    onDateFilterChange(event, dt) {
        //console.log(event.value);
        dt.filter(event.value, 'filterDate', 'custom');
        let key = "filterDate";
        var keyValue = [];
        keyValue = event.value;
        localStorage.setItem(key, JSON.stringify(keyValue));
        //console.log(JSON.parse(localStorage.getItem("filterDate")));           
        
    }

    onAreaFilterChange(event, dt) {
        //console.log(event);
        dt.filter(event, 'strAreaNumber', 'contains');
        let key = "filterArea";
        const keyValue = event;       
        localStorage.setItem(key, keyValue);
        //console.log(localStorage.getItem("filterArea"));    
    }

    createFilterDate(time) {
        return this.datePipe.transform(new Date(time), 'yyyy-MM-dd');
    }

    filterWoString(wo): void {
        this.jobService.searchWo(wo)
            .subscribe(wos => {
                this.wos = wos;
            });
    }

    onSelect(value) {
        this.dateReceived = value;

        this.task.dtmStartDate = this._dateService.toString(this.dateReceived);
    }

    getSupervisors(id): void {
        this.supervisorService.getSupervisorsList()
            .subscribe(supervisors => this.supervisors = supervisors);
    }

    getActiveSupervisorsList(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('name'));
            });
    }

    addDFR(): void {
        this.dfrService.addDFR(this.dfr)
            .subscribe(data => {
                this.insertedDfrId = data.intReportNo;
                this.dfr = data;
                this.showSuccessViaToastAddDFR();
            });
    }

    getDfrbyWoId(id): void {
        this.dfrService.getDFRbyWoId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;
               
                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                //console.log(this.userpermission);

                //set the MinDate depending on user permission
                if (this.userpermission.intAdmin == 1) {
                    this.canDateOverRide = true;
                    this.isAdmin = true;
                    this.minDate = new Date(new Date().setDate(new Date().getDate() - 31));
                    this.maxDate = new Date();
                                

                } else {
                    this.isAdmin = false;
                    //this.minDate = new Date(new Date().setDate(new Date().getDate() - 3));
                    this.maxDate = new Date();
                }

                if (this.userpermission.crewEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.crewCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                if (this.userpermission.dfrapproval == 1 || this.userpermission.intAdmin == 1) {
                    this.canApprove = true;
                } else {
                    this.canApprove = false;
                }

                if (this.userpermission.intViewDfrprice == 1) {
                    this.viewPrices = true;
                } else {
                    this.viewPrices = false;
                }

                if (this.userpermission.dateOverride == 1 && this.userpermission.intAdmin != 1) {
                    this.canDateOverRide = true;
                    this.minDate = new Date(new Date().setDate(new Date().getDate() - 7));                    
                } else {
                    this.canDateOverRide = false;
                    //this.minDate = new Date(new Date().setDate(new Date().getDate() - 3));          
                }


                this.setDfrApproveButton(this.canApprove);
            });

       
       
    }

    deleteDfr(id): void {
        this.dfrService.deleteDfr(id)
            .subscribe();
        this.getUnapprovedDfrs();
    }

    getUnapprovedDfrs(): void {
        this.dfrService.getUnapprovedDfrs()
            .subscribe(dfrs => {
                this.unApprovedDfrs = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getDfrbyDfrId(id): void {
        this.dfrService.getDFRbyDfrId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;

                this.dfr = dfrs.find(d => d.intReportNo == this.insertedDfrId);
                if (this.dfr) {
                    this.showCrewDl = false;
                    this.showForemanDl = false;
                    this.showFilterWO = false;
                    this.isReadOnly = true;
                    this.crewId = this.dfr.intCrewKey;
                    this.userform.get('dfrdate').setValue(new Date(this._dateService.toString(this.dfr.dtmWorkDate)));
                    this.userform.get('jobname').setValue(this.dfr.strJobName);
                    this.userform.get('crew').setValue(this.dfr.crew);
                    this.userform.get('foreman').setValue(this.dfr.foreman);
                    this.userform.get('contract').setValue(this.dfr.strContractNo);
                    this.userform.get('task').setValue(this.dfr.taskname);
                    this.userform.get('supervisor').setValue(this.dfr.supervisor);
                    this.userform.get('area').setValue(this.dfr.strAreaNumber);
                    this.userform.get('estftg').setValue(this.dfr.intEstFootage);
                    this.payrollDate = this._dateService.toString(this.userform.get('dfrdate').value);

                    if (this.dfr.taskstatus === 'InProcess' || this.dfr.taskstatus === 'Inprocess' ) {
                        this.userform.get('taskstatus').setValue('In-Process');
                    } else {
                        this.userform.get('taskstatus').setValue(this.dfr.taskstatus);
                    }
                    //this.userform.get('taskstatus').setValue(this.dfr.taskstatus);
                    this.userform.get('hittype').setValue(this.dfr.selUhmarked);
                    this.userform.get('hitreportno').setValue(this.dfr.strAccidentReportNo);
                    this.intContractId = this.dfr.intContractKey;
                    this.intWoId = this.dfr.intJobId;
                    this.selectedWo = this.dfr.strJob;
                    this.jobName = this.dfr.strJobName;
                    this.crewHours = this.dfr.intCrewHours;
                    this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
                    this.userform.get('comments').setValue(this.dfr.mmoComments);
                    this.userform.get('wo').setValue(this.dfr.strJob);
                    this.crew = this.crews.find(c => c.intCrewKey == this.crewId);
                    //this.loadTaskFields(this.dfr.intTaskId);

                    if (this.crew) {
                        this.crewNumber = this.crew.strCrewId;
                        this.crewType = this.crew.intCrewType;
                    }
                    //this.userform.get('utilityhit').setValue(this.dfr.selUtilityHit);
                    //alert(this.dfr.selUhmarked);
                    if (this.dfr.selUtilityHit == '1') {
                        this.displayHitDetails = true;
                        //this.dfr.selUtilityHit = '1';
                        this.userform.get('utilityhit').setValue(true);
                    } else {
                        this.displayHitDetails = false;
                        this.userform.get('utilityhit').setValue(false);
                        //this.dfr.selUtilityHit = '2';
                    }
                    if (this.dfr.selPersonalInjury == '1') {
                        this.userform.get('pi').setValue(true);
                    } else {
                        this.userform.get('pi').setValue(false);
                    }
                    if (this.dfr.selVehicleAccident == '1') {
                        this.userform.get('va').setValue(true);
                    } else {
                        this.userform.get('va').setValue(false);
                    }

                    if (this.dfr.ysnApproved == -1) {
                        this.approvedButtonClass = "ui-button-success";
                        this.approved = 'Approved';
                        this.dfrApproved = true;
                        this.showDelete = false;
                        this.dateDisabled = true;
                        this.userform.get('dfrdate').disable();
                    } else {
                        this.dfrApproved = false;
                        this.approved = 'Approve';
                        this.approvedButtonClass = "ui-button-warning";
                        this.showDelete = true;
                        this.userform.get('dfrdate').enable();
                    }

                    this.showProof = true;
                    if (this.dfr.isProofed == 1) {
                        this.isProofed = true;

                    } else {
                        this.isProofed = false;
                    }

                    if (this.dfr.isTabletRow == 1) {
                        this.isTabletEntry = true;
                    } else {
                        this.isTabletEntry = false;
                    }

                    this.getTaskbyWoId(this.intWoId);

                    //console.log(this.dfr.intTaskId);
                   
                    let todaysDate = formatDate(new Date(), 'shortDate', 'en');
                    //let dfrapprovedate = formatDate(this.dfr.dtmApprovedDate, 'shortDate', 'en');

                    if (this.dfr.dtmApprovedDate) {
                        //console.log(this.userpermission.dfrapproval);
                        let dfrapprovedate = formatDate(this.dfr.dtmApprovedDate, 'shortDate', 'en');
                        if (todaysDate == dfrapprovedate &&  this.canApprove) {
                            this.showApprove = true;
                            //this.dateDisabled = false;
                        } else if (this.isAdmin) {
                            this.showApprove = true;
                        } else {
                            this.showApprove = false;
                        }
                    } else if (this.isAdmin || this.canApprove) {
                        this.showApprove = true;
                        //console.log(this.showApprove);
                        //this.dateDisabled = false;
                    }
                } else {
                    this.showNoDfrToast();
                    this.resetforms();
                    //this.collapseDetail = true;
                }

              

                 
            });
    }

    getDfrbyCrewId(id): void {
        this.dfrService.getDFRbyCrewId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;
            });
    }

    getTaskbyWoId(id): void {
        this.taskService.getTaskbyJobId(id)
            .subscribe(tasks => {
                this.tasks = tasks;
                if (this.newTask)
                    this.loadTaskFields(this.addedTaskId);
            });
    }

    getTaskDelaysbyWoId(id): void {
        this.taskDelayService.getTaskDelaysbyWoId(id)
            .subscribe(tasksdelays => {
                this.taskDelays = tasksdelays;
               
            });
    }

    getCrews(): void {
        this.crewService.getCrewsNoLegacy()
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
            });
    }

    getCrewsbyStatus(): void {
        this.crewService.getCrewsNoLegacyFilter('-1')
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
            });
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
                //console.log(this.userpermission);
            });
    }

    addTask(task): void {
        if (!task) { return; }
        this.taskService.addTask(this.task)
            .subscribe(
                data => {
                    let message = data.strTaskName + '-' + data.strTaskName;
                    this.showSuccessViaToast(message);
                    this.addedTaskId = data.intTaskId;
                    this.intUnitId = data.intTaskId;
                    this.getTaskbyWoId(this.intWoId);
                });
    }

    addTaskDelay(taskdelay): void {
        if (!taskdelay) { return; }
        this.taskDelay.dtmDateCreated = new Date(new Date().setDate(new Date().getDate()));
        this.taskDelayService.addTaskDelay(this.taskDelay)
            .subscribe(
                data => {
                    let message = 'Delay Added';
                    this.showSuccessViaToast(message);
                    //this.addedTaskId = data.intTaskId;
                    //this.intUnitId = data.intTaskId;
                    this.getTaskbyWoId(this.intWoId);
                });
    }

    updateTaskDelay(id, delay): void {
        this.unitName = this.task.strTaskName;
        //this.task.dtmLastUpdatedDate = new Date(new Date().setDate(new Date().getDate()));

        this.taskDelayService.updateTaskDelay(id, delay)

            .subscribe(data => {
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    loadTaskFields(taskid) {
        //console.log(this.tasks);
        let tasks = [...this.tasks];
        this.task = tasks.find(item => item.intTaskId == taskid);
        //console.log(event.data.intTaskId);
        //console.log(event.data.strContractNo);
        this.userform.get('task').setValue(this.task.strTaskName);
        //this.userform.get('task').disable();
        this.userform.get('crew').setValue(this.task.crew);
        //this.userform.get('crew').disable();
        this.userform.get('foreman').setValue(this.task.foreman);
        //this.userform.get('foreman').disable();
        this.userform.get('supervisor').setValue(this.task.supervisor);
    }

    updateTask(): void {
        this.unitName = this.task.strTaskName;
        this.task.dtmLastUpdatedDate = new Date(new Date().setDate(new Date().getDate()));
        
        this.taskService.updateTask(this.intUnitId, this.task)

            .subscribe(data => {
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    updateDfr(crewhours: boolean): void {
        let dfrid = this.insertedDfrId;
        this.dfrService.updateDFR(this.insertedDfrId, this.dfr)

            .subscribe(data => {
                if (this.clickedApprove) {
                    if (this.approved === 'Approved') {
                        this.showDfrApprovedToast();
                    } else if (this.approved === 'Approve') {
                        this.showDfrUnApprovedToast()
                    } else {
                        this.showSuccessDfrUpdateViaToast(dfrid);
                    }
                } else {
                    if (crewhours) {
                    } else {
                        this.showSuccessDfrUpdateViaToast(dfrid);
                    }
                }
                this.clickedApprove = false;
                this.getUnapprovedDfrs();
                //this.handleError("err:Test");
            });

        this.getUnapprovedDfrs();
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        if (this.intWoId) {
            this.newTask = true;
            this.taskform.get('datestart').setValue(new Date(this.maxDate));
            this.task = {};
            this.wo = {};
            this.displayDialog = true;
        } else {
            this.displayDialog = false;
            this.service.add({ key: 'tst', severity: 'warn', summary: 'Select a WO', detail: 'No WO# selected' });
        }
    }

    clone(c: Task): Task {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.task = {};

        this.taskform.reset();
    }

    showSuccessViaToast(message) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Added', detail: message });
    }

    showSuccessViaToastAddDFR() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'DFR Added', detail: 'Report # ' + this.insertedDfrId });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Updated', detail: this.unitName });
    }

    showSuccessDfrUpdateViaToast(dfrid) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'DFR Updated', detail: 'Report # ' + dfrid });
    }

    showSuccessDfrUpdateNewViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'DFR Updated', detail: 'DFR Updated' });
    }

    showNoDfrToast() {
        this.service.add({ key: 'tst', severity: 'warn', summary: 'DFR # not Found', detail: 'DFR #' + this.insertedDfrId + ' was not found' });
    }

    showDfrApprovedToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'DFR Approved ', detail: 'DFR #' + this.insertedDfrId + ' was Approved' });
    }

    showDfrUnApprovedToast() {
        this.service.add({ key: 'tst', severity: 'warn', summary: 'DFR Unapproved ', detail: 'DFR #' + this.insertedDfrId + ' was Unapproved' });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intTaskId;

        this.newTask = false;
        this.task = this.clone(rowdata);

        this.displayDialog = true;

        this.userform.get('crew').setValue(this.task.intCrewId);
        this.userform.get('foreman').setValue(this.task.intForeman);
        this.userform.get('task').setValue(this.task.strTaskName);
        this.userform.get('taskstatus').setValue(this.task.strStatus);
        this.userform.get('tasknote').setValue(this.task.strTaskDes);
        this.userform.get('taskowner').setValue(this.task.intTaskOwner);
        if (this.task.dtmStartDate !== null) {
            this.userform.get('datestart').setValue(new Date(this.task.dtmStartDate));
        }

        if (this.task.strStatus == 'In-Process') {
            this.userform.get('taskstatus').setValue('Inprocess');
        } else if (this.task.strStatus == 'InProcess') {
            this.userform.get('taskstatus').setValue(this.task.strStatus);
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    saveDfr() {
        this.dfrSubmitted = true
        this.payrollDate = this._dateService.toString(this.userform.get('dfrdate').value);
        if (this.updateUnAssignedTask) {
            this.dfr.intJobId = this.intWoId;
            this.dfr.intCrewKey = this.crewId;
            this.dfr.intForeman = this.foremanId;
            this.dfr.selUhmarked = this.userform.get('hittype').value;
            this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
            this.dfr.dtmWorkDate = this.dfrDate;
            this.dfr.mmoComments = this.userform.get('comments').value;
            this.dfr.intTaskId = this.intUnitId;
            this.dfr.strAccidentReportNo = this.userform.get('hitreportno').value
        } else {
            this.dfr.intJobId = this.intWoId;
            this.dfr.intCrewKey = this.crewId;
            this.dfr.intForeman = this.foremanId;
            this.dfr.selUhmarked = this.userform.get('hittype').value;
            this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
            this.dfr.dtmWorkDate = this.dfrDate;
            this.dfr.mmoComments = this.userform.get('comments').value;
            this.dfr.intTaskId = this.intUnitId;
            this.dfr.strAccidentReportNo = this.userform.get('hitreportno').value;
        }
        this.dfr.intDFRCreator = this.userId;
        this.task.strStatus = this.userform.get('taskstatus').value;
        if (this.task.strStatus === 'Complete') {
               this.task.taskCompletionDate = new Date(new Date().setDate(new Date().getDate()));



           } else {
               this.task.taskCompletionDate = null;
           }
        this.updateTask();
        this.addDFR();
        this.dfrSubmitButtonDisabled = true;
        this.collapseDetail = true;
        
        //this.showApprove = true;
        this.showDelete = true;
        this.showProof = true;
        //this.setDfrApproveButton(this.canApprove);
        //if (this.dfrSubmitted) {
        //    this.showApprove = true;
        //    this.dfrApproved = false;
        //    this.approved = 'Approve';
        //    this.approvedButtonClass = "ui-button-warning";
        //    this.showDelete = true;
        //    this.userform.get('dfrdate').enable();
        //} else {
           
        //}

        if (this.userpermission.dfrapproval == 1) {
            this.showApprove = true;
        } else {
            this.showApprove = false;
        }
    }

    onSubmit(value: string, overlaypanel: OverlayPanel) {
        this.submitted = true;
        //let tasks = [...this.tasks];

        this.task.intCrewId = this.taskform.get('crew').value;
        this.task.intForeman = this.taskform.get('foreman').value;
        this.task.strTaskName = this.taskform.get('task').value;
        this.task.strStatus = this.taskform.get('taskstatus').value;
        this.task.strTaskDes = this.taskform.get('tasknote').value;
        this.task.intTaskOwner = this.taskform.get('taskowner').value;
        this.task.dtmStartDate = this.taskform.get('datestart').value;
        this.task.intSupervisorId = this.selectedAreaSupervisor.strSupervisorName;

        if (this.newTask) {
            this.task.intJobId = this.intWoId;
            //console.log(this.task);
            this.addTask(this.task);
        }
        else {
            this.updateTask();
        }

        this.displayDialog = false;
        this.submitted = false;
        this.taskform.reset();
        this.task = {};
    }

    filterWoSupervisor(areasuper, intWoSupervisors: Supervisor[]) {
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intReportingSupervisorId == areasuper) {
                filtered.push(supervisor);
            }
        }
        return filtered;
        ;
    }

    filterSupervisorId(areasuper, intWoSupervisors: Supervisor[]) {
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intSupervisorKey == areasuper) {
                filtered.push(supervisor);
            }
        }
        return filtered;
        ;
    }

    onChangeForeman(event) {
        this.task.intForeman = event.value;
        this.foremanId = event.value;
    }

    onChangeTaskForeman(event) {
        this.task.intForeman = event.value;
        this.foremanId = event.value;
    }

    onChangeCrew(event) {
        this.crewId = event.value;

        this.crew = this.crews.find(c => c.intCrewKey == this.crewId);

        this.foremanId = this.crew.intForeman;

        this.selectedForeman = this.employees.find(e => e.strEmployeeId == this.foremanId);

        //If foreman is not active set value to '' for validation

        if (this.selectedForeman) {
            this.userform.get('foreman').setValue(this.foremanId);
        }
        else {
            this.userform.get('foreman').setValue('');
        }

        this.task.intCrewId = this.crewId;

        this.task.intForeman = this.userform.get('foreman').value;

        this.crewNumber = this.crew.strCrewId;

        this.crewType = this.crew.intCrewType;
    }

    onChangeTaskCrew(event) {
        this.crewId = event.value;

        this.crew = this.crews.find(c => c.intCrewKey == this.crewId);

        this.foremanId = this.crew.intForeman;

        this.taskform.get('foreman').setValue(this.foremanId);

        this.crewNumber = this.crew.strCrewId;

        this.crewType = this.crew.intCrewType;
    }

    onConfirm() {
        this.service.clear('c');
        if (this.taskDropdown === 'Craft-Work') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        } else if (this.taskDropdown === 'Placement') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        }
    }

    onReject() {
        this.service.clear('c');
    }

    onRejectDfr() {
        this.service.clear('d');
    }

    onConfirmDfr() {
        this.service.clear('d');
        this.deleteDfr(this.insertedDfrId);
        this.resetforms();
    }

    woChange(event) {
        let query = event.query;
        this.filterWoString(query);
    }

    copyInputMessage(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this.service.add({ key: 'tst', severity: 'success', summary: 'Text Copied', detail: this.dfr.strJobName });
       
    }

    captureId(event: any) {
        this.intWoId = event.intJobId;
        this.intContractId = event.intContractKey;
        //alert(this.intContractId);
        this.selectedWo = event.strJob;
        this.jobName = event.strJobName;
        this.getSupervisors(event.intSupervisor);
        this.isReadOnly = true;
        this.selectedAreaSupervisor = this.supervisors.find(s => s.intSupervisorKey == event.intSupervisor);
        this.filterWoString(this.selectedWo);
        this.userform.get('task').setValue("");
        this.userform.get('crew').setValue("");
        this.userform.get('foreman').setValue("");
        this.userform.get('supervisor').setValue("");
        this.userform.get('taskstatus').setValue("");

        this.userform.get('jobname').setValue(event.strJobName);
        this.userform.get('estftg').setValue(event.intEstFootage);
       

        this.userform.get('area').setValue(event.areanumber);
        //this.userform.get('area').disable();
        this.userform.get('contract').setValue(event.strContractNo);
        //this.userform.get('contract').disable();
        this.getTaskbyWoId(this.intWoId);
    }

    onDfrRowSelect(event, task: Task, overlaypanel: OverlayPanel) {
        //console.log(event.data.intReportNo);
        this.updatePayrollDates = false;
        this.showApprove = false;
        this.searchForDfr = true;
        this.insertedDfrId = '';
        this.insertedDfrId = event.data.intReportNo.toString().trim();
        this.payrollDate = '';
        //console.log(this.searchDfrNum);

        this.dfrSubmitButtonDisabled = true;
        this.getDfrbyDfrId(this.insertedDfrId);

        overlaypanel.hide();
    }

    onRowSelect(event, task: Task, overlaypanel: OverlayPanel) {
        this.intUnitId = event.data.intTaskId;
        let tasks = [...this.tasks];
        this.task = tasks.find(item => item.intTaskId == this.intUnitId);
        //console.log(event.data.intTaskId);
        //console.log(event.data.strContractNo);
        this.userform.get('task').setValue(event.data.strTaskName);
        //this.userform.get('task').disable();
        this.userform.get('crew').setValue(event.data.crew);
        //this.userform.get('crew').disable();
        this.userform.get('foreman').setValue(event.data.foreman);
        //this.userform.get('foreman').disable();
        this.userform.get('supervisor').setValue(event.data.supervisor);
        //this.userform.get('supervisor').disable();
        //console.log(event.data.strStatus);
        if (event.data.strStatus == 'UnAssigned') {
            this.showCrewDl = true;
            this.showForemanDl = true;

            this.task.dtmStartDate = this.userform.get('dfrdate').value;

            this.updateUnAssignedTask = true;
        } else {
            this.showCrewDl = false;
            this.showForemanDl = false;
        }
        this.foremanId = event.data.intForeman;
        this.crewNumber = this.userform.get('crew').value;
        this.crewId = event.data.intCrewId
        //console.log(this.crewId);
        overlaypanel.hide();
    }

    UtilityHitChange(e) {
        var isChecked = e.checked;
        if (isChecked) {
            this.displayHitDetails = true;
            this.dfr.selUtilityHit = '1';
        } else {
            this.displayHitDetails = false;
            this.dfr.selUtilityHit = '2';
            this.userform.get('hittype').setValue('');
            this.userform.get('hitreportno').setValue('');
        }
    }

    piChange(e) {
        var isChecked = e.checked;
        if (isChecked) {
            this.dfr.selPersonalInjury = '1';
        } else {
            this.dfr.selPersonalInjury = '2';
        }
    }

    vaChange(e) {
        var isChecked = e.checked;
        if (isChecked) {
            this.dfr.selVehicleAccident = '1';
        } else {
            this.dfr.selVehicleAccident = '2';
        }
    }

    subSurfaceChange(e) {
        var isChecked = e.checked;
        if (isChecked) {
            console.log('Checked')
            //this.dfr.selUtilityHit = '1';
        } else {

            //this.dfr.selUtilityHit = '2';

        }
    }

    getDfrDate() {
        if (new Date().getDay() == 1) {
            this.userform.get('dfrdate').setValue(new Date(new Date().setDate(new Date().getDate() - 3)));
        } else {
            this.userform.get('dfrdate').setValue(new Date(new Date().setDate(new Date().getDate() - 1)));
        }
    }

    weekendChecker(date) {
        if (date.getDay() == 6 || date.getDay() == 0) {
            return true;
        }
        return false;
    }

    onChangeTask(event) {
        this.selectedTaskName = event.value;
        this.taskform.get('taskstatus').setValue('Inprocess');
    }

   onChangeTaskStatus(event) {
       //console.log(this.tasks);
       if (this.tasks && this.tasks.length>0 && this.insertedDfrId) {
           this.task = this.tasks.find(d => d.intTaskId == this.dfr.intTaskId);
           this.intUnitId = this.task.intTaskId;
           let status = event.value;
           if (status === 'Complete') {
               this.task.taskCompletionDate = this._dateService.toString(new Date(new Date().setDate(new Date().getDate() )))
              


           } else if (status === 'On Hold') {
               this.taskDelay = {};
               this.displayOnHold = true;
               this.newTaskDelay = true;
           }

           else {
               this.task.taskCompletionDate = null;
               this.newTaskDelay = false;
           }
           this.task.strStatus = status;
           this.updateTask();
       }
    }

    onCancelTaskDelay() {
        this.taskDelay = {};
        this.displayOnHold = false;
    }

    onSaveTaskDelay() {
        let taskdelays =[]
        if (this.taskDelays && this.taskDelays.length > 0) {
           taskdelays  = [...this.taskDelays];
        } else {

        }
            
        //this.newEquipmentId = this.equipment.newEquipNo;

        //let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        if (this.newTaskDelay) {
            this.taskDelay.intModifiedBy = this.userpermission.fkEmployeeId;
            this.taskDelay.intTaskId = this.task.intTaskId;
            this.addTaskDelay(this.taskDelay);
            taskdelays.push(this.taskDelay);

        }
        else {
            this.updateTaskDelay(this.taskDelay.intTaskDelayId, this.taskDelay);
            taskdelays.push(this.taskDelay);
        }


        this.displayOnHold = false;
        this.taskDelays = taskdelays;
        this.taskDelay = {};

    }

    cloneTaskDelay(c: TaskDelay): TaskDelay {
        let equipment = {};
        for (let prop in c) {
            equipment[prop] = c[prop];
        }
        return equipment;
    }

    checkDuplicateTaskforCrew() {
        let tasks = [...this.tasks];

        let existItem = tasks.filter(item => item.strTaskName === this.selectedTaskName && item.intCrewId == this.crewId);

        if (existItem.length >= 1) {
            this.service.add({
                key: 'e',
                sticky: true,
                severity: 'warn',
                summary: this.crewNumber + ' already has ' + this.selectedTaskName + ' assigned to them.',
                detail: 'Click Ok to close.'
            });
        } else {
            if (this.taskDropdown === 'Craft-Work') {
                this.taskform.get('task').setValue(this.taskDropdown);
                this.taskform.get('taskstatus').setValue('Inprocess');
                this.service.clear();
            } else if (this.taskDropdown === 'Placement') {
                this.taskform.get('task').setValue(this.taskDropdown);
                this.taskform.get('taskstatus').setValue('Inprocess');
                this.service.clear();
            }
        }
    }

    onRejectDuplicate() {
        this.service.clear('e');
        this.taskform.get('task').setValue(null);
    }

    selectTask(event, task: Task, overlaypanel: OverlayPanel) {
        overlaypanel.toggle(event);
    }

    dfrHoursChange(event) {
        this.crewHours = event;
        if (this.crewHours)
            this.dfr.intCrewHours = this.crewHours;
        this.updateDfr(true);
    }

    onClickSearch(event) {
        this.showApprove = false;
        this.searchForDfr = true;
        this.insertedDfrId = '';
        this.insertedDfrId = this.searchDfrNum.toString().trim();
        //console.log(this.searchDfrNum);

        this.dfrSubmitButtonDisabled = true;
        this.getDfrbyDfrId(this.insertedDfrId);

        //this.userform.get('dfrdate').setValue(this.dfr.dtmWorkDate);

        //this.userform.get('dfrdate').setValue(new Date(this.dfr.dtmWorkDate));
    }

    onRouteDfrId(dfrid) {
        this.showApprove = false;
        this.searchForDfr = true;
        this.insertedDfrId = '';
        this.insertedDfrId = dfrid.toString().trim();
        //console.log(this.searchDfrNum);

        this.dfrSubmitButtonDisabled = true;
        this.getDfrbyDfrId(this.insertedDfrId);

       
    }

    updateDate(event) {

        if (this.dfr.intReportNo) {

            this.service.add({
                key: 'e',
                sticky: true,
                severity: 'warn',
                summary: 'Confirm Date Change?',
                detail: ''
            });
        } else {
            //this.payrollDate = this._dateService.toString(this.userform.get('dfrdate').value);
        }
    }

    onConfirmDate(date) {
        this.dateChange(date);
        this.service.clear('e');
    }

    onRejectDate() {
        this.service.clear('e');
        this.userform.get('dfrdate').setValue(new Date(this._dateService.toString(this.prevDfrDate)));
    }


    dateChange(event) {
        const format = 'MM/dd/yyyy';
        const sqlFormat = 'MM/dd/yyyy hh:mm:ss a ';
        let prevDate 
        const myDate = '2019-06-29';
        const locale = 'en-US';

        this.updatePayrollDates = true;
       

        if (this.dfr.dtmWorkDate) {
            prevDate = formatDate(this.dfr.dtmWorkDate, format, locale)
            this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
            if (formatDate(this.dfr.dtmWorkDate, format, locale) != formatDate(this.dfrDate, format, locale)) {
                this.dfr.dtmWorkDate = this.dfrDate;
                this.service.add({ key: 'tst', severity: 'info', summary: 'DFR Date Changed', detail: 'From: ' + prevDate + ' To: ' + formatDate(this.dfrDate, format, locale) });
                this.updateDfr(false);
            }
        }
        this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
        this.payrollDate = this._dateService.toString(this.userform.get('dfrdate').value);
        //console.log(formatDate(this.userform.get('dfrdate').value, sqlFormat, locale));
    }

    updateandNewDfrbutton() {
        this.dfr.selUhmarked = this.userform.get('hittype').value;
        this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
        this.dfr.dtmWorkDate = this.dfrDate;
        this.dfr.mmoComments = this.userform.get('comments').value;
        //this.dfr.intTaskId = this.intUnitId;
        this.dfr.strAccidentReportNo = this.userform.get('hitreportno').value
        this.updateDfr(false);
        this.resetforms();
        //this.userform.reset();
        //this.taskform.reset();
        //this.dfrSubmitButtonDisabled = false;
        //this.showFilterWO = true;
        //this.isReadOnly = false;
        //this.searchDfrNum = '';
        //this.wofilter = null;
        //this.insertedDfrId = null;
        //this.displayHitDetails = false;
        //this.userform.get('hitreportno').setValue("");
        //this.dfr = {};
        //this.approved = 'Approve';
        //this.approvedButtonClass = this.approvedButtonClass = "ui-button-danger";
        //this.dfrApproved = false;
        //this.submitted = false;
        //this.selectedWo = {};
        //this.jobName = "";
        //this.getDfrDate();
    }

    resetforms() {
        this.userform.reset();
        this.taskform.reset();
        this.dfrSubmitButtonDisabled = false;
        this.showFilterWO = true;
        this.isReadOnly = false;
        this.searchDfrNum = '';
        this.wofilter = null;
        this.insertedDfrId = null;
        this.displayHitDetails = false;
        this.userform.get('hitreportno').setValue("");
        this.dfr = {};
        this.approved = 'Approve';
        this.approvedButtonClass = this.approvedButtonClass = "ui-button-warning";
        this.dfrApproved = false;
        this.submitted = false;
        this.selectedWo = {};
        this.jobName = "";
        this.getDfrDate();
        this.crewHours = null;
        this.crewNumber = "";
        this.dfr = {};
        this.dfrs = [];
        this.task = {};
        this.tasks = [];
        this.showDelete = false;
        this.showApprove = false;
        this.showProof = false;
        this.isProofed = false;
        this.intUnitId = null;
        this.dateDisabled = false;
        this.userform.get('dfrdate').enable();
        this.crewType = null;
        this.dfrDate = null;
        this.payrollDate = '';
        this.updatePayrollDates = false;
       
    }

    updateDfrbutton() {
        //console.log(this.dfrDate);
        const epochNow = (new Date).getTime();
        this.clickedApprove = false;
        this.dfr.selUhmarked = this.userform.get('hittype').value;
        this.dfrDate = this._dateService.toString(this.userform.get('dfrdate').value);
        //console.log(this.dfrDate);
        this.dfr.dtmWorkDate = this.dfrDate;
        this.dfr.mmoComments = this.userform.get('comments').value;
        //this.dfr.intTaskId = this.intUnitId;
        this.dfr.strAccidentReportNo = this.userform.get('hitreportno').value
        this.dfr.mDateUpdated = epochNow;
        this.updateDfr(false);
    }

    approveDfr() {
        //console.log(this.approved);
        let todaysDate = new Date();
        this.dfr.dtmApprovedDate = formatDate(new Date(), 'short', 'en');
        this.dfr.intApprovedBy = this.userId;
        this.clickedApprove = true;
        if (this.approved === 'Approved') {
            this.approvedButtonClass = "ui-button-warning";
            this.approved = 'Approve';
            this.dfrApproved = false;
            this.dfr.ysnApproved = 0;
            this.dfr.dtmApprovedDate = null;
            this.dfr.intApprovedBy = null;
            this.showDelete = true;
            this.userform.get('dfrdate').enable();
        } else {
            let todaysDate = formatDate(new Date(), 'shortDate', 'en');
            if (this.dfr.dtmApprovedDate) {
                //console.log(this.userpermission.dfrapproval);
                let dfrapprovedate = formatDate(this.dfr.dtmApprovedDate, 'shortDate', 'en');
                if (todaysDate == dfrapprovedate) {
                    this.userform.get('dfrdate').enable();
                } else {
                    this.userform.get('dfrdate').enable();
                }
            }
            this.approvedButtonClass = "ui-button-success";
            this.approved = 'Approved';
            this.dfrApproved = true;
            this.dfr.ysnApproved = -1;
            this.showDelete = false;
            this.isReadOnly = true;
            this.dateDisabled = true;

            this.userform.get('dfrdate').disable();
        }
        this.updateDfr(false);
    }

    clearValue() {
        this.wofilter = null;
    }

    delete() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete DFR?',
            detail: 'Delete DFR' + this.insertedDfrId
        });
    }

    printDFR() {
        this.displayReport = true;
        this.selectedDfrId = this.insertedDfrId;
        this.selectedReportName = "DFRs/Infratech DFR no prices.trdp";
        //console.log(this.selectedDfrId);
    }

    handleChange(e) {
        this.isProofed = e.checked;
        if (e.checked) {
            if (e.checked == true)
                this.dfr.isProofed = 1;
        } else {
            this.dfr.isProofed = 0;
        }
    }

    setDfrApproveButton(canapprove) {
        this.showApprove = false;
        
        //console.log(this.showApprove);
        //console.log(this.dfr);
        if (Array.isArray(this.dfrs) && (this.dfrs.length) ) {
            let todaysDate = formatDate(new Date(), 'shortDate', 'en');
            //let dfrapprovedate = formatDate(this.dfr.dtmApprovedDate, 'shortDate', 'en');
           
            if (this.dfr.ysnApproved == -1) {
                this.approvedButtonClass = "ui-button-success";
                this.approved = 'Approved';
                this.dfrApproved = true;
                this.showDelete = false;
                this.dateDisabled = true;               
                this.userform.get('dfrdate').disable();
            } else {
                this.dfrApproved = false;
                this.approved = 'Approve';
                this.approvedButtonClass = "ui-button-warning";
                this.showDelete = true;                
                this.userform.get('dfrdate').enable();
            }

            if (this.dfr.dtmApprovedDate) {
                //console.log(this.userpermission.dfrapproval);
                let dfrapprovedate = formatDate(this.dfr.dtmApprovedDate, 'shortDate', 'en');
                if (todaysDate == dfrapprovedate && canapprove) {
                    this.showApprove = true;
                    //this.dateDisabled = false;
                } else if (this.isAdmin  || this.canDateOverRide) {
                    this.showApprove = true;
                } else {
                    this.showApprove = false;
                }
            } else if (canapprove) {
                this.showApprove = true;
                //console.log(this.showApprove);
                //this.dateDisabled = false;
            } else {
                this.showApprove = false;
            }

           
        } else {
            this.showApprove = false;
          
        }

        //console.log(this.showApprove);
    }
}
