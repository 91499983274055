import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Crew } from '../domain/crew';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CrewService {
    crew: Observable<Crew[]>;
    newcrew: Crew;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrewService');
    }
    baseUrl: string = 'api/Crews/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getCrews(): Observable<Crew[]> {
        return this.http.get<Crew[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCrews', []))

            );
    }

    getCrewsNoLegacy(): Observable<Crew[]> {
        return this.http.get<Crew[]>(this.baseUrl + 'nolegacy')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCrews', []))

            );
    }

    getCrewsNoLegacyFilter(status): Observable<Crew[]> {
        return this.http.get<Crew[]>(this.baseUrl + 'nolegacyfilter/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getFilterCrews', []))

            );
    }

    getRpCrews(id): Observable<Crew[]> {
        return this.http.get<Crew[]>(this.baseUrl + 'rpcrews/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getRpCrews', []))

            );
    }

    duplicateCrew(crew): Observable<Crew[]> {
        return this.http.get<Crew[]>(this.baseUrl + 'checkduplicate/' + crew)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('duplicatecrew', []))

            );
    }

    //update(crew: Crew) {
       
    //        return this.http.post<Crew>(this.baseUrl, crew)
    //        .pipe(
    //            map(res => {
    //                this.toastrService.success('Campaign Updated');
    //                return res;
    //            }),
    //        )
    //        .toPromise()
    //        .catch(e => console.error(e));
    //}


    addCrew(crew: Crew): Observable<Crew> {
        return this.http.post<Crew>(this.baseUrl, crew)
            .pipe(
                catchError(this.handleError('addCrew', crew))
            );
    }

    updateCrew(id, crew: Crew): Observable<Crew> {
        return this.http.put(this.baseUrl + id, crew)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateCrew', crew))
            );
    }

    /* GET heroes whose name contains search term */
    searchCrews(term: string): Observable<Crew[]> {
        term = term.trim();

        // Add safe, URL encoded search parameter if there is a search term
        const options = term ?
            { params: new HttpParams().set('strCrewId', term) } : {};

        return this.http.get<Crew[]>(this.baseUrl, options)
            .pipe(
                catchError(this.handleError<Crew[]>('searchCrews', []))
            );
    }

    //private searchcrew = new Observable<Crew[]>();
    //searchHeroes(term: string): Observable<Crew[]> {
    //    this.searchcrew = this.http.get<Crew[]>(`${this.baseUrl}/?name=${term}`);
    //    if (!term.trim()) {
    //        // if not search term, return empty hero array.
    //        return of([]);
    //    }
    //    return this.http.get<Crew[]>(`${this.baseUrl}/?name=${term}`).pipe(
    //        tap(result => {
    //            if (result.length > 0) {
    //                this.log(`found heroes matching "${term}"`)
    //            } else {
    //                this.log('no matching heroes found');
    //            }
    //        }),
    //        catchError(this.handleError<Crew[]>('searchHeroes', [])));
    //}
    //log(arg0: string): void {
    //    throw new Error("Method not implemented.");
    //}
}
