import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomerInvoiceService } from '../service/customerinvoiceservice';
import { CustomerGroupInvoiceService} from '../service/customergroupinvoiceservice';
import { CustomerInvoice } from '../domain/customerinvoice'
import { CustomerGroupInvoice } from '../domain/customergroupinvoice '
import { HttpClientModule } from '@angular/common/http';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
import { MessageService, FilterUtils } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Contract } from '../domain/contract'
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { DateService } from '../../_services/date.service';
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';
import { CustomerService } from '../service/customerservice';
import { Customer } from '../domain/customer'
import { Observable } from "rxjs/Rx";




@Component({
    selector: 'groupinvoices-table',
    templateUrl: './customergroupinvoices.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService, DatePipe]
})
export class CustomerGroupInvoicesComponent implements OnInit {
    customerInvoice: CustomerInvoice = {};

    customerInvoices: CustomerInvoice[];

    customerGroupInvoice: CustomerGroupInvoice = {};

    customerGroupInvoices: CustomerGroupInvoice[];

    newGroupInvoices: CustomerGroupInvoice[];

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    activeFilter: SelectItem[];

    customerName = "";

    contractName = "";

    filter: any = {};

    newInvoice: boolean = false;    

    displayContractDialog: boolean = false;

    selectedActive: string = "-1";

    selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() invoiceCreated: boolean = false;

    userform: FormGroup;

    selectedInvoice: any;

    selectedInvoiceToGroupInvoice: CustomerInvoice[];

    displayReport: boolean = false;
        
    selectedInvoiceId: string = null;

    selectedReportName: string = null;

    @Output() invoiceDeleted = new EventEmitter<number>();

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    rangeDates: Date;

    dateFilters: any;

    selectedColumns: any[];

    sumSelectedInvoice: number = 0;

    invoiceDate: Date;

    invoiceDueDate: Date;

    customer: Customer = {};

    customers: Customer[];

    selectedCustomer: Customer = {};

    private datePipe: DatePipe

    @Input() customerId: number;

    progressBar: string;

    groupInvoiceNum: string;

    amount: number;

    formattedAmount: string;

   

    


    constructor(private customerInvoiceService: CustomerInvoiceService, private service: MessageService, public snackbar: MatSnackBar, private contractService: ContractService,
        private route: Router, private employeeService: EmployeeService, private fb: FormBuilder, private billableproductionService: BillableProductionService,
        private _dateService: DateService, private customerService: CustomerService, private groupInvoiceSerivce: CustomerGroupInvoiceService, private currencyPipe: CurrencyPipe  ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'customerId') {
                //this.onRowSelect(curVal);
                this.selectedCustomerId = curVal;
                if (this.selectedCustomerId) {
                    this.getGroupInvoicesbyCustomer(this.selectedCustomerId);
                   
                    //console.log(this.selectedCustomerId);
                }
            }

            if (propName === 'invoiceCreated') {
                //console.log(this.invoiceCreated);
                if (curVal == 'true') {
                    this.getGroupInvoicesbyCustomer(this.selectedCustomerId);
                }
                //console.log(this.selectedWoId);
            }

            //if (propName === 'selectedASupervisor') {
            //    //this.onRowSelect(curVal);
            //    this.selectedASupervisor = curVal;
            //    //console.log(this.selectedASupervisor);
            //}
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'wo': new FormControl(''),
            'invoicenumber': new FormControl(''),
            'invoicedate': new FormControl(''),
            'invoicedue': new FormControl(''),
            'invoiceamount': new FormControl(''),
            'adjustment': new FormControl(''),
            'revised': new FormControl(''),
            'weekending': new FormControl(''),
            //'orientationdate': new FormControl(''),
        });

        this.active = [
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
           
            //{ field: 'id', header: 'Id' },
            { field: 'invoiceNum', header: 'Invoice #' },
            { field: 'invoiceDate', header: 'Invoice Date' },
            { field: 'invoiceDueDate', header: 'Invoice Due' },
            { field: 'invoiceTotal', header: 'Invoice Total' },
            { field: 'groupInvoiceNum', header: 'Group Invoice #' },
            { field: 'weekEnding', header: 'Week Ending' },
            //{ field: 'intJobID', header: 'Job Id' },
            //{ field: 'strCustomerName', header: 'Customer' },
        ];

        var _self = this;

        this.selectedColumns = this.cols;

        FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
            // get the from/start value
            var s = _self.rangeDates[0].getTime();
            //console.log(s);
            var e;
            // the to/end value might not be set
            // use the from/start date and add 1 day
            // or the to/end date and add 1 day
            if (_self.rangeDates[1]) {
                e = _self.rangeDates[1].getTime() + 86400000;
            } else {
                e = s + 86400000;
            }

            var dateValue = new Date(value);
            //console.log(e)
            // compare it to the actual values
            return dateValue.getTime() >= s && dateValue.getTime() < e;
        }

        this.invoiceDate = new Date();

        //this.getInvoicesforGroupInvoice();

        this.getCustomersFilter(-1);

        //this.getGroupInvoices();
    }

    getCustomersFilter(status): void {
        this.customerService.getCustomerFilter(status)
            .subscribe(customers => {
                this.customers = customers.sort(this.dynamicSort('strCustomerName'));
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getInvoicesbyWoId(id): void {
        this.customerInvoiceService.getCustomerInvoicebyWoId(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getInvoicesforGroupInvoice(id): void {
        this.customerInvoiceService.getInvoiceforGroupInvoice(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getGroupInvoices(): void {
        this.groupInvoiceSerivce.getCustomerInvoices()
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getGroupInvoicesbyCustomer(id): void {
        this.groupInvoiceSerivce.getGroupInvoicesbyCustomerId(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    addCustomerInvoice(invoice): void {
        if (!invoice) { return; }
        this.customerInvoiceService.addCustomerInvoice(this.customerInvoice)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addCustomerGroupInvoice(invoice){
        if (!invoice) { return; }
        this.groupInvoiceSerivce.addCustomerInvoice(invoice)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    updateCustomerInvoice(id, invoice): void {
        //let customerInvoices = [...this.customerInvoices];
        //customerInvoices[this.customerInvoices.indexOf(this.selectedCustomer)] = this.customer;
        //this.customerName = this.customer.strCustomerName;
        this.customerInvoiceService.updateCustomerInvoice(id, invoice)

            .subscribe(data => {
                console.log(data);
                //this.showSuccessUpdateViaToast();
                //this.ngOnInit();

                //this.handleError("err:Test");
            });
    }

    deleteCustomerInvoice(id): void {
        this.customerInvoiceService.deleteCustomerInvoice(id)
            .subscribe(data => {
                this.showDeleteViaToast();
                this.getInvoicesbyWoId(this.selectedWoId);
            });
        
    }

    updateUnbilled(id, billableproduction, showtoast: boolean): void {
        //let crews = [...this.crews];
        //crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.billableproductionService.updateBillableProduction(id, billableproduction)

            .subscribe(data => {
                this.invoiceDeleted.emit(1);
                //console.log(data);
                //this.ngOnInit();
                if (showtoast) {
                    //this.showSuccessUpdateViaToast();
                    //this.invoiceDeleted.emit(1);
                }
                //this.handleError("err:Test");
            });
        //if (!showtoast) {
        //    this.showSuccessUpdateViaToast();
        //}
    }

    getBillableProductionbyWoId(id, invoiceid): void {
        this.billableproductionService.getBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                this.billableProductions = this.billableProductions.filter(bp => bp.strInvoiceNum == invoiceid);
                this.unbillProduction();
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.billableProductions);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newInvoice = true;
        this.userform.reset();
        this.customerGroupInvoice = {};
        this.userform.get('invoiceamount').setValue(this.sumSelectedInvoice);
        this.userform.get('invoicedate').setValue(this.invoiceDate);
        this.displayDialog = true;

    }

   

    onRowUnselect() {
        //this.displayContract = false;
    }

    printGroupInvoice(rowdata) {
        this.displayReport = true;
        this.groupInvoiceNum = rowdata.groupInvoiceNum;
        this.selectedReportName = "Infratrac/Group Invoice.trdp";
        //console.log(rowdata.groupInvoiceNum);
    }

    onRowSelect(event) {
        //this.displayContract = !this.displayContract;
       
        if (event.data.groupInvoiceNum) {
            //console.log(event.data.groupInvoiceNum)
            this.groupInvoiceNum = event.data.groupInvoiceNum;
        }
       
    }

    cloneInvoice(c: CustomerInvoice): CustomerInvoice {
        let contract = {};
        for (let prop in c) {
            contract[prop] = c[prop];
        }
        return contract;
    }

    cancel() {
        this.displayDialog = false;
    }

    onCancelContract() {
        this.displayContractDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Added', detail: this.customerName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: ' Updated', detail: this.customerName });
    }

    showDeleteViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Invoice Deleted', detail:''  });
    }

    editCustomer(rowdata) {
        this.newInvoice = false;
        this.customerInvoice = this.cloneInvoice(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
        this.userform.get('wo').setValue(this.customerInvoice.intWoid);
        this.userform.get('final').setValue(this.customerInvoice.ynFinal);
        this.userform.get('invoicenumber').setValue(this.customerInvoice.strInvoiceNo);
        this.userform.get('invoiceamount').setValue(this.customerInvoice.curInvoiceTotal.toLocaleString('en-US', { minimumFractionDigits: 2 }));
        this.userform.get('adjustment').setValue(this.customerInvoice.curAdjustment);
        this.userform.get('revised').setValue(this.customerInvoice.revised);
        if (this.customerInvoice.dtmInvoiceDate !== null) {
            this.userform.get('invoicedate').setValue(new Date(this.customerInvoice.dtmInvoiceDate));
        }
        if (this.customerInvoice.dtmInvoiceDue !== null) {
            this.userform.get('invoicedue').setValue(new Date(this.customerInvoice.dtmInvoiceDue));
        }
        this.userform.controls['wo'].disable();
        this.userform.controls['invoicenumber'].disable();
        this.userform.controls['invoiceamount'].disable();
        this.userform.controls['invoicedate'].disable();
        this.userform.controls['invoicedue'].disable();
        this.userform.controls['invoiceamount'].disable();
    }

    async deleteInvoice() {
        //this.invoiceDeleted.emit(0);
        this.newInvoice = false;
        //this.customerInvoice = this.cloneInvoice(rowdata);
        this.deleteCustomerInvoice(this.customerInvoice.intInvoiceId);
        //this.getInvoicesbyWoId(this.selectedWoId);
        //this.getBillableProductionbyWoId(this.selectedWoId, this.customerInvoice.strInvoiceNo);
       
       
       
    }

    async deleteBilledInvoice() {
        const deleteinvoice = this.deleteInvoice();
        await deleteinvoice;
        this.getInvoicesbyWoId(this.selectedWoId);
        this.getBillableProductionbyWoId(this.selectedWoId, this.customerInvoice.strInvoiceNo)
        this.invoiceDeleted.emit(0);
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    printGroupInvoiceRow(groupinvoice: CustomerGroupInvoice) {
        this.groupInvoiceNum = groupinvoice.groupInvoiceNum;
        this.displayReport = true;
        //this.groupInvoiceNum = rowdata.intInvoiceId;
        this.selectedReportName = "Infratrac/Group Invoice.trdp";
        //console.log(rowdata.intInvoiceId);
    }

    async unbillProduction() {
        //this.invoiceCreated.emit(false);    

        //console.log('unbilled');
        //console.log(this.padLeft(newinvoicenum.toString(), '0', 3));  // '000123')
        //console.log((String(0).repeat(3 - parseInt(this.newInvoiceNo) + 1).length) + this.newInvoiceNo));
        //let invoicenum = this.newInvoiceNo

        if (Array.isArray(this.billableProductions) && (this.billableProductions.length)) {
            let billed = [...this.billableProductions];

            for (let i = 0; i < billed.length; i++) {
                //if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                //console.log(formatDate(payrollhours[i].dtDate, format, locale));
                //console.log(formatDate(date, format, locale));
                let data = billed[i];
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).strStatus = 0;
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).dtmInvoiceDate = null;
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).strInvoiceNum = null;
                let results = await this.updateUnbilled(billed[i].intProductionId, data, true);

                //console.log(unbilled[i].intProductionId);


                //this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);

            }

            //this.invoiceDeleted.emit(1);

        }

        //this.invoiceDeleted.emit(1);
     
    }

    deleteInvoiceConfirm(rowdata) {
        this.customerInvoice = this.cloneInvoice(rowdata);
        this.service.add({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Invoice?',
            //detail: this.deletedQty + ' of ' + this.deletedBc + ' : ' + this.deletedBcDescription
        });

    }

    onConfirm() {
        this.service.clear('c');
        this.deleteBilledInvoice();
    }

    onReject() {
        this.service.clear('c');
        this.customerInvoice = {};
    }

    groupBillInvoiceSelect(row, control) {
        //this.disableInvoiceBtn = true;
        //console.log(control.checked);
        //console.log(row.intProductionId);

        if (control.checked) {
            //this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 2;
            //this.updateUnbilled(row.intProductionId, row, true);
            this.addTotal();
        } else {
            //this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 0;
            //this.updateUnbilled(row.intProductionId, row, true);
            this.addTotal();
        }

        //this.disableInvoiceBtn = false;

    }

    addTotal() {
        let myArray = this.selectedInvoiceToGroupInvoice

        this.sumSelectedInvoice = 0;
        if (myArray.length > 0) {
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i].curInvoiceTotal) {
                    this.sumSelectedInvoice += ((myArray[i].curInvoiceTotal));

                }
            }
        }
        //this.costTotal.emit(this.sumBillableProductions);
    }

    updateTotal() {
        this.addTotal();
    }

    onDateSelect(event, dt) {

        dt.filter(this.rangeDates, 'invoiceDate', 'dateRangeFilter');

    }

    onCustomerSelect(event) {
        if (event.value) {
            //console.log(event.value);
            this.getGroupInvoicesbyCustomer(event.value);
            this.customerId = event.value;
        } else {
            this.customerInvoices = [];
        }
    }

   

    onSubmit(value: string) {
        //this.submitted = true;

        //this.task.intCrewId = this.userform.get('crew').value;
        //this.task.intForeman = this.userform.get('foreman').value;
        //this.task.strTaskName = this.userform.get('task').value;
        //this.task.strStatus = this.userform.get('taskstatus').value;
        //this.task.strTaskDes = this.userform.get('tasknote').value;
        //this.task.intTaskOwner = this.userform.get('taskowner').value;
        //this.task.dtmStartDate = this.userform.get('datestart').value;

        //if (this.newTask) {
        //    this.task.intJobId = this.selectedWoId;
        //    this.task.intSupervisorId = this.selectedASupervisor;
        //    this.addTask(this.task);
        //} else {
        //    this.updateTask(this.task.intTaskId, this.task);
        //}
        //this.displayDialog = false;
        //this.task = {};
        //this.userform.controls['task'].enable();
        //this.userform.controls['crew'].enable();
        //this.userform.controls['foreman'].enable();
        //this.userform.reset();
    }

    createFilterDate(time) {
        return this.datePipe.transform(new Date(time), 'yyyy-MM-dd');
    }

    async createGroupInvoice(groupinvoice, invoiceid, invoice) {
        const creategroupinvoice = this.addCustomerGroupInvoice(groupinvoice);
        const updatecustomerinvoice = this.updateCustomerInvoice(invoiceid, invoice);
        await creategroupinvoice;
        await updatecustomerinvoice;
        return;
    }

    
    transformAmount(element) {
        this.amount = element.target.value.replace(/[$,]/g, "");
        this.formattedAmount = this.currencyPipe.transform(this.amount, '$')

        element.target.value = this.formattedAmount;
    }
   
    

    async  onSave() {
        //this.customerGroupInvoice = {};
        //let groupinvoices = [...this.customerGroupInvoices];
        //this.submitted = true;
        this.progressBar = 'indeterminate'
        let invoicedate = this._dateService.toString(this.userform.get('invoicedate').value);
        let invoicedue = this._dateService.toString(this.userform.get('invoicedue').value);
        let weekending = this._dateService.toString(this.userform.get('weekending').value);
        let invoicenum = this.userform.get('invoicenumber').value
        let invoiceamnt = this.userform.get('invoiceamount').value;
 
        
        //this.task.intTaskOwner = this.userform.get('taskowner').value;
        //this.task.dtmStartDate = this.userform.get('datestart').value;
        //this.crewName = this.crew.strCrewId;
        //let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        if (this.newInvoice) {

            if (Array.isArray(this.selectedInvoiceToGroupInvoice) && (this.selectedInvoiceToGroupInvoice.length)) {
                let selectedgroupinvoices = [...this.selectedInvoiceToGroupInvoice];
                //let newgroupinvoices = [];
                //newgroupinvoices = [];

               
                //console.log(this.selectedInvoiceToGroupInvoice);
                for (const groupinvoice of this.selectedInvoiceToGroupInvoice) {
                        //if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                        //console.log(formatDate(payrollhours[i].dtDate, format, locale));
                        //console.log(groupinvoice);
                   
                    this.customerGroupInvoice.groupInvoiceNum = invoicenum;
                        this.customerGroupInvoice.invoiceDate = invoicedate;
                        this.customerGroupInvoice.invoiceDueDate = invoicedue;
                        this.customerGroupInvoice.weekEnding = weekending;
                        this.customerGroupInvoice.invoiceTotal = invoiceamnt;
                        this.customerGroupInvoice.intJobId = groupinvoice.intJobNum;
                        this.customerGroupInvoice.invoiceNum = groupinvoice.intWoid + '-' + groupinvoice.strInvoiceNo;
                        this.customerGroupInvoice.invoiceTotal = groupinvoice.curInvoiceTotal;
                        this.selectedInvoiceToGroupInvoice.find(b => b.intInvoiceId == groupinvoice.intInvoiceId).groupInvoiced = 1;
                        this.selectedInvoiceToGroupInvoice.find(b => b.intInvoiceId == groupinvoice.intInvoiceId).groupInvoiceNum = invoicenum ;

                        //this.selectedInvoiceToGroupInvoice.find(b => b.intProductionId == data.intProductionId).dtmInvoiceDate = this._dateService.toString(this.invoiceDate);
                        //this.selectedInvoiceToGroupInvoice.find(b => b.intProductionId == data.intProductionId).strInvoiceNum = this.padLeft(newinvoicenum.toString(), '0', 3);
                        //this.updateUnbilled(selectedgroupinvoices[i].intProductionId, data, true);
                    //newgroupinvoices.push(this.customerGroupInvoice);
                    this.createGroupInvoice(this.customerGroupInvoice, groupinvoice.intInvoiceId, groupinvoice);
                    
                    //this.addCustomerGroupInvoice(this.customerGroupInvoice);
                    //console.log('Loop');
                    await new Promise(resolve => setTimeout(resolve, 200)); // .2 sec
                    this.displayDialog = false;
                    //this.updateCustomerInvoice(groupinvoice.intInvoiceId, groupinvoice);

                   

                        //this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);

                }
                //console.log('Loop End');
                //this.displayDialog = false;
                //this.customerGroupInvoice = {};
                //this.userform.reset();
                this.getInvoicesforGroupInvoice(this.customerId);
                this.sumSelectedInvoice = 0;
                this.progressBar = 'determinate'
                this.showSuccessViaToast();
                
            }
           
               
                //groupinvoices.push(this.customerGroupInvoi;\';ce);
                //this.crew = null;
                
            
            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            //this.updateCrew();
            //this.crew = null;
            //this.displayDialog = false;
            //this.crews = crews;
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }
        //this.crews = crews;
        //this.customerGroupInvoice = {};
        //this.displayDialog = false;
    }
}
