import { AfterViewInit, Component, ViewChild, ViewEncapsulation, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission'
@Component({
    //encapsulation: ViewEncapsulation.None,
    selector: 'report-page',
    templateUrl: './reportpage.component.html',
    styleUrls: ['./reportpage.component.css'],
})

export class ReportPageComponent {
    viewerContainerStyle = {
        position: 'relative',
        width: '100%',
        height: '800px',
        ['font-family']: 'ms sans serif'
    };

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    reportName: string;
    reportDfrId: string;

    WONum: string = null;
    selectedReportName: string;
    displayReport: boolean = true;
    private _isAdmin: boolean = false;
    private _viewRevenueReports: boolean = true;

    home: MenuItem;

    items: MenuItem[];

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    userEmail: string;

    userId: string;

    view: boolean = false;

    constructor(private adalSvc: MsAdalAngular6Service, private userpermissionservice: UserPermissionService, private employeeService: EmployeeService, private ref: ChangeDetectorRef) {
        this.userEmail = this.adalSvc.LoggedInUserEmail;
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(this.userEmail);
            //this.getEmployeesbyEmail();
        });

        this.getEmployeesbyEmail();

       

       
    }

    ngAfterViewInit() {
        //this.showHideMenuItems(this.userpermission.revenueReports);
        //this.getEmployeesbyEmail();
        //this.getUserPermissions(this.userId);
        //console.log(localStorage.getItem("filterArea"));
        //this.selectedAreaFilter = (localStorage.getItem("filterArea"));
    }

    ngAfterContentChecked(): void {
        


    }

     

    ngOnInit() {

       
        //reportName: string = this.reportName1;
        //reportDFR: string = this.reportDFRNum;
        //this.getEmployeesbyEmail();
        this.home = { icon: 'pi pi-home' };
        //this.getEmployeesbyEmail();
        //console.log(this.reportName);

        this.items = [
            { label: 'Reports' },
            {
                label: 'Contract',
                items: [{
                    label: 'DEC SL',

                    items: [
                        {
                            label: 'Unbilled', command: () => {
                                this.selectedReportName = "DEC Reports/Duke SL Unbilled Revenue by Ops Center and Contract.trdp";

                                //console.log(this.displayReport);

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        {
                            label: 'Status Report', command: () => {
                                this.selectedReportName = "DEC Reports/Duke SL Status Report.trdp";

                                //console.log(this.displayReport);

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        //{
                        //    label: 'Repair Jobs', command: () => {
                        //        this.selectedReportName = "DEC Reports/Duke SL Repair Jobs by Ops Center and Contract.trdp";
                        //        this.displayReport = true;

                        //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        //    }
                        //},
                        //{
                        //    label: 'Replacement Jobs', command: () => {
                        //        this.selectedReportName = "DEC Reports/Duke SL Replacement Jobs by Ops Center and Contract.trdp";
                        //        this.displayReport = true;

                        //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        //    }
                        //},
                    ]
                },
                    //{
                    //    label: 'JPD', command: () => {
                    //        this.selectedReportName = "DFRs/JPD.trdp";
                    //        this.displayReport = true;

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //},
                    //{ label: 'Quit' }
                ]
            },
            {
                label: 'Customer',
                items: [{
                    label: 'Contract',

                    items: [
                        {
                            label: 'Billing Codes', command: () => {
                                this.selectedReportName = "DFRs/Customer Billing Codes by Contract.trdp";

                                //console.log(this.displayReport);

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        {
                            label: 'Estimated Units by WO', command: () => {
                                this.selectedReportName = "Infratrac/WO Estimated Units vs Actual.trdp";

                                //console.log(this.displayReport);

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        {
                            label: 'Active Bid Jobs', command: () => {
                                this.selectedReportName = "Infratrac/Bid Job Details.trdp";
                                //this.displayReport = true;

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        {
                            label: 'BC Export for Import', command: () => {
                                this.selectedReportName = "Customer/Customer Billing Codes by Contract Export for Import.trdp";
                                //this.displayReport = true;

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                        {
                            label: 'Customer Active Contracts', command: () => {
                                this.selectedReportName = "Customer/Customer Active Contracts.trdp";
                                //this.displayReport = true;

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
  {
                            label: 'Bid Jobs Units Placed vs Actual', command: () => {
                                this.selectedReportName = "Backlog/Bid Jobs Units Placed vs Estimated.trdp";
                                //this.displayReport = true;

                                //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                            }
                        },
                    ]
                },
                    //{
                    //    label: 'Black & Veatch',
                      
                    //    items: [
                    //        {
                    //            label: 'BV Hours Report', command: () => {
                    //                this.selectedReportName = "Payroll/BV Hours Report v1.trdp";
                    //                //this.displayReport = true;
                    //            }
                    //        },
                    //        {
                    //            label: 'BV Equipment Usage', command: () => {
                    //                this.selectedReportName = "Infratrac/BV Equipment Usage.trdp";
                    //                //this.displayReport = true;
                    //            }
                    //        },
                    //        {
                    //            label: 'Estimated Hours Report', command: () => {
                    //                this.selectedReportName = "Backlog/Black and Veatch Report.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //        {
                    //            label: 'Invoices By Date', command: () => {
                    //                this.selectedReportName = "Infratrac/Invoices by Date for Overland.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //        {
                    //            label: 'BV Billable Hours', command: () => {
                    //                this.selectedReportName = "Infratrac/BV Billable Hours Report.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //        {
                    //            label: 'BV Equipment Hours by Foreman Hours', command: () => {
                    //                this.selectedReportName = "Equipment/BV Equipment Used with Crew Hours.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //        {
                    //            label: 'BV Unbilled', command: () => {
                    //                this.selectedReportName = "Unbilled/Black and Veatch Unbilled.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //        {
                    //            label: 'BV Underground Units Report', command: () => {
                    //                this.selectedReportName = "Customer/BV Underground CWP Report.trdp";
                    //                this.displayReport = false;
                    //            }
                    //        },
                    //    ],
                    //},
                    {
                        label: 'Dominion',

                        items: [
                            {
                                label: 'Designer Hours Export by Crew', command: () => {
                                    this.selectedReportName = "Customer/Designer Hours Export by Crew.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Dominion JPD', command: () => {
                                    this.selectedReportName = "Infratrac/Dominion JPD.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Billable Hours Report', command: () => {
                                    this.selectedReportName = "Customer/Dominion Billable Hours Report.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Invoices by Date', command: () => {
                                    this.selectedReportName = "Customer/Invoices by Date for Dominion.trdp";
                                    //this.displayReport = true;
                                }
                            }, {
                                label: 'Billable Hours Report - Earth', command: () => {
                                    this.selectedReportName = "Customer/Dominion Billable Hours Report - Earth.trdp";
                                    //this.displayReport = true;
                                }
                            }, {
                                label: 'WOs with Production Non Design', command: () => {
                                    this.selectedReportName = "Customer/Dominion WOs Production Only No Design.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            //{
                            //    label: '9010 Cable Rehab Backlog', command: () => {
                            //        this.selectedReportName = "Backlog/FPL Cable Rehab Report 9010 - Group by Percent.trdp";
                            //        //this.displayReport = true;
                            //    }
                            //},
                            //{
                            //    label: 'OAxx Unbilled Detail', command: () => {
                            //        this.selectedReportName = "FPL/Operational Adder Unbilled URD Jobs with OAAxx.trdp";
                            //        //this.displayReport = true;
                            //    }
                            //},
                            //{
                            //    label: 'FPL URD Jobs', command: () => {
                            //        this.selectedReportName = "Backlog/FPL URD and Bid Jobs - Group by Percent.trdp";
                            //        //this.displayReport = true;
                            //    }
                            //}

                        ],
                    },
                    {
                        label: 'FPL',

                        items: [
                            {
                                label: 'Daily Field Report with OAAXX', command: () => {
                                    this.selectedReportName = "DFRs/Infratech DFRs by WO FPL OAxx.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Daily Field Report with OAAXX by Date', command: () => {
                                    this.selectedReportName = "DFRs/Infratech DFRs by WO FPL OAxx and Date.trdp";
                                    //this.displayReport = true;
                                }
                            },
                           {
                                label: 'FPL WIP Supervisor Report', command: () => {
                                    this.selectedReportName = "Backlog/FPL WIP Supervisor Report - Group by Percent.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: '9010 Cable Rehab Backlog', command: () => {
                                    this.selectedReportName = "Backlog/FPL Cable Rehab Report 9010 - Group by Percent.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'OAxx Unbilled Detail', command: () => {
                                    this.selectedReportName = "FPL/Operational Adder Unbilled URD Jobs with OAAxx.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'FPL URD Jobs', command: () => {
                                    this.selectedReportName = "Backlog/FPL URD and Bid Jobs - Group by Percent.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'JPD with DFR Comments', command: () => {
                                    this.selectedReportName = "DFRs/JPD with DFR Comments.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'FPL JPD', command: () => {
                                    this.selectedReportName = "FPL/FPL Production Details.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'FPL Unbilled Dump', command: () => {
                                    this.selectedReportName = "FPL/FPL Unbilled Data Dump.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'FPL RP Bore Units Placed by Date', command: () => {
                                    this.selectedReportName = "Infratrac/FTG Placed by FPL RPs.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'FPL WOs not Final Billed', command: () => {
                                    this.selectedReportName = "Backlog/FPL WO's not FInaled - Group by Contract.trdp";
                                    //this.displayReport = true;
                                }
                            }
                          
                           
                          
                        ],
                    },
                    {
                        label: 'DEC',

                        items: [
                            {
                                label: 'SO Summary by Job Status', command: () => {
                                    this.selectedReportName = "DEC Reports/Duke SO Summary by Job Status.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DEC Revenue by Zone', command: () => {
                                    this.selectedReportName = "DEC Reports/DEC Revenue by Zone by Area and Crew Date Select.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DEC Timesheet', command: () => {
                                    this.selectedReportName = "DEC Reports/DEC Timesheet.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DEC SL Jobs Unbilled', command: () => {
                                    this.selectedReportName = "DEC Reports/Duke SL Unbilled Jobs All.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Duke Summary by Status', command: () => {
                                    this.selectedReportName = "DEC Reports/Duke Summary by Job Status.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DEC Billable Hours', command: () => {
                                    this.selectedReportName = "Customer/DEC Billable Hours Report.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DRG Hours Report', command: () => {
                                    this.selectedReportName = "DEC Reports/BRG Hours Report.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'Duke Summary by Status Upstate', command: () => {
                                    this.selectedReportName = "DEC Reports/Duke Summary by Job Status UpState.trdp";
                                    //this.displayReport = true;
                                }
                            },
                            {
                                label: 'DEC FTG Breakdown', command: () => {
                                    this.selectedReportName = "DEC Reports/DEC FTG Breakdown.trdp";
                                    //this.displayReport = true;
                                }
                            },


                        ],
                    }


                ]
            },
            {
                label: 'Crew',

                items: [
                    {
                        label: 'Crews No DFR', command: () => {
                            this.selectedReportName = "Infratrac/Crews Missing DFR.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'WO Hours Summary by Crew and Date', command: () => {
                            this.selectedReportName = "Payroll/Payroll Hours by WO and Crew.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                ]
            },
            {
                label: 'Equipment',

                items: [
                    {
                        label: 'Active Equipment', command: () => {
                            this.selectedReportName = "Equipment/Active Equipment.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Inspection Dates', command: () => {
                            this.selectedReportName = "Equipment/Equipment Inspection Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Maintenance Report', command: () => {
                            this.selectedReportName = "Equipment/Equipment Maint Record Select.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Asset Report', command: () => {
                            this.selectedReportName = "Equipment/Assets - Tristan.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Equipment Prechecks', command: () => {
                            this.selectedReportName = "Infratrac/Equipment Precheck.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    //{
                    //    label: 'Employee Missing Hours', command: () => {
                    //        this.selectedReportName = "Payroll/Employees with No Daily Hours.trdp";

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //}, {
                    //    label: 'Hours Upload', command: () => {
                    //        this.selectedReportName = "Payroll/Employee Hours Upload.trdp";

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //},
                ]
            },
            {
                label: 'Invoicing',

                items: [
                    {
                        label: 'Units Billed Summary', command: () => {
                            this.selectedReportName = "DEC Reports/SL Billing Report.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'FPL Invoices by Date', command: () => {
                            this.selectedReportName = "Infratrac/FPL Invoices by Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Customer Invoices by Contract and Date', command: () => {
                            this.selectedReportName = "Infratrac/Customer Invoices by Contract and Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    //{
                    //    label: 'Hours by Crew', command: () => {
                    //        this.selectedReportName = "Payroll/Employee Hours by Crew.trdp";

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //},
                    //{
                    //    label: 'Employee Missing Hours', command: () => {
                    //        this.selectedReportName = "Payroll/Employees with No Daily Hours.trdp";

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //}, {
                    //    label: 'Hours Upload', command: () => {
                    //        this.selectedReportName = "Payroll/Employee Hours Upload.trdp";

                    //        //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                    //    }
                    //},
                ]
            },
            {
                label: 'Payroll',

                items: [
                    {
                        label: 'Hours by Date', command: () => {
                            this.selectedReportName = "Payroll/Employee Hours by Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Hours by Crew', command: () => {
                            this.selectedReportName = "Payroll/Employee Hours by Crew.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Employee Missing Hours', command: () => {
                            this.selectedReportName = "Payroll/Employees with No Daily Hours.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    }, {
                        label: 'Hours Upload', command: () => {
                            this.selectedReportName = "Payroll/Employee Hours Upload.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    }, {
                        label: 'Hours by Area and Date', command: () => {
                            this.selectedReportName = "Payroll/Payroll Hours by Area and Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                    }, {
                        label: 'Employee Hours Summary', command: () => {
                            this.selectedReportName = "Payroll/Employee Hours Summary.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                    },


                ]
            },

            {
                label: 'Production',

                items: [
                    {
                        label: 'JPD', command: () => {
                            this.selectedReportName = "DFRs/JPD.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFRs by DFR ID', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFR no prices (Multiple).trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFRs by WO', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFRs by WO.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFR by Date & Area', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFR by Date and Area.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFR by Date & Area & Contract', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFR by Date and Area and Contract.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFR by Date & Crew', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFR by Date and Crew.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'DFR by Date & Area & Contract', command: () => {
                            this.selectedReportName = "DFRs/Infratech DFR by Date and Area and Contract.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Units Placed by Contract', command: () => {
                            this.selectedReportName = "DFRs/Units Placed by Contract.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Units Placed by Contract and Date with Revenue', command: () => {
                            this.selectedReportName = "Infratrac/Units Placed by Contract with Revenue by Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Units Placed by Crew and Date with Revenue', command: () => {
                            this.selectedReportName = "Infratrac/Units Placed by Contract with Revenue by Date by Crew.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Units Placed by Billing Code ', command: () => {
                            this.selectedReportName = "Infratrac/Units Placed by Billing Code.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'WO Summary', command: () => {
                            this.selectedReportName = "WO/WO Summary.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },

                ]
            },
            {
                label: 'RP',

                items: [
                    {
                        label: 'Backup Invoice', command: () => {
                            this.selectedReportName = "DFRs/RP Backup Invoice.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Backup Invoice Summary', command: () => {
                            this.selectedReportName = "DFRs/RP Invoice Summary by Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'Class D', command: () => {
                            this.selectedReportName = "Infratrac/Class D Report.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'RP Crew List', command: () => {
                            this.selectedReportName = "RP/RP Crews.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'RP Employee List', command: () => {
                            this.selectedReportName = "Employee/RP Employee List.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'RP Daily DFRs All', command: () => {
                            this.selectedReportName = "DFRs/RP DFR by RP and Date All.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },
                    {
                        label: 'RP Daily DFRs RP Select', command: () => {
                            this.selectedReportName = "DFRs/RP DFR by RP and Date.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        }
                    },

                ]
            },
            {
                label: 'Revenue',
                visible: this._viewRevenueReports,
                items: [
                    {
                        label: 'By Contract with Date Range',
                        command: () => {
                            this.selectedReportName = "Revenue/Revenue Daily by Contract v2 Date Select.trdp";
                          
                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                          visible: this._viewRevenueReports,
                    },
                    {
                        label: 'By Area with Date Range',
                        command: () => {
                            this.selectedReportName = "Revenue/Revenue WTD v2 Area by Date Select.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                        visible: this._viewRevenueReports,
                    },
                  

                ]

                
            },
            {
                label: 'Training',
                visible: true,
                items: [
                    {
                        label: 'CS01 Training Report',
                        command: () => {
                            this.selectedReportName = "Training/CS01 Training Report.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                       
                    },
                    {
                        label: 'Training by Module Id',
                        command: () => {
                            this.selectedReportName = "Employee/Employee Training Records by Module.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                       
                    },


                ]


            },
            {
                label: 'Unbilled',
                visible: this._viewRevenueReports,
                items: [
                    {
                        label: 'Company Unbilled Detail',
                        command: () => {
                            this.selectedReportName = "Unbilled/Company Unbilled Aging Detail.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                        //visible: this._viewRevenueReports,
                    },
                    {
                        label: 'Company Unbilled Summary',
                        command: () => {
                            this.selectedReportName = "Unbilled/Company Unbilled Aging Summary.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                    },
                    {
                        label: 'Company Unbilled Summary by Area',
                        command: () => {
                            this.selectedReportName = "Unbilled/Company Unbilled Aging Summary by Area.trdp";

                            //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                        },
                    },


                ]


            }
        ];




    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
                //console.log(this.userpermission);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                //console.log(this.userpermission);

                //set the MinDate depending on user permission
                if (this.userpermission.revenueReports == 1) {
                    this._viewRevenueReports = true;
                    this.showHideMenuItems(1, 'Unbilled');
                    this.showHideMenuItems(1, 'Revenue');
                  
                } else {
                    this._viewRevenueReports = false;
                    this.showHideMenuItems(0, 'Unbilled');
                    this.showHideMenuItems(0, 'Revenue');
                  
                }
               
           
 

              
            });

       



    }

    private getMenuItem(array: any, label: string): any {
        return array.find(item => item.label === label);
       
    }

    public showHideMenuItems(roleID: number, menu: string): void {
        //console.log('fired');
        const topLevel = this.getMenuItem(this.items, menu);
        //console.log(topLevel);
        if (menu === 'Unbilled') {
            if (roleID == 1) {
                topLevel.visible = true;
                this.getMenuItem(topLevel.items, 'Company Unbilled Detail').visible = true;
                this.getMenuItem(topLevel.items, 'Company Unbilled Summary').visible = true;
                //this.getMenuItem(topLevel.items, 'By Contract with Date Range').visible = true;
                //this.getMenuItem(topLevel.items, 'Menu 3').visible = true;
            } else {
                topLevel.visible = false;
                this.getMenuItem(topLevel.items, 'Company Unbilled Detail').visible = false;
                this.getMenuItem(topLevel.items, 'Company Unbilled Summary').visible = false;
                //this.getMenuItem(topLevel.items, 'By Contract with Date Range').visible = false;
                //this.getMenuItem(topLevel.items, 'Menu 2').visible = false;
                //this.getMenuItem(topLevel.items, 'Menu 3').visible = false;
            }
        } else if(menu === 'Revenue') {
            if (roleID == 1) {
                topLevel.visible = true;
                this.getMenuItem(topLevel.items, 'By Contract with Date Range').visible = true;
                //this.getMenuItem(topLevel.items, 'Menu 3').visible = true;
            } else {
                topLevel.visible = false;
                this.getMenuItem(topLevel.items, 'By Contract with Date Range').visible = false;
                //this.getMenuItem(topLevel.items, 'Menu 2').visible = false;
                //this.getMenuItem(topLevel.items, 'Menu 3').visible = false;
            }

        }

    }

    sendReport() {
    }
    //@ViewChild('viewer1') viewer: TelerikReportViewerComponent;

    //reportName: string = this.reportName1;
    //reportDfrId: string = this.reportDFRNum;
}
