import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CrewTypeService } from '../service/crewtypeservice';
import { CrewType } from '../domain/crewtype'
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { Message } from 'primeng/components/common/api';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
//import { isNumber } from 'util';
@Component({
    templateUrl: './crew.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService, ]
})
export class CrewComponent implements OnInit {
    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    crew: Crew = {};

    crews: Crew[];

    allCrews: Crew[];

    crewtype: CrewType = {};

    crewtypes: CrewType[];

    duplicateCrew: boolean;

    employees: Employee[];

    supervisors: Supervisor[];

    subNames: Employee[];

    subName: Employee = {};

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    displayCrewDetails: boolean;

    selectedCrew: Crew;

    newCrew: boolean;

    activeFilter: SelectItem[];

    crewName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    filteredCrews: Crew[];

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    filteredNoLegacyCrews: Crew[];

    selectedActive: string = "-1";

    selectedCrewId: string;

    msgs: Message[] = [];

    canEdit: boolean = false;

    canAdd: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    constructor(private crewService: CrewService, private service: MessageService, public snackbar: MatSnackBar, private route: Router,
        private crewTypeService: CrewTypeService, private employeeService: EmployeeService, private supervisorService: SupervisorService,
        private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service) {

        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    ngOnInit() {
        //this.getCrewsNoLegacy();

        if (this.selectedActive == '-1') {
            this.getCrewsNoLegacyFilter('-1');
        } else if (this.selectedActive == '0') {
            this.getCrewsNoLegacyFilter('0');
        } else {
            this.getCrewsNoLegacy();
        }
        this.getCrewTypes();

        this.getEmployeesbyStatus();

        this.getActiveSupervisors();

        this.getSubContractors();
        //this.employees.sort(this.dynamicSort("strLastname"));

        //console.log(this.selectedActive);

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intCrewKey', header: 'Crew ID' },
            { field: 'strCrewId', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'strArea', header: 'Area' },
            { field: 'ysnSubContractor', header: 'RP' },
            { field: 'crewtype', header: 'Crew Type' },

            //{ field: 'intForeman', header: 'Foreman' },
            { field: 'ysnActive', header: 'Active' }
        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;
                
                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                //console.log(this.userpermission.crewEdit);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.crewEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.crewCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                //console.log(this.canEdit);
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getSubContractors(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.subNames = employees.filter(e => e.intPosition == "25"));
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getActiveSupervisors(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('strAreaNumber'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    filterCrewSingle(event) {
        let query = event.query;
        this.crewService.getCrews()
            .subscribe(crews => {
                this.filteredCrews = this.filterActiveCrew(query, crews);
            });
    }

    filterActiveCrew(query, crews: any[]): any[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        for (let i = 0; i < crews.length; i++) {
            let crew = crews[i];
            if (crew.ysnActive == -1) {
                filtered.push(crew);
            }
        }
        return filtered;
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    filterNoLegacyCrews(filteredcrews: Crew[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Crew[] = [];
        for (let i = 0; i < filteredcrews.length; i++) {
            let crew = filteredcrews[i];
            if (crew.intCrewKey > '515') {
                filtered.push(crew);
            }
        }
        return filtered;
    }

    getCrewTypes(): void {
        this.crewTypeService.getCrewTypes()
            .subscribe(crewtypes => {
                this.crewtypes = crewtypes.sort(this.dynamicSort('strCrewType'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
            });
    }

    getCrewsNoLegacy(): void {
        this.crewService.getCrewsNoLegacy()
            .subscribe(crews => {
                this.crews = crews;
                //books = books.filter(v => v.id == this.filter.id);
            });
    }

    getCrewsNoLegacyFilter(status): void {
        this.crewService.getCrewsNoLegacyFilter(status)
            .subscribe(crews => {
                this.crews = crews;
                //books = books.filter(v => v.id == this.filter.id);
            });
    }

    getCrews(): void {
        this.crewService.getCrews()
            .subscribe(crews => {
                this.crews = crews;
                this.allCrews = crews;
                //books = books.filter(v => v.id == this.filter.id);
                this.filteredNoLegacyCrews = this.filterNoLegacyCrews(this.crews);
            });
    }

    getCrewsforAdding(): void {
        this.crewService.getCrews()
            .subscribe(crews => {                
                this.allCrews = crews;
                //books = books.filter(v => v.id == this.filter.id);
                
            });
    }

    addCrew(crew): void {
        if (!crew) { return; }
        this.crewService.addCrew(this.crew)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updateCrew(): void {
        let crews = [...this.crews];
        crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.crewService.updateCrew(this.crew.intCrewKey, this.crew)

            .subscribe(data => {
                //console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    checkDuplicate(crew): void {
        this.crewService.duplicateCrew(crew)
            .subscribe(crews => {
                this.crews = crews;
                //console.log(this.wos.length);
                if (this.crews.length >= 1) {
                    this.duplicateCrew = true;
                    this.service.add({
                        severity: 'error',
                        summary: 'Duplicate Crew #',
                        detail: this.crew.strCrewId + ' already exist in the system'
                    });
                } else {
                    this.duplicateCrew = false;
                    this.crew.strCrewName="Crew " + this.crew.strCrewId
                }
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.getCrewsNoLegacy();
        this.newCrew = true;
        this.crew = {};
        this.displayDialog = true;
        //console.log(this.crew);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
    }

    onSave() {
        let crews = [...this.crews];
        this.crewName = this.crew.strCrewId;
        let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        if (this.newCrew) {
            if (this.duplicateCrew) {
                this.service.add(
                    { key: 'tst', severity: 'warn', summary: 'Duplicate Crew Name', detail: this.crewName });
            }
            else {
                this.addCrew(crews);
                crews.push(this.crew);
            }
        }
        else {
            this.updateCrew();
            crews.push(this.crew);
        }

        this.crew = null;
        this.displayDialog = false;
        this.crews = crews;
        this.duplicateCrew = false;
    }

    cloneCrew(c: Crew): Crew {
        let crew = {};
        for (let prop in c) {
            crew[prop] = c[prop];
        }
        return crew;
    }

    cancel() {
        this.displayDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Added', detail: this.crewName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: this.crewName });
    }

    editCrew(rowdata) {
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);

        this.newCrew = false;
        this.crew = this.cloneCrew(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onLoad() {
        var acrews = this.crews;
        if (this.filter.id) {
            acrews = acrews.filter(v => v.YsnActive == '-1');
        }
        this.crews = acrews;
    }

    filterCountry(query, countries: any[]): any[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        for (let i = 0; i < countries.length; i++) {
            let country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }
        return filtered;
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getCrewsNoLegacy();
        } else {
            this.getCrewsNoLegacyFilter(event.value);
        }

        this.selectedActive = event.value;
    }

    onRowUnselect() {
        this.displayCrewDetails = false;
    }

    onRowSelect(event) {
        this.displayCrewDetails = true;
        //console.log(event.data.intCrewKey);
        this.selectedCrewId = event.data.intCrewKey;
    }

    onBlur() {
        this.msgs = [];
        this.checkDuplicate(this.crew.strCrewId);
        //console.log(this.userform.get('wo').value);
    }

    incrementAlphanumeric(str) {
        const numPart = str.match(/(0?[1-9])+$|0?([1-9]+?0+)$/)[0];
        const strPart = str.slice(0, str.indexOf(numPart));
        const isLastIndexNine = numPart.match(/9$/);

        // If we have a leading zero (e.g. - 'L100A099') 
        // or there is no prefix - we should just increment the number
        if (isLastIndexNine || strPart != null) {
            return strPart + numPart.replace(/\d+$/, (n) => ++n);
        }
        // Increment the number and add the missing zero
        else {
            return strPart + '0' + numPart.replace(/\d+$/, (n) => ++n);
        }
    }

    onChangeCrewType(event) {
        let crewtypeid = event.value;       

        let filtercrews = [...this.crews];

        
        if (filtercrews.length > 0) {
            filtercrews = filtercrews.filter(c => c.intCrewType == crewtypeid).sort(this.dynamicSort('strCrewId'));
        } else {
            //this.crew.strCrewId = '';
        }

        if (this.newCrew)  {

            if (filtercrews.length > 0) {
                //console.log(filtercrews.slice(-1)[0].strCrewId);
                var myInt = parseInt(filtercrews.slice(-1)[0].strCrewId);
                if (typeof myInt !== "number" || isNaN(myInt)) {
                    this.crew.strCrewId = '';
                    this.crew.strCrewName = '';
                    this.service.add({ key: 'tst', severity: 'warn', summary: 'Crew # Auto Increment Error', detail: 'Enter Crew # Prefix xx01' });
                } else {
                    this.crew.strCrewId = ((+myInt) + 1).toString();
                    this.crew.strCrewName = "Crew " + this.crew.strCrewId


                }
                //console.log(this.filteredProficiencies.slice(-1)[0].moduleInternalId);

            } else {
                this.crew.strCrewId = '';
                this.crew.strCrewName = '';
                this.service.add({ key: 'tst', severity: 'warn', summary: 'No Crew # to Auto Increment', detail: 'Enter Crew # Prefix xx01' });
            }

        }
    }

   
}
