import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingUnitTypeService } from '../service/billingunittypeservice';
import { BillingUnitType } from '../domain/billingunittype'
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission'
@Component({
    templateUrl: './billingunittype.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class BillingUnitTypeComponent implements OnInit {
    billingunittype: BillingUnitType = {};

    billingunittypes: BillingUnitType[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedBillingUnitType: BillingUnitType;

    newBillingUnitType: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitTypeId: number;

    canEdit: boolean = false;

    canAdd: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router,
        private billingUnitTypeService: BillingUnitTypeService, private fb: FormBuilder, private adalSvc: MsAdalAngular6Service, private userpermissionservice: UserPermissionService) {
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'unittype': new FormControl('', Validators.required),
            'unitdescription': new FormControl('', Validators.required),
        });
        this.getBillingUnitTypes();

        //this.employees.sort(this.dynamicSort("strLastname"));

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRP = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intBillingUnitsKey', header: 'Unit ID' },
            { field: 'strUnit', header: 'Unit Type' },
            { field: 'strDescription', header: 'Description' },

        ];
    }

    getBillingUnitTypes(): void {
        this.billingUnitTypeService.getBillingUnitTypes()
            .subscribe(billingunittypes => {
                this.billingunittypes = billingunittypes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    addBillingUnitType(billingunittype): void {
        if (!billingunittype) { return; }
        this.billingUnitTypeService.addBillingUnitTypes(this.billingunittype)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updateBillingUnitType(): void {
        let billingunittypes = [...this.billingunittypes];
        billingunittypes[this.billingunittypes.indexOf(this.selectedBillingUnitType)] = this.billingunittype;
        this.unitName = this.billingunittype.strUnit;
        //console.log(this.billingunittype);
        this.billingUnitTypeService.updateBillingUnitTypes(this.intUnitTypeId, this.billingunittype)

            .subscribe(data => {
                console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newBillingUnitType = true;
        this.billingunittype = {};
        this.displayDialog = true;
    }

    cloneBillingCodeType(c: BillingUnitType): BillingUnitType {
        let crewtype = {};
        for (let prop in c) {
            crewtype[prop] = c[prop];
        }
        return crewtype;
    }

    cancel() {
        this.displayDialog = false;
        this.billingunittype = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Type Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Type Updated', detail: this.unitName });
    }

    editCrewType(rowdata) {
        this.intUnitTypeId = rowdata.intBillingUnitsKey;
        this.newBillingUnitType = false;
        this.billingunittype = this.cloneBillingCodeType(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
        this.userform.get('unittype').setValue(this.billingunittype.strUnit);
        this.userform.get('unitdescription').setValue(this.billingunittype.strDescription);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.billingunittype.strUnit = this.userform.get('unittype').value;
        this.billingunittype.strDescription = this.userform.get('unitdescription').value;

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newBillingUnitType) {
            this.addBillingUnitType(this.billingunittype);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateBillingUnitType();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.billingunittype = {};
        this.submitted = false;
        this.userform.reset();
        this.displayDialog = false;
    }
}
