import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Job } from '../domain/job';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class JobService {
    job: Observable<Job[]>;
    newjob: Job;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('JobService');
    }
    baseUrl: string = 'api/Jobs/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getJobs(): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getJobs', []))

            );
    }

    getJobsbyContract(id): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + 'contract/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getJobsbyContract', []))

            );
    }

    getWO(id): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getJob', []))

            );
    }

    getbyWoId(id): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + 'wo/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getJobById', []))

            );
    }

    getProjects(): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + 'projects/')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getProjects', []))

            );
    }

    searchWo(wo): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + 'filterwo/' + wo)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('searchJobs', []))

            );
    }

    duplicateWo(wo): Observable<Job[]> {
        return this.http.get<Job[]>(this.baseUrl + 'duplicatewo/' + wo)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('searchJobs', []))

            );
    }

    addJob(job: Job): Observable<Job> {
        return this.http.post<Job>(this.baseUrl, job)
            .pipe(
                catchError(this.handleError('addJob', job))
            );
    }

    updateJob(id, job: Job): Observable<Job> {
        return this.http.put(this.baseUrl + id, job)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateJob', job))
            );
    }

    patchJobContract(id, job: Job): Observable<Job> {
        return this.http.patch(this.baseUrl + id, job)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateJob', job))
            );
    }

    //search(filter: { name: string } = { name: '' }, page = 1): Observable<Job> {
    //    return this.http.get<Job>(this.baseUrl)
    //        .pipe(
    //            tap((response: Job) => {
    //                response.results = response.results
    //                    .map(user => new User(user.id, user.name))
    //                    // Not filtering in the server since in-memory-web-api has somewhat restricted api
    //                    .filter(user => user.name.includes(filter.name))

    //                return response;
    //            })
    //        );
    //}

    /* GET heroes whose name contains search term */
    //searchCrews(term: string): Observable<Crew[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('crew', term) } : {};

    //    return this.http.get<Crew[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Crew[]>('searchCrew', []))
    //        );
    //}
}
