import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingUnitTypeService } from '../service/billingunittypeservice';
import { BillingUnitType } from '../domain/billingunittype'
import { BillingCodeInfratech } from '../domain/billingcodeinfratech'
import { BillingCodesInfratechService } from '../service/billingcodesinfratechservice';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DynamicControl } from '../domain/dynamiccontrol';
import { DynamicControlsService } from '../service/dynamiccontrolservice';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
@Component({
    selector: 'contract-overview',
    templateUrl: './contractoverview.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class ContractOverviewComponent implements OnInit {
    billingunittype: BillingUnitType = {};

    billingunittypes: BillingUnitType[];

    billingCodeInfratech: BillingCodeInfratech = {};

    billingCodesInfratech: BillingCodeInfratech[];

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedBillingUnitType: BillingUnitType;

    selectedBillingCode: BillingCodeCustomer;

    newBillingUnitType: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    sub;

    id;

    intContractId: string;

    selectedContractId: string = "";

    intCustomerId: string;

    contractNumber: string;

    dynamiccontrols;

    @Input() tabIndex: number = 0;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    canEditCustom: boolean = false;

    canAddCustom: boolean = false;

    canViewCustom: boolean = false;

    canViewBillingCodes: boolean = false;

    canAddWo: boolean = false;

    canEditWo: boolean = false;

    canEditBillingCodes: boolean = false;

   

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private billingUnitTypeService: BillingUnitTypeService, private fb: FormBuilder,
        private billingCodeInfratechService: BillingCodesInfratechService, private billingCodeCustomerService: BillingCodesCustomerService, private activatedroute: ActivatedRoute, private dynamicControlService: DynamicControlsService,
        private employeeService: EmployeeService, private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    //ngOnChanges(changes: SimpleChanges) {


    //    for (let propName in changes) {
    //        let change = changes[propName];
    //        let curVal = JSON.stringify(change.currentValue);
    //        let prevVal = JSON.stringify(change.previousValue);

    //        //console.log(curVal);
    //        //console.log(prevVal);

    //        if (propName === 'canAddWo') {
    //            //this.onRowSelect(curVal);
    //            this.canAddWo = JSON.parse(curVal);
                
    //            //this.getContracts(this.customerId);
    //        }

    //        if (propName === 'canEditWo') {
    //            //this.onRowSelect(curVal);
    //            this.canEditWo = JSON.parse(curVal);

    //            //this.getContracts(this.customerId);
    //        }
    //        //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
    //    }

    //    // changes.prop contains the old and the new value...


    //}

    ngOnInit() {
        //this.sub = this.activatedroute.params.subscribe(params => {
        //    this.id = params['intContractId'];
        //    this.id = this.activatedroute.snapshot.params['intContractId'];
        //});
        //console.log('loaded');
        this.intContractId = this.activatedroute.snapshot.params['intContractId'];

        this.intCustomerId = this.activatedroute.snapshot.params['intCustomerId'];

        this.dynamiccontrols = [];
        this.getDynamicControls(this.intContractId);

        this.userform = this.fb.group({
            'unittype': new FormControl('', Validators.required),
            'unitdescription': new FormControl('', Validators.required),
            'active': new FormControl('', Validators.required),
            'unit': new FormControl('', Validators.required),
            'unitprice': new FormControl('', Validators.required),
            'subprice': new FormControl(''),
            'sub2price': new FormControl(''),
        });
        this.getBillingUnitTypes();

        this.getBillingCodeInfratech();

        this.getBillingCodesCustomerbyContract(this.intContractId);

        //this.employees.sort(this.dynamicSort("strLastname"));

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'strCustomerBillingCode', header: 'Billing Code' },
            { field: 'strCustomerProductDesc', header: 'Billing Description' },
            { field: 'curUnitRate', header: 'Rate' },
            { field: 'curSubUnitRate', header: 'Sub Price' },
            { field: 'curSub2UnitRate', header: 'Sub2 Price' },
            //{ field: 'intInfratechBillingCode', header: 'Infratech Code' },
            { field: 'ysnActive', header: 'Active' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];
    }



    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                //console.log(this.userpermission.crewEdit);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;

                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.workOrderEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEditWo = true;
                } else {
                    this.canEditWo = false;
                }

                if (this.userpermission.contractEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.contractCreate == 1 || this.userpermission.intAdmin == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                if (this.userpermission.customFieldAdd == 1 || this.userpermission.intAdmin == 1) {
                    this.canAddCustom = true;
                } else {
                    this.canAddCustom = false;
                }

                if (this.userpermission.customFieldView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewCustom = true;
                } else {
                    this.canViewCustom = false;
                }

                if (this.userpermission.customFieldEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEditCustom = true;
                } else {
                    this.canEditCustom = false;
                }

                if (this.userpermission.billingCodeView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewBillingCodes = true;
                } else {
                    this.canViewBillingCodes = false;
                }

                if (this.userpermission.billingCodeView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewBillingCodes = true;
                } else {
                    this.canViewBillingCodes = false;
                }

                if (this.userpermission.workOrderCreate == 1 || this.userpermission.intAdmin == 1) {
                    this.canAddWo = true;
                } else {
                    this.canAddWo = false;
                }

                if (this.userpermission.workOrderEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEditWo = true;
                } else {
                    this.canEditWo = false;
                }

                if (this.userpermission.billingCodeEdit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEditBillingCodes = true;
                } else {
                    this.canEditBillingCodes = false;
                }



                //console.log(this.canEditWo);
            });
    }

    controlChangedHandler(count: number) {
        this.getDynamicControls(this.intContractId);
        this.tabIndex = count;
        //console.log('tab' + count);
    }

    onChangeTab(event): void {
        this.tabIndex = event.index;
        //console.log(this.tabIndex);
    }

    getDynamicControls(id): void {
        this.dynamicControlService.getDynamicControlbyContractId(id)
            .subscribe(controls => {
                this.dynamiccontrols = controls;
                //books = books.filter(v => v.id == this.filter.id);
                //console.log(this.dynamiccontrols);
            });
        //console.log(this.dynamiccontrols);
    }

    getBillingUnitTypes(): void {
        this.billingUnitTypeService.getBillingUnitTypes()
            .subscribe(billingunittypes => {
                this.billingunittypes = billingunittypes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    

    getBillingCodeInfratech(): void {
        this.billingCodeInfratechService.getBillingCodesInfratech()
            .subscribe(billingcodes => {
                this.billingCodesInfratech = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getBillingCodesCustomerbyContract(id): void {
        this.billingCodeCustomerService.getBillingCodesbyContract(id)
            .subscribe(billingcodes => {
                this.billingCodesCustomer = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getBillingCodesCustomerbyCustomer(id): void {
        this.billingCodeCustomerService.getBillingCodesbyCustomer(id)
            .subscribe(billingcodes => {
                this.billingCodesCustomer = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    addBillingCodeCustomer(billingcode): void {
        if (!billingcode) { return; }
        this.billingCodeCustomerService.addBillingCodeCustomer(this.billingCodeCustomer)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    updateBillingCodeCustomer(): void {
        let billingcodes = [...this.billingCodesCustomer];
        billingcodes[this.billingCodesCustomer.indexOf(this.selectedBillingCode)] = this.billingCodeCustomer;
        this.unitName = this.billingCodeInfratech.strInfratechCode;
        //console.log(this.billingunittype);
        this.billingCodeCustomerService.updateBillingCodeCustomer(this.intUnitId, this.billingCodeCustomer)

            .subscribe(data => {
                console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newBillingUnitType = true;
        this.billingunittype = {};
        this.displayDialog = true;
    }

    cloneBillingCode(c: BillingCodeCustomer): BillingCodeCustomer {
        let bcode = {};
        for (let prop in c) {
            bcode[prop] = c[prop];
        }
        return bcode;
    }

    cancel() {
        this.displayDialog = false;
        this.billingunittype = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Type Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Type Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intCustomerBillingCodesKey;
        this.newBillingUnitType = false;
        this.billingCodeCustomer = this.cloneBillingCode(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
        this.userform.get('unittype').setValue(this.billingCodeCustomer.strCustomerBillingCode);
        this.userform.get('unitdescription').setValue(this.billingCodeCustomer.strCustomerProductDesc);
        this.userform.get('active').setValue(this.billingCodeCustomer.ysnActive);
        this.userform.get('unit').setValue(this.billingCodeCustomer.intInfratechBillingCode);
        this.userform.get('unitprice').setValue(this.billingCodeCustomer.curUnitRate);
        this.userform.get('subprice').setValue(this.billingCodeCustomer.curSubUnitRate);
        this.userform.get('sub2price').setValue(this.billingCodeCustomer.curSub2UnitRate);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.billingCodeCustomer.strCustomerBillingCode = this.userform.get('unittype').value;
        this.billingCodeCustomer.strCustomerProductDesc = this.userform.get('unitdescription').value;
        this.billingCodeCustomer.ysnActive = this.userform.get('active').value;
        this.billingCodeCustomer.intInfratechBillingCode = this.userform.get('unit').value;
        this.billingCodeCustomer.curUnitRate = this.userform.get('unitprice').value;
        this.billingCodeCustomer.curSubUnitRate = this.userform.get('subprice').value;
        this.billingCodeCustomer.curSub2UnitRate = this.userform.get('sub2price').value;

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newBillingUnitType) {
            this.addBillingCodeCustomer(this.billingCodeCustomer);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateBillingCodeCustomer();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.billingCodeCustomer = {};
        this.submitted = false;
        this.userform.reset();
        this.displayDialog = false;
    }
}
