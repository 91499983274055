import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input, SimpleChanges } from '@angular/core';
import * as pbi from 'powerbi-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare var powerbi: any;


@Component({
    selector: 'powerbi',
    templateUrl: './powerbi.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
})
export class PowerBiComponent implements OnInit {
    @ViewChild('embeddedReport', { static: false })
    embeddedReport: ElementRef;
    config: any;
    screenHeight: number;
    report: pbi.Embed;
    @Input() selectedWoId: string = "";
    @Input() selectedContractId: string = "";
    intJobId: string = "";
    intContractId: string = "";
    //@ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

    constructor(private httpClient: HttpClient) { }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);



            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                //this.insertedEstimatedUnitId = 0;

                this.selectedWoId = curVal;
                if (this.selectedWoId) {
                    this.intJobId = JSON.parse(this.selectedWoId);
                    //console.log(this.intJobId);
                    //this.showReport();
                    //this.showReport();
                }


                //console.log(this.selectedWoId);
            }

            if (propName === 'selectedContractId') {
                this.selectedContractId = curVal;
                //console.log(this.selectedContractId);
                if (this.selectedContractId) {
                    this.intContractId = JSON.parse(this.selectedContractId);
                }
            }

           


        }

        this.showReport();

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        //this.showReport();
        //let filter = {
        //    $schema: "http://powerbi.com/product/schema#advanced",
        //    target: {
        //        table: "JobSummary",
        //        column: "intJobID"
        //    },
        //    operator: "In",
        //    values: [this.intJobId]
        //};

        //this.screenHeight = (window.screen.height);
        //this.httpClient.get<any>("api/powerbi/")
        //    .subscribe(config => {
        //        this.config = config;
        //        const model = pbi.models;
        //        const embedConfig = {
        //            type: 'report',
        //            tokenType: model.TokenType.Embed,
        //            accessToken: config.token,
        //            embedUrl: config.embedUrl,
        //            permissions: model.Permissions.All,
        //            filters: [filter],
        //            settings: {
        //                filterPaneEnabled: true,
        //                navContentPaneEnabled: true
        //            }
        //        };
        //        powerbi.embed(this.embeddedReport.nativeElement, embedConfig);
        //        //console.log(powerbi.embed(this.embeddedReport.nativeElement, embedConfig))
        //    }
        //);
    }

    showReport() {
        //console.log(this.selectedWoId);
        let filter = {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
                table: "JobSummary",
                column: "intJobID"
            },
            operator: "In",
            values: [this.intJobId]
        };
         let filter2 = {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
                table: "JobSummary",
                column: "intContractId"
            },
            operator: "In",
            values: [this.intContractId]
        };
        let filter3 = {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
                table: "JobCrewSummary",
                column: "intJobID"
            },
            operator: "In",
            values: [this.intJobId]
        };
        let filter4 = {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
                table: "CrewHrsByType",
                column: "intJobID"
            },
            operator: "In",
            values: [this.intJobId]
        };

        this.screenHeight = (window.screen.height);
        this.httpClient.get<any>("api/powerbi/")
            .subscribe(config => {
                this.config = config;
                const model = pbi.models;
                const embedConfig = {
                    type: 'report',
                    tokenType: model.TokenType.Embed,
                    accessToken: config.token,
                    embedUrl: config.embedUrl,
                    permissions: model.Permissions.All,
                    filters: [filter,filter2,filter3,filter4],
                    settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: true
                    }
                };
                powerbi.embed(this.embeddedReport.nativeElement, embedConfig);
                //console.log(powerbi.embed(this.embeddedReport.nativeElement, embedConfig))
            }
        );
       
    }

    //showReport(Token) {
    //    // Embed URL    
    //    let embedUrl = environment.powerBI.reportBaseURL;
    //    let embedReportId = environment.powerBI.reportID;
    //    let settings: pbi.IEmbedSettings = {
    //        filterPaneEnabled: false,
    //        navContentPaneEnabled: false,
    //    };
    //    let config: pbi.IEmbedConfiguration = {
    //        type: 'report',
    //        tokenType: pbi.models.TokenType.Embed,
    //        accessToken: Token.token,
    //        embedUrl: embedUrl,
    //        id: embedReportId,
    //        filters: [],
    //        settings: settings
    //    };
    //    let reportContainer = this.reportContainer.nativeElement;
    //    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    //    this.report = powerbi.embed(reportContainer, config);
    //    this.report.off("loaded");
    //    this.report.on("loaded", () => {
    //        console.log("Loaded");
    //        this.setTokenExpirationListener(Token.expiration, 2);
    //    });
    //    this.report.on("error", () => {
    //        console.log("Error");
    //    });
    //}


    //showReport(accessToken) {
    //    // Embed URL    
    //    let embedUrl = POWERBI.BASE_URL + POWERBI.REPORT_ID + "&groupId=" + POWERBI.GROUP_ID;
    //    let embedReportId = POWERBI.REPORT_ID;
    //    let config = {
    //        type: 'report',
    //        accessToken: accessToken,
    //        embedUrl: embedUrl,
    //        id: embedReportId,
    //        settings: {}
    //    };
    //    let reportContainer = this.embeddedReport.nativeElement;
    //    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    //    let report = powerbi.embed(reportContainer, config);
    //    report.off("loaded");
    //    report.on("loaded", () => {
    //        console.log("Loaded");
    //    });
    //    report.on("error", () => {
    //        //this.getToken();
    //    });
    //}  
}
