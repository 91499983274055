import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DynamicControl } from '../domain/dynamiccontrol';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DynamicControlsService {
    crew: Observable<DynamicControl[]>;
    newDynamicControl: DynamicControl;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrewService');
    }
    baseUrl: string = 'api/DynamicControls/';
    errorUrl: string = 'http://localhost:5243/api/DynamicControl/';

    getDynamicControls(): Observable<DynamicControl[]> {
        return this.http.get<DynamicControl[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDynamicControls', []))

            );
    }

    getDynamicControlbyContractId(id): Observable<DynamicControl[]> {
        return this.http.get<DynamicControl[]>(this.baseUrl + 'getControlByContractId/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            //.pipe(
            //    retry(3), // retry a failed request up to 3 times
            //    catchError(this.handleError('getControlById', []))

            //);

            .pipe(map(response => {
                if (response) {
                    return Object.values(response); //This will return the array of object values.
                }
                return []; // If response is null return empty array for safety.
            }));
    }

    getDynamicControlbyWoId(id): Observable<DynamicControl[]> {
        return this.http.get<DynamicControl[]>(this.baseUrl + 'getControlsByWoId/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            //.pipe(
            //    retry(3), // retry a failed request up to 3 times
            //    catchError(this.handleError('getControlById', []))

            //);

            .pipe(map(response => {
                if (response) {
                    return Object.values(response); //This will return the array of object values.
                }
                return []; // If response is null return empty array for safety.
            }));
    }

   

    


    addDynamicControl(control: DynamicControl): Observable<DynamicControl> {
        return this.http.post<DynamicControl>(this.baseUrl, control)
            .pipe(
                catchError(this.handleError('addControl', control))
            );
    }

    updateControl(id, control: DynamicControl): Observable<DynamicControl> {
        return this.http.put(this.baseUrl + id, control)
            .pipe(
                tap(_ => console.log(`updated control id=${id}`)),
                catchError(this.handleError('updateControl', control))
            );
    }

   
}
