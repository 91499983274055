import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { RepMaintActivity } from '../domain/repmaintactivity';
import { RepMaintVendor } from '../domain/repmaintvendors';
import { RepMaintItem } from '../domain/repmaintitem';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { RepMaintActivityService } from '../service/repmaintactivityservice';
import { RepMaintItemService } from '../service/repmaintitemservice';
import { RepMaintVendorService } from '../service/repmaintvendorservice';


@Component({
    selector: 'repmaintactivites-table',
    templateUrl: './repmaintactivitestable.component.html',

    providers: [MessageService]
})
export class RepMaintActivitiesTableComponent implements OnInit {
    repairActivities: RepMaintActivity[];

    repairActivity: RepMaintActivity = {};

    repMaintVendors: RepMaintVendor[];

    repMaintVendor: RepMaintVendor = {};

    repMaintItems: RepMaintItem[];

    repMaintItem: RepMaintItem = {};

    cols: any[];

    txType: SelectItem[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedEquipmentId: string = "";

    yearFilter: number;

    yearTimeout: any;

    displayDialog: boolean = false;

    displayAddDialog: boolean = false;

    displayAddItemDialog: boolean = false;

    transactionDate: Date;

    newRepairActivity: boolean = false;

    //isDeposit: boolean = true;

    private searchTerms = new Subject<string>();

    amount: number;

    formattedAmount: string;

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private _dateService: DateService,
        private repMaintVendorService: RepMaintVendorService,
        private repMaintActivityService: RepMaintActivityService,
        private repMaintItemsService: RepMaintItemService,
        private currencyPipe: CurrencyPipe ) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEquipmentId') {
                //this.onRowSelect(curVal);
                this.selectedEquipmentId = curVal;
                this.getRepMaintActivitiesbyId(this.selectedEquipmentId)
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.getRepMaintVendors();
        this.getRepMaintItems();

        

        //this.getActiveEmployees();

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.txType = [
            { label: 'Select One', value: null },
            { label: 'M=Maintenance', value: '1' },
            { label: 'R=Repair', value: '2' },

        ];

        this.cols = [
            //{ field: 'EmployeeID', header: 'intEmployee' },
            { field: 'dtmActivityDate', header: 'Date' },
            { field: 'abbr', header: 'Type' },
            { field: 'description', header: 'Description' },
            { field: 'maintVendor', header: 'Vendor' },
            { field: 'strPurchaseOrderNo', header: 'PO #' },
            { field: 'curPurchaseOrderAmnt', header: 'PO Amount' },
            { field: 'strInvoiceNo', header: 'Invoice #' },
            { field: 'curInvoiceAmnt', header: 'Invoice Amount' },
            //{ field: 'curDeposit', header: 'Deposit Amount' },
            { field: 'strAdditionalComments', header: 'Comments' },

            //{ field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    getRepMaintActivitiesbyId(id): void {
        this.repMaintActivityService.getRepairsbyEquipmentId(id)
            .subscribe(repairactivities => this.repairActivities = repairactivities);
    }

    addRepMaintActivity(repair): void {
        if (!repair) { return; }
        this.repMaintActivityService.addRepair(this.repairActivity)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getRepMaintActivitiesbyId(this.selectedEquipmentId);
                //this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addRepMainVendor(vendor): void {
        if (!vendor) { return; }
        this.repMaintVendorService.addRepairVendor(this.repMaintVendor)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getRepMaintVendors();
                //this.getLinemanToolPurchases(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    getRepMaintItems(): void {
        this.repMaintItemsService.getRepairItems()
            .subscribe(items => this.repMaintItems = items);

    }

    addRepMaintItem(item): void {
        if (!item) { return; }
        this.repMaintItemsService.addRepairItem(item)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getRepMaintItems();
                //this.getLinemanToolPurchases(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }


    updateMaintActivity(): void {
        //let contracts = [...this.contracts];
        //contracts[this.contracts.indexOf(this.selectedContract)] = this.customer;
        //this.contractName = this.customer.strCustomerName;
        this.repMaintActivityService.updateRepair(this.repairActivity.intRepMaintActivityID, this.repairActivity)

            .subscribe(data => {
                this.showSuccessUpdateViaToast();
                //this.showSuccessUpdateViaToast();
                this.getRepMaintActivitiesbyId(this.selectedEquipmentId);
                this.ngOnInit();

                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

    getRepMaintVendors(): void {
        this.repMaintVendorService.getRepairVendors()
            .subscribe(vendors => this.repMaintVendors = vendors);
        
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    showDialogToAdd() {
        this.repairActivity = {};
        this.repairActivity.intEquipNo = this.selectedEquipmentId;
        this.repairActivity.dtmActivityDate = new Date(Date.now());
        //this.repairActivity.intTypeDesc = '1';
        this.newRepairActivity = true;
        this.displayDialog = true;
    }

    showDialogToAddVendor() {
       
        this.displayAddDialog = true;
    }

    showDialogToAddItem() {

        this.displayAddItemDialog = true;
    }

    onSelect(value) {
        //const value = event.target.value;
        this.transactionDate = value;
        //console.log(this.transactionDate);
        this.repairActivity.dtmActivityDate = new Date( this._dateService.toString(this.transactionDate));
    }

    onChange(event) {
        //console.log(event.value);
        //if (event.value == '1') {
        //    this.isDeposit = true;
        //} else if (event.value == '2') {
        //    this.isDeposit = false;
        //} else {
        //    this.isDeposit = null;
        //}
    }

    onCancel() {
        this.displayDialog = false;
        //this.isDeposit = true;
        this.repairActivity = {};
    }

    onCancelAddVendor() {
        this.displayAddDialog = false;
        //this.isDeposit = false;
        this.repMaintVendor = {};
    }

    onCancelAddItem() {
        this.displayAddItemDialog = false;
        //this.isDeposit = false;
        this.repMaintItem = {};
    }

    onNewItemSave() {
        let items = [...this.repMaintItems];
        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();

        this.addRepMaintItem(this.repMaintItem);
        items.push(this.repMaintItem);

        //this.customerService.addCustomer(this.customer)
        //    .subscribe(data => {
        //        customers.push(this.customer);
        //        console.log(data);
        //        this.ngOnInit();
        //        this.showSuccessViaToast();
        //    });



        this.repMaintItems = items;
        this.repMaintItem = {};
        this.displayAddItemDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    onNewVendorSave() {
        let vendors = [...this.repMaintVendors];
        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
      
            this.addRepMainVendor(vendors);
            vendors.push(this.repMaintVendor);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        
       

        this.repMaintVendors = vendors;
        this.repMaintVendor = {};
        this.displayAddDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    onSave() {
        let repairs = [...this.repairActivities];
        const epochNow = (new Date).getTime();
        //console.log(epochNow);

        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
        if (this.newRepairActivity) {
            this.addRepMaintActivity(repairs);
            repairs.push(this.repairActivity);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.repairActivity.mDateUpdated = epochNow;
            this.updateMaintActivity();

            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.repairActivities = repairs;
        this.repairActivity = {};
        this.displayDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    editPurchase(rowdata) {
        this.newRepairActivity = false;
        this.repairActivity = this.clone(rowdata);
        //console.log(this.linemanToolPurchase);
        this.repairActivity.dtmActivityDate = new Date(this.repairActivity.dtmActivityDate);
        //if (this.linemanToolPurchase.intTxtype == '1') {
        //    this.isDeposit = true;
        //} else if (this.linemanToolPurchase.intTxtype == '2') {
        //    this.isDeposit = false;
        //} else {
        //    this.isDeposit = null;
        //}
        this.displayDialog = true;
    }

    clone(c: RepMaintActivity): RepMaintActivity {
        let purchase = {};
        for (let prop in c) {
            purchase[prop] = c[prop];
        }
        return purchase;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Maintenance Activity Added', detail: 'Added' });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Maintenance Activity Updated', detail: 'Updated' });
    }

    transformAmount(element) {
        this.amount = element.target.value.replace(/[$,]/g, "");
        this.formattedAmount = this.currencyPipe.transform(this.amount, '$')

        element.target.value = this.formattedAmount;
        //console.log(element.target)
        if (element.target.name === "poamount") {
            this.repairActivity.curPurchaseOrderAmnt = this.amount
        } else if (element.target.name === "invoiceamnt") {
            this.repairActivity.curInvoiceAmnt = this.amount

        }
    }

    removeCurrencyPipeFormat(formatedNumber) {
        return formatedNumber.replace(/[$,]/g, "")
    }

    validateOnlyNumbers(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
}
