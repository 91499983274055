import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Contract } from '../domain/contract';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ContractService {
    contract: Observable<Contract[]>;
    newcontract: Contract;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ContractService');
    }
    baseUrl: string = 'api/Contracts/';
    errorUrl: string = 'http://localhost:5243/api/Contracts/';

    getContracts(): Observable<Contract[]> {
        return this.http.get<Contract[]>(this.baseUrl)

            // .pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getContracts', []))

            );
    }

    getContract(id): Observable<Contract[]> {
        return this.http.get<Contract[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getContract', []))

            );
    }

    getContractbyId(id): Observable<Contract[]> {
        return this.http.get<Contract[]>(this.baseUrl + 'contractbyid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getContractbyID', []))

            );
    }

    getContractbyCustomerId(id): Observable<Contract[]> {
        return this.http.get<Contract[]>(this.baseUrl + 'contractbycustomerid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getContractbyCustomerID', []))

            );
    }

    addContract(contract: Contract): Observable<Contract> {
        return this.http.post<Contract>(this.baseUrl, contract)
            .pipe(
                catchError(this.handleError('addContract', contract))
            );
    }

    updateContract(id, contract: Contract): Observable<Contract> {
        return this.http.put(this.baseUrl + id, contract)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateContract', contract))
            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
