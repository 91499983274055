import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CustomerInvoice } from '../domain/customerinvoice';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CustomerInvoiceService {
    customerInvoice: Observable<CustomerInvoice[]>;
    newCustomerInvoice: CustomerInvoice;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CustomerInvoice');
    }
    baseUrl: string = 'api/CustomerInvoices/';
    errorUrl: string = 'http://localhost:5243/api/Customers/';

    getCustomerInvoices(): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCustomerInvoices', []))

            );
    }

    getCustomerInvoicebyWoId(id): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl + 'getCustomerInvoicesbyWoId/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getWoInvoices', []))

            );
    }

    getInvoiceforGroupInvoice(id): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl + 'getInvoicesforGroupInvoice/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getInvoicesforGroupInvoice', []))

            );
    }

    getInvoiceforGroupInvoicebyContractId(id): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl + 'getInvoicesforGroupInvoicebyContract/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getInvoicesforGroupInvoicebyContract', []))

            );
    }

    getInvoiceforPaymentContractId(id): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl + 'getInvoicesforPaymentbyContract/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getInvoicesforPaymentbyContract', []))

            );
    }

    getInvoiceforPaymentCustomerId(id): Observable<CustomerInvoice[]> {
        return this.http.get<CustomerInvoice[]>(this.baseUrl + 'getInvoicesforPaymentbyCustomer/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getInvoicesforPaymentbyCustomer', []))

            );
    }

    addCustomerInvoice(customerInvoice: CustomerInvoice): Observable<CustomerInvoice> {
        return this.http.post<CustomerInvoice>(this.baseUrl, customerInvoice)
            .pipe(
                catchError(this.handleError('addCustomerInvoice', customerInvoice))
            );
    }

    updateCustomerInvoice(id, customerInvoice: CustomerInvoice): Observable<CustomerInvoice> {
        return this.http.put(this.baseUrl + id, customerInvoice)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateCustomer', customerInvoice))
            );
    }

    deleteCustomerInvoice(id): Observable<CustomerInvoice> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Invoice id=${id}`)),
                catchError(this.handleError('Delete Customer Invoice'))
            );
    }
}
