import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/primeng';
import { DailyFieldReport } from '../domain/dailyfieldreport';
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { DatePipe } from '@angular/common';
import { Job } from '../domain/job';

import { JobService } from '../service/jobservice';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    templateUrl: './project.component.html',
    providers: [MessageService]
})

export class ProjectComponent implements OnInit {
    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    ytdRevenue: DailyFieldReport[];

    dailyRevenue: DailyFieldReport[];

    wtdRevenue: DailyFieldReport[];

    mtdRevenue: DailyFieldReport[];

    billRequests: DailyFieldReport[];

    

    cols: any[];

   

    items: MenuItem[];

    newDFRs: number;

    revenueYTD: number;

    revenueDaily: number;

    revenueWTD: number;

    http: any;
    response: any;
    mydefaultImageResponse: any;

    userName: string;

    lastUpdate: Date;

    wo: Job = {};

    wos: Job[];

    public isAuthenticated: boolean;

    my_token: string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyIsImtpZCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyJ9.eyJhdWQiOiJodHRwczovL21hbmFnZW1lbnQuY29yZS53aW5kb3dzLm5ldC8iLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC81NzVjYjk5Yy1iZjI5LTQ4NjYtYThkMy1kMmJjMTgxMTUzNTYvIiwiaWF0IjoxNTc0MDQxMDMyLCJuYmYiOjE1NzQwNDEwMzIsImV4cCI6MTU3NDA0NDkzMiwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhOQUFBQUVvQnByNUluakZsbCtDOWNBK2xGZ3NlalRJYjlFV1hWMG1zRjBIUTZTbUxoaFEwekJ6cnhNTlJRMXJ2bmRmaTBEcVVrNEdDL2o3aGFVNWNCU0JHSW9EVFoza2hNOHN3WlQxV0JBQ0h0bUFVPSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiI3ZjU5YTc3My0yZWFmLTQyOWMtYTA1OS01MGZjNWJiMjhiNDQiLCJhcHBpZGFjciI6IjIiLCJmYW1pbHlfbmFtZSI6IkluZnJhdGVjaCIsImdpdmVuX25hbWUiOiJBZG1pbmlzdHJhdG9yIiwiZ3JvdXBzIjpbIjU1NDY4OWNlLTZjZDYtNDEwMi1iMzc5LTJjM2Y2ZjNlYzI5ZSJdLCJpcGFkZHIiOiIxMDcuMTk4LjQxLjM1IiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJvaWQiOiI0Y2I4OGFiOC0xYjY4LTQwZDQtOTM2ZS0xMmQ2NDMzYTUwZWYiLCJwdWlkIjoiMTAwMzdGRkU4NzczRUNGNyIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6InlZZWdxWWtGZW9JN2txX0JjMS1rNnphV0ZUWHlCT08zUzkzSHJhR2dGc0kiLCJ0aWQiOiI1NzVjYjk5Yy1iZjI5LTQ4NjYtYThkMy1kMmJjMTgxMTUzNTYiLCJ1bmlxdWVfbmFtZSI6IkluZnJhdGVjaEFkbWluQGluZnJhdGVjaGNvcnAuY29tIiwidXBuIjoiSW5mcmF0ZWNoQWRtaW5AaW5mcmF0ZWNoY29ycC5jb20iLCJ1dGkiOiJKWEdoaVNiQm5FdTF5UUVqUGpXZ0FBIiwidmVyIjoiMS4wIiwid2lkcyI6WyI2MmU5MDM5NC02OWY1LTQyMzctOTE5MC0wMTIxNzcxNDVlMTAiXX0.F4AYMGRzI-BmMp1qArsIrrEPamjfRD17p8BRXcITHG5xcRvfPTcnp7TFyfhQ5kAwmrrvpel6AU21zwL0TF9Lem_CvEno97bH1nwhfLUBIFtW_SbPmLr7koCV5pbaX6N_Qm5XIm - clvC9PHUVx6EmNMH_PDw2rMbuEIRWuElMgw2GP2d8h8_TyXj - gwEqge0pRlJfDiB - Zh_Rm8zXZ5E5sqFmwVYK0WiGWsW7yt - HCe3k2ksG5ofXaisj9zaojuGUe8mj3keE2k6RHElxmaxdXQ2pZj4MZ73X8h_ygp0T55B9YYpYIG4QN4DSz7QrcVVgRWaoUOs1boYLGr_H1FhGxw'

  

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private dfrService: DailyFieldReportService, private adalSvc: MsAdalAngular6Service, private jobService: JobService) {
        //console.log(this.adalSvc.userInfo);
        this.userName = this.adalSvc.LoggedInUserName;
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
        });
    }

    ngOnInit() {
        //this.carService.getCarsMedium().then(cars => this.cars = cars);

        //this.getUnapprovedDfrs();

        //this.getRevenueYTD();

        //this.getRevenueDaily();

        this.lastUpdate = new Date(new Date().setDate(new Date().getDate()));

        //this.getRevenueWTD();

        //this.getRevenueMTD();

        this.getProjects();

        //this.getBillRequest();

        //this.getAzureStatus('https://management.azure.com/subscriptions/f73c759e-96e5-48ec-82e4-d073e3957276/resourceGroups/sql/providers/Microsoft.Sql/servers/Infratech/databases/Infratrac?api-version=2017-10-01-preview');

        //this.getImageUrlStatus('http://win-87ve089ko00:8080/api/OfficeLocations');

        //this.http.get('https://jsonplaceholder.typicode.com/todos/2')
        //    .subscribe((data) => {
        //        this.data = data;
        //    });

        //this.http.get('https://jsonplaceholder.typicode.com/todo/2').pipe(
        //    catchError((err) => {
        //        this.snackbar.open('Network request is failed', 'Failed', {
        //            duration: 1500,
        //        });
        //        return of();
        //    })
        //);



        this.cols = [
            { field: 'formattedDate', header: 'Date' },
            { field: 'intReportNo', header: 'DFR#' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'strJob', header: 'WO#' },
            { field: 'strJobName', header: 'WO Job Name' },
            { field: 'isTabletRow', header: 'Tablet' },
            { field: 'intCrewHours', header: 'Crew Hours' },
            { field: 'taskname', header: 'Task' },
        ];

        this.items = [
            { label: 'Settings', icon: 'fa fa-cog' },
            { label: 'Sign Out', icon: 'fa fa-sign-out' },
        ];
    }

    getUnapprovedDfrs(): void {
        this.dfrService.getUnapprovedDfrs()
            .subscribe(dfrs => {
                this.dfrs = dfrs;
                this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getRevenueYTD(): void {
        this.dfrService.getRevenueYTD()
            .subscribe(dfrs => {
                this.ytdRevenue = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.ytdRevenue);
            });
    }

    getRevenueDaily(): void {
        this.dfrService.getRevenueDaily()
            .subscribe(dfrs => {
                this.dailyRevenue = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.ytdRevenue);
            });
    }

    getRevenueWTD(): void {
        this.dfrService.getRevenueWTD()
            .subscribe(dfrs => {
                this.wtdRevenue = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.ytdRevenue);
            });
    }

    getRevenueMTD(): void {
        this.dfrService.getRevenueMTD()
            .subscribe(dfrs => {
                this.mtdRevenue = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.ytdRevenue);
            });
    }

    getBillRequest(): void {
        this.dfrService.getInvoiceRequest()
            .subscribe(dfrs => {
                this.billRequests = dfrs;
                //this.newDFRs = dfrs.length;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.ytdRevenue);
            });
    }




    getImageUrlStatus(url: string) {
        this.http.get(url).subscribe(
            response => this.response = response.toString(),
            error => this.response = this.mydefaultImageResponse);
        console.log(this.response);
    }

    getProjects(): void {
        this.jobService.getProjects()
            .subscribe(wos => {
                this.wos = wos;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    makeRequest() {
        return this.http.get('https://management.azure.com/subscriptions/f73c759e-96e5-48ec-82e4-d073e3957276/resourceGroups/sql/providers/Microsoft.Sql/servers/Infratech/databases/Infratrac?api-version=2017-10-01-preview', {
            headers: new HttpHeaders(
                {
                    'Authorization': 'Bearer' + this.my_token,
                    'Content-Type': 'application/json'
                })
            
        });
    }

    getAzureStatus(url: string) {
        this.http.get(url, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.my_token) })
            .subscribe(data => {
                console.log(data);
            });
    }

    //public IsClientCreditCardExits(companyId: string, token: any) {
    //    let header = new Headers({ 'Authorization': `Bearer ${token}` });
    //    const options = new RequestOptions({
    //        headers: header,
    //    });
    //    return this._http.get(this.ApiURL + "api/Subscriptions/IsClientCreditCardExits/" + companyId + "/", options);
    //}

    
}

