import { AfterViewInit, Component, ViewChild, ViewEncapsulation, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'report-viewer',
    templateUrl: './reportviewer.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('dialog', [
            transition('void => *', [
                style({ transform: 'scale3d(.3, .3, .3)' }),
                animate(100)
            ]),
            transition('* => void', [
                animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
            ])
        ])
    ]
})

export class ReportViewerComponent {
    @Input() closable = true;
    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() reportName1: string;
    @Input() reportDFRNum: string;
    @Input() reportInvoiceId: string;
    @Input() employeeId: string;
    @Input() GroupInvoiceNum: string;
    reportName: string;
    reportDfrId: string;
    @Input() WO: string;
    WONum: string = null;
    groupInvoiceNum: string;

    constructor() { }
    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'reportName1') {
                //this.onRowSelect(curVal);
                this.reportName = change.currentValue;
                //console.log(this.reportName);
            }

            if (propName === 'reportDFRNum') {
                //this.onRowSelect(curVal);
                this.reportDfrId = change.currentValue;
                //console.log(this.reportDfrId);
            }

            if (propName === 'WO') {
                //this.onRowSelect(curVal);
                this.WONum = change.currentValue;
                //console.log(this.reportDfrId);
            }

            if (propName === 'GroupInvoiceNum') {
                //this.onRowSelect(curVal);
                this.groupInvoiceNum = change.currentValue;
                //console.log(this.reportDfrId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }
    ngOnInit() {
        //reportName: string = this.reportName1;
        //reportDFR: string = this.reportDFRNum;
    }

    close() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }
    //@ViewChild('viewer1') viewer: TelerikReportViewerComponent;
    viewerContainerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        ['font-family']: 'ms sans serif',
       
        
    };

    //reportName: string = this.reportName1;
    //reportDfrId: string = this.reportDFRNum;
}
