import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CrewMember } from '../domain/crewmembers';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CrewMemberService {
    contract: Observable<CrewMember[]>;
    newcontract: CrewMember;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrewMemberService');
    }
    baseUrl: string = 'api/CrewMembers/';
    errorUrl: string = 'http://localhost:5243/api/Contracts/';

    getCrewMember(): Observable<CrewMember[]> {
        return this.http.get<CrewMember[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getContracts', []))

            );
    }

    getCrewMemberbyId(id): Observable<CrewMember[]> {
        return this.http.get<CrewMember[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCrewMember', []))

            );
    }

    getCrewMemberbyCrewId(id): Observable<CrewMember[]> {
        return this.http.get<CrewMember[]>(this.baseUrl + 'crewmembersbycrewid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCrewMemberbyCrewID', []))

            );
    }

    addCrewMember(crewmember: CrewMember): Observable<CrewMember> {
        return this.http.post<CrewMember>(this.baseUrl, crewmember)
            .pipe(
                catchError(this.handleError('addCrewMember', crewmember))
            );
    }

    updateCrewMember(id, crewmember: CrewMember): Observable<CrewMember> {
        return this.http.put(this.baseUrl + id, crewmember)
            .pipe(
                tap(_ => console.log(`updated Crew Member id=${id}`)),
                catchError(this.handleError('updateCrewMember', crewmember))
            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
