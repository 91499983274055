import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SubContractor } from '../domain/subcontractor';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SubContractorService {
    subcontractor: Observable<SubContractor[]>;
    newcontract: SubContractor;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('SubContractorService');
    }
    baseUrl: string = 'api/SubContractors/';
    errorUrl: string = 'http://localhost:5243/api/Contracts/';

    getSubContractors(): Observable<SubContractor[]> {
        return this.http.get<SubContractor[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSubContractors', []))

            );
    }

    getSubContractor(id): Observable<SubContractor[]> {
        return this.http.get<SubContractor[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSubContractor', []))

            );
    }


    addSubContractor(subcontractor: SubContractor): Observable<SubContractor> {
        return this.http.post<SubContractor>(this.baseUrl, subcontractor)
            .pipe(
                catchError(this.handleError('addSubContractor', subcontractor))
            );
    }

    updateSubContractor(id, subcontractor: SubContractor): Observable<SubContractor> {
        return this.http.put(this.baseUrl + id, subcontractor)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateSubContractor', subcontractor))
            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
