import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EstimatedUnit } from '../domain/estimatedunits';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class EstimatedUnitService {
    delay: Observable<EstimatedUnit[]>;
    newestimatedunit: EstimatedUnit;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('EstimatedUnitService');
    }
    baseUrl: string = 'api/EstimatedUnits/';
    errorUrl: string = 'http://localhost:5243/api/EstimatedUnits/';

    getEstimatedUnit(): Observable<EstimatedUnit[]> {
        return this.http.get<EstimatedUnit[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnit', []))

            );
    }

    //getEquipmentbyStatus(status): Observable<EstimatedUnit[]> {
    //    return this.http.get<EstimatedUnit[]>(this.baseUrl + 'activefilter/' + status)

    //        //.pipe(
    //        //    map((e: any[]) => e.json()),
    //        //    catchError((e: Response) => throwError(e))
    //        //);
    //        .pipe(
    //            retry(3), // retry a failed request up to 3 times
    //            catchError(this.handleError('getActiveStatus', []))

    //        );
    //}

    addEstimatedUnit(unit: EstimatedUnit): Observable<EstimatedUnit> {
        return this.http.post<EstimatedUnit>(this.baseUrl, unit)
            .pipe(
                catchError(this.handleError('addEstimtatedUnit', unit))
            );
    }

    updateEstimatedUnit(id, estimatedunit: EstimatedUnit): Observable<EstimatedUnit> {
        return this.http.put(this.baseUrl + id, estimatedunit)
            .pipe(
                tap(_ => console.log(`updated Estimated Unit id=${id}`)),
                catchError(this.handleError('updateEstimatedUnit', estimatedunit))
            );
    }

    deleteEstimatedUnit(id): Observable<EstimatedUnit> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Estimated Unit id=${id}`)),
                catchError(this.handleError('Estimated Unit'))
            );
    }

    getEstimatedUnitId(id): Observable<EstimatedUnit[]> {
        return this.http.get<EstimatedUnit[]>(this.baseUrl + 'unitid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnitbyID', []))

            );
    }

    getEstimatedUnitsbyJobId(id): Observable<EstimatedUnit[]> {
        return this.http.get<EstimatedUnit[]>(this.baseUrl + 'jobid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEstimatedUnitbyJobID', []))

            );
    }
}
