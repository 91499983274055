import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectItem, Dropdown } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { ProductionDelay } from '../domain/productiondelay'
import { ProductionDelayService } from '../service/productiondelayservice';

@Component({
    selector: 'dfrproductiondelays-table',
    templateUrl: './dfrproductiondelays.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class DfrProductionDelaysComponent implements OnInit {
    cols: any[];

    productionDelays: ProductionDelay[] = [];

    productionDelay: ProductionDelay = {};

    selectedProductionDelay: ProductionDelay;

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    newProductionDelay: boolean;

    activeFilter: SelectItem[];

    filter: any = {};

    submitted: boolean;

    @Input() insertedDfrId: string = "";

    showDelete: boolean = false;

    productionDelayItems: SelectItem[];

    productionDelayId: string;

    @ViewChild('qty', { static: false }) qty: ElementRef;

    dfrId: string;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private fb: FormBuilder,
        private _dateService: DateService, private renderer: Renderer, private productiondelayservice: ProductionDelayService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'insertedDfrId') {
                this.insertedDfrId = curVal;
                if (this.insertedDfrId)
                this.dfrId = JSON.parse(this.insertedDfrId);
                this.insertedDfrId = this.dfrId
                //console.log(this.selectedContractId);
                if (this.insertedDfrId) {
                    this.getDelaysByDFR(this.insertedDfrId);
                }
            }
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'productionDelay', header: 'Delay Type', width: '15%' },
            { field: 'intHours', header: 'Hours', width: '15%' },
            { field: 'strComments', header: 'Comments', width: '70%' },

        ];

        this.productionDelayItems = [
            { label: 'Select Delay', value: null },
            { label: 'Travel', value: 1 },
            { label: 'Soil', value: 2 },
            { label: 'Utility', value: 3 },
            { label: 'Utility Hit', value: 4 },
            { label: 'Weather', value: 5 },
            { label: 'Other', value: 6 },

        ];
    }

    getDelaysByDFR(id): void {
        this.productiondelayservice.getDelaysbyId(id)
            .subscribe(data => this.productionDelays = data);
    }

    addProductionDelays(delay, dfrid): void {
        if (!delay) { return; }
        this.productiondelayservice.addDelay(delay)
            .subscribe(
                data => {
                    //console.log(data);
                    let message = 'Added';
                    this.getDelaysByDFR(dfrid);
                    this.showSuccessViaToast(message);
                });
    }

    updateDelay(delayid, delay, dfrid): void {
        if (!delay) { return; }
        this.productiondelayservice.updateDelay(delayid, delay)
            .subscribe(
                data => {
                    this.getDelaysByDFR(dfrid);
                    this.showSuccessUpdateViaToast();
                });
    }

    deleteProductionDelay(id): void {
        this.productiondelayservice.deleteDelay(id)
            .subscribe();
        this.showdeletedmessage('Deleted', 'Deleted');
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAdd() {
        this.newProductionDelay = true;
        this.productionDelay = {};
        this.showDelete = false;
        this.displayDialog = true;
    }

    cancel() {
        this.displayDialog = false;
    }

    showBcSuccessViaToast(qty: string, billingcode: string) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Delay Added', detail: qty + '-' + billingcode });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Delay Updated', detail: '' });
    }

    showSuccessViaToast(message) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Delay Updated', detail: message });
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onChange(event) {
        setTimeout(() => this.qty.nativeElement.focus());
    }

    onConfirm() {
        this.service.clear('d');
        let index = this.productionDelays.indexOf(this.selectedProductionDelay);
        this.deleteProductionDelay(this.productionDelayId);
        this.productionDelays = this.productionDelays.filter((val, i) => i != index);
        this.productionDelay = null;
        this.displayDialog = false;
    }

    onReject() {
        this.service.clear('d');
    }

    save() {
        if (this.productionDelay.intProdDelayId) {
            this.productionDelay.intReportNo = this.insertedDfrId;
            //this.productionDelay.intProdDelayId = '1';
            //console.log(this.insertedDfrId);
            console.log(this.productionDelay);
            let productiondelays = [...this.productionDelays];

            if (this.newProductionDelay) {
                productiondelays.push(this.productionDelay);
                this.addProductionDelays(this.productionDelay, this.insertedDfrId);
            }
            else {
                productiondelays[this.productionDelays.indexOf(this.selectedProductionDelay)] = this.productionDelay;
                this.updateDelay(this.productionDelayId, this.productionDelay, this.insertedDfrId);
            }
            this.productionDelays = productiondelays;
            this.productionDelay = {};
            this.displayDialog = false;
        } else {
            this.showdNeedDelayType();
        }
    }

    onRowSelect(event) {
        this.showDelete = true;
        this.newProductionDelay = false;
        this.productionDelay = this.cloneProduction(event.data);
        this.displayDialog = true;
        this.productionDelayId = this.productionDelay.intProductionDelayKey;
        //this.deletedBc = this.billableProduction.strCustomerBillingCode;
        //this.deletedBcDescription = this.billableProduction.strBcdescription;
        //this.deletedQty = this.billableProduction.intQty;
    }

    cloneProduction(p: ProductionDelay): ProductionDelay {
        let productiondelay = {};
        for (let prop in p) {
            productiondelay[prop] = p[prop];
        }
        return productiondelay;
    }

    delete() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Delay?',
            detail: 'Deleted Production Delay'
        });
    }

    showdeletedmessage(billingcode, description) {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Delay Deleted', detail: 'Deleted' });
    }

    showdNeedDelayType() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Need Delay Type', detail: 'Select a Delay Type' });
    }
}
