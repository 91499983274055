import { Component, OnInit, ViewEncapsulation, ViewChild, NgModule, Input, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem, MenuItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolVendor } from '../domain/linemantoolvendor';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from '../service/linemantoolvendorservice';
import { PayRollHour } from '../domain/payrollhour';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';

@Component({
    selector: 'user-permissions',
    templateUrl: './userpermission.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class UserPermissionComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    employees: Employee[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    employee: Employee = {};

    payrollHours: PayRollHour[];

    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolVendors: LinemanToolVendor[];

    cols: any[];

    payrollcols: any[];

    linemantoolPurccols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedEmployee: Employee;

    activeFilter: SelectItem[];

    perdiem: SelectItem[];

    txType: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    displayEmployeeDetails: boolean = false;

    newEmployee: boolean;

    selectedActive: string = "Active";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];
        

    userform: FormGroup;

    submitted: boolean;

    genders: SelectItem[];

    description: string;

    hireDate: Date;

    termDate: Date;

    rehireDate: Date;

    orientationDate: Date;

    bootPurchaseDate: Date;

    glassesPurchaseDate: Date;

    benefits: SelectItem[];

    viewRate: boolean = false;

    employeeName: string;

    yearFilter: number;

    yearTimeout: any;

    visible: boolean = true;

    orientationDone: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    newAuth: boolean = false;

    items: MenuItem[];

    displayReport: boolean = false;

    selectedDfrId: string = null;

    selectedReportName: string = null;

    private searchTerms = new Subject<string>();

    @Input() selectedEmployeeId: string = "";

    @Input() save: boolean = false;

    @Input() isNewEmployee: boolean = false;

    savePermissions: boolean = false;

    addNewPermissions: boolean = false;

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private payRollHourService: PayRollHourService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private linemanToolVendorService: LinemanToolVendorServices,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEmployeeId') {
                if (this.selectedEmployeeId) { 
                this.selectedEmployeeId = curVal;
                //console.log(this.selectedEmployeeId);
                //console.log('fired');
                if (this.selectedEmployeeId) {
                    this.getUserPermissions(this.selectedEmployeeId);
                }
            }
            }

            if (propName === 'save') {
                if (this.save) {
                     //console.log('fired');
                    this.savePermissions = this.save;
                    this.onSave();

                }
            }

            if (propName === 'isNewEmployee') {
                 //console.log('fired');
                if (this.isNewEmployee) {
                    //console.log('new Employee');
                    //this.addNewPermissions = true;
                    this.userform.reset;
                } else {
                    //console.log('Existing Employee');
                    //this.addNewPermissions = false;
                }

                
            }

            
        }
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'contractCreate': new FormControl('', ),
            'contractView': new FormControl('', ),
            'contractEdit': new FormControl(''),
            'customerCreate': new FormControl(''),
            'customerView': new FormControl(''),
            'customerEdit': new FormControl(''),
            'employeeCreate': new FormControl(''),
            'employeeView': new FormControl(''),
            'employeeEdit': new FormControl(''),          
            'bcCreate': new FormControl(''),
            'bcView': new FormControl(''),
            'bcEdit': new FormControl(''),
            'crewCreate': new FormControl(''),
            'crewView': new FormControl(''),
            'crewEdit': new FormControl(''),
            'jobBriefCreate': new FormControl(''),
            'jobBriefView': new FormControl(''),
            'jobBriefEdit': new FormControl(''),
            'woCreate': new FormControl(''),
            'woView': new FormControl(''),
            'woEdit': new FormControl(''),
            'dfrCreate': new FormControl(''),
            'dfrView': new FormControl(''),
            'dfrEdit': new FormControl(''),
            'dfrApproval': new FormControl(''),
            'dfrSubmitDate': new FormControl(''),
            'dfrViewPrice': new FormControl(''),
            'dfrEditPrice': new FormControl(''),
            'dfrEditApprovedDfr': new FormControl(''),
            'piCreate': new FormControl(''),
            'piView': new FormControl(''),
            'piEdit': new FormControl(''),
            'pdCreate': new FormControl(''),
            'pdView': new FormControl(''),
            'pdEdit': new FormControl(''),
            'uhCreate': new FormControl(''),
            'uhView': new FormControl(''),
            'uhEdit': new FormControl(''),
            'vaCreate': new FormControl(''),
            'vaView': new FormControl(''),
            'vaEdit': new FormControl(''),
            'qaCreate': new FormControl(''),
            'qaView': new FormControl(''),
            'qaEdit': new FormControl(''),
            'saCreate': new FormControl(''),
            'saView': new FormControl(''),
            'saEdit': new FormControl(''),
            'spCreate': new FormControl(''),
            'spView': new FormControl(''),
            'spEdit': new FormControl(''),
            'submitBilling': new FormControl(''),
            'createInvoice': new FormControl(''),
            'skipBriefPreCheck': new FormControl(''),
            'eJobs': new FormControl(''),
            'viewEJobs': new FormControl(''),
            'vendorCreate': new FormControl(''),
            'vendorView': new FormControl(''),
            'vendorEdit': new FormControl(''),
            'rpCreate': new FormControl(''),
            'rpView': new FormControl(''),
            'rpEdit': new FormControl(''),
            'isAdmin': new FormControl(''),
            'viewPayRate': new FormControl(''),
            'intAdmin': new FormControl(''),
            'equipmentView': new FormControl(''),
            'equipmentAdd': new FormControl(''),
            'equipmentEdit': new FormControl(''),
            'employeeCounselingAdd': new FormControl(''),
            'employeeCounselingEdit': new FormControl(''),
            'employeeCounselingView': new FormControl(''),
            'dateOveride': new FormControl(''),
            'revenueReports': new FormControl(''),
            'trainingCreate': new FormControl(''),
            'trainingView': new FormControl(''),
            'trainingEdit': new FormControl(''),
            'customFieldAdd': new FormControl(''),
            'customFieldView': new FormControl(''),
            'customFieldEdit': new FormControl(''),


        });
    }

    onSave() {
        if (this.savePermissions ) {
            //console.log(this.addNewPermissions);
            //console.log(this.isNewEmployee);
        if (!this.addNewPermissions) {
            this.savePermissions = this.save;
            //console.log(this.selectedEmployeeId);
            //console.log('Update Permissions')
            this.updateUserPermission();
        } else {
            //console.log('Add Permissions')
            this.addUserPermission(this.userpermission)
            this.addNewPermissions = false;

            }

            //this.userform.reset();
        }

        //if (this.savePermissions && this.isNewEmployee) {
        //    console.log(this.addNewPermissions);
           
        //        console.log('Add New User Permissions')
        //        this.addUserPermission(this.userpermission)
        //        this.addNewPermissions = false;

           
        //}
          
          
    }

    addUserPermission(userpermission): void {
        //if (!userpermission) {
        //    return;
        //}
        if (this.userform.get('contractCreate').value == true) {
            this.userpermission.contractCreate = 1;
        } else {
            this.userpermission.contractCreate = 0;
        }

        if (this.userform.get('contractView').value == true) {
            this.userpermission.contractView = 1;
        } else {
            this.userpermission.contractView = 0;
        }

        if (this.userform.get('contractEdit').value == true) {
            this.userpermission.contractEdit = 1;
        } else {
            this.userpermission.contractEdit = 0;
        }

        if (this.userform.get('customerCreate').value == true) {
            this.userpermission.customerCreate = 1;
        } else {
            this.userpermission.customerCreate = 0;
        }

        if (this.userform.get('customerView').value == true) {
            this.userpermission.customerView = 1;
        } else {
            this.userpermission.customerView = 0;
        }

        if (this.userform.get('customerEdit').value == true) {
            this.userpermission.customerEdit = 1;
        } else {
            this.userpermission.customerEdit = 0;
        }

        if (this.userform.get('employeeCreate').value == true) {
            this.userpermission.employeeCreate = 1;
        } else {
            this.userpermission.employeeCreate = 0;
        }

        if (this.userform.get('employeeView').value == true) {
            this.userpermission.employeeView = 1;
        } else {
            this.userpermission.employeeView = 0;
        }

        if (this.userform.get('employeeEdit').value == true) {
            this.userpermission.employeeEdit = 1;
        } else {
            this.userpermission.employeeEdit = 0;
        }

        if (this.userform.get('bcCreate').value == true) {
            this.userpermission.billingCodeCreate = 1;
        } else {
            this.userpermission.billingCodeCreate = 0;
        }

        if (this.userform.get('bcView').value == true) {
            this.userpermission.billingCodeView = 1;
        } else {
            this.userpermission.billingCodeView = 0;
        }

        if (this.userform.get('bcEdit').value == true) {
            this.userpermission.billingCodeEdit = 1;
        } else {
            this.userpermission.billingCodeEdit = 0;
        }

        if (this.userform.get('crewCreate').value == true) {
            this.userpermission.crewCreate = 1;
        } else {
            this.userpermission.crewCreate = 0;
        }

        if (this.userform.get('crewView').value == true) {
            this.userpermission.crewView = 1;
        } else {
            this.userpermission.crewView = 0;
        }

        if (this.userform.get('crewEdit').value == true) {
            this.userpermission.crewEdit = 1;
        } else {
            this.userpermission.crewEdit = 0;
        }

        if (this.userform.get('dfrCreate').value == true) {
            this.userpermission.dfrcreate = 1;
        } else {
            this.userpermission.dfrcreate = 0;
        }

        if (this.userform.get('dfrView').value == true) {
            this.userpermission.dfrview = 1;
        } else {
            this.userpermission.dfrview = 0;
        }

        if (this.userform.get('dfrEdit').value == true) {
            this.userpermission.dfredit = 1;
        } else {
            this.userpermission.dfredit = 0;
        }

        if (this.userform.get('dfrApproval').value == true) {
            this.userpermission.dfrapproval = 1;
        } else {
            this.userpermission.dfrapproval = 0;
        }

        if (this.userform.get('dfrSubmitDate').value == true) {
            this.userpermission.dfrsubmitDate = 1;
        } else {
            this.userpermission.dfrsubmitDate = 0;
        }

        if (this.userform.get('dfrViewPrice').value == true) {
            this.userpermission.intViewDfrprice = 1;
        } else {
            this.userpermission.intViewDfrprice = 0;
        }

        if (this.userform.get('dfrEditPrice').value == true) {
            this.userpermission.intEditDfrprice = 1;
        } else {
            this.userpermission.intEditDfrprice = 0;
        }
        if (this.userform.get('dfrEditApprovedDfr').value == true) {
            this.userpermission.intEditApprovedDfr = 1;
        } else {
            this.userpermission.intEditApprovedDfr = 0;
        }
        if (this.userform.get('piCreate').value == true) {
            this.userpermission.personalInjuryCreate = 1;
        } else {
            this.userpermission.personalInjuryCreate = 0;
        }
        if (this.userform.get('piView').value == true) {
            this.userpermission.personalInjuryView = 1;
        } else {
            this.userpermission.personalInjuryView = 0;
        }
        if (this.userform.get('piEdit').value == true) {
            this.userpermission.perosnalInjuryEdit = 1;
        } else {
            this.userpermission.perosnalInjuryEdit = 0;
        }
        if (this.userform.get('pdCreate').value == true) {
            this.userpermission.propertyDamageCreate = 1;
        } else {
            this.userpermission.propertyDamageCreate = 0;
        }
        if (this.userform.get('pdView').value == true) {
            this.userpermission.propertyDamageView = 1;
        } else {
            this.userpermission.propertyDamageView = 0;
        }
        if (this.userform.get('pdEdit').value == true) {
            this.userpermission.propertyDamageEdit = 1;
        } else {
            this.userpermission.propertyDamageEdit = 0;
        }
        if (this.userform.get('uhCreate').value == true) {
            this.userpermission.utilityHitCreate = 1;
        } else {
            this.userpermission.utilityHitCreate = 0;
        }
        if (this.userform.get('uhView').value == true) {
            this.userpermission.utilityHitView = 1;
        } else {
            this.userpermission.utilityHitView = 0;
        }
        if (this.userform.get('uhEdit').value == true) {
            this.userpermission.utilityHitEdit = 1;
        } else {
            this.userpermission.utilityHitEdit = 0;
        }
        if (this.userform.get('vaCreate').value == true) {
            this.userpermission.vehicleAccidentCreate = 1;
        } else {
            this.userpermission.vehicleAccidentCreate = 0;
        }
        if (this.userform.get('vaView').value == true) {
            this.userpermission.vehicleAccidentView = 1;
        } else {
            this.userpermission.vehicleAccidentView = 0;
        }
        if (this.userform.get('vaEdit').value == true) {
            this.userpermission.vehicleAccidentEdit = 1;
        } else {
            this.userpermission.vehicleAccidentEdit = 0;
        }
        if (this.userform.get('qaCreate').value == true) {
            this.userpermission.qualityAuditsCreate = 1;
        } else {
            this.userpermission.qualityAuditsCreate = 0;
        }
        if (this.userform.get('qaView').value == true) {
            this.userpermission.qualityAuditsView = 1;
        } else {
            this.userpermission.qualityAuditsView = 0;
        }
        if (this.userform.get('qaEdit').value == true) {
            this.userpermission.qualityAuditsEdit = 1;
        } else {
            this.userpermission.qualityAuditsEdit = 0;
        }
        if (this.userform.get('saCreate').value == true) {
            this.userpermission.safetyAuditsCreate = 1;
        } else {
            this.userpermission.safetyAuditsCreate = 0;
        }
        if (this.userform.get('saView').value == true) {
            this.userpermission.safetyAuditsView = 1;
        } else {
            this.userpermission.safetyAuditsView = 0;
        }
        if (this.userform.get('saEdit').value == true) {
            this.userpermission.safetyAuditsEdit = 1;
        } else {
            this.userpermission.safetyAuditsEdit = 0;
        }
        if (this.userform.get('spCreate').value == true) {
            this.userpermission.spotAuditisCreate = 1;
        } else {
            this.userpermission.spotAuditisCreate = 0;
        }
        if (this.userform.get('spView').value == true) {
            this.userpermission.spotAuditsView = 1;
        } else {
            this.userpermission.spotAuditsView = 0;
        }
        if (this.userform.get('submitBilling').value == true) {
            this.userpermission.productionBillingInvoice = 1;
        } else {
            this.userpermission.productionBillingInvoice = 0;
        }
        if (this.userform.get('createInvoice').value == true) {
            this.userpermission.customerBillingInvoice = 1;
        } else {
            this.userpermission.customerBillingInvoice = 0;
        }
        if (this.userform.get('skipBriefPreCheck').value == true) {
            this.userpermission.intJobEquipSkip = 1;
        } else {
            this.userpermission.intJobEquipSkip = 0;
        }
        if (this.userform.get('woCreate').value == true) {
            this.userpermission.workOrderCreate = 1;
        } else {
            this.userpermission.workOrderCreate = 0;
        }
        if (this.userform.get('woEdit').value == true) {
            this.userpermission.workOrderEdit = 1;
        } else {
            this.userpermission.workOrderEdit = 0;
        }
        if (this.userform.get('woView').value == true) {
            this.userpermission.workOrderView = 1;
        } else {
            this.userpermission.workOrderView = 0;
        }
        if (this.userform.get('vendorCreate').value == true) {
            this.userpermission.vendorCreate = 1;
        } else {
            this.userpermission.vendorCreate = 0;
        }
        if (this.userform.get('vendorView').value == true) {
            this.userpermission.vendorView = 1;
        } else {
            this.userpermission.vendorView = 0;
        }
        if (this.userform.get('vendorEdit').value == true) {
            this.userpermission.vendorEdit = 1;
        } else {
            this.userpermission.vendorEdit = 0;
        }
        if (this.userform.get('jobBriefCreate').value == true) {
            this.userpermission.jobBriefingCreate = 1;
        } else {
            this.userpermission.jobBriefingCreate = 0;
        }
        if (this.userform.get('jobBriefView').value == true) {
            this.userpermission.jobBriedingView = 1;
        } else {
            this.userpermission.jobBriedingView = 0;
        }
        if (this.userform.get('intAdmin').value == true) {
            this.userpermission.intAdmin = 1;
        } else {
            this.userpermission.intAdmin = 0;
        }
        if (this.userform.get('viewPayRate').value == true) {
            this.userpermission.intPayRate = 1;
        } else {
            this.userpermission.intPayRate = 0;
        }
        if (this.userform.get('employeeCounselingAdd').value == true) {
            this.userpermission.employeeCounselingAdd = 1;
        } else {
            this.userpermission.employeeCounselingAdd = 0;
        }
        if (this.userform.get('employeeCounselingView').value == true) {
            this.userpermission.employeeCounselingView = 1;
        } else {
            this.userpermission.employeeCounselingView = 0;
        }
        if (this.userform.get('employeeCounselingEdit').value == true) {
            this.userpermission.employeeCounselingEdit = 1;
        } else {
            this.userpermission.employeeCounselingEdit = 0;
        }
        if (this.userform.get('equipmentAdd').value == true) {
            this.userpermission.equipmentAdd = 1;
        } else {
            this.userpermission.equipmentAdd = 0;
        }
        if (this.userform.get('equipmentView').value == true) {
            this.userpermission.equipmentView = 1;
        } else {
            this.userpermission.equipmentView = 0;
        }
        if (this.userform.get('equipmentEdit').value == true) {
            this.userpermission.equipmentEdit = 1;
        } else {
            this.userpermission.equipmentEdit = 0;
        }
        if (this.userform.get('dateOveride').value == true) {
            this.userpermission.dateOverride = 1;
        } else {
            this.userpermission.dateOverride = 0;
        }
        if (this.userform.get('revenueReports').value == true) {
            this.userpermission.revenueReports = 1;
        } else {
            this.userpermission.revenueReports = 0;
        }
        //if (this.userform.get('vendorCreate').value == true) {
        //    this.userpermission.vendorCreate = 1;
        //} else {
        //    this.userpermission.vendorCreate = 0;
        //}
        //if (this.userform.get('vendorView').value == true) {
        //    this.userpermission.vendorView = 1;
        //} else {
        //    this.userpermission.vendorView = 0;
        //}
        //if (this.userform.get('vendorEdit').value == true) {
        //    this.userpermission.vendorEdit = 1;
        //} else {
        //    this.userpermission.vendorEdit = 0;
        //}
        if (this.userform.get('rpCreate').value == true) {
            this.userpermission.rpCreate = 1;
        } else {
            this.userpermission.rpCreate = 0;
        }
        if (this.userform.get('rpView').value == true) {
            this.userpermission.rpView = 1;
        } else {
            this.userpermission.rpView = 0;
        }
        if (this.userform.get('rpEdit').value == true) {
            this.userpermission.rpEdit = 1;
        } else {
            this.userpermission.rpEdit = 0;
        }
        if (this.userform.get('trainingCreate').value == true) {
            this.userpermission.trainingAdd = 1;
        } else {
            this.userpermission.trainingAdd = 0;
        }
        if (this.userform.get('trainingView').value == true) {
            this.userpermission.trainingView = 1;
        } else {
            this.userpermission.trainingView = 0;
        }
        if (this.userform.get('trainingEdit').value == true) {
            this.userpermission.trainingEdit = 1;
        } else {
            this.userpermission.trainingEdit = 0;
        }
        if (this.userform.get('trainingCreate').value == true) {
            this.userpermission.trainingAdd = 1;
        } else {
            this.userpermission.trainingAdd = 0;
        }
        if (this.userform.get('trainingView').value == true) {
            this.userpermission.trainingView = 1;
        } else {
            this.userpermission.trainingView = 0;
        }
        if (this.userform.get('trainingEdit').value == true) {
            this.userpermission.trainingEdit = 1;
        } else {
            this.userpermission.trainingEdit = 0;
        }
        if (this.userform.get('customFieldAdd').value == true) {
            this.userpermission.customFieldAdd = 1;
        } else {
            this.userpermission.customFieldAdd = 0;
        }
        if (this.userform.get('customFieldView').value == true) {
            this.userpermission.customFieldView = 1;
        } else {
            this.userpermission.customFieldView = 0;
        }
        if (this.userform.get('customFieldEdit').value == true) {
            this.userpermission.customFieldEdit = 1;
        } else {
            this.userpermission.customFieldEdit = 0;
        }


        this.userpermission.fkEmployeeId = this.selectedEmployeeId;

        this.userpermissionservice.addUserPermission(this.userpermission)

            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
               
            });
    }

    updateUserPermission(): void {
        let permissions = [...this.userpermissions];
        //permissions[this.userpermissions.indexOf(this.selectedEmployeeId)] = this.userp;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.userpermission);
        if (this.userform.get('contractCreate').value == true) {
            this.userpermission.contractCreate = 1;
        } else {
            this.userpermission.contractCreate = 0;
        }

        if (this.userform.get('contractView').value == true) {
            this.userpermission.contractView = 1;
        } else {
            this.userpermission.contractView = 0;
        }

        if (this.userform.get('contractEdit').value == true) {
            this.userpermission.contractEdit = 1;
        } else {
            this.userpermission.contractEdit = 0;
        }

        if (this.userform.get('customerCreate').value == true) {
            this.userpermission.customerCreate = 1;
        } else {
            this.userpermission.customerCreate = 0;
        }

        if (this.userform.get('customerView').value == true) {
            this.userpermission.customerView = 1;
        } else {
            this.userpermission.customerView = 0;
        }

        if (this.userform.get('customerEdit').value == true) {
            this.userpermission.customerEdit = 1;
        } else {
            this.userpermission.customerEdit = 0;
        }

        if (this.userform.get('employeeCreate').value == true) {
            this.userpermission.employeeCreate = 1;
        } else {
            this.userpermission.employeeCreate = 0;
        }

        if (this.userform.get('employeeView').value == true) {
            this.userpermission.employeeView = 1;
        } else {
            this.userpermission.employeeView = 0;
        }

        if (this.userform.get('employeeEdit').value == true) {
            this.userpermission.employeeEdit = 1;
        } else {
            this.userpermission.employeeEdit = 0;
        }

        if (this.userform.get('bcCreate').value == true) {
            this.userpermission.billingCodeCreate = 1;
        } else {
            this.userpermission.billingCodeCreate = 0;
        }

        if (this.userform.get('bcView').value == true) {
            this.userpermission.billingCodeView = 1;
        } else {
            this.userpermission.billingCodeView = 0;
        }

        if (this.userform.get('bcEdit').value == true) {
            this.userpermission.billingCodeEdit = 1;
        } else {
            this.userpermission.billingCodeEdit = 0;
        }

        if (this.userform.get('crewCreate').value == true) {
            this.userpermission.crewCreate = 1;
        } else {
            this.userpermission.crewCreate = 0;
        }

        if (this.userform.get('crewView').value == true) {
            this.userpermission.crewView = 1;
        } else {
            this.userpermission.crewView = 0;
        }

        if (this.userform.get('crewEdit').value == true) {
            this.userpermission.crewEdit = 1;
        } else {
            this.userpermission.crewEdit = 0;
        }

        if (this.userform.get('dfrCreate').value == true) {
            this.userpermission.dfrcreate = 1;
        } else {
            this.userpermission.dfrcreate = 0;
        }

        if (this.userform.get('dfrView').value == true) {
            this.userpermission.dfrview = 1;
        } else {
            this.userpermission.dfrview = 0;
        }

        if (this.userform.get('dfrEdit').value == true) {
            this.userpermission.dfredit = 1;
        } else {
            this.userpermission.dfredit = 0;
        }

        if (this.userform.get('dfrApproval').value == true) {
            this.userpermission.dfrapproval = 1;
        } else {
            this.userpermission.dfrapproval = 0;
        }

        if (this.userform.get('dfrSubmitDate').value == true) {
            this.userpermission.dfrsubmitDate = 1;
        } else {
            this.userpermission.dfrsubmitDate= 0;
        }

        if (this.userform.get('dfrViewPrice').value == true) {
            this.userpermission.intViewDfrprice = 1;
        } else {
            this.userpermission.intViewDfrprice = 0;
        }

        if (this.userform.get('dfrEditPrice').value == true) {
            this.userpermission.intEditDfrprice = 1;
        } else {
            this.userpermission.intEditDfrprice = 0;
        }
        if (this.userform.get('dfrEditApprovedDfr').value == true) {
            this.userpermission.intEditApprovedDfr = 1;
        } else {
            this.userpermission.intEditApprovedDfr = 0;
        }
        if (this.userform.get('piCreate').value == true) {
            this.userpermission.personalInjuryCreate = 1;
        } else {
            this.userpermission.personalInjuryCreate = 0;
        }
        if (this.userform.get('piView').value == true) {
            this.userpermission.personalInjuryView = 1;
        } else {
            this.userpermission.personalInjuryView = 0;
        }
        if (this.userform.get('piEdit').value == true) {
            this.userpermission.perosnalInjuryEdit = 1;
        } else {
            this.userpermission.perosnalInjuryEdit = 0;
        }
        if (this.userform.get('pdCreate').value == true) {
            this.userpermission.propertyDamageCreate = 1;
        } else {
            this.userpermission.propertyDamageCreate = 0;
        }
        if (this.userform.get('pdView').value == true) {
            this.userpermission.propertyDamageView = 1;
        } else {
            this.userpermission.propertyDamageView = 0;
        }
        if (this.userform.get('pdEdit').value == true) {
            this.userpermission.propertyDamageEdit = 1;
        } else {
            this.userpermission.propertyDamageEdit = 0;
        }
        if (this.userform.get('uhCreate').value == true) {
            this.userpermission.utilityHitCreate = 1;
        } else {
            this.userpermission.utilityHitCreate = 0;
        }
        if (this.userform.get('uhView').value == true) {
            this.userpermission.utilityHitView = 1;
        } else {
            this.userpermission.utilityHitView = 0;
        }
        if (this.userform.get('uhEdit').value == true) {
            this.userpermission.utilityHitEdit = 1;
        } else {
            this.userpermission.utilityHitEdit = 0;
        }
        if (this.userform.get('vaCreate').value == true) {
            this.userpermission.vehicleAccidentCreate = 1;
        } else {
            this.userpermission.vehicleAccidentCreate = 0;
        }
        if (this.userform.get('vaView').value == true) {
            this.userpermission.vehicleAccidentView = 1;
        } else {
            this.userpermission.vehicleAccidentView = 0;
        }
        if (this.userform.get('vaEdit').value == true) {
            this.userpermission.vehicleAccidentEdit = 1;
        } else {
            this.userpermission.vehicleAccidentEdit = 0;
        }
        if (this.userform.get('qaCreate').value == true) {
            this.userpermission.qualityAuditsCreate = 1;
        } else {
            this.userpermission.qualityAuditsCreate = 0;
        }
        if (this.userform.get('qaView').value == true) {
            this.userpermission.qualityAuditsView = 1;
        } else {
            this.userpermission.qualityAuditsView = 0;
        }
        if (this.userform.get('qaEdit').value == true) {
            this.userpermission.qualityAuditsEdit = 1;
        } else {
            this.userpermission.qualityAuditsEdit = 0;
        }
        if (this.userform.get('saCreate').value == true) {
            this.userpermission.safetyAuditsCreate = 1;
        } else {
            this.userpermission.safetyAuditsCreate = 0;
        }
        if (this.userform.get('saView').value == true) {
            this.userpermission.safetyAuditsView = 1;
        } else {
            this.userpermission.safetyAuditsView = 0;
        }
        if (this.userform.get('saEdit').value == true) {
            this.userpermission.safetyAuditsEdit = 1;
        } else {
            this.userpermission.safetyAuditsEdit = 0;
        }
        if (this.userform.get('spCreate').value == true) {
            this.userpermission.spotAuditisCreate = 1;
        } else {
            this.userpermission.spotAuditisCreate = 0;
        }
        if (this.userform.get('spView').value == true) {
            this.userpermission.spotAuditsView = 1;
        } else {
            this.userpermission.spotAuditsView = 0;
        }
        if (this.userform.get('submitBilling').value == true) {
            this.userpermission.productionBillingInvoice = 1;
        } else {
            this.userpermission.productionBillingInvoice = 0;
        }
        if (this.userform.get('createInvoice').value == true) {
            this.userpermission.customerBillingInvoice = 1;
        } else {
            this.userpermission.customerBillingInvoice = 0;
        }
        if (this.userform.get('skipBriefPreCheck').value == true) {
            this.userpermission.intJobEquipSkip = 1;
        } else {
            this.userpermission.intJobEquipSkip = 0;
        }
        if (this.userform.get('woCreate').value == true) {
            this.userpermission.workOrderCreate = 1;
        } else {
            this.userpermission.workOrderCreate = 0;
        }
        if (this.userform.get('woEdit').value == true) {
            this.userpermission.workOrderEdit = 1;
        } else {
            this.userpermission.workOrderEdit = 0;
        }
        if (this.userform.get('woView').value == true) {
            this.userpermission.workOrderView = 1;
        } else {
            this.userpermission.workOrderView = 0;
        }
        if (this.userform.get('vendorCreate').value == true) {
            this.userpermission.vendorCreate = 1;
        } else {
            this.userpermission.vendorCreate = 0;
        }
        if (this.userform.get('vendorView').value == true) {
            this.userpermission.vendorView = 1;
        } else {
            this.userpermission.vendorView = 0;
        }
        if (this.userform.get('vendorEdit').value == true) {
            this.userpermission.vendorEdit = 1;
        } else {
            this.userpermission.vendorEdit = 0;
        }
        if (this.userform.get('jobBriefCreate').value == true) {
            this.userpermission.jobBriefingCreate = 1;
        } else {
            this.userpermission.jobBriefingCreate = 0;
        }
        if (this.userform.get('jobBriefView').value == true) {
            this.userpermission.jobBriedingView = 1;
        } else {
            this.userpermission.jobBriedingView = 0;
        }
        if (this.userform.get('intAdmin').value == true) {
            this.userpermission.intAdmin = 1;
        } else {
            this.userpermission.intAdmin = 0;
        }
        if (this.userform.get('viewPayRate').value == true) {
            this.userpermission.intPayRate = 1;
        } else {
            this.userpermission.intPayRate = 0;
        }
        if (this.userform.get('employeeCounselingAdd').value == true) {
            this.userpermission.employeeCounselingAdd = 1;
        } else {
            this.userpermission.employeeCounselingAdd = 0;
        }
        if (this.userform.get('employeeCounselingView').value == true) {
            this.userpermission.employeeCounselingView = 1;
        } else {
            this.userpermission.employeeCounselingView = 0;
        }
        if (this.userform.get('employeeCounselingEdit').value == true) {
            this.userpermission.employeeCounselingEdit = 1;
        } else {
            this.userpermission.employeeCounselingEdit = 0;
        }
        if (this.userform.get('equipmentAdd').value == true) {
            this.userpermission.equipmentAdd = 1;
        } else {
            this.userpermission.equipmentAdd = 0;
        }
        if (this.userform.get('equipmentView').value == true) {
            this.userpermission.equipmentView = 1;
        } else {
            this.userpermission.equipmentView = 0;
        }
        if (this.userform.get('equipmentEdit').value == true) {
            this.userpermission.equipmentEdit = 1;
        } else {
            this.userpermission.equipmentEdit = 0;
        }
        if (this.userform.get('dateOveride').value == true) {
            this.userpermission.dateOverride = 1;
        } else {
            this.userpermission.dateOverride = 0;
        }
        if (this.userform.get('revenueReports').value == true) {
            this.userpermission.revenueReports = 1;
        } else {
            this.userpermission.revenueReports = 0;
        }
        //if (this.userform.get('vendorCreate').value == true) {
        //    this.userpermission.vendorCreate = 1;
        //} else {
        //    this.userpermission.vendorCreate = 0;
        //}
        //if (this.userform.get('vendorView').value == true) {
        //    this.userpermission.vendorView = 1;
        //} else {
        //    this.userpermission.vendorView = 0;
        //}
        //if (this.userform.get('vendorEdit').value == true) {
        //    this.userpermission.vendorEdit = 1;
        //} else {
        //    this.userpermission.vendorEdit = 0;
        //}
        if (this.userform.get('rpCreate').value == true) {
            this.userpermission.rpCreate = 1;
        } else {
            this.userpermission.rpCreate = 0;
        }
        if (this.userform.get('rpView').value == true) {
            this.userpermission.rpView = 1;
        } else {
            this.userpermission.rpView = 0;
        }
        if (this.userform.get('rpEdit').value == true) {
            this.userpermission.rpEdit = 1;
        } else {
            this.userpermission.rpEdit = 0;
        }

        if (this.userform.get('trainingCreate').value == true) {
            this.userpermission.trainingAdd = 1;
        } else {
            this.userpermission.trainingAdd = 0;
        }
        if (this.userform.get('trainingView').value == true) {
            this.userpermission.trainingView = 1;
        } else {
            this.userpermission.trainingView = 0;
        }
        if (this.userform.get('trainingEdit').value == true) {
            this.userpermission.trainingEdit = 1;
        } else {
            this.userpermission.trainingEdit = 0;
        }
        if (this.userform.get('customFieldAdd').value == true) {
            this.userpermission.customFieldAdd = 1;
        } else {
            this.userpermission.customFieldAdd = 0;
        }
        if (this.userform.get('customFieldView').value == true) {
            this.userpermission.customFieldView = 1;
        } else {
            this.userpermission.customFieldView = 0;
        }
        if (this.userform.get('customFieldEdit').value == true) {
            this.userpermission.customFieldEdit = 1;
        } else {
            this.userpermission.customFieldEdit = 0;
        }

        this.userpermissionservice.updateUserPermission(this.userpermission.id, this.userpermission)

            .subscribe(data => {
                //console.log(data);
                //this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.userpermissio = {};
                //this.userform.reset();
                //this.handleError("err:Test");
            });
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getEmployeeAuth(id): void {
        this.userauthservice.getAuthId(id)
            .subscribe(auths => {
                this.userauths = auths

                this.userauth = auths.find(e => e.fkEmpId == id);
                //console.log(this.userauth);
                //console.log(this.userauths);
                if (this.userauth) {
                    this.userform.get('username').setValue(this.userauth.userId);
                    this.userform.get('password').setValue(this.userauth.password);
                    this.newAuth = false;
                } else {
                    this.newAuth = true;
                }
            });
    }

    getUserPermissions(id): void {
        //let permission = <UserPermission>{ };
        this.userpermissionservice.getPermissionbyId(id)

            .subscribe(permissions => {
                this.userpermissions = permissions;
                //console.log(this.userpermissions)
                if (this.userpermissions.length>0) {
                    this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                    this.addNewPermissions = false;
                } else {
                    this.addNewPermissions = true;
                    this.userpermission = {};
                    this.userform.reset();
                }

                if (this.userpermission) {
                    //this.addNewPermissions = false;
                    this.userform.get('contractCreate').setValue(this.userpermission.contractCreate);
                    this.userform.get('contractEdit').setValue(this.userpermission.contractEdit);
                    this.userform.get('contractView').setValue(this.userpermission.contractView);
                    this.userform.get('customerCreate').setValue(this.userpermission.customerCreate);
                    this.userform.get('customerEdit').setValue(this.userpermission.customerEdit);
                    this.userform.get('customerView').setValue(this.userpermission.customerView);
                    this.userform.get('customerEdit').setValue(this.userpermission.customerEdit);
                    this.userform.get('employeeCreate').setValue(this.userpermission.employeeCreate);
                    this.userform.get('employeeEdit').setValue(this.userpermission.employeeEdit);
                    this.userform.get('employeeView').setValue(this.userpermission.employeeView);
                    this.userform.get('bcCreate').setValue(this.userpermission.billingCodeCreate);
                    this.userform.get('bcView').setValue(this.userpermission.billingCodeView);
                    this.userform.get('bcEdit').setValue(this.userpermission.billingCodeEdit);
                    this.userform.get('crewCreate').setValue(this.userpermission.crewCreate);
                    this.userform.get('crewEdit').setValue(this.userpermission.crewEdit);
                    this.userform.get('crewView').setValue(this.userpermission.crewView);
                    this.userform.get('crewCreate').setValue(this.userpermission.crewCreate);
                    this.userform.get('jobBriefCreate').setValue(this.userpermission.jobBriefingCreate);
                    this.userform.get('jobBriefEdit').setValue(this.userpermission.jobBriefingEdit);
                    this.userform.get('jobBriefView').setValue(this.userpermission.jobBriedingView);
                    this.userform.get('woCreate').setValue(this.userpermission.workOrderCreate);
                    this.userform.get('woView').setValue(this.userpermission.workOrderView);
                    this.userform.get('woEdit').setValue(this.userpermission.workOrderEdit);
                    this.userform.get('dfrCreate').setValue(this.userpermission.dfrcreate);
                    this.userform.get('dfrEdit').setValue(this.userpermission.dfredit);
                    this.userform.get('dfrView').setValue(this.userpermission.dfrview);
                    this.userform.get('dfrApproval').setValue(this.userpermission.dfrapproval);
                    this.userform.get('dfrSubmitDate').setValue(this.userpermission.dfrsubmitDate);
                    this.userform.get('dfrEditPrice').setValue(this.userpermission.intEditDfrprice);
                    this.userform.get('dfrEditApprovedDfr').setValue(this.userpermission.intEditApprovedDfr);
                    this.userform.get('dfrViewPrice').setValue(this.userpermission.intViewDfrprice);
                    this.userform.get('piCreate').setValue(this.userpermission.personalInjuryCreate);
                    this.userform.get('piView').setValue(this.userpermission.personalInjuryView);
                    this.userform.get('piEdit').setValue(this.userpermission.perosnalInjuryEdit);
                    this.userform.get('pdEdit').setValue(this.userpermission.propertyDamageEdit);
                    this.userform.get('pdCreate').setValue(this.userpermission.propertyDamageCreate);
                    this.userform.get('pdView').setValue(this.userpermission.propertyDamageView);
                    this.userform.get('uhView').setValue(this.userpermission.utilityHitView);
                    this.userform.get('uhCreate').setValue(this.userpermission.utilityHitCreate);
                    this.userform.get('uhEdit').setValue(this.userpermission.utilityHitEdit);
                    this.userform.get('vaCreate').setValue(this.userpermission.vehicleAccidentCreate);
                    this.userform.get('vaEdit').setValue(this.userpermission.vehicleAccidentEdit);
                    this.userform.get('vaView').setValue(this.userpermission.vehicleAccidentView);
                    this.userform.get('qaCreate').setValue(this.userpermission.qualityAuditsCreate);
                    this.userform.get('qaEdit').setValue(this.userpermission.qualityAuditsEdit);
                    this.userform.get('qaView').setValue(this.userpermission.qualityAuditsView);
                    this.userform.get('saView').setValue(this.userpermission.safetyAuditsView);
                    this.userform.get('saCreate').setValue(this.userpermission.safetyAuditsCreate);
                    this.userform.get('saEdit').setValue(this.userpermission.safetyAuditsEdit);
                    this.userform.get('spCreate').setValue(this.userpermission.spotAuditisCreate);
                    this.userform.get('spView').setValue(this.userpermission.spotAuditsView);
                    this.userform.get('submitBilling').setValue(this.userpermission.productionBillingInvoice);
                    this.userform.get('createInvoice').setValue(this.userpermission.customerBillingInvoice);
                    this.userform.get('createInvoice').setValue(this.userpermission.customerBillingInvoice);
                    this.userform.get('skipBriefPreCheck').setValue(this.userpermission.intJobEquipSkip);
                    //this.userform.get('ejobs').setValue(this.userpermission.intAdhocJobsView);
                    //this.userform.get('ejobsview').setValue(this.userpermission.intAdhocJobsViewApp);
                    this.userform.get('vendorView').setValue(this.userpermission.vendorView);
                    this.userform.get('vendorCreate').setValue(this.userpermission.vendorCreate);
                    this.userform.get('vendorEdit').setValue(this.userpermission.vendorEdit);
                    this.userform.get('rpView').setValue(this.userpermission.rpView);
                    this.userform.get('rpCreate').setValue(this.userpermission.rpCreate);
                    this.userform.get('rpEdit').setValue(this.userpermission.rpEdit);
                    this.userform.get('intAdmin').setValue(this.userpermission.intAdmin);
                    this.userform.get('viewPayRate').setValue(this.userpermission.intPayRate);
                    this.userform.get('dateOveride').setValue(this.userpermission.dateOverride);
                    this.userform.get('revenueReports').setValue(this.userpermission.revenueReports);
                    this.userform.get('equipmentAdd').setValue(this.userpermission.equipmentAdd);
                    this.userform.get('equipmentView').setValue(this.userpermission.equipmentView);
                    this.userform.get('equipmentEdit').setValue(this.userpermission.equipmentEdit);
                    this.userform.get('employeeCounselingAdd').setValue(this.userpermission.employeeCounselingAdd);
                    this.userform.get('employeeCounselingView').setValue(this.userpermission.employeeCounselingView);
                    this.userform.get('employeeCounselingEdit').setValue(this.userpermission.employeeCounselingEdit);
                    this.userform.get('trainingCreate').setValue(this.userpermission.trainingAdd);
                    this.userform.get('trainingView').setValue(this.userpermission.trainingView);
                    this.userform.get('trainingEdit').setValue(this.userpermission.trainingEdit);
                    this.userform.get('customFieldAdd').setValue(this.userpermission.customFieldAdd);
                    this.userform.get('customFieldView').setValue(this.userpermission.customFieldView);
                    this.userform.get('customFieldEdit').setValue(this.userpermission.customFieldEdit);
                    //console.log(this.userpermission.trainingAdd);
                    //this.userform.get('vendorView').setValue(this.userpermission.vendorView);
                    //this.userform.get('vendorEdit').setValue(this.userpermission.vendorEdit);
                    //this.userform.get('rpAdd').setValue(this.userpermission.rpCreate);
                    //this.userform.get('rpView').setValue(this.userpermission.rpView);
                    //this.userform.get('rpEdit').setValue(this.userpermission.rpEdit);

                } else {
                   //this.addNewPermissions = true;
                    this.userpermission = {};
                    this.userform.reset();
                }
            });

               
    }

    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    onRowUnselect() {
        //this.payrollHours = [];
        this.linemanToolPurchases = [];
        this.displayEmployeeDetails = false;
    }

    onRowSelect(event) {
        if (event.data.strEmployeeId) {
            this.selectedEmployeeId = event.data.strEmployeeId;
            //this.getEmployeeHours(this.selectedEmployeeId);
            this.getLinemanToolPurchases(this.selectedEmployeeId);
            this.displayEmployeeDetails = true;
        }
    }

    onActiveChange(event) {
        console.log(event.value);
        if (event.value = 'active') {
            this.getEmployeesbyStatus('active');
        } else if (event.value = 'inactive') {
            this.getEmployeesbyStatus('inActive');
        } else {
            this.getEmployees();
        }

        this.updateVisibility();
    }

    onSelect(value) {
        //const value = event.target.value;
        this.hireDate = value;
        //console.log(element.getAttribute('formControlName'));
        this.employee.dtmHireDate = this._dateService.toString(this.hireDate);
    }

    onTermSelect(value) {
        //const value = event.target.value;
        this.termDate = value;
        this.employee.drmTermDate = this._dateService.toString(this.termDate);
    }

    onRehireSelect(value) {
        //const value = event.target.value;
        this.rehireDate = value;
        this.employee.dtmRehireDate = this._dateService.toString(this.rehireDate);
    }

    onOrientSelect(value) {
        //const value = event.target.value;
        this.orientationDate = value;
        this.employee.dtmOrientationCompl = this._dateService.toString(this.orientationDate);
    }

    onBootPurSelect(value) {
        //const value = event.target.value;
        this.bootPurchaseDate = value;
        this.employee.bootPurchase = this._dateService.toString(this.bootPurchaseDate);
    }

    onGlassPurSelect(value) {
        //const value = event.target.value;
        this.glassesPurchaseDate = value;
        this.employee.glassesPurchase = this._dateService.toString(this.glassesPurchaseDate);
    }

    cancel() {
        this.displayEmployeeDialog = false;
        this.employee = {};
        this.userform.reset();
        //this.userform.get('active').setValue(null);
        //this.userform.get('position').setValue(null);
        //this.userform.get('perdiem').setValue(null);
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.employee.strLastName = this.userform.get('lastname').value;
        this.employee.strFirstName = this.userform.get('firstname').value;
        this.employee.txtNotes = this.userform.get('notes').value;
        this.employee.ysnActive = this.userform.get('active').value;
        this.employee.intPosition = this.userform.get('position').value;
        this.employee.strPerDiemType = this.userform.get('perdiem').value;
        this.employee.intBenefits = this.userform.get('benefit').value;
        this.employee.strEmail = this.userform.get('email').value;
        this.employee.strCrew = this.userform.get('crew').value;
        this.employee.intPayRate = this.userform.get('payrate').value;
        if (this.orientationDone === true) {
            this.employee.ysnOrientation = 1;
        } else {
            this.employee.ysnOrientation = 0;
        }

        //let username = this.userform.get('username').value;
        //let pass = this.userform.get('password').value;
        ////console.log(username);
        //if ((username) && (pass)) {
        //    this.userauth.userId = username;
        //    this.userauth.password = pass;
        //    //console.log('setUsername')
        //}

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newEmployee) {
            this.addEmployee(this.employee);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        } else {
            this.updateEmployee();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        if (this.newAuth) {
            this.userauth = {};
            //console.log(this.userform.get('username').value);
            let username = this.userform.get('username').value;
            this.userauth.userId = this.userform.get('username').value
            this.userauth.password = this.userform.get('password').value;
            this.addEmployeeAuth(this.userauth);
        } else {
            this.userauth.userId = this.userform.get('username').value;
            this.userauth.password = this.userform.get('password').value;
            this.updateEmployeeAuth();
        }

        this.employee = {};
        this.userauth = {};
        this.userpermission = {};
        this.submitted = false;
        this.userform.reset();
        this.displayEmployeeDialog = false;
    }

    //get diagnostic() { return JSON.stringify(this.userform.value); };
    getEmployeeHours(id): void {
        this.payRollHourService.getEmployeePayRollHours(id)
            .subscribe(employeehours => this.payrollHours = employeehours);
    }

    getLinemanToolPurchases(id): void {
        this.linemanToolPurchaseService.getToolPurchasesbyID(id)
            .subscribe(toolpurchases => this.linemanToolPurchases = toolpurchases);
    }

    getLinemanToolVendors(): void {
        this.linemanToolVendorService.getToolVendors()
            .subscribe(toolvendors => this.linemanToolVendors = toolvendors);
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    getEmployeesbyStatus(status): void {
        this.employeeService.getEmployeesbyStatus(status)
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    addEmployee(employee): void {
        if (!employee) {
            return;
        }
        this.employeeService.addEmployee(this.employee)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                if (this.selectedActive == 'Active') {
                    this.getEmployeesbyStatus('Active');
                } else if (this.selectedActive == 'InActive') {
                    this.getEmployeesbyStatus('InActive');
                } else {
                    this.getEmployees();
                }
            });
    }

    updateEmployee(): void {
        let employees = [...this.employees];
        employees[this.employees.indexOf(this.selectedEmployee)] = this.employee;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        //console.log(this.selectedEmployee.strEmployeeID);
        this.employeeName = this.employee.strFirstName + ' ' + this.employee.strLastName;
        this.employeeService.updateEmployee(this.selectedEmployeeId, this.employee)
            .subscribe(data => {
                //console.log(data);
                //this.ngOnInit();
                this.showSuccessUpdateViaToast();
                if (this.selectedActive == 'Active') {
                    this.getEmployeesbyStatus('Active');
                } else if (this.selectedActive == 'InActive') {
                    this.getEmployeesbyStatus('InActive');
                } else {
                    this.getEmployees();
                }
            });
    }

    updateEmployeeAuth(): void {
        let id = this.userauth.id;
        this.userauthservice.updateUserAuth(id, this.userauth)
            .subscribe(data => {
            });
    }

    addEmployeeAuth(auth): void {
        if (!auth) {
            return;
        }
        //console.log('adding');
        this.userauth.createdByUserId = this.userId
        this.userauth.creationDate = this._dateService.toString(new Date());
        this.userauth.fkEmpId = this.selectedEmployeeId;
        this.userauthservice.addUserAuth(auth)
            .subscribe(data => {
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Permissions Added' });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Employee Updated', detail: this.employeeName });
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployees("Active")
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAddEmployee() {
        this.newEmployee = true;
        this.employee = {};
        this.userauth = {};
        this.userform.reset();
        //this.employee.strEmployeeID = this.selectedEmployee.strEmployeeID;
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.displayEmployeeDialog = true;
      
        //console.log(this.displayEmployeeDialog);
    }

    editEmployee(rowdata) {
        this.selectedEmployeeId = rowdata.strEmployeeId;       
        //this.getEmployeeAuth(this.selectedEmployeeId);
        //this.newEmployee = false;
        //this.employee = this.cloneEmployee(rowdata);
        ////console.log(this.employees);
        //this.displayEmployeeDialog = true;
        //this.userform.get('active').setValue(this.employee.ysnActive);
        //this.userform.get('position').setValue(this.employee.intPosition);
        //this.userform.get('perdiem').setValue(this.employee.strPerDiemType);
        //this.userform.get('firstname').setValue(this.employee.strFirstName);
        //this.userform.get('lastname').setValue(this.employee.strLastName);
        //this.userform.get('hiredate').setValue(new Date(this.employee.dtmHireDate));
        //if (this.employee.drmTermDate !== null) {
        //    this.userform.get('termdate').setValue(new Date(this.employee.drmTermDate));
        //}
        //if (this.employee.dtmHireDate !== null) {
        //    this.userform.get('hiredate').setValue(new Date(this.employee.dtmHireDate));
        //}
        //if (this.employee.dtmRehireDate !== null) {
        //    this.userform.get('rehiredate').setValue(new Date(this.employee.dtmRehireDate));
        //}
        //if (this.employee.dtmOrientationCompl !== null) {
        //    this.userform.get('orientationdate').setValue(new Date(this.employee.dtmOrientationCompl));
        //}
        //if (this.employee.bootPurchase !== null) {
        //    this.userform.get('bootpurchasedate').setValue(new Date(this.employee.bootPurchase));
        //}
        //if (this.employee.glassesPurchase !== null) {
        //    this.userform.get('glassespurchasedate').setValue(new Date(this.employee.glassesPurchase));
        //}
        //this.userform.get('benefit').setValue(this.employee.intBenefits);
        //this.userform.get('notes').setValue(this.employee.txtNotes);
        //this.userform.get('email').setValue(this.employee.strEmail);
        //this.userform.get('crew').setValue(this.employee.strCrew);
        //this.userform.get('payrate').setValue(this.employee.intPayRate);

        //if (this.employee.ysnOrientation == '1') {
        //    this.orientationDone = true;
        //} else {
        //    this.orientationDone = false;
        //}

        //this.userform.get('orientationdone').setValue(this.orientationDone);
    }
    //onRowUnselect() {
    //    this.displayContract = false;
    //}

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    //showSuccessViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    //}

    //showSuccessUpdateViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    //}

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        },
            250);
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getEmployees();
        } else {
            this.getEmployeesbyStatus(event.value);
        }

        this.selectedActive = event.value;
    }

    onOrientationCheck(isChecked: boolean) {
        //console.log(isChecked);
        if (isChecked == true) {
            this.orientationDone = true;
            this.userform.get('orientationdate').setValue(new Date());
            this.employee.dtmOrientationCompl = this._dateService.toString(new Date());
            //this.employee.ysnOrientation = 1;
        } else {
            this.orientationDone = false;
            //this.employee.ysnOrientation = 0;
        }
    }

    onBlur(val) {
        if (val === "area") {
            this.userform.get('hiredate').setValue(new Date(1977, 1, 1));
            this.userform.get('position').setValue(24);
            //this.userform.get('perdiem').setValue('0');
            this.userform.get('benefit').setValue(0);
            this.employee.dtmHireDate = this._dateService.toString(this.userform.get('hiredate').value);
        }
    }

    onBlurLastName(val) {
        //this.userform.get('crew').setValue(val);
    }

    printToolBankBalance() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;       
        this.selectedReportName = "Employee/Toolbank Balance.trdp";
        //console.log(this.selectedDfrId);
    }

    printQtrToolBankBalance() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;
        this.selectedReportName = "Employee/Qtr Toolbank Balance.trdp";
        //console.log(this.selectedDfrId);
    }

    upDatePermisson() {
        this.updateUserPermission();
    }
        
    
}
