import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomerInvoiceService } from '../service/customerinvoiceservice';
import { CustomerGroupInvoiceService} from '../service/customergroupinvoiceservice';
import { CustomerInvoice } from '../domain/customerinvoice'
import { CustomerGroupInvoice } from '../domain/customergroupinvoice '
import { HttpClientModule } from '@angular/common/http';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
import { MessageService, FilterUtils } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Contract } from '../domain/contract'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { DateService } from '../../_services/date.service';
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';
import { CustomerService } from '../service/customerservice';
import { Customer } from '../domain/customer'
import { Observable } from "rxjs/Rx";
import { invoicepayments } from '../domain/invoicepayments'
import { InvoicePaymentsServices } from '../service/invoicepayments';


@Component({
    selector: 'groupinvoice-table',
    templateUrl: './grouppayment.component.html',
    styles: [

        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService, DatePipe]
})
export class GroupInvoicePaymentComponent implements OnInit {
    customerInvoice: CustomerInvoice = {};

    customerInvoices: CustomerInvoice[];

    customerGroupInvoice: CustomerGroupInvoice = {};

    customerGroupInvoices: CustomerGroupInvoice[] = [{}];

    newGroupInvoices: CustomerGroupInvoice[];

    employees: Employee[];

    invoicePayments: invoicepayments[];

    invoicePayment: invoicepayments = {};

    clonedCustomerInvoices: CustomerInvoice[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    activeFilter: SelectItem[];

    customerName = "";

    contractName = "";

    filter: any = {};

    newInvoice: boolean = false;    

    displayContractDialog: boolean = false;

    selectedActive: string = "-1";

    selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    invoiceCreated: boolean = false;

    userform: FormGroup;

    selectedInvoice: any;

    selectedInvoiceToGroupInvoice: any[];

    displayReport: boolean = false;
        
    selectedInvoiceId: string = null;

    selectedReportName: string = null;

    //@Output() invoiceDeleted = new EventEmitter<number>();

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    rangeDates: Date;

    dateFilters: any;

    selectedColumns: any[];

    sumSelectedInvoice: number = 0;

    sumSelectedPayments: number = 0;

    invoiceDate: Date;

    invoiceDueDate: Date;

    customer: Customer = {};

    customers: Customer[];

    selectedCustomer: Customer = {};

    private datePipe: DatePipe

    customerId: number;

    progressBar: string;

    contracts: Contract[];

    contract: Contract = {};

    selectedContract: Contract = {};

    achnumber: string;

    selectedContact: number;

    selectedContractId: number = 0;

    


    constructor(private customerInvoiceService: CustomerInvoiceService, private service: MessageService, public snackbar: MatSnackBar, private contractService: ContractService,
        private route: Router, private employeeService: EmployeeService, private fb: FormBuilder, private billableproductionService: BillableProductionService,
        private _dateService: DateService, private customerService: CustomerService, private groupInvoiceSerivce: CustomerGroupInvoiceService, private paymentservice: InvoicePaymentsServices) {
    }

   

    ngOnInit() {
      

        this.active = [
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'fakeBill', header: 'Pay' },
            { field: 'intWoid', header: 'WO#' },
            { field: 'strInvoiceNo', header: 'Invoice #' },
            { field: 'dtmInvoiceDate', header: 'Invoice Date' },
            //{ field: 'dtmInvoiceDue', header: 'Invoice Due' },
            { field: 'curInvoiceTotal', header: 'Invoice Total' },
            { field: 'pastPayments', header: 'Past Payments' },
            { field: 'ynFinal', header: 'Finaled' },
            //{ field: 'revised', header: 'Revised' },
            //{ field: 'strCustomerName', header: 'Customer' },         
            { field: 'payments', header: 'Payment' },
            { field: 'balance', header: 'Balance' },
            { field: 'notes', header: 'Notes' },
        ];

       

        this.selectedColumns = this.cols;

        this.clonedCustomerInvoices = [];

        this.invoiceDate = new Date();

        //this.getInvoicesforGroupInvoice();

        this.getCustomersFilter(-1);
    }

    ngAfterViewInit() {
        var _self = this;
        FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
            // get the from/start value
            var s = _self.rangeDates[0].getTime();
            //console.log(s);
            var e;
            // the to/end value might not be set
            // use the from/start date and add 1 day
            // or the to/end date and add 1 day
            if (_self.rangeDates[1]) {
                e = _self.rangeDates[1].getTime() + 86400000;
            } else {
                e = s + 86400000;
            }

            var dateValue = new Date(value);
            //console.log(e)
            // compare it to the actual values
            return dateValue.getTime() >= s && dateValue.getTime() < e;
        }
    }

    addInvoicePayment(payment): void {
        if (!payment) { return; }
        this.paymentservice.addInvoicePayment(payment)
            .subscribe(data => {
                //customers.push(this.customer);
                //connsole.log(data);
                //this.service.add({ severity: 'success', summary: 'Payment Added', detail: 'Amount:' });
                //this.getInvoicesbyWoId(this.selectedWoId);
            },
                error => {
                    //in case of error, add the callback to bring the item back and re-throw the error.
                    //error.callback = () => this.data.splice(index, 0, item);
                    throw error;
                },
                () => {
                    // 'onCompleted' callback.
                    this.service.add({ severity: 'success', summary: 'Payment Added', detail: 'Amount:' + payment.paymentAmount });
                }
            );
    }

    getInvoicePayments(id, invoicenum): void {
        this.paymentservice.getInvoicePaymentbyInvoiceNum(id, invoicenum)
            .subscribe(payments => {
                this.invoicePayments = payments;
                this.addTotal();
            });
    }

    getCustomersFilter(status): void {
        this.customerService.getCustomerFilter(status)
            .subscribe(customers => {
                this.customers = customers.sort(this.dynamicSort('strCustomerName'));
            });
    }

    getContractsbyCustomer(customerid): void {
        this.contractService.getContractbyCustomerId(customerid)
            .subscribe(contracts => {
                this.contracts = contracts
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getInvoicesbyWoId(id): void {
        this.customerInvoiceService.getCustomerInvoicebyWoId(id) 
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getInvoicesforGroupInvoice(id): void {
        this.customerInvoiceService.getInvoiceforGroupInvoice(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getInvoicesforGroupInvoicebyCustomerId(id): void {
        this.customerInvoiceService.getInvoiceforGroupInvoicebyContractId(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    getInvoicesforPaymentbyContract(id): void {
        this.customerInvoiceService.getInvoiceforPaymentContractId(id)
            .subscribe(invoices => {
               
                //this.customerInvoices = invoices.filter(i => i.balance != 0);
                this.customerInvoices = invoices;
                this.progressBar = 'determinate'
                //this.clonedCustomerInvoices = [...this.customerInvoices];
                this.clonedCustomerInvoices = invoices;
            });
    }

    getInvoicesforPaymentbyCustomer(id): void {
        this.customerInvoiceService.getInvoiceforPaymentCustomerId(id)
            .subscribe(invoices => {

                //this.customerInvoices = invoices.filter(i => i.balance != 0);
                this.customerInvoices = invoices;
                this.progressBar = 'determinate'
                this.clonedCustomerInvoices = [...this.customerInvoices];
            });
    }

  

   

   

   

   

   

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newInvoice = true;
        this.userform.reset();
        this.customerGroupInvoice = {};
        this.userform.get('invoiceamount').setValue(this.sumSelectedInvoice);
        this.userform.get('invoicedate').setValue(this.invoiceDate);
        this.displayDialog = true;

    }

   

    onRowUnselect() {
        //this.displayContract = false;
    }

    //onRowSelect(event) {
    //    //this.displayContract = !this.displayContract;
    //    this.displayContract = true;
    //    var cont = this.allcontracts;
    //    if (event.data.intCustomerKey) {
    //        cont = cont.filter(v => v.intCustomerId == event.data.intCustomerKey);
    //        this.selectedCustomerId = event.data.intCustomerKey;
    //    }
    //    this.contracts = cont;
    //    //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.selectedCustomerId });
    //    //this.newCustomer = false;
    //    //this.customer = this.cloneCustomer(event.data);
    //    ////console.log(this.tbUsers.selection);
    //    //this.displayDialog = true;
    //}

    cloneInvoice(c: CustomerInvoice): CustomerInvoice {
        let contract = {};
        for (let prop in c) {
            contract[prop] = c[prop];
        }
        return contract;
    }

    cancel() {
        this.displayDialog = false;
    }

    onCancelContract() {
        this.displayContractDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Group Payments Posted', detail: this.customerName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: ' Updated', detail: this.customerName });
    }

    showDeleteViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Invoice Deleted', detail:''  });
    }

   

   

   

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    printDFR(rowdata) {
        this.displayReport = true;
        this.selectedInvoiceId = rowdata.intInvoiceId;
        this.selectedReportName = "Infratrac/Invoice.trdp";
        //console.log(rowdata.intInvoiceId);
    }

  

   

    onReject() {
        this.service.clear('c');
        this.customerInvoice = {};
    }

    groupBillInvoiceSelect(row, control) {
        //this.disableInvoiceBtn = true;
        //console.log(control.checked);
        //console.log(row.intProductionId);

        if (control.checked) {
            //this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 2;
            //this.updateUnbilled(row.intProductionId, row, true);
            row.payments = row.balance *-1;
            row.notes = this.achnumber;
            row.balance = row.curInvoiceTotal - (row.payments + row.pastPayments);
            this.addTotal();
        } else {
            //this.unbilledProductions.find(b => b.intProductionId == row.intProductionId).strStatus = 0;
            //this.updateUnbilled(row.intProductionId, row, true);
            row.payments= 0;
            row.balance = row.pastPayments - row.curInvoiceTotal;
            row.notes =''
            
            this.addTotal();
        }

        //this.disableInvoiceBtn = false;

    }

    addTotal() {
        let myArray = this.selectedInvoiceToGroupInvoice

        this.sumSelectedInvoice = 0;
        this.sumSelectedPayments = 0;
        if (myArray.length > 0) {
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i].payments) {
                    //this.sumSelectedInvoice += ((myArray[i].curInvoiceTotal));
                    this.sumSelectedPayments += Number(myArray[i].payments);
                  
                    //console.log(myArray[i].payments)
                }
            }
        }
        //this.costTotal.emit(this.sumBillableProductions);
    }

    ResetPayments() {
        if (this.customerInvoices.length > 0) {
            for (let i = 0; i < this.customerInvoices.length; i++) {

                //this.sumSelectedInvoice += ((myArray[i].curInvoiceTotal));
                //this.sumSelectedPayments += Number(myArray[i].payments);
                this.customerInvoices[i].payments =0;
                this.customerInvoices[i].balance = (this.customerInvoices[i].pastPayments) - this.customerInvoices[i].curInvoiceTotal;
                this.customerInvoices[i].notes = "";


            }
        }
    }

    selectInvoice(row, control) {
       
        //console.log(control);
        this.sumSelectedInvoice = 0;
        this.sumSelectedPayments = 0;

        if (control.checked) {
            
           
            if (this.selectedInvoiceToGroupInvoice.length > 0) {
                for (let i = 0; i < this.selectedInvoiceToGroupInvoice.length; i++) {

                    //this.sumSelectedInvoice += ((myArray[i].curInvoiceTotal));
                    //this.sumSelectedPayments += Number(myArray[i].payments);
                    this.selectedInvoiceToGroupInvoice[i].payments = this.selectedInvoiceToGroupInvoice[i].balance *-1;
                    this.selectedInvoiceToGroupInvoice[i].balance = this.selectedInvoiceToGroupInvoice[i].curInvoiceTotal - (this.selectedInvoiceToGroupInvoice[i].payments + this.selectedInvoiceToGroupInvoice[i].pastPayments);
                    this.selectedInvoiceToGroupInvoice[i].notes = this.achnumber;


                }
            }

        } else {
            //this.customerInvoices = [];
           
            //console.log(myArray)
            //this.ngOnInit();
            //this.progressBar = 'indeterminate'
            //this.getInvoicesforPayment(this.selectedContract);
            //this.customerInvoices = [];
            //this.customerInvoices = this.clonedCustomerInvoices;
            //console.log(this.customerInvoices);
            this.ResetPayments();


            
        }

        this.addTotal();
    }    
      
    

    onEditComplete(event, row) {
        this.addTotal();
        //console.log(event.data.payments);
        //console.log(event.data.pastPayments);
        //console.log(event.data.curInvoiceTotal);

        let pastpayments = 0
        if (event.data.pastPayment) {
            let pastpayments = event.data.pastPayment
        }
        //let balance = 0 
        //if (event.data.balance) {
        //    balance = event.data.balance
        //} else {
        //     balance =0 
        //}


        event.data.balance = event.data.curInvoiceTotal - (event.data.payments + pastpayments)
        //event.data.balance = balance

        //console.log(event.data.payments);
    }

  

    onDateSelect(event, dt) {
        //console.log('fired');
        dt.filter(this.rangeDates, 'dtmInvoiceDate', 'dateRangeFilter');

    }

    onCustomerSelect(event) {
        this.progressBar = 'indeterminate'
        if (event.value) {
            //console.log(event.value);
            //this.getInvoicesforGroupInvoice(event.value);
            this.getContractsbyCustomer(event.value);
            this.getInvoicesforPaymentbyCustomer(event.value);
            this.customerId = event.value;
        } else {
            this.customerInvoices = [];
        }
    }

    onContractSelect(event) {
        this.progressBar = 'indeterminate'
        if (event.value) {
            //console.log(event.value);
            this.selectedContract=event.value
            this.getInvoicesforPaymentbyContract(this.selectedContract);
            
            this.selectedContractId = event.value;
        } else {
            this.customerInvoices = [];
        }
    }     

    createFilterDate(time) {
        return this.datePipe.transform(new Date(time), 'yyyy-MM-dd');
    }
       

    async onPaymentSubmit() {

        if (Array.isArray(this.selectedInvoiceToGroupInvoice) && (this.selectedInvoiceToGroupInvoice.length)) {
            this.progressBar = 'indeterminate'
           if (this.selectedInvoiceToGroupInvoice.length > 0) {
               for (const groupinvoice of this.selectedInvoiceToGroupInvoice) {
                   
                       this.invoicePayment.customerId = this.customerId;
                       this.invoicePayment.invoiceNumber = groupinvoice.strInvoiceNo;
                       this.invoicePayment.jobId = groupinvoice.intJobNum;
                       this.invoicePayment.notes = groupinvoice.notes;
                       this.invoicePayment.paymentAmount = groupinvoice.payments;
                       this.invoicePayment.dateRec = this.invoiceDate;
                       //this.sumSelectedInvoice += ((myArray[i].curInvoiceTotal));
                       //this.sumSelectedPayments += Number(myArray[i].payments);

                       if (this.invoicePayment.paymentAmount == groupinvoice.curInvoiceTotal) {
                           this.invoicePayment.paymentInFull = -1;
                       } else {
                           this.invoicePayment.paymentInFull = 0;
                       }

                       await new Promise(resolve => setTimeout(resolve, 500)); // .5 sec
                       this.addInvoicePayment(this.invoicePayment);


               }
               this.progressBar = 'determinate'
               this.showSuccessViaToast();
               await new Promise(resolve => setTimeout(resolve, 500)); // .5 sec
               //console.log(this.selectedContractId);
               if (this.selectedContractId!=0) {
                   //console.log('Contract Selected');              
                   this.getInvoicesforPaymentbyContract(this.selectedContractId);
               } else {
                   //console.log('Customer Selected');    
                   this.getInvoicesforPaymentbyCustomer(this.customerId);
               }
               this.selectedInvoiceToGroupInvoice = [];
               this.sumSelectedPayments = 0;

           }

           
       }
    }
   
    

   
}
