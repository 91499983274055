import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Supervisor } from '../domain/supervisor';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SupervisorService {
    supervisor: Observable<Supervisor[]>;
    newsupervisor: Supervisor;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('SupervisorService');
    }
    baseUrl: string = 'api/Supervisors/';
    errorUrl: string = 'http://localhost:5243/api/Contracts/';

    getSupervisors(): Observable<Supervisor[]> {
        return this.http.get<Supervisor[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSupervisors', []))

            );
    }

    getSupervisor(id): Observable<Supervisor[]> {
        return this.http.get<Supervisor[]>(this.baseUrl + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSupervisor', []))

            );
    }

    getSupervisorsList(): Observable<Supervisor[]> {
        return this.http.get<Supervisor[]>(this.baseUrl + 'supervisors')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSupervisorsList', []))

            );
    }

    getActiveSupervisors(): Observable<Supervisor[]> {
        return this.http.get<Supervisor[]>(this.baseUrl + 'activesupervisors')

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getActiveSupervisorsList', []))

            );
    }

    getSupervisorsbyStatus(status): Observable<Supervisor[]> {
        return this.http.get<Supervisor[]>(this.baseUrl + 'supervisorsfilter/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSupervisorsFilter', []))

            );
    }

    addSupervisor(supervisor: Supervisor): Observable<Supervisor> {
        return this.http.post<Supervisor>(this.baseUrl, supervisor)
            .pipe(
                catchError(this.handleError('addSupervisor', supervisor))
            );
    }

    updateSupervisor(id, supervisor: Supervisor): Observable<Supervisor> {
        return this.http.put(this.baseUrl + id, supervisor)
            .pipe(
                tap(_ => console.log(`updated supervisor id=${id}`)),
                catchError(this.handleError('updateSupervisor', supervisor))
            );
    }

    /* GET heroes whose name contains search term */
    //searchContracts(term: string): Observable<Contract[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('contract', term) } : {};

    //    return this.http.get<Contract[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Contract[]>('searchContract', []))
    //        );
    //}
}
