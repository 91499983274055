import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LocateTicket } from '../domain/locateticket';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LocateTicketService {
    positions: Observable<LocateTicket[]>;
    newdowntime: LocateTicket;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('LocateTicketService');
    }
    baseUrl: string = 'api/LocateTicketInformations/';
    errorUrl: string = 'http://localhost:5243/api/locateticketinformation/';

    getLocateTickets(): Observable<LocateTicket[]> {
        return this.http.get<LocateTicket[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getLocateTicket', []))

            );
    }

    addLocateTicket(downtime: LocateTicket): Observable<LocateTicket> {
        return this.http.post<LocateTicket>(this.baseUrl, downtime)
            .pipe(
                catchError(this.handleError('add Locate Ticket', downtime))
            );
    }

    updateLocateTicket(id, downtime: LocateTicket): Observable<LocateTicket> {
        return this.http.put(this.baseUrl + id, downtime)
            .pipe(
                tap(_ => console.log(`updated position id=${id}`)),
                catchError(this.handleError('update Locate Ticket', downtime))
            );
    }

    deleteLocateTicket(id): Observable<LocateTicket> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted Locate Ticket id=${id}`)),
                catchError(this.handleError('Deleted Locate Ticket'))
            );
    }

    getLocateTicketbyDfrId(id): Observable<LocateTicket[]> {
        return this.http.get<LocateTicket[]>(this.baseUrl + 'dfrid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getLocateTicketsDfrbyID', []))

            );
    }
}
