import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { JobService } from '../service/jobservice';
import { SelectItem, Dropdown } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { DailyFieldReport } from '../domain/dailyfieldreport'
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';
import { DatePipe } from '@angular/common';
import { format, parse } from 'url';
import { formatDate } from '@angular/common';

@Component({
    selector: 'dfrbp-table',
    templateUrl: './dfrbillableproduction.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class DfrBillableProductionComponent implements OnInit {
    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    billableEquipmentHour: BillableProduction = {};

    billableEquipmentHours: BillableProduction[];

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    selectedBp: BillingCodeCustomer;

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    adjustmentReason: SelectItem[];

    displayDialog: boolean;

    newTask: boolean;

    newBp: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    isReadOnly: boolean = false;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    @Input() insertedDfrId: string = "";

    @Input() insertedDfrDate: string = "";

    @Input() dfrApproved: string = "false";

    @Input() viewPrices: boolean

    @Input() crewType: string;

    @ViewChild('qty', { static: false }) qty: ElementRef;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    invoiceDetails: boolean = false;

    selectedColumns: any[];

    selectedBillingCodeId: string;

    selectedBillingCode: BillingCodeCustomer;

    bcQty: string;

    intProductionId: string;

    deletedBc: string;

    deletedBcDescription: string;

    deletedQty: string;

    adjustment: boolean = false;

    showDelete: boolean = false;

    dfrId: string;

    dfrDate: string;

    canViewPrices: boolean;

    isSub: boolean = false;

    sumBillableProductions: number;

    sumSubCost: number;

    sumSub2Cost: number;

    bcExtended: number = 0;

    subExtended: number = 0;

    bcCostDisabled: boolean = true;

    subCost: number = 0;

    selectedWklyHours: number = 0;

    showWklyHrs: boolean = false;


    //@Output() costTotal = new EventEmitter<number>();

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private datePipe: DatePipe, 
        private dfrService: DailyFieldReportService, private billableproductionService: BillableProductionService, private renderer: Renderer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedContractId') {
                this.selectedContractId = curVal;
                //console.log(this.selectedContractId);
                if (this.selectedContractId) {
                    this.getActiveBillingCodes(this.selectedContractId)
                }
            }

            if (propName === 'insertedDfrId') {
                this.insertedDfrId = curVal;
                if (this.insertedDfrId)
                    this.dfrId = JSON.parse(this.insertedDfrId);
                this.insertedDfrId = this.dfrId
                //console.log(this.selectedContractId);
                if (this.insertedDfrId) {
                    this.getProductionbyDfrId(this.insertedDfrId);
                }
            }

            if (propName === 'selectedWoId') {
                this.selectedWoId = curVal;
                //console.log(this.selectedContractId);
                if (this.selectedContractId) {
                    //this.getActiveBillingCodes(this.selectedContractId)
                }
            }

            if (propName === 'insertedDfrDate') {
                this.insertedDfrDate = curVal;
                this.dfrDate = this.insertedDfrDate;
               
                if (this.insertedDfrDate) {
                    //this.getActiveBillingCodes(this.selectedContractId)
                    //console.log(this.dfrDate);
                }
            }

            if (propName === 'dfrApproved') {
                //console.log(curVal);
                //this.dfrApproved = curVal;
                this.dfrApproved = curVal;
                if (this.dfrApproved == "true") {
                    this.isReadOnly = true;
                }
                else {
                    this.isReadOnly = false;
                }

                //console.log('RO' + this.isReadOnly);
            }

            if (propName === 'crewType') {
                //console.log(curVal);
                //this.dfrApproved = curVal;
                this.crewType = curVal;
                //console.log(this.crewType);
                if (this.crewType == '21' || this.crewType == '6') {
                    this.isSub = true
                } else {
                    this.isSub = false;
                }
            }

            if (propName === 'viewPrices') {
                //console.log(curVal);
                //this.dfrApproved = curVal;

                if (curVal == "true") {
                    this.canViewPrices = true;
                }
                else {
                    this.canViewPrices = false;
                }
            }
        }
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl(''),
            'taskowner': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
        });

        //this.getCrews();

        //this.getEmployeesbyStatus();

        this.billableProductions = [];

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intQty', header: 'Qty', width: '8%' },
            { field: 'bcode', header: 'BC', width: '15%' },
            { field: 'bcdescription', header: 'Description', width: '20%' },
            { field: 'strConstPt1', header: 'CPoint 1' },
            { field: 'strConstPt2', header: 'CPoint 2' },
            { field: 'strDetails', header: 'Comments' },

        ];

        this.adjustmentReason = [
            { label: 'Qty Misreport by Crew', value: 'Qty Misreport by Crew' },
            { label: 'Crew Failed to Report Production', value: 'Crew Failed to Report Production' },
            { label: 'Billing Code Misreported by Crew', value: 'Billing Code Misreported by Crew' },
            { label: 'Job Number Misreported by Crew', value: 'Job Number Misreported by Crew' },
            { label: 'PO Placeholder', value: 'PO Placeholder' },
            { label: 'Third-Party Invoice', value: 'Third- Party Invoice' },
            { label: 'Payment Difference', value: 'Payment Difference' },
            { label: 'Lump Sum Adjustment for Billing', value: 'Lump Sum Adjustment for Billing' },
            { label: 'Admin Error', value: 'Admin Error' },
            { label: 'Rejected by Customer', value: 'Rejected by Customer' },
            { label: 'Other - State in DFR Comments', value: 'Other - State in DFR Comments' },
        ];

        this.selectedColumns = this.cols;
    }

    formatDate(time) {
        return this.datePipe.transform(new Date(time), 'yyyy-MM-dd');
    }

    addBillableProduction(bp, dfrid): void {
        if (!bp) { return; }
        this.billableproductionService.addBillableProduction(bp)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    this.getProductionbyDfrId(dfrid);
                    this.showBcSuccessViaToast(data.intQty, data.strCustomerBillingCode);
                });
    }

    updateBillableProduction(productionid, bp, dfrid): void {
        if (!bp) { return; }
        this.billableproductionService.updateBillableProduction(productionid, bp)
            .subscribe(
                data => {
                    //console.log(data);
                    this.getProductionbyDfrId(dfrid);
                    this.showSuccessUpdateViaToast();
                });
    }

    getProductionbyWoId(id): void {
        this.billableproductionService.getBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEquipmentBillabeHours(date): void {
        this.billableproductionService.getBillableEquipmentHours(date)
            .subscribe(bp => {
                this.billableEquipmentHours = bp;
                if (this.billableEquipmentHours) {
                    this.billableEquipmentHour = this.billableEquipmentHours.find(bc => bc.strCustomerBillingCode == this.selectedBillingCode.strCustomerBillingCode);
                    if (this.billableEquipmentHour) {
                        this.selectedWklyHours = this.billableEquipmentHour.total;
                        //console.log(this.billableEquipmentHour.total)

                    } else {
                        this.selectedWklyHours = 0;
                    }
                } else {
                   
                  
                }
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    deleteProduction(id): void {
        this.billableproductionService.deleteBillableProduction(id)
            .subscribe();
        this.showdeletedBillingCode(this.deletedBc, this.deletedBcDescription);
        this.addTotal();
    }

    getActiveBillingCodes(id): void {
        this.billingCodeCustomerService.getActiveBillingCodesbyContract(id)
            .subscribe(bc => {
                this.billingCodesCustomer = bc;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(bc);
                //this.addTotal();
            });
    }

    getProductionbyDfrId(id): void {
        this.billableproductionService.getBillableProductionbyDfrId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                this.addTotal();
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAdd() {
        this.adjustment = false;
        this.bcExtended = 0;
        this.subExtended = 0;
        this.subCost = 0;
        this.newBp = true;
        this.billableProduction = {};
        this.showDelete = false;
        this.bcCostDisabled = true;
        this.displayDialog = true;
        this.billableProduction.curBcprice = 0;
        this.showWklyHrs = false;
    }

    cancel() {
        this.displayDialog = false;
        this.showWklyHrs = false;
    }

    showBcSuccessViaToast(qty: string, billingcode: string) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Added', detail: qty + '-' + billingcode });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Updated', detail: this.unitName });
    }

    showSuccessViaToast(message) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Production Updated', detail: message });
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onChangeBillingCode(event) {
        this.selectedBillingCodeId = event.value;

        this.selectedBillingCode = this.billingCodesCustomer.find(bc => bc.intCustomerBillingCodesKey == this.selectedBillingCodeId);

        this.billableProduction.strCustomerBillingCode = this.selectedBillingCode.strCustomerBillingCode;

        this.billableProduction.strBcdescription = this.selectedBillingCode.strCustomerProductDesc;

        this.billableProduction.curBcprice = this.selectedBillingCode.curUnitRate;

        this.billableProduction.curSubBcprice = this.selectedBillingCode.curSubUnitRate;

        this.billableProduction.curSub2Bcprice = this.selectedBillingCode.curSub2UnitRate;


        // If Billing Code is Adjustment allow editing of billing code cost
        //console.log(this.selectedBillingCode.intInfratechBillingCode);
        if (this.selectedBillingCode.intInfratechBillingCode == 174 && !this.isReadOnly) {
           
            this.bcCostDisabled = false;
        //3rd Party Pass-Through Billing Codes make Cost Editable
        } else if (this.selectedBillingCode.intInfratechBillingCode == 26 && !this.isReadOnly) {
            this.bcCostDisabled = false;
        } else {
            this.bcCostDisabled = true;
        }

        //3rd Party Pass-Through Billing Codes make Cost Editable
        //console.log(this.selectedBillingCode.intInfratechBillingCode);
        //if (this.selectedBillingCode.intInfratechBillingCode == 26 && !this.isReadOnly) {
        //    this.bcCostDisabled = false;
        //} else {
        //    this.bcCostDisabled = true;
        //}

        //If Billing Code has HREQ show Total Hours for Weekly Billing
        if (this.selectedBillingCode.intInfratechBillingCode == 355 && this.selectedBillingCode.intCustomer==422) {
            let dateString = this.insertedDfrDate.replace(/^"(.*)"$/, '$1');
            let newDate = formatDate(dateString, 'shortDate', 'en');
            this.showWklyHrs = true;
            //console.log(this.formatDate(newDate));
            this.getEquipmentBillabeHours(this.formatDate(dateString));
            //this.calculateHREQHours(0);
            //console.log(this.billableEquipmentHour);
        } else {
            this.showWklyHrs = false;
        }

        this.calculateExtended()

        setTimeout(() => this.qty.nativeElement.focus());
    }

    myDateParser(dateStr: string): string {
        // 2018-01-01T12:12:12.123456; - converting valid date format like this

        let date = dateStr.substring(0, 10);
        let time = dateStr.substring(11, 19);
        let millisecond = dateStr.substring(20)

        let validDate = date ;
        //console.log(validDate)
        return validDate
    }

    onConfirm() {
        this.service.clear('d');
        let index = this.billableProductions.indexOf(this.selectedBp);
        this.deleteProduction(this.intProductionId);
        this.billableProductions = this.billableProductions.filter((val, i) => i != index);
        this.billableProduction = null;
        this.adjustment = false;
        this.displayDialog = false;
        this.bcExtended = 0;
        this.subExtended = 0;
        this.addTotal();
    }

    onReject() {
        this.service.clear('d');
    }

    save() {
        const epochNow = (new Date).getTime();
        if (this.billableProduction.intBccustomer) {
            //console.log(this.billableProduction.intBccustomer);
            this.billableProduction.intReportId = this.insertedDfrId;
            this.billableProduction.intJobId = this.selectedWoId
            let billableproductions = [...this.billableProductions];
            //console.log(this.billableProduction);
            if (this.newBp) {
                billableproductions.push(this.billableProduction);
                this.addBillableProduction(this.billableProduction, this.insertedDfrId);
            }
            else {

                billableproductions[this.billableProductions.indexOf(this.selectedBp)] = this.billableProduction;
                this.billableProduction.mDateUpdated = epochNow;
                this.updateBillableProduction(this.intProductionId, this.billableProduction, this.insertedDfrId);
            }
            this.addTotal();
            this.billableProductions = billableproductions;
            this.billableProduction = null;
            this.adjustment = false;
            this.displayDialog = false;
            this.bcExtended = 0;
            this.subExtended = 0;
            this.showWklyHrs = false;

        }
        else {
            this.showdNeedBillingCode();
        }
    }

    onRowSelect(event) {
       
        this.bcExtended = 0;
        this.subExtended = 0;
        this.subCost = 0;
        if (this.isReadOnly) {
            this.showDelete = false
        } else {
            this.showDelete = true;
        }
        this.adjustment = false;
        this.newBp = false;
        this.billableProduction = this.cloneProduction(event.data);
        this.intProductionId = this.billableProduction.intProductionId
        this.deletedBc = this.billableProduction.strCustomerBillingCode;
        this.deletedBcDescription = this.billableProduction.strBcdescription;
        this.deletedQty = this.billableProduction.intQty;
        this.calculateExtended();
        if (this.billableProduction.ysnAdjustment == -1) {
            this.adjustment = true
        } else {
            this.adjustment = false
        }

        //Adjustment Billing Code make Cost Editable
        //if (this.billableProduction.strCustomerBillingCode === 'ADJ' && !this.isReadOnly) {
        //    this.bcCostDisabled = false;
        //} else {
        //    this.bcCostDisabled = true;
        //}

       


        this.selectedBillingCodeId = this.billableProduction.intBccustomer;

        this.selectedBillingCode = this.billingCodesCustomer.find(bc => bc.intCustomerBillingCodesKey == this.selectedBillingCodeId);

        //3rd Party Pass-Through Billing Codes make Cost Editable
        //console.log(this.selectedBillingCode.intInfratechBillingCode);
        // If Billing Code is Adjustment allow editing of billing code cost
        //console.log(this.selectedBillingCode.intInfratechBillingCode);
        if (this.selectedBillingCode.intInfratechBillingCode == 174 && !this.isReadOnly) {

            this.bcCostDisabled = false;
            //3rd Party Pass-Through Billing Codes make Cost Editable
        } else if (this.selectedBillingCode.intInfratechBillingCode == 26 && !this.isReadOnly) {
            this.bcCostDisabled = false;
        } else {
            this.bcCostDisabled = true;
        }

        if (this.selectedBillingCode.intInfratechBillingCode == 355 && this.selectedBillingCode.intCustomer == 422) {
            let dateString = this.insertedDfrDate.replace(/^"(.*)"$/, '$1');
            let newDate = formatDate(dateString, 'shortDate', 'en');
            this.showWklyHrs = true;
            //console.log(this.formatDate(newDate));
            this.getEquipmentBillabeHours(this.formatDate(dateString));
            //this.calculateHREQHours(0);
            //console.log(this.billableEquipmentHour);
        } else {
            this.showWklyHrs = false;
        }


        this.displayDialog = true;

    }

    cloneProduction(p: BillableProduction): BillableProduction {
        let billableproduction = {};
        for (let prop in p) {
            billableproduction[prop] = p[prop];
        }
        return billableproduction;
    }

    delete() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Production?',
            detail: this.deletedQty + ' of ' + this.deletedBc + ' : ' + this.deletedBcDescription
        });
    }

    showdeletedBillingCode(billingcode, description) {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Production Deleted', detail: this.deletedBc + '-' + this.deletedBcDescription });
    }

    showdNeedBillingCode() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Need Billing Code', detail: 'Select a Billing Code' });
    }

    onAdjustmentChange(event) {
        if (event) {
            this.billableProduction.ysnAdjustment = -1;
        } else {
            this.billableProduction.ysnAdjustment = 0;
            this.billableProduction.strBillingComments = "";
        }
    }

    addTotal() {
        let myArray = this.billableProductions

        this.sumBillableProductions = 0;
        this.sumSub2Cost = 0;
        this.sumSubCost = 0;
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].intQty && myArray[i].curBcprice) {
                this.sumBillableProductions += ((myArray[i].intQty) * (myArray[i].curBcprice));
                this.sumSubCost += ((myArray[i].intQty) * (myArray[i].curSubBcprice));
                this.sumSub2Cost += ((myArray[i].intQty) * (myArray[i].curSub2Bcprice))
            }
        }

        //this.costTotal.emit(this.sumBillableProductions);
    }

    calculateExtended() {
        this.subExtended = 0;
        this.bcExtended = 0;
        this.subCost = 0
        //let wklytotal = 0
        //if (!isNaN(this.billableEquipmentHour.total)) {
        //    wklytotal = this.billableEquipmentHour.total;
        //} else {
        //    wklytotal=0
        //}
        this.bcExtended = this.billableProduction.curBcprice * this.billableProduction.intQty;
        if (this.crewType == '6') {
            this.subExtended = this.billableProduction.intQty * this.billableProduction.curSubBcprice;
            this.subCost = this.billableProduction.curSubBcprice;
        }
        else if (this.crewType == '21') {
            this.subExtended = this.billableProduction.intQty * this.billableProduction.curSub2Bcprice;
            this.subCost = this.billableProduction.curSub2Bcprice;
        }

       
        //if (this.deletedQty != this.billableProduction.intQty) {
        //    let wklytotal = 0;
        //    let qty = 0;
        //    qty = value;
        //    wklytotal = this.selectedWklyHours;
        //    //console.log(value);
        //    this.selectedWklyHours = (wklytotal - Number(this.deletedQty) + qty);
        //    console.log(this.selectedWklyHours);
        //}
            
       
        
       

        

    }

    calculateHREQHours(changedqty) {
        //let wklytotal = 0
        //let qty = 0
        //qty = changedqty
        //console.log(wklytotal)
        //console.log(qty)
        // if (!this.newBp) {
        //    if (this.billableProduction.intQty != this.deletedQty) {
        //        if (this.selectedWklyHours) {
        //            wklytotal = this.selectedWklyHours
        //        } else {
        //            wklytotal=0
        //        }
        //        this.selectedWklyHours = (Number(this.deletedQty)) + qty;
        //        console.log(wklytotal)
        //        console.log(qty)

        //    } else {
        //        this.selectedWklyHours = wklytotal;
        //        console.log("other")
        //    }
        //} else if (this.newBp) {
        //    console.log("New BP")
        //    this.selectedWklyHours = Number(wklytotal) + this.billableProduction.intQty;
        //} else {
        //    this.selectedWklyHours = wklytotal;
        //    console.log("other")
        //}



    }

    onCostChange(event) {
        //console.log(event)
        let pricechange = event.replace(/[$,]/g, "");
        this.billableProduction.curBcprice = pricechange;
        this.calculateExtended();
    }
}
