import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes, routes } from './app.routes';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
//import { ClipboardModule } from '@angular/cdk/clipboard'
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';

import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { CustomerComponent } from './infratrac/customer-form/customer.component';
import { CarService } from './demo/service/carservice';
import { CountryService } from './demo/service/countryservice';
import { EventService } from './demo/service/eventservice';
import { NodeService } from './demo/service/nodeservice';
import { AuthService } from './auth.service';
import { HttpErrorHandler } from './http-error-handler.service';
import { MessageService } from './message.service';
import { MessagesComponent } from './messages/messages.component';
import { PackageSearchComponent } from './package-search/package-search.component';
import { UploaderComponent } from './uploader/uploader.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { httpInterceptorProviders } from './http-interceptors/index';

import { BillingCodeCustomerComponent } from './infratrac/billingcodecustomer/billingcodecustomer.component';
import { BillingCodeImportComponent } from './infratrac/billingcode-import/billingcode-import.component';
import { BillingCodeInfratechComponent } from './infratrac/billingcodeinfratech-table/billingcodeinfratech.component';
import { BillingUnitTypeComponent } from './infratrac/billingunittype-table/billingunittype.component';
import { BillingCodesCustomerService } from './infratrac/service/billingcodescustomerservice';
import { BillingCodesInfratechService } from './infratrac/service/billingcodesinfratechservice';
import { BillingUnitTypeService } from './infratrac/service/billingunittypeservice';
import { BlobStorageService } from './infratrac/service/blobuploaderservice';
import { BlobComponent } from './infratrac/blob-uploader/blob.component';
import { CrewTypeComponent } from './infratrac/crewtypes-form/crewtype.component';
import { CrewTypeService } from './infratrac/service/crewtypeservice';
import { CrewMemberService } from './infratrac/service/crewmembersservice';
import { CrewService } from './infratrac/service/crewservice';
import { ContractChildComponent } from './infratrac/contract-details/contract.component';
import { ContractOverviewComponent } from './infratrac/contract-overview/contractoverview.component';
import { ContractFormComponent } from './infratrac/contract-form/contract-form.component';
import { ContractService } from './infratrac/service/contractservice';
import { CustomerService } from './infratrac/service/customerservice';
import { CustomerInvoiceService } from './infratrac/service/customerinvoiceservice';
import { EmployeeCounselingService } from './infratrac/service/employeecounselingservice';
import { CustomerGroupInvoiceService } from './infratrac/service/customergroupinvoiceservice';
import { CustomerGroupInvoiceComponent } from './infratrac/customergroupinvoicing/customergroupinvoice.component';
import { CustomerGroupInvoicesComponent } from './infratrac/customergroupinvoices/customergroupinvoices.component';
import { TaskService } from './infratrac/service/taskservice';
import { TaskDelayServices } from './infratrac/service/taskdelayservice';

import { CustomerInvoiceComponent } from './infratrac/customerinvoices-table/customerinvoice.component';
import { WoInvoiceComponent} from './infratrac/invoicing/woinvoice.component'
import { CrewComponent } from './infratrac/crew-form/crew.component';
import { CrewMemberComponent } from './infratrac/crewmembers/crewmember.component';
import { DfrComponent } from './infratrac/dfr/dfr.component';
import { DfrBillableProductionComponent } from './infratrac/dfrbillableproduction/dfrbillableproduction.component';
import { DfrEquipmentUsageComponent } from './infratrac/dfrequipmentusage/dfrequipmentusage.component';
import { DfrSystemDownTimeComponent } from './infratrac/dfrsystemdowntime/dfrsystemdowntime.component';
import { DfrPayRollHoursTableComponent } from './infratrac/dfrpayrollhours/dfrpayrollhour.component';
import { DfrProductionDelaysComponent } from './infratrac/dfrProductionDelays/dfrproductiondelays.component';
import { DfrLocateTicketComponent } from './infratrac/dfrlocatetickets/dfrlocateticket.component';
import { EmployeeComponent } from './infratrac/employee-form/employee.component';
import { EmployeeDetailsComponent } from './infratrac/employee-details/employee-details.component';
import { EmployeeService } from './infratrac/service/employeeservice';
import { EquipmentUsageService } from './infratrac/service/equipmentusageservice';
import { EquipmentService } from './infratrac/service/equipmentservice';
import { EstimatedUnitService } from './infratrac/service/estimatedunitservice';
import { EstimatedUnitComponent } from './infratrac/estimatedunits/estimatedunit.component';
import { DetailedEstimatedUnitService } from './infratrac/service/detailedestimatedunitservice';
import { InvoicePaymentsServices } from './infratrac/service/invoicepayments';
import { ExcelImportComponent } from './infratrac/excel-importer/excelimport.component';
import { InvoicePaymentListComponent } from './infratrac/invoicepaymentslist/invoicepaymentlist.component';
import { JsonParsePipe } from './_pipes/jsonparse.pipe';
import { SubContractorComponent } from './infratrac/subcontractor-form/subcontractor.component';
import { DropdownPipe } from './dropdown.pipe';
import { SplitCommaStringPipe } from './_pipes/split.pipe';
import { SubContractorService } from './infratrac/service/subcontractorservice';
import { SupervisorService } from './infratrac/service/supervisorservice';
import { PayRollHourService } from './infratrac/service/payrollhourservice';
import { PayRollHoursTableComponent } from './infratrac/payroll-table/payroll-table.component';
import { LinemanToolTableComponent } from './infratrac/linemantool-table/linemantooltable.component';
import { LinemanToolPurchasesServices } from './infratrac/service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from './infratrac/service/linemantoolvendorservice';
import { RepMaintActivitiesTableComponent } from './infratrac/repmaintactivies-table/repmaintactivitestable.component';
import { LocateTicketService } from './infratrac/service/locateticketservice';
import { OfficeLocationService } from './infratrac/service/officelocationservice';
import { OfficeLocationsTableComponent } from './infratrac/officelocation-table/officelocationtable.component';
import { ProductionDelayService } from './infratrac/service/productiondelayservice';
import { ProgressiveIncidentService } from './infratrac/service/progressiveincidentservice';
import { SystemDowntimeService } from './infratrac/service/systemdowntimeservice';
import { PositionService } from './infratrac/service/positionservice';
import { JobService } from './infratrac/service/jobservice';
import { RepMaintVendorService } from './infratrac/service/repmaintvendorservice';
import { RepMaintItemService } from './infratrac/service/repmaintitemservice';
import { RepMaintActivityService } from './infratrac/service/repmaintactivityservice';
import { WoTableComponent } from './infratrac/wo-table/wotable.component';
import { WoSearchComponent } from './infratrac/wo-search/wosearch.component';
import { ReportViewerComponent } from './infratrac/telerik-reportviewer/reportviewer.component';
import { ReportPageComponent } from './infratrac/reportpage/reportpage.component';
import { ReportPageViewerComponent } from './infratrac/reportviewer/reportviewer.component';
import { RpCrewComponent } from './infratrac/rpcrew-list/rpcrew.component';
import { SupervisorComponent } from './infratrac/supervisor-table/supervisor.component';
import { TaskTableComponent } from './infratrac/task-table/tasktable.component';
import { DfrTableComponent } from './infratrac/dfr-table/dfrtable.component';
import { BpTableComponent } from './infratrac/billableproduction-table/bptable.component';
import { DailyFieldReportService } from './infratrac/service/dailyfieldreportservice';
import { BillableProductionService } from './infratrac/service/billableproductionservice';
import { VendorComponent } from './infratrac/vendor-form/vendor.component';
import { VendorTableComponent } from './infratrac/vendor-dfrlist/vendortable.component';
import { AlertComponent } from './_directives/index';
import { AlertService } from './_services/index';
import { DateService } from './_services/date.service';
import { ValidationDemo } from './infratrac/validationdemo/validationdemo.component';
import { RouterModule } from '@angular/router';
import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6';
import { UserPermissionService } from './infratrac/service/userpermissionsservice';
import { UserPermissionComponent } from './infratrac/userpermissions/userpermission.component';
import { UserAuthService } from './infratrac/service/userauthservice';
import { FsclyrwksService } from './infratrac/service/fsclyrwksservice';
import { UnbilledTableComponent } from './infratrac/unbilled/unbilledtable.component';
import { ConfirmationService } from 'primeng/api';
import { GroupInvoicePaymentComponent } from './infratrac/invoicegrouppayment/grouppayment.component';
import { EquipmentComponent } from './infratrac/equipment/equipment.component';
import { EmployeeCounselingComponent } from './infratrac/employeecounseling/employeecounseling.component';
import { PositionComponent } from './infratrac/position/position.component';
import { PositionMapperComponent } from './infratrac/positionmapper/positionmapper.component';
import { PositionMapperService } from './infratrac/service/positionmapperservice';
import { ProficiencyActivityService } from './infratrac/service/proficiencyactivityservice';
import { ProficiencyService } from './infratrac/service/proficiencyservice';
import { ProjectComponent } from './infratrac/projects/project.component';
import { TrainingComponent } from './infratrac/training/training.component';
import { PowerBiComponent } from './infratrac/powerbi/powerbi.component';
import { EmployeeImportComponent } from './infratrac/employeeimport/employee-import.component';
import { EditPersonComponent } from './infratrac/persondemo/person-edit.component';
import { DynamicControlComponent } from './infratrac/dynamic-controls/dynamic-control.component';
import { DynamicControlsService } from './infratrac/service/dynamiccontrolservice';
import { DynamicFormComponent } from './infratrac/dynamic-form/dynamic-form.component';
import { WoContractUpdateComponent } from './infratrac/wocontractupdate-import/wocontractupdate-import.component';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        //ClipboardModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        DynamicDialogModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        GrowlModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,      
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        MatSnackBarModule,
        KeyFilterModule,
        TelerikReportingModule,
        TableModule,
       
        MsAdalAngular6Module.forRoot({
            instance: 'https://login.microsoftonline.com/',
            tenant: '575cb99c-bf29-4866-a8d3-d2bc18115356',
            clientId: '43b25371-d6ec-4ca2-aee4-7d0074888c77',
            redirectUri: window.location.origin,
            navigateToLoginRequestUrl: true,
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true
        }),
        RouterModule.forRoot(
            routes,
            { enableTracing: true }
        ),

    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        DynamicFormComponent,
        SampleDemoComponent,
        FormsDemoComponent,
        DataDemoComponent,
        InvoicePaymentListComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        CustomerComponent,
        MessagesComponent,
        UploaderComponent,
        PackageSearchComponent,
        CrewComponent,
        DropdownPipe,
        EmployeeComponent,
        EstimatedUnitComponent,
        AlertComponent,
        ContractChildComponent,
        SplitCommaStringPipe,
        JsonParsePipe,
        EmployeeDetailsComponent,
        ValidationDemo,
        CrewTypeComponent,
        SubContractorComponent,
        PayRollHoursTableComponent,
        LinemanToolTableComponent,
        BillingUnitTypeComponent,
        BillingCodeInfratechComponent,
        BillingCodeCustomerComponent,
        ContractOverviewComponent,
        WoTableComponent,
        ContractFormComponent,
        ReportViewerComponent,
        WoSearchComponent, 
        WoInvoiceComponent,
        RepMaintActivitiesTableComponent,
        TaskTableComponent,
        SupervisorComponent,
        DfrTableComponent,
        DfrBillableProductionComponent,
        DfrLocateTicketComponent,
        DfrPayRollHoursTableComponent,
        DfrProductionDelaysComponent,
        DfrSystemDownTimeComponent,
        DfrEquipmentUsageComponent,
        BpTableComponent,
        CustomerInvoiceComponent,
        VendorComponent,
        DfrComponent,
        RpCrewComponent,
        VendorTableComponent,
        OfficeLocationsTableComponent,
        CrewMemberComponent,
        BlobComponent,
        ReportPageComponent,
        ReportPageViewerComponent,
        ExcelImportComponent,
        BillingCodeImportComponent,
        UnbilledTableComponent,
        CustomerGroupInvoiceComponent,
        CustomerGroupInvoicesComponent,
        GroupInvoicePaymentComponent,
        UserPermissionComponent,
        EquipmentComponent,
        EmployeeCounselingComponent,
        PositionComponent,
        PositionMapperComponent,
        ProjectComponent,    
        TrainingComponent,
        PowerBiComponent,
        EmployeeImportComponent,
        EditPersonComponent,
        DynamicControlComponent,
        WoContractUpdateComponent

        
    ],
    providers: [AuthService, HttpErrorHandler, MessageService, DateService, AuthenticationGuard, CurrencyPipe,
        { provide: LocationStrategy, useClass: HashLocationStrategy, },
        CarService, CountryService, EventService, NodeService, CustomerService, ContractService, CrewService, CrewTypeService, EmployeeService, AlertService, DateService, PositionService,
        SubContractorService, PayRollHourService, LinemanToolPurchasesServices, LinemanToolVendorServices, BillingUnitTypeService, BillingCodesInfratechService, BillingCodesCustomerService,
        JobService, OfficeLocationService, SupervisorService, TaskService, DailyFieldReportService, BillableProductionService, CustomerInvoiceService, OfficeLocationService, CrewMemberService,
        BlobStorageService, SystemDowntimeService, ProductionDelayService, SystemDowntimeService, EquipmentService, EquipmentUsageService, LocateTicketService, UserPermissionService, UserAuthService,
        CustomerGroupInvoiceService, FsclyrwksService, ConfirmationService, InvoicePaymentsServices, EstimatedUnitService, DetailedEstimatedUnitService, TaskDelayServices, RepMaintActivityService,
        RepMaintItemService, RepMaintVendorService, ProgressiveIncidentService, EmployeeCounselingService, PositionMapperService, ProficiencyActivityService, ProficiencyService, DynamicControlsService

    ],

    entryComponents: [
        InvoicePaymentListComponent
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
