import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { TaskService } from '../service/taskservice';
import { Task } from '../domain/task';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { DailyFieldReport } from '../domain/dailyfieldreport'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';
@Component({
    selector: 'dfr-table',
    templateUrl: './dfrtable.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class DfrTableComponent implements OnInit {
    dfr: DailyFieldReport = {};

    dfrs: DailyFieldReport[];

    wo: Job = {};

    wos: Job[];

    task: Task = {};

    tasks: Task[];

    crews: Crew[];

    crew: Crew = {};

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newTask: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() selectedASupervisor: string = "";

    @Input() selectedCrewId: string = "";

    @Input() selectedEmployeeId: string = "";

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    taskName: SelectItem[];

    taskStatus: SelectItem[];

    foremanId: string = "";

    crewId: string = "";

    crewNumber: string;

    taskDropdown: string;

    displayReport: boolean = false;

    selectedDfr: DailyFieldReport;

    selectedDfrId: string = null;

    selectedReportName: string = null;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    newAuth: boolean = false;

    canView: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private taskService: TaskService, private crewService: CrewService, private dfrService: DailyFieldReportService, private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedCrewId') {
                //this.onRowSelect(curVal);
                this.selectedCrewId = curVal;
                this.getDfrbyCrewId(this.selectedCrewId);
                //console.log('fired');
            }

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.selectedWoId = curVal;
                this.getDfrbyWoId(this.selectedWoId);
                //console.log(this.selectedWoId);
            }

            if (propName === 'selectedASupervisor') {
                //this.onRowSelect(curVal);
                this.selectedASupervisor = curVal;
                //console.log(this.selectedASupervisor);
            }

            if (propName === 'selectedEmployeeId') {
                //this.onRowSelect(curVal);
                this.selectedEmployeeId = curVal;
                this.getDfrbyEmployeeId(this.selectedEmployeeId);
                //console.log(this.selectedASupervisor);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'crew': new FormControl('', Validators.required),
            'foreman': new FormControl('', Validators.required),
            'task': new FormControl('', Validators.required),
            'taskstatus': new FormControl(''),
            'tasknote': new FormControl(''),
            'datestart': new FormControl(''),
            'taskowner': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
        });



        this.getCrews();

        this.getEmployeesbyStatus();

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'intReportNo', header: 'DFR #' },
            { field: 'dtmWorkDate', header: 'DFR Date' },
            { field: 'strJob', header: 'WO#' },
            { field: 'crew', header: 'Crew' },
            { field: 'foreman', header: 'Foreman' },
            { field: 'taskname', header: 'Task' },
            //{ field: 'selUtilityHit', header: 'Utility Hit' },
            //{ field: 'selPersonalInjury', header: 'Personal Injury' },
            //{ field: 'selVehicleAccident', header: 'Vehicle Accident' },
            { field: 'mmoComments', header: 'Comments' },
            { field: 'ysnApproved', header: 'Approved' },
            { field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getEmployeeAuth(id): void {
        this.userauthservice.getAuthId(id)
            .subscribe(auths => {
                this.userauths = auths
                if (this.userauths.length > 0) {
                    this.userauth = auths.find(e => e.fkEmpId == id);
                    //console.log(this.userauth);
                    //console.log(this.userauths);
                    if (this.userauth) {
                        this.userform.get('username').setValue(this.userauth.userId);
                        this.userform.get('password').setValue(this.userauth.password);
                        this.newAuth = false;
                    } else {
                        //this.newAuth = true;
                    }

                } else {
                    this.newAuth = true;
                }
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

              
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.dfredit == 1 || this.userpermission.intAdmin == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.dfrview == 1 || this.userpermission.intAdmin == 1) {
                    this.canView = true;
                } else {
                    this.canView = false;
                }

              



            });

        //this.items = [

        //    {
        //        label: 'Tool Bank Qtr Balance', icon: 'pi pi-print', command: () => {
        //            this.printQtrToolBankBalance();
        //        }
        //    },
        //    {
        //        label: 'Tool Bank Balance', icon: 'pi pi-print', command: () => {
        //            this.printToolBankBalance();
        //        }
        //    },
        //    //{
        //    //    label: 'Employee Counseling', icon: 'pi pi-print', visible: this.canViewEmployeeCounseling, command: () => {
        //    //        this.printEmployeeCounseling();
        //    //    }
        //    //},
        //    {
        //        label: 'Employee Training', icon: 'pi pi-print', command: () => {
        //            this.printEmployeeTraining();
        //        }
        //    },
        //    {
        //        label: 'Absentee Report', icon: 'pi pi-print', command: () => {
        //            this.displayReport = true;
        //            this.selectedReportName = "Employee/Employee Absentee Report.trdp";
        //        }
        //    },
        //    {
        //        label: 'NP Report', icon: 'pi pi-print', command: () => {
        //            this.displayReport = true;
        //            this.selectedReportName = "Employee/Employee Hours NP Report.trdp";
        //        }
        //    },
        //];
    }

    onSelect(value) {
        //const value = event.target.value;
        this.dateReceived = value;
        //console.log(element.getAttribute('formControlName'));
        this.task.dtmStartDate = this._dateService.toString(this.dateReceived);
    }

    getDfrbyWoId(id): void {
        this.dfrService.getDFRbyWoId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getCrews(): void {
        this.crewService.getCrewsNoLegacy()
            .subscribe(crews => {
                this.crews = crews.sort(this.dynamicSort('strCrewId'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getDfrbyCrewId(id): void {
        this.dfrService.getDFRbyCrewId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getDfrbyEmployeeId(id): void {
        this.dfrService.getDFRbyForemanId(id)
            .subscribe(dfrs => {
                this.dfrs = dfrs;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    addTask(task): void {
        if (!task) { return; }
        this.taskService.addTask(this.task)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    this.getDfrbyWoId(this.selectedWoId);
                    this.showSuccessViaToast();
                });
    }

    updateTask(): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        this.unitName = this.task.strTaskName;
        //console.log(this.billingunittype);
        this.taskService.updateTask(this.intUnitId, this.task)

            .subscribe(data => {
                //console.log(data);
                this.getDfrbyWoId(this.selectedWoId);
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newTask = true;
        this.wo = {};
        this.displayDialog = true;
    }

    clone(c: Task): Task {
        let task = {};
        for (let prop in c) {
            task[prop] = c[prop];
        }
        return task;
    }

    cancel() {
        this.displayDialog = false;
        this.wo = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Task Updated', detail: this.unitName });
    }

    edit(rowdata) {
        this.intUnitId = rowdata.intTaskId;

        this.newTask = false;
        this.task = this.clone(rowdata);

        //this.insertASupertoWoSuper(this.wo.intSupervisor);

        //console.log(this.selectedASupervisor);
        this.displayDialog = true;
        //this.userform.get('supervisor').setValue(this.task.supervisor);
        this.userform.get('crew').setValue(this.task.intCrewId);
        this.userform.get('foreman').setValue(this.task.intForeman);
        this.userform.get('task').setValue(this.task.strTaskName);
        this.userform.get('taskstatus').setValue(this.task.strStatus);
        this.userform.get('tasknote').setValue(this.task.strTaskDes);
        this.userform.get('taskowner').setValue(this.task.intTaskOwner);
        if (this.task.dtmStartDate !== null) {
            this.userform.get('datestart').setValue(new Date(this.task.dtmStartDate));
        }

        if (this.task.strStatus == 'In-Process') {
            this.userform.get('taskstatus').setValue('Inprocess');
        } else if (this.task.strStatus == 'InProcess') {
            this.userform.get('taskstatus').setValue(this.task.strStatus);
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;

        this.task.intCrewId = this.userform.get('crew').value;
        this.task.intForeman = this.userform.get('foreman').value;
        this.task.strTaskName = this.userform.get('task').value;
        this.task.strStatus = this.userform.get('taskstatus').value;
        this.task.strTaskDes = this.userform.get('tasknote').value;
        this.task.intTaskOwner = this.userform.get('taskowner').value;
        this.task.dtmStartDate = this.userform.get('datestart').value;

        if (this.newTask) {
            this.task.intJobId = this.selectedWoId;
            this.task.intSupervisorId = this.selectedASupervisor;
            this.addTask(this.task);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateTask();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.displayDialog = false;
        this.submitted = false;
        this.userform.reset();
        this.task = {};
    }

    filterWoSupervisor(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intReportingSupervisorId == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    filterSupervisorId(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intSupervisorKey == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    onChangeCrew(event) {
        this.crewId = event.value;

        this.crew = this.crews.find(c => c.intCrewKey == this.crewId);

        this.foremanId = this.crew.intForeman;

        this.userform.get('foreman').setValue(this.foremanId);

        this.crewNumber = this.crew.strCrewId;

        if (this.crewNumber.startsWith("5")) {
            //this.userform.get('task').setValue('Craft-Work');
            if (this.task.strTaskName !== 'Craft-Work') {
                this.taskDropdown = 'Craft-Work';
                this.service.clear();
                this.service.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Change Task to Craft-Work for 50xx crew type?', detail: 'Confirm to proceed' });
            }
        } else if (this.crewNumber.startsWith("7")) {
            this.taskDropdown = 'Placement';
            this.service.clear();
            this.service.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Change Task to Placement for 70xx crew type?', detail: 'Confirm to proceed' });
        }
    }

    onConfirm() {
        this.service.clear('c');
        if (this.taskDropdown === 'Craft-Work') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        } else if (this.taskDropdown === 'Placement') {
            this.userform.get('task').setValue(this.taskDropdown);
            this.service.clear();
        }
    }

    onReject() {
        this.service.clear('c');
    }

    printDFR(rowdata) {
        this.displayReport = true;
        this.selectedDfrId = rowdata.intReportNo;
        this.selectedReportName = "DFRs/Infratech DFR no prices.trdp";
        //console.log(rowdata.intReportNo);
    }

    onRowSelect(event) {
        //console.log(event.data.intCrewKey);
        this.selectedDfrId = event.data.intReportNo;
        //console.log(this.selectdDfrId);
        this.selectedReportName = "DFRs/Infratech DFR.trdp";
    }
}
