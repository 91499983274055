import { Component, OnInit, ViewEncapsulation, ViewChild, Input, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EstimatedUnitService } from '../service/estimatedunitservice';
import { DetailedEstimatedUnitService } from '../service/detailedestimatedunitservice';
import { EstimatedUnit } from '../domain/estimatedunits'
import { DetailedEstimatedUnit } from '../domain/detailedestimatedunits'
import { SelectItem } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission'
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
@Component({
    selector: 'wo-estimatedunits',
    templateUrl: './estimatedunit.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class EstimatedUnitComponent implements OnInit {
    estimatedUnit: EstimatedUnit = {};

    estimatedUnits: EstimatedUnit[];

    detailEstimatedUnit: DetailedEstimatedUnit = {};

    detailEstimatedUnits: DetailedEstimatedUnit[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedDetailUnit: DetailedEstimatedUnit;

    newDetailUnit: boolean;

    activeFilter: SelectItem[];

    unitName = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitTypeId: number;

    canEdit: boolean = false;

    canAdd: boolean = false;

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    @Input() selectedWoId: string = "";

    @Input() selectedContractId: string = "";

    selectedBillingCodeId: string;

    selectedBillingCode: BillingCodeCustomer;

    insertedEstimatedUnitId: number;

    selectedDeleteId: number;

    needUnitId: boolean = false;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private billingCodeCustomerService: BillingCodesCustomerService,
        private estimatedUnitService: EstimatedUnitService, private detailUnitService: DetailedEstimatedUnitService, private fb: FormBuilder, private adalSvc: MsAdalAngular6Service, private userpermissionservice: UserPermissionService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

         

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.insertedEstimatedUnitId = 0;
               
                this.selectedWoId = curVal;
                if (this.selectedWoId) {
                    this.estimatedUnit = {};
                    this.estimatedUnits = [];
                    this.getEstimatedUnitbyJob(this.selectedWoId);
                    //this.getDetailEstimatedUnit(this.selectedWoId);
                }
                   
                    
                //console.log(this.selectedWoId);
            }

            if (propName === 'selectedContractId') {
                this.selectedContractId = curVal;
                //console.log(this.selectedContractId);
                if (this.selectedContractId) {
                    this.getActiveBillingCodes(this.selectedContractId)
                }
            }

         
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'unittype': new FormControl('', Validators.required),
            'unitdescription': new FormControl('', Validators.required),
        });
        //this.getBillingUnitTypes();

        //this.employees.sort(this.dynamicSort("strLastname"));

        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRP = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            //{ field: 'intDetailId', header: 'Detail ID' },
            //{ field: 'intUnitId', header: 'Unit Id' },
            { field: 'quantity', header: 'Qty' },
            { field: 'billingCode', header: 'Billing Code' },
            //{ field: 'quantity', header: 'Qty' },
            { field: 'billingCodeDescrption', header: 'Description' },

        ];
    }

    getEstimatedUnit(id): void {
        this.estimatedUnitService.getEstimatedUnitId(id)
            .subscribe(estimatedunit => {
                this.estimatedUnits = estimatedunit;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getEstimatedUnitbyJob(id): void {
        this.estimatedUnitService.getEstimatedUnitsbyJobId(id)
            .subscribe(estimatedunit => {
                this.estimatedUnits = estimatedunit;
                if (this.estimatedUnits.length > 0) {
                    this.estimatedUnit = estimatedunit.find(e => e.intJobId == id);
                    this.getDetailEstimatedbyUnitId(this.estimatedUnit.intUnitId);
                    this.needUnitId = false;
                } else {
                    this.needUnitId = true;
                }
               
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.estimatedUnit);
            });
    }

    getDetailEstimatedUnitsbyJobiId(id): void {
        this.detailUnitService.getEstimatedUnitsbyJobId(id)
            .subscribe(detailestimatedunit => {
                this.detailEstimatedUnits = detailestimatedunit;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getDetailEstimatedbyUnitId(id): void {
        this.detailUnitService.getEstimatedUnitsbyUnitId(id)
            .subscribe(detailestimatedunit => {
                this.detailEstimatedUnits = detailestimatedunit;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    addDetailEstimatedUnit(unit): void {
        if (!unit) { return; }
        this.detailUnitService.addDetailEstimatedUnit(unit)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    this.showSuccessViaToast();
                    if (this.estimatedUnit.intUnitId) {
                        this.getDetailEstimatedbyUnitId(this.estimatedUnit.intUnitId);
                    } else {
                        this.getDetailEstimatedbyUnitId(this.insertedEstimatedUnitId);
                    }
                });
    }

    addEstimatedUnit(unit): void {
        if (!unit) { return; }
        this.estimatedUnitService.addEstimatedUnit(unit)
            .subscribe(
                data => {
                    this.insertedEstimatedUnitId = data.intUnitId;
                    this.needUnitId = false;
                    this.getEstimatedUnitbyJob(this.selectedWoId);
                    //this.addDetailEstimatedUnit(this.detailEstimatedUnit);
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    //this.showSuccessViaToast();
                   
                });
    }

    updateEstimatedDetailUnit(id, estimatedunit): void {
        if (!estimatedunit) { return;}
            
        //console.log(this.billingunittype);
        this.detailUnitService.updateDetailEstimatedUnit(id, estimatedunit)

            .subscribe(data => {
                //console.log(data);
                //this.ngOnInit();
                this.showSuccessUpdateViaToast();
                if (this.estimatedUnit.intUnitId) {
                    this.getDetailEstimatedbyUnitId(this.estimatedUnit.intUnitId);
                } else {
                    this.getDetailEstimatedbyUnitId(this.insertedEstimatedUnitId);
                }
                //this.handleError("err:Test");
            });
    }

    getActiveBillingCodes(id): void {
        this.billingCodeCustomerService.getActiveBillingCodesbyContract(id)
            .subscribe(bc => {
                this.billingCodesCustomer = bc;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(bc);
                //this.addTotal();
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newDetailUnit = true;
        this.detailEstimatedUnit = {};
        this.displayDialog = true;
    }

    cloneBillingCodeType(c: DetailedEstimatedUnit): DetailedEstimatedUnit {
        let crewtype = {};
        for (let prop in c) {
            crewtype[prop] = c[prop];
        }
        return crewtype;
    }

    cancel() {
        this.displayDialog = false;
        this.detailEstimatedUnit = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Updated', detail: this.unitName });
    }

    editRow(rowdata) {
        //this.detailEstimatedUnit = {};
        this.intUnitTypeId = rowdata.intDetailId;
        this.newDetailUnit = false;
        //this.detailEstimatedUnit.intBillingCode = rowdata.intBillingCode;
        this.detailEstimatedUnit = this.cloneBillingCodeType(rowdata);
        //console.log(this.detailEstimatedUnit);
        this.displayDialog = true;
        //this.userform.get('unittype').setValue(this.billingunittype.strUnit);
        //this.userform.get('unitdescription').setValue(this.billingunittype.strDescription);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onChange(event) {
        this.selectedBillingCodeId = event.value;

        this.selectedBillingCode = this.billingCodesCustomer.find(bc => bc.intCustomerBillingCodesKey == this.selectedBillingCodeId);

        this.detailEstimatedUnit.billingCode = this.selectedBillingCode.strCustomerBillingCode;

        this.detailEstimatedUnit.billingCodeDescrption = this.selectedBillingCode.strCustomerProductDesc;

        this.detailEstimatedUnit.intBillingCode = this.selectedBillingCodeId;

        

       

       

        //setTimeout(() => this.qty.nativeElement.focus());
    }

   

    async save() {
        //console.log('Save');
        if (this.detailEstimatedUnit.intBillingCode) {
            //console.log(this.billableProduction.intBccustomer);
            //this.detailEstimatedUnit.intUnitId =this.estimatedUnit.intUnitId;
            //this.billableProduction.intJobId = this.selectedWoId
            //let detailunits = [...this.detailEstimatedUnits];
            //console.log(this.billableProduction);
            if (this.newDetailUnit) {
                if (this.needUnitId) {
                     //console.log('Create Estimated Unit');
                    this.estimatedUnit.intJobId = this.selectedWoId;
                    this.addEstimatedUnit(this.estimatedUnit);
                 
                    await new Promise(resolve => setTimeout(resolve, 500)); // .5 sec
                   
                    this.detailEstimatedUnit.intUnitId = this.insertedEstimatedUnitId;
                   
                    this.addDetailEstimatedUnit(this.detailEstimatedUnit);
                    
                } else {
                    
                    //console.log('Save Existing')
                    this.detailEstimatedUnit.intUnitId = this.estimatedUnit.intUnitId;
                 
                    this.addDetailEstimatedUnit(this.detailEstimatedUnit);
                    //this.getDetailEstimatedbyUnitId(this.estimatedUnit.intUnitId);

                }
            }
            else {
                //let detailunits = [...this.detailEstimatedUnits];
                //detailunits[this.detailEstimatedUnits.indexOf(this.selectedDetailUnit)] = this.detailEstimatedUnit;
                this.updateEstimatedDetailUnit(this.detailEstimatedUnit.intDetailId, this.detailEstimatedUnit);
            }
            //let detailunits = [...this.detailEstimatedUnits];
            //this.detailEstimatedUnits = detailunits;
           
            
            this.displayDialog = false;
            this.detailEstimatedUnit = {};
           
        }
        else {
            this.showdNeedBillingCode();
        }

       
    }

    showdNeedBillingCode() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Need Billing Code', detail: 'Select a Billing Code' });
    }

    deleteRow(rowdata) {
        this.detailEstimatedUnit = this.cloneBillingCodeType(rowdata);
        this.service.add({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Unit?',
            //detail: this.deletedQty + ' of ' + this.deletedBc + ' : ' + this.deletedBcDescription
        });

    }

    onConfirm() {
        this.service.clear('c');
        this.deleteEstimatedUnit(this.detailEstimatedUnit.intDetailId);
    }

    onReject() {
        this.service.clear('c');
        this.detailEstimatedUnit = {};
    }

    deleteEstimatedUnit(id): void {
        this.detailUnitService.deleteDetailEstimatedUnit(id)
            .subscribe(data => {

                this.showDeleteViaToast();
                this.getDetailEstimatedbyUnitId(this.estimatedUnit.intUnitId)
            });

    }

    showDeleteViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Unit Deleted', detail: '' });
    }
}
