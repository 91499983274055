import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolVendor } from '../domain/linemantoolvendor';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from '../service/linemantoolvendorservice';
import { PayRollHour } from '../domain/payrollhour';

@Component({
    selector: 'linemantool-table',
    templateUrl: './linemantooltable.component.html',

    providers: [MessageService]
})
export class LinemanToolTableComponent implements OnInit {
    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolPurchase: LinemanToolPurchase = {};

    linemanToolVendors: LinemanToolVendor[];

    linemanToolVendor: LinemanToolVendor = {};

    linemantoolPurccols: any[];

    txType: SelectItem[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    activeFilter: SelectItem[];

    filter: any = {};

    selectedActive: string = "-1";

    @Input() selectedEmployeeId: string = "";

    yearFilter: number;

    yearTimeout: any;

    displayDialog: boolean = false;

    displayAddDialog: boolean = false;

    transactionDate: Date;

    newToolPurchase: boolean = false;

    isDeposit: boolean = true;

    private searchTerms = new Subject<string>();

    amount: number;

    formattedAmount: string;

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private _dateService: DateService,
        private linemanToolVendorService: LinemanToolVendorServices,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private currencyPipe: CurrencyPipe ) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEmployeeId') {
                //this.onRowSelect(curVal);
                this.selectedEmployeeId = curVal;
                this.getLinemanToolPurchases(this.selectedEmployeeId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.getLinemanToolVendors();

        //this.getActiveEmployees();

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.txType = [
            { label: 'Select One', value: null },
            { label: 'Deposit', value: 1 },
            { label: 'Withdraw', value: 2 },

        ];

        this.linemantoolPurccols = [
            //{ field: 'EmployeeID', header: 'intEmployee' },
            { field: 'dtmDate', header: 'Date' },
            { field: 'intTxtype', header: 'TX Type' },
            { field: 'vendorname', header: 'Vendor' },
            { field: 'strPono', header: 'PO #' },
            { field: 'curPoamnt', header: 'PO Amount' },
            { field: 'strInvNo', header: 'Invoice #' },
            { field: 'curInvAmnt', header: 'Invoice Amount' },
            { field: 'curDeposit', header: 'Deposit Amount' },

            //{ field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    getLinemanToolPurchases(id): void {
        this.linemanToolPurchaseService.getToolPurchasesbyID(id)
            .subscribe(toolpurchases => this.linemanToolPurchases = toolpurchases);
    }

    addToolPurchase(toolpurchase): void {
        if (!toolpurchase) { return; }
        this.linemanToolPurchaseService.addToolPurchase(this.linemanToolPurchase)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getLinemanToolPurchases(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addToolVendors(vendor): void {
        if (!vendor) { return; }
        this.linemanToolVendorService.addToolVendor(this.linemanToolVendor)
            .subscribe(data => {
                this.showSuccessViaToast();
                //customers.push(this.customer);
                this.getLinemanToolVendors();
                //this.getLinemanToolPurchases(this.selectedEmployeeId);
                //this.showSuccessViaToast();
                //this.ngOnInit();
            });
    }

    updateToolPurchase(): void {
        //let contracts = [...this.contracts];
        //contracts[this.contracts.indexOf(this.selectedContract)] = this.customer;
        //this.contractName = this.customer.strCustomerName;
        this.linemanToolPurchaseService.updateToolPurchase(this.linemanToolPurchase.id, this.linemanToolPurchase)

            .subscribe(data => {
                this.showSuccessUpdateViaToast();
                //this.showSuccessUpdateViaToast();
                this.getLinemanToolPurchases(this.selectedEmployeeId);
                this.ngOnInit();

                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

    getLinemanToolVendors(): void {
        this.linemanToolVendorService.getToolVendors()
            .subscribe(toolvendors => this.linemanToolVendors = toolvendors);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    showDialogToAdd() {
        this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
        this.linemanToolPurchase.dtmDate = new Date(Date.now());
        this.linemanToolPurchase.intTxtype = '1';
        this.newToolPurchase = true;
        this.displayDialog = true;
    }

    showDialogToAddVendor() {
       
        this.displayAddDialog = true;
    }

    onSelect(value) {
        //const value = event.target.value;
        this.transactionDate = value;
        //console.log(this.transactionDate);
        this.linemanToolPurchase.dtmDate = this._dateService.toString(this.transactionDate);
    }

    onChange(event) {
        //console.log(event.value);
        if (event.value == '1') {
            this.isDeposit = true;
        } else if (event.value == '2') {
            this.isDeposit = false;
        } else {
            this.isDeposit = null;
        }
    }

    onCancel() {
        this.displayDialog = false;
        this.isDeposit = true;
        this.linemanToolPurchase = {};
    }

    onCancelAddVendor() {
        this.displayAddDialog = false;
        this.isDeposit = false;
        this.linemanToolVendor = {};
    }

    onNewVendorSave() {
        let toolvendors = [...this.linemanToolVendors];
        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
      
            this.addToolVendors(toolvendors);
            toolvendors.push(this.linemanToolVendor);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        
       

        this.linemanToolVendors = toolvendors;
        this.linemanToolVendor = {};
        this.displayAddDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    onSave() {
        let toolpurchases = [...this.linemanToolPurchases];
        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
        if (this.newToolPurchase) {
            this.addToolPurchase(toolpurchases);
            toolpurchases.push(this.linemanToolPurchase);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateToolPurchase();

            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.linemanToolPurchases = toolpurchases;
        this.linemanToolPurchase = {};
        this.displayDialog = false;
        this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    editPurchase(rowdata) {
        this.newToolPurchase = false;
        this.linemanToolPurchase = this.clone(rowdata);
        //console.log(this.linemanToolPurchase);
        this.linemanToolPurchase.dtmDate = new Date(this.linemanToolPurchase.dtmDate);
        if (this.linemanToolPurchase.intTxtype == '1') {
            this.isDeposit = true;
        } else if (this.linemanToolPurchase.intTxtype == '2') {
            this.isDeposit = false;
        } else {
            this.isDeposit = null;
        }
        this.displayDialog = true;
    }

    clone(c: LinemanToolPurchase): LinemanToolPurchase {
        let purchase = {};
        for (let prop in c) {
            purchase[prop] = c[prop];
        }
        return purchase;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Tool Purchase Added', detail: 'Added' });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Tool Purchase Updated', detail: 'Updated' });
    }

    transformAmount(element) {
        this.amount = element.target.value.replace(/[$,]/g, "");
        this.formattedAmount = this.currencyPipe.transform(this.amount, '$')

        element.target.value = this.formattedAmount;
        //console.log(element.target)
        if (element.target.name === "poamount") {
            this.linemanToolPurchase.curPoamnt = this.amount;
        } else if (element.target.name === "invoiceamnt") {
            this.linemanToolPurchase.curInvAmnt = this.amount;

        } else if (element.target.name === "depositamnt") {
            this.linemanToolPurchase.curDeposit=this.amount;
        }
    }
}
