import { Component, OnInit, ViewEncapsulation, ViewChild, SimpleChanges, Input } from '@angular/core';
import { CrewService } from '../service/crewservice';
import { Crew } from '../domain/crew'
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Message } from 'primeng/components/common/api';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ProficiencyActivityService } from '../service/proficiencyactivityservice';
import { ProficiencyService } from '../service/proficiencyservice';
import { ProficiencyActivity } from '../domain/proficiencyactivity';
import { Proficiency } from '../domain/proficiency';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DateService } from '../../_services/date.service';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position'

@Component({
    selector: 'training-table',
    templateUrl: './training.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService,]
})
export class TrainingComponent implements OnInit {

    position: Position = {};

    positions: Position[];

    filteredPositions: Position[];

    proficiencyActivities: ProficiencyActivity[];

    proficiencyActivity: ProficiencyActivity = {};

    filteredProficiencies: Proficiency[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    crew: Crew = {};

    crews: Crew[];

    proficiency: Proficiency = {};

    proficiencies: Proficiency[];

    selectedProficiencies: Proficiency[];

    duplicateCrew: boolean;

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    displayCrewDetails: boolean;

    selectedProficiency: ProficiencyActivity;

    newActivity: boolean;

    activeFilter: SelectItem[];

    trainingModule = "";

    filter: any = {};

    displayContract: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    filteredCrews: Crew[];

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    filteredNoLegacyCrews: Crew[];

    selectedActive: string = "-1";

    selectedCrewId: string;

    msgs: Message[] = [];

    canEdit: boolean = false;

    canAdd: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    @Input() selectedEmployeeId: string = "";

    transactionDate: Date;

    displayAddDialog: boolean = false;


    constructor(private crewService: CrewService, private service: MessageService, public snackbar: MatSnackBar, private route: Router,
        private employeeService: EmployeeService, private proficiencyservice: ProficiencyService, private positionService: PositionService,
        private proficiencyactivityservice: ProficiencyActivityService, private _dateService: DateService,
        private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service) {

        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedEmployeeId') {
                //this.onRowSelect(curVal);
                this.selectedEmployeeId = curVal;
                this.getProfiencyActivitiesbyEmployeeId(this.selectedEmployeeId);

            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {

        this.getEmployees();
        this.getProficiencies();
        this.getPositions();


        //this.employees.sort(this.dynamicSort("strLastname"));

        //console.log(this.selectedActive);

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'moduleInternalId', header: 'Module' },
            { field: 'description', header: 'Description' },
            { field: 'dateCompleted', header: 'Date' },
            { field: 'comments', header: 'Comments' },
            { field: 'trainingHours', header: 'Hours' },
            //{ field: 'crewtype', header: 'Crew Type' },

            //{ field: 'intForeman', header: 'Foreman' },
            //{ field: 'ysnActive', header: 'Active' }
        ];
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => {
                this.positions = positions.filter(p => p.ysnFieldPosition == -1).sort(this.dynamicSort('strPosition'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getProfiencyActivitiesbyEmployeeId(id): void {
        this.proficiencyactivityservice.getProficiencyActivitybyEmployeeId(id)
            .subscribe(activities => this.proficiencyActivities = activities);
        //console.log(activities);
    }


    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                //console.log(this.userpermission.crewEdit);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.trainingEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.trainingAdd == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                //console.log(this.canEdit);
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getProficiencies(): void {
        this.proficiencyservice.getProficiencies()
            .subscribe(proficiencies => {
                this.proficiencies = this.proficiencies = proficiencies.sort(this.dynamicSort('moduleInternalId'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(proficiencies);
            });
    }

    addProficiency(proficiency): void {
        if (!proficiency) { return; }
        this.proficiencyservice.addProficiency(this.proficiency)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    this.getProficiencies();
                    this.showSuccessViaToast();
                });
    }








    addProficiencyActivity(proficiency): void {
        if (!proficiency) { return; }
        this.proficiencyactivityservice.addProficiencyActivity(this.proficiencyActivity)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    //console.log(data);
                    //this.ngOnInit();
                    this.getProfiencyActivitiesbyEmployeeId(this.selectedEmployeeId);
                    this.showSuccessViaToast();
                });
    }

    updateProficiencyActivity(): void {
        let proficiencyactivity = [...this.proficiencyActivities];
        proficiencyactivity[this.proficiencyActivities.indexOf(this.selectedProficiency)] = this.proficiencyActivity;
        this.trainingModule = this.proficiencyActivity.moduleInternalId;
        //console.log(this.crew);
        this.proficiencyactivityservice.updateProficiencyActivity(this.proficiencyActivity.proficienciesActId, this.proficiencyActivity)

            .subscribe(data => {
                //console.log(data);

                this.getProfiencyActivitiesbyEmployeeId(this.selectedEmployeeId);
                this.showSuccessUpdateViaToast();
                //this.handleError("err:Test");
            });
    }

    checkDuplicate(crew): void {
        this.crewService.duplicateCrew(crew)
            .subscribe(crews => {
                this.crews = crews;
                //console.log(this.wos.length);
                if (this.crews.length >= 1) {
                    this.duplicateCrew = true;
                    this.service.add({
                        severity: 'error',
                        summary: 'Duplicate Crew #',
                        detail: this.crew.strCrewId + ' already exist in the system'
                    });
                } else {
                    this.duplicateCrew = false;
                }
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newActivity = true;
        this.crew = {};
        this.displayDialog = true;
        //console.log(this.crew);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
    }

    onSave() {
        //let proficiencyactivities = [...this.proficiencyActivities];
        //this.trainingModule = this.proficiencyActivity.moduleInternalId;

        //let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        if (this.newActivity) {
            //this.proficiencyActivity.fullName = this.selectedEmployeeId;
            //this.proficiencyActivity.ysnActive = 1;
            //this.proficiencyActivity.intPosition = 0;
            //this.proficiencyActivity.description = 'Form Entered';
            //this.proficiencyActivity.ysnCompleted = 1;
           

            this.AddMultipleTrainings();

            //this.addProficiencyActivity(proficiencyactivities);
            //proficiencyactivities.push(this.proficiencyActivity);

        }
        else {
            let proficiencyactivities = [...this.proficiencyActivities];
            this.trainingModule = this.proficiencyActivity.moduleInternalId;
            this.updateProficiencyActivity();
            //proficiencyactivities.push(this.proficiencyActivity);
            this.proficiencyActivities = proficiencyactivities;
            this.proficiencyActivity = {};
            this.displayDialog = false;
        }



        //this.proficiencyActivities = proficiencyactivities;
        //this.proficiencyActivity = {};
        //this.displayDialog = false;

    }

    cloneProficiency(c: ProficiencyActivity): ProficiencyActivity {
        let proficiency = {};
        for (let prop in c) {
            proficiency[prop] = c[prop];
        }
        return proficiency;
    }

    cancel() {
        this.displayDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Training Added', detail: this.trainingModule });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Training Updated', detail: this.trainingModule });
    }

    editCrew(rowdata) {
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);

        this.newActivity = false;
        this.proficiencyActivity = this.cloneProficiency(rowdata);
        this.proficiencyActivity.dateCompleted = new Date(this.proficiencyActivity.dateCompleted);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onLoad() {
        var acrews = this.crews;
        if (this.filter.id) {
            acrews = acrews.filter(v => v.YsnActive == '-1');
        }
        this.crews = acrews;
    }

    filterCountry(query, countries: any[]): any[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        for (let i = 0; i < countries.length; i++) {
            let country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }
        return filtered;
    }



    onRowUnselect() {
        this.displayCrewDetails = false;
    }

    onRowSelect(event) {
        this.displayCrewDetails = true;
        //console.log(event.data.intCrewKey);
        this.selectedCrewId = event.data.intCrewKey;
    }

    onBlur() {
        this.msgs = [];
        this.checkDuplicate(this.crew.strCrewId);
        //console.log(this.userform.get('wo').value);
    }

    onSelect(value) {
        //const value = event.target.value;
        this.transactionDate = value;
        //console.log(this.transactionDate);
        this.proficiencyActivity.dateCompleted = new Date(this._dateService.toString(this.transactionDate));
        this.proficiencyActivity.createDate = new Date(this._dateService.toString(this.transactionDate));
        this.proficiencyActivity.approvalDate = new Date(this._dateService.toString(this.transactionDate));
    }

    showDialogToAddVendor() {

        this.displayAddDialog = true;
    }

    onCancelAddTraining() {
        this.displayAddDialog = false;
        this.proficiency = {};
        //console.log(this.incrementAlphanumeric('ST21'));
    }

    incrementAlphanumeric(str) {
        const numPart = str.match(/(0?[1-9])+$|0?([1-9]+?0+)$/)[0];
        const strPart = str.slice(0, str.indexOf(numPart));
        const isLastIndexNine = numPart.match(/9$/);

        // If we have a leading zero (e.g. - 'L100A099') 
        // or there is no prefix - we should just increment the number
        if (isLastIndexNine || strPart != null) {
            return strPart + numPart.replace(/\d+$/, (n) => ++n);
        }
        // Increment the number and add the missing zero
        else {
            return strPart + '0' + numPart.replace(/\d+$/, (n) => ++n);
        }
    }

    onChangePosition(event) {
        let positionid = event.value;

        let filteredproficiencies = [...this.proficiencies];

        this.filteredProficiencies = filteredproficiencies.filter(p => p.position == positionid);

        this.proficiency.moduleInternalId = this.incrementAlphanumeric(this.filteredProficiencies.slice(-1)[0].moduleInternalId);

        //console.log(this.filteredProficiencies.slice(-1)[0].moduleInternalId);


    }

    onNewTrainingSave() {
        let proficiencies = [...this.proficiencies];
        //this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
        this.proficiency.required = -1;
        this.proficiency.createDate = new Date();
        this.addProficiency(this.proficiency);
        proficiencies.push(this.proficiency);

        //this.customerService.addCustomer(this.customer)
        //    .subscribe(data => {
        //        customers.push(this.customer);
        //        console.log(data);
        //        this.ngOnInit();
        //        this.showSuccessViaToast();
        //    });



        this.proficiencies = proficiencies;
        this.proficiency = {};
        this.displayAddDialog = false;
        //this.linemanToolPurchase.intEmployee = this.selectedEmployeeId;
    }

    async AddMultipleTrainings() {
        if (this.selectedProficiencies.length > 0) {
            //this.getMaxInvoiceNo(this.selectedWoId);
            let employeeid = this.selectedEmployeeId;


            if (Array.isArray(this.selectedProficiencies) && (this.selectedProficiencies.length)) {
                let addtraining = [...this.selectedProficiencies];
                //console.log(addtraining);
                for (let i = 0; i < addtraining.length; i++) {
                    //if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                    //console.log(formatDate(payrollhours[i].dtDate, format, locale));
                    //console.log(formatDate(date, format, locale));
                   let data = addtraining[i];
                   this.proficiencyActivity.fullName= employeeid;
                   this.proficiencyActivity.ysnActive=  1;
                   this.proficiencyActivity.intPosition = 0;
                   this.proficiencyActivity.description = 'Form Entered';
                    this.proficiencyActivity.ysnCompleted = 1;
                    this.proficiencyActivity.proficiencyId = addtraining[i];
                  
                    //console.log(this.proficiencyActivity);
                    this.addProficiencyActivity(data);
                    //addtraining.push(this.proficiencyActivity);
                   

                    await new Promise(resolve => setTimeout(resolve, 800)); // 2 sec



                    //this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);

                }

                //this.proficiencyActivities = proficiencyactivities;
                this.proficiencyActivity = {};
                this.displayDialog = false;
                this.selectedProficiencies = [];

            }
        }
    }
}
