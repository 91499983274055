import { Component, SimpleChanges } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { environment } from '../environments/environment';
import { UserPermissionService } from './infratrac/service/userpermissionsservice';
import { UserPermission } from './infratrac/domain/userpermission'
import { EmployeeService } from './infratrac/service/employeeservice';
import { Employee } from './infratrac/domain/employee';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(private adalSvc: MsAdalAngular6Service, private userpermissionservice: UserPermissionService, private employeeService: EmployeeService) {
        //console.log(this.adalSvc.userInfo);
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;
            
            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
       
    }

    canInvoice: boolean;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canViewVendors: boolean;

    canViewRps: boolean;

    canViewAudits: boolean;

    canViewCustomers: boolean;

    canViewEquipment: boolean;

    canViewEmployee: boolean;

    canViewDfrs: boolean;

    canViewCrew: boolean;

    canViewWo: boolean;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    userEmail: string;

    userId: string;

    menuMode = 'static';

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    layoutMenuScroller: HTMLDivElement;

    lightMenu = true;

    lightTopbar = true;

    topbarColor = 'layout-topbar-bluegrey';

    menuClick: boolean;

    topbarItemClick: boolean;

    resetMenu: boolean;

    menuHoverActive: boolean;

    ngOnInit() {
        //this.getEmployeesbyEmail();
        //this.getUserPermissions(this.userId);
        //this.getEmployeesbyEmail();
        //console.log('onInit')
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;
                //console.log(this.userpermissions);
                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                    
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.customerBillingInvoice == 1 || this.userpermission.productionBillingInvoice == 1 || this.userpermission.intAdmin==1) {
                    this.canInvoice = true;
                } else {
                    this.canInvoice = false;
                }

                if (this.userpermission.vendorView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewVendors = true;
                } else {
                    this.canViewVendors = false;
                }

                if (this.userpermission.rpView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewRps = true;
                } else {
                    this.canViewRps = false;
                }

                if (this.userpermission.customerView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewCustomers = true;
                } else {
                    this.canViewCustomers = false;
                }

                if (this.userpermission.qualityAuditsView == 1 || this.userpermission.intAdmin == 1 || this.userpermission.safetyAuditsView || this.userpermission.spotAuditsView) {
                    this.canViewAudits = true;
                } else {
                    this.canViewAudits = false;
                }

                if (this.userpermission.equipmentView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewEquipment = true;
                } else {
                    this.canViewEquipment = false;
                }

                if (this.userpermission.employeeView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewEmployee = true;
                } else {
                    this.canViewEmployee = false;
                }

                 if (this.userpermission.dfrview == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewDfrs = true;
                } else {
                    this.canViewDfrs = false;
                }

                if (this.userpermission.crewView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewCrew = true;
                } else {
                    this.canViewCrew = false;
                }

                if (this.userpermission.workOrderView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewWo = true;
                } else {
                    this.canViewWo = false;
                }
                //console.log(this.userpermission.equipmentView);
            });
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    isHorizontal() {
        return this.menuMode === 'horizontal';
    }

    isSlim() {
        return this.menuMode === 'slim';
    }

    isOverlay() {
        return this.menuMode === 'overlay';
    }

    isStatic() {
        return this.menuMode === 'static';
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    changeMenuMode(menuMode: string) {
        this.menuMode = menuMode;
        this.staticMenuDesktopInactive = false;
        this.overlayMenuActive = false;
    }
}
