import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Customer } from '../domain/customer';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CustomerService {
    customers: Observable<Customer[]>;
    newcustomer: Customer;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CustomerService');
    }
    baseUrl: string = 'api/Customers/';
    errorUrl: string = 'http://localhost:5243/api/Customers/';

    getCustomers(): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCustomers', []))

            );
    }

    getCustomerFilter(status): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.baseUrl + 'getCustomerByStatus/' + status)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getFilterCustomers', []))

            );
    }

    getCustomerbyId(id): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.baseUrl + 'getCustomerById/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCustomerById', []))

            );
    }

    addCustomer(customer: Customer): Observable<Customer> {
        return this.http.post<Customer>(this.baseUrl, customer)
            .pipe(
                catchError(this.handleError('addCustomer', customer))
            );
    }

    updateCustomer(id, customer: Customer): Observable<Customer> {
        return this.http.put(this.baseUrl + id, customer)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateCustomer', customer))
            );
    }
}
