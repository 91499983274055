import { AfterViewInit, Component, ViewChild, ViewEncapsulation, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';

@Component({
    //encapsulation: ViewEncapsulation.None,
    selector: 'reportpage-viewer',
    templateUrl: './reportviewer.component.html',
    styleUrls: ['./app.component.css'],
})

export class ReportPageViewerComponent {
    viewerContainerStyle = {
        position: 'relative',
        width: '100%',
        height: '800px',
        ['font-family']: 'ms sans serif'
    };

    @ViewChild("reportviewer", { static: false }) reportViewer: TelerikReportViewerComponent;
    @Input() closable = true;
    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() reportName1: string;
    @Input() reportDFRNum: string;
    @Input() reportInvoiceId: string;
    @Input() groupInvoiceNum: string;
    reportName: string;
    reportDfrId: string;
    @Input() WO: string;
    WONum: string = null;
    selectedReportName: string;
    invoiceId: string;
    displayReport: boolean = false;
    private _isAdmin: boolean = false;

    home: MenuItem;

    items: MenuItem[];

    constructor() { }
    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'reportName1') {
                //this.onRowSelect(curVal);
                this.reportName = change.currentValue;
                //console.log(this.reportName);
                this.selectionChange(this.reportName);
            }

            if (propName === 'reportDFRNum') {
                //this.onRowSelect(curVal);
                this.reportDfrId = change.currentValue;
                //console.log(this.reportDfrId);
            }

            if (propName === 'reportInvoiceId') {
                //this.onRowSelect(curVal);
                this.invoiceId = change.currentValue;
                //console.log(this.reportDfrId);
            }

            if (propName === 'WO') {
                //this.onRowSelect(curVal);
                this.WONum = change.currentValue;
                //console.log(this.reportDfrId);
            }

            //if (propName === 'visible') {
            //    //this.onRowSelect(curVal);
            //    this.displayReport = change.currentValue;
            //    //console.log(this.displayReport);
            //}
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }
    //ngAfterViewInit() {
    //    this.selectionChange(this.reportName);
    //}

    public selectionChange(value: any): void {
        if (value) {
            //var reportSource = this.reportViewer.getReportSource();
            ////console.log(value);
            //reportSource.report = value;
            //reportSource.parameters = {};
            let rs = {
                report: value,
                parameters: {}
            } as unknown as JSON;

            this.reportViewer.setReportSource(rs);
        }
    }
}
