import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/en-gb';

@Injectable()
export class DateService {

    constructor() {
        moment.locale('en-gb');
    }

    toString(date: any, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
        return date !== null ? moment(date).format(format) : null;
    }

}
