
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicControl } from '../domain/dynamiccontrol';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DynamicControlsService } from '../service/dynamiccontrolservice';
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Message } from 'primeng/components/common/api';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { person } from '../domain/person';


@Component({
    selector: 'dynamic-controls',
    templateUrl: './dynamic-control.component.html',
      styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService,]
})
export class DynamicControlComponent {
    dynamiccontrol: DynamicControl = {};

    dynamiccontrols;

    person;

    constructor(private dynamicControlService: DynamicControlsService, private service: MessageService, public snackbar: MatSnackBar, private route: Router,        
        private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service) {

        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            //this.userEmail = this.adalSvc.LoggedInUserEmail;
            //this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });
      this.dynamiccontrols = [];
        this.getDynamicControls(1);
        this.person = person;
        //this.getEmployeesbyEmail();
    }

    ngOnInit() {
        //this.getCrewsNoLegacy();
       
       

        
        //this.employees.sort(this.dynamicSort("strLastname"));

        //console.log(this.person);

      
    }

    getDynamicControls(id): void {
        this.dynamicControlService.getDynamicControlbyContractId(1)
            .subscribe(controls => {
                this.dynamiccontrols = controls;
                //books = books.filter(v => v.id == this.filter.id);
                //console.log(this.dynamiccontrols);
            });
        //console.log(this.dynamiccontrols);
    }
}
