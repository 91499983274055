import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SystemDowntime } from '../domain/systemdowntime';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SystemDowntimeService {
    positions: Observable<SystemDowntime[]>;
    newdowntime: SystemDowntime;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('SystemDowntimeService');
    }
    baseUrl: string = 'api/SystemDowntimes/';
    errorUrl: string = 'http://localhost:5243/api/systemdowntimes/';

    getDowntimes(): Observable<SystemDowntime[]> {
        return this.http.get<SystemDowntime[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getSystemDowntime', []))

            );
    }

    addDownTime(downtime: SystemDowntime): Observable<SystemDowntime> {
        return this.http.post<SystemDowntime>(this.baseUrl, downtime)
            .pipe(
                catchError(this.handleError('addPosition', downtime))
            );
    }

    updateDownTime(id, downtime: SystemDowntime): Observable<SystemDowntime> {
        return this.http.put(this.baseUrl + id, downtime)
            .pipe(
                tap(_ => console.log(`updated position id=${id}`)),
                catchError(this.handleError('updatePosition', downtime))
            );
    }

    deleteDowntime(id): Observable<SystemDowntime> {
        return this.http.delete(this.baseUrl + id)
            .pipe(
                tap(_ => console.log(`Deleted System Downtime id=${id}`)),
                catchError(this.handleError('Deleted System Downtime'))
            );
    }

    getDowntimebyId(id): Observable<SystemDowntime[]> {
        return this.http.get<SystemDowntime[]>(this.baseUrl + 'dfrid/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getDfrbyID', []))

            );
    }
}
