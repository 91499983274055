import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EmployeeCounseling } from '../domain/employeecounseling';
import { Configuration } from '../../app.constants';
import { Observable, throwError } from 'rxjs/';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class EmployeeCounselingService {
    crewtype: Observable<EmployeeCounseling[]>;
    newcrewtype: EmployeeCounseling ;
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('RepMainActivity');
    }
    baseUrl: string = 'api/EmployeeCounselings/';
    errorUrl: string = 'http://localhost:5243/api/Crews/';

    getEmployeeCounselings(): Observable<EmployeeCounseling[]> {
        return this.http.get<EmployeeCounseling[]>(this.baseUrl)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getEmployeeCounselings', []))

            );
    }

    addCounseling(counseling: EmployeeCounseling): Observable<EmployeeCounseling> {
        return this.http.post<EmployeeCounseling>(this.baseUrl, counseling)
            .pipe(
                catchError(this.handleError('addCounseling', counseling))
            );
    }

    updateCounseling(id, counseling: EmployeeCounseling): Observable<EmployeeCounseling> {
        return this.http.put(this.baseUrl + id, counseling)
            .pipe(
                tap(_ => console.log(`updated product id=${id}`)),
                catchError(this.handleError('updateCounseling', counseling))
            );
    }

    getCounselingbyEmployeeId(id): Observable<EmployeeCounseling[]> {
        return this.http.get<EmployeeCounseling[]>(this.baseUrl + 'employee/' + id)

            //.pipe(
            //    map((e: any[]) => e.json()),
            //    catchError((e: Response) => throwError(e))
            //);
            .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError('getCounselingbyEmployeeId', []))

            );
    }

    /* GET heroes whose name contains search term */
    //searchCrews(term: string): Observable<Crew[]> {
    //    term = term.trim();

    //    // Add safe, URL encoded search parameter if there is a search term
    //    const options = term ?
    //        { params: new HttpParams().set('crew', term) } : {};

    //    return this.http.get<Crew[]>(this.baseUrl, options)
    //        .pipe(
    //            catchError(this.handleError<Crew[]>('searchCrew', []))
    //        );
    //}
}
