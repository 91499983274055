import { Component, OnInit, ViewEncapsulation, ViewChild, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolVendor } from '../domain/linemantoolvendor';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from '../service/linemantoolvendorservice';
import { PayRollHour } from '../domain/payrollhour';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';

@Component({
    templateUrl: './supervisor.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class SupervisorComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    employees: Employee[];

    employee: Employee = {};

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    payrollHours: PayRollHour[];

    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolVendors: LinemanToolVendor[];

    cols: any[];

    payrollcols: any[];

    linemantoolPurccols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedSupervisor: Supervisor;

    activeFilter: SelectItem[];

    perdiem: SelectItem[];

    txType: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    displayEmployeeDetails: boolean = false;

    newEmployee: boolean;

    selectedActive: boolean = true;

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    selectedSupervisorId: string = "";

    userform: FormGroup;

    submitted: boolean;

    genders: SelectItem[];

    description: string;

    hireDate: Date;

    termDate: Date;

    rehireDate: Date;

    orientationDate: Date;

    benefits: SelectItem[];

    viewRate: boolean = false;

    employeeName: string;

    yearFilter: number;

    yearTimeout: any;

    visible: boolean = true;

    area: string;

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private payRollHourService: PayRollHourService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private linemanToolVendorService: LinemanToolVendorServices,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private supervisorService: SupervisorService) {
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'supervisor': new FormControl('', Validators.required),
            'area': new FormControl('', Validators.required),
            'descriptor': new FormControl(''),
            'active': new FormControl('', Validators.required),
            'creditaccount': new FormControl(''),
            'debitaccount': new FormControl(''),
            'rpaccount': new FormControl(''),
            'billableaccount': new FormControl(''),
            'classdaccount': new FormControl(''),
            'mechanic': new FormControl(''),
            'reportingsuper': new FormControl(''),
        });

        this.getEmployees();

        this.getActiveSupervisors();

        //this.getEmployeesbyStatus('active');

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        this.cols = [
            { field: 'intSupervisorKey', header: 'Supervisor ID' },
            { field: 'name', header: 'Supervisor ID' },
            { field: 'strAreaNumber', header: 'Area' },
            { field: 'strDescriptors', header: 'Descriptor' },
            { field: 'showOnPicklist', header: 'Active' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    onRowUnselect() {
        //this.payrollHours = [];
        this.linemanToolPurchases = [];
        this.displayEmployeeDetails = false;
    }

    onRowSelect(event) {
        if (event.data.strEmployeeId) {
            this.selectedSupervisorId = event.data.intSupervisorKey;
            //this.getEmployeeHours(this.selectedEmployeeId);
            //this.getLinemanToolPurchases(this.selectedEmployeeId);
            this.displayEmployeeDetails = true;
        }
    }

    onActiveChange(event) {
        console.log(event.value);
        if (event.value = 'active') {
            this.getEmployeesbyStatus('active');
        } else if (event.value = 'inactive') {
            this.getEmployeesbyStatus('inActive');
        } else {
            this.getEmployees();
        }

        this.updateVisibility();
    }

    onSelect(value) {
        //const value = event.target.value;
        this.hireDate = value;
        //console.log(element.getAttribute('formControlName'));
        this.employee.dtmHireDate = this._dateService.toString(this.hireDate);
    }

    onTermSelect(value) {
        //const value = event.target.value;
        this.termDate = value;
        this.employee.drmTermDate = this._dateService.toString(this.termDate);
    }

    onRehireSelect(value) {
        //const value = event.target.value;
        this.rehireDate = value;
        this.employee.dtmRehireDate = this._dateService.toString(this.rehireDate);
    }

    onOrientSelect(value) {
        //const value = event.target.value;
        this.orientationDate = value;
        this.employee.dtmOrientationCompl = this._dateService.toString(this.orientationDate);
    }

    cancel() {
        this.displayEmployeeDialog = false;
        this.supervisor = {};
        this.userform.reset();
        //this.userform.get('active').setValue(null);
        //this.userform.get('position').setValue(null);
        //this.userform.get('perdiem').setValue(null);
    }

    onSubmit(value: string) {
        this.submitted = true;
        this.supervisor.strAreaNumber = this.userform.get('area').value;
        this.supervisor.strDescriptors = this.userform.get('descriptor').value;
        this.supervisor.intMechanicId = this.userform.get('mechanic').value;
        this.supervisor.strSupervisorName = this.userform.get('supervisor').value;
        this.supervisor.sap3rdParty = this.userform.get('billableaccount').value;
        this.supervisor.intReportingSupervisorId = this.userform.get('reportingsuper').value;
        this.supervisor.revCreditAccount = this.userform.get('creditaccount').value;
        this.supervisor.revDebitAccount = this.userform.get('debitaccount').value;
        this.supervisor.saprpaccount = this.userform.get('rpaccount').value;
        this.supervisor.sapClassDGlaccount = this.userform.get('classdaccount').value;
        this.supervisor.showOnPicklist = this.userform.get('active').value;

        //this.onSaveEmployee();

        if (this.newEmployee) {
            this.addSupervisor(this.supervisor);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateSupervisor();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.supervisor = {};
        this.submitted = false;
        this.userform.reset();
        this.displayEmployeeDialog = false;
    }

    //get diagnostic() { return JSON.stringify(this.userform.value); };
    getSupervisors(): void {
        this.supervisorService.getSupervisorsList()
            .subscribe(supervisors => this.supervisors = supervisors);
    }

    getActiveSupervisors(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => this.supervisors = supervisors);
    }

    getSupervisorsFilter(status): void {
        this.supervisorService.getSupervisorsbyStatus(status)
            .subscribe(supervisors => this.supervisors = supervisors);
    }

    getLinemanToolVendors(): void {
        this.linemanToolVendorService.getToolVendors()
            .subscribe(toolvendors => this.linemanToolVendors = toolvendors);
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    getEmployeesbyStatus(status): void {
        this.employeeService.getEmployeesbyStatus(status)
            .subscribe(employees => this.employees = employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    addSupervisor(supervisor): void {
        if (!supervisor) { return; }
        this.supervisorService.addSupervisor(this.supervisor)
            .subscribe(data => {
                //customers.push(this.customer);
                console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    updateSupervisor(): void {
        let supervisors = [...this.supervisors];
        supervisors[this.supervisors.indexOf(this.selectedSupervisor)] = this.supervisor;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        //console.log(this.selectedEmployee.strEmployeeID);
        this.area = this.supervisor.strAreaNumber;
        this.supervisorService.updateSupervisor(this.selectedSupervisorId, this.supervisor)

            .subscribe(data => {
                console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Area Added', detail: this.area });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Area Updated', detail: this.area });
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployees("Active")
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAddEmployee() {
        this.newEmployee = true;
        this.employee = {};
        this.userform.reset();
        //this.employee.strEmployeeID = this.selectedEmployee.strEmployeeID;
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.displayEmployeeDialog = true;
        //console.log(this.displayEmployeeDialog);
    }

    editEmployee(rowdata) {
        this.selectedSupervisorId = rowdata.intSupervisorKey;
        this.newEmployee = false;
        this.supervisor = this.clone(rowdata);
        //console.log(this.employees);
        this.displayEmployeeDialog = true;
        this.userform.get('area').setValue(this.supervisor.strAreaNumber);
        this.userform.get('descriptor').setValue(this.supervisor.strDescriptors);
        this.userform.get('supervisor').setValue(this.supervisor.strSupervisorName);
        this.userform.get('creditaccount').setValue(this.supervisor.revCreditAccount);
        this.userform.get('debitaccount').setValue(this.supervisor.revDebitAccount);
        this.userform.get('rpaccount').setValue(this.supervisor.saprpaccount);
        this.userform.get('billableaccount').setValue(this.supervisor.sap3rdParty);
        this.userform.get('classdaccount').setValue(this.supervisor.sapClassDGlaccount);
        this.userform.get('active').setValue(this.supervisor.showOnPicklist);
        this.userform.get('mechanic').setValue(this.supervisor.intMechanicId);
        this.userform.get('reportingsuper').setValue(this.supervisor.intReportingSupervisorId);
    }
    //onRowUnselect() {
    //    this.displayContract = false;
    //}

    clone(c: Supervisor): Supervisor {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    //showSuccessViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    //}

    //showSuccessUpdateViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    //}

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        }, 250);
    }

    OnActiveChange(event) {
        //console.log(event.value);
        if (event.value === null) {
            this.getSupervisors();;
        } else {
            this.getSupervisorsFilter(event.value);
        }

        this.selectedActive = event.value;
    }
}
