import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingCodeCustomer } from '../domain/billingcodecustomer'
import { OfficeLocation } from '../domain/officelocation'
import { Job } from '../domain/job'
import { BillingCodesCustomerService } from '../service/billingcodescustomerservice';
import { OfficeLocationService } from '../service/officelocationservice';
import { JobService } from '../service/jobservice';
import { SelectItem, MenuItem, AutoCompleteModule, AutoComplete } from 'primeng/primeng';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
import { CommonModule, CurrencyPipe } from '@angular/common';

@Component({
    encapsulation: ViewEncapsulation.None,

    selector: 'wo-invoice',
    templateUrl: './woinvoice.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class WoInvoiceComponent implements OnInit {
    wo: Job = {};

    wos: Job[];

    filteredWo: Job[];

    billingCodeCustomer: BillingCodeCustomer = {};

    billingCodesCustomer: BillingCodeCustomer[];

    officeLocations: OfficeLocation[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    intWoSupervisor: Employee[];

    intAreaSupervisor: Supervisor = {};

    intWoSupervisors: Supervisor[];

    selectedAreaSupervisor: Supervisor = {};

    selectedAreaSupervisorId: string;

    selectedAreaSupervisorEmplId: string;

    employees: Employee[];

    wofilter: any;

    //filteredWo: any[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedWo: Job;

    newWo: boolean;

    activeFilter: SelectItem[];

    unitName: string = "";

    filter: any = {};

    displayWoDetails: boolean = false;

    selectCrewTypes: SelectItem[];

    ysnRP: SelectItem[];

    ysnRPFilter: SelectItem[];

    userform: FormGroup;

    submitted: boolean;

    intUnitId: number;

    strJob: string;

    @Input() selectedContractId: string = "";

    @Input() selectedCustomerId: string = "";

    dateReceived: Date;

    dateStart: Date;

    dateEnd: Date;

    dateSRD: Date;

    datePartial: Date;

    dateCertifiedasBuilts: Date;

    date699: Date;

    issuedStatus: SelectItem[];

    jobType: SelectItem[];

    area: SelectItem[];

    jobCode: SelectItem[];

    notesStatus: SelectItem[];

    feederStatus: SelectItem[];

    lateralStatus: SelectItem[];

    wlStatus: SelectItem[];

    status99: SelectItem[];

    selectedRow: SelectItem[];

    isFeeder: boolean = false;

    isLateral: boolean = true;

    isFPL: boolean = false;

    intWoId: string;

    selectedWoId: string = null;

    items: MenuItem[];

    displayReport: boolean = false;

    customerId: string;

    woAreaSupervisor: string = null;

    selectedWONum: string = null;

    selectedReportName: string = null;

    @ViewChild('woautocomplete', { static: false }) autocompleteCharge: AutoComplete;

    bidFormattedAmount: string;

    bidAmount: string;

    isProject: boolean = false;

    newInvoice: boolean;

    deletedInvoiceNum: number;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private jobService: JobService, private fb: FormBuilder,
        private billingCodeCustomerService: BillingCodesCustomerService, private _dateService: DateService, private officeLocationService: OfficeLocationService,
        private employeeService: EmployeeService, private supervisorService: SupervisorService, private currencyPipe: CurrencyPipe ) {
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'wo': new FormControl('', Validators.required),
            'woname': new FormControl('', Validators.required),
            'finaled': new FormControl('', Validators.required),
            'jobid': new FormControl(''),
            'contractid': new FormControl(''),
            'customerid': new FormControl(''),
            'officelocationid': new FormControl(''),
            'datereceived': new FormControl(''),
            'estftg': new FormControl(''),
            'datestart': new FormControl(''),
            'dateend': new FormControl(''),
            'asupervisor': new FormControl(''),
            'wosupervisor': new FormControl(''),
            'billingcode': new FormControl(''),
            'comments': new FormControl(''),
            'active': new FormControl(''),
            'project': new FormControl(''),
            'revisedftg': new FormControl(''),
            'childnum': new FormControl(''),
            'feeder': new FormControl(''),
            'faultrepair': new FormControl(''),
            'groundstrap': new FormControl(''),
            'issuedstatus': new FormControl(''),
            'jobtype': new FormControl(''),
            'feederstatus': new FormControl(''),
            'lateralstatus': new FormControl(''),
            'loop': new FormControl(''),
            'switch1': new FormControl(''),
            'switch2': new FormControl(''),
            'srd': new FormControl(''),
            'area': new FormControl(''),
            'pl': new FormControl(''),
            'contact': new FormControl(''),
            'fpljobcode': new FormControl(''),
            'notesstatus': new FormControl(''),
            'percentcomplete': new FormControl(''),
            'fplothercomments': new FormControl(''),
            'partialbilled': new FormControl(''),
            'holddate': new FormControl(''),
            'date818': new FormControl(''),
            'date699': new FormControl(''),
            'status99': new FormControl(''),
            'billingcomments': new FormControl(''),
            'wlstatus': new FormControl(''),
            'asbuilts': new FormControl(''),
            'location': new FormControl(''),
            'bidamount': new FormControl(''),

            //'estimatedftg': new FormControl('', Validators.required),
            //'unitprice': new FormControl('', Validators.required),
            //'subprice': new FormControl(''),
            //'sub2price': new FormControl(''),
        });

        //this.getBillingCodesCustomerbyContract(this.selectedContractId);
        ////console.log(this.selectedContractId);
        //this.getWobyContract(this.selectedContractId);
        ////this.employees.sort(this.dynamicSort("strLastname"));
        //this.getEmployees();

        //this.getSupervisorsList();

        //this.getOfficeLocationbyCustomerId(this.selectedCustomerId);

        this.getEmployees();

        this.getActiveSupervisorsList();

        //console.log(this.selectedWo);

        if (!this.selectedWo == null) {
            this.filterWoString(this.selectedWo);
           
        }

        this.active = [

            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRP = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.ysnRPFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.issuedStatus = [
            { label: 'FRO', value: 'FRO' },
            { label: 'TSC', value: 'TSC' },
            { label: 'CIC', value: 'CIC' },
            { label: 'BR', value: 'BR' },
            { label: 'FRR', value: 'FRR' },
            { label: 'FDR', value: 'FDR' },
            { label: 'PIP/T', value: 'PIP/T' },

        ];

        this.jobType = [
            { label: 'Feeder', value: 'Feeder' },
            { label: 'SMO', value: 'SMO' },
            { label: 'Lateral', value: 'Lateral' },
            { label: 'ADDL CONT', value: 'ADDL CONT' },
            { label: 'Overhead', value: 'Overhead' },
            { label: 'Hardening', value: 'Hardening' },
            { label: 'PIP', value: 'PIP' },
            { label: 'Bid', value: 'Bid' },

        ];

        this.notesStatus = [
            { label: 'FR - Fault Repair', value: 'FR - Fault Repair' },
            { label: 'FRO', value: 'FRO' },
            { label: 'FM - Flowmole, incomplete FR', value: 'FM - Flowmole, incomplete FR' },
            { label: 'FMFR - Flowmole, complete FR', value: 'FMFR - Flowmole, complete FR' },
            { label: 'FM - Flow Mole', value: 'FM - Flow Mole' },
            { label: 'BC - Bore Complete', value: 'BC - Bore Complete' },
            { label: 'PCBC - Pull Cable in Bored Conduit', value: 'PCBC - Pull Cable in Bored Conduit' },
            { label: 'PCSC - Pull Cable in Spare Conduit', value: 'PCSC - Pull Cable in Spare Conduit' },
            { label: 'SI - Switch-In', value: 'SI - Switch-In' },
            { label: 'Complete', value: 'Complete' },
            { label: 'Invoice to CCR', value: 'Invoice to CCR' },
            { label: 'CCR Denied', value: 'CCR Denied' },
            { label: 'CCR Hold', value: 'CCR Hold' },
            { label: 'Past Due', value: 'Past Due' },
            { label: 'Paid', value: 'Paid' },
            { label: 'Partial Paid', value: 'Partial Paid' },
            { label: 'Test PVC', value: 'Test PVC' },
            { label: 'Test Cable', value: 'Test Cable' },
            { label: 'Investigation', value: 'Investigation' },
            { label: 'Bore', value: 'Bore' },
            { label: 'Cable Pull Complete', value: 'Cable Pull Complete' },
            { label: 'Fdr Jobs Investigation Complete – Material Ordered', value: 'Fdr Jobs Investigation Complete – Material Ordered' },
            { label: 'Partial Invoice', value: 'Partial Invoice' },
            { label: 'Issued', value: 'Issued' },
            { label: 'UL - Utility Locate', value: 'UL - Utility Locate' },
            { label: 'FR-Fault Repair', value: 'FR-Fault Repair' },
            { label: 'URD-30', value: 'URD-30' },
            { label: 'URD-40', value: 'URD-40' },
            { label: 'URD-50', value: 'URD-50' },
            { label: 'URD-60', value: 'URD-60' },
            { label: 'URD-70', value: 'URD-70' },
            { label: 'URD-80', value: 'URD-80' },
            { label: 'URD-90', value: 'URD-90' },
            { label: 'URD-95', value: '95' },
            { label: 'Bore in Process', value: 'Bore in Process' },
            { label: 'Ready to Bore', value: 'Ready to Bore' },
            { label: 'Letters Requested (Proactive)', value: 'Letters Requested (Proactive)' },
            { label: 'GS Removed Info to FPL', value: 'GS Removed Info to FPL' },
            { label: 'Investigation Completed', value: 'Investigation Completed' },
            { label: 'CR- Status 20', value: 'CR- Status 20' },
            { label: 'SMO- Ground Strap Removed', value: 'SMO- Ground Strap Removed' },
            { label: 'GS-Issued', value: 'GS-Issued' },
            { label: 'GS-Bore Complete', value: 'GS-Bore Complete' },
            { label: 'GS-Ground Strap Removed', value: 'GS-Ground Strap Removed' },
            { label: 'GS-Field Complete', value: 'GS-Field Complete' },
            { label: 'Re-engineering-AMG', value: 'Re-engineering-AMG' },

        ];

        this.area = [
            { label: 'CF', value: 'CF' },
            { label: 'BV', value: 'BV' },
            { label: 'TC', value: 'TC' },
            { label: 'WB', value: 'WB' },
            { label: 'BR', value: 'BR' },
            { label: 'NB', value: 'NB' },
            { label: 'CB', value: 'CB' },
            { label: 'SB', value: 'SB' },
            { label: 'ND', value: 'ND' },
            { label: 'CD', value: 'CD' },
            { label: 'WD', value: 'WD' },
            { label: 'SD', value: 'SD' },
            { label: 'MS', value: 'MS' },
            { label: 'TB', value: 'TB' },
            { label: 'FM', value: 'FM' },
            { label: 'NA', value: 'NA' },
            { label: 'NF', value: 'NF' },
            { label: 'CF', value: 'CF' },
            { label: 'BV', value: 'BV' },
            { label: 'WP', value: 'WP' },
        ];

        this.jobCode = [
            { value: '1', label: '18504 Reactive - DB Lat Fault Repair Only' },
            { value: '2', label: '18506 Reactive - PNC Lat Fault Repair/Replace Cbl' },
            { value: '3', label: '18525 Reactive - PNC Lat Replace In Spare Duct' },
            { value: '4', label: '18526 Reactive - PNC Lat Inject Cable' },
            { value: '5', label: '18527 Reactive - PNC Lat Bore Replace' },
            { value: '6', label: '84519 Feeder - Direct Buried Feeder Replacement - Area Discretion' },
            { value: '7', label: '84522 Feeder - Direct Buried Feeder Replacement in Spare Conduit' },
            { value: '8', label: '84523 Feeder - Direct Buried Feeder Replacement - Bore' },
            { value: '9', label: '84524 Feeder - Direct Buried Feeder - Inject Cable' },
            { value: '10', label: '84525 Reactive - DB Lat Replace In Spare Duct' },
            { value: '11', label: '84526 Reactive - DB Lat Inject Cable' },
            { value: '12', label: '84527 Reactive - DB Lat Bore Replace' },
            { value: '13', label: '84528 Proactive Multi - DB Lat Replace In Ex/Spare Duct' },
            { value: '14', label: '84529 Proactive Multi - DB Lat Inject Cable' },
            { value: '15', label: '84530 Proactive Multi - DB Lat Bore Replace' },
            { value: '16', label: '84531 Proactive Special - DB Replace in Ex/Spare Duct' },
            { value: '17', label: '84532 Proactive Special - DB Lat Inject' },
            { value: '18', label: '84533 Proactive Special - DB Lat Bore Replace' },
            { value: '19', label: 'N/A' },
            { value: '20', label: 'SVUGB' },
            { value: '21', label: '75BLS' },
            { value: '22', label: '75ANS' },
            { value: '23', label: '878' },
            { value: '24', label: '85A' },
            { value: '25', label: '61A' },
            { value: '26', label: '61C' },
            { value: '27', label: '61F' },
            { value: '28', label: '84A' },
            { value: '29', label: '84B' },
            { value: '30', label: '77A' },
            { value: '31', label: 'Other' },
            { value: '32', label: '85E' },
            { value: '33', label: '190' },
            { value: '34', label: '18A' },
            { value: '35', label: '84F' },
        ];

        this.status99 = [
            { value: '1', label: 'Field Complete' },
            { value: '2', label: 'Restoration' },
            { value: '3', label: 'Materials' },
            { value: '4', label: 'As-Builts' },
            { value: '5', label: 'Job Packet Submitted' },
            { value: '6', label: 'Other' },
            { value: '7', label: 'NA' },
            { value: '8', label: '699' },
            { value: '9', label: '802 Requested' },
            { value: '10', label: '802/808 Complete' },
            { value: '11', label: '802 Disapproved' },
            { value: '12', label: 'OJM' },
            { value: '13', label: 'NUT Form Approvals Pending' },
            { value: '14', label: 'Invoice Draft Created' },
            { value: '15', label: 'Invoice sent to FPL' },
            { value: '16', label: 'CR- Waiting on 699 Approval' },
            { value: '17', label: 'Paid in Full' },
            { value: '18', label: 'Partially Paid' },
            { value: '19', label: 'Permit' },
            { value: '20', label: 'Billing' },
            { value: '21', label: 'Waiting on Pictures' },
            { value: '22', label: 'CR- PL to approve hours' },
            { value: '23', label: '3rd Party MOT Invoices' },
            { value: '24', label: 'Waiting on approval of rock photos' },
            { value: '25', label: 'Partial Bill' },
            { value: '26', label: 'Waiting Field Check' },

        ];

        this.feederStatus = [
            { value: '1', label: '1% - Issued' },
            { value: '2', label: '15% - Investigation Complete/Material Ordered' },
            { value: '3', label: '50% - Bore Complete' },
            { value: '4', label: '75% - Cable Pulled' },
            { value: '5', label: '90% - Cable Term(Only Switching Left)' },
            { value: '6', label: '99% - Complete' },
        ];

        this.wlStatus = [
            { value: 'Complete-In field only', label: 'Complete-In field only' },
            { value: 'Complete-Chgs. Pending', label: 'Complete-Chgs. Pending' },
            { value: 'Complete-Ready for 699">Complete-Ready for 699', label: 'Complete-Ready for 699">Complete-Ready for 699' },

        ];

        this.lateralStatus = [
            { value: '1', label: '1% - Issued' },
            { value: '2', label: '10% - Fault Repaired' },
            { value: '3', label: '50% - Bore Complete' },
            { value: '4', label: '75% - Cable Pulled' },
            { value: '5', label: '90% - Cable Term(Only Switching Left)' },
            { value: '6', label: 'Complete' },
            { value: '8', label: '5%' },
            { value: '9', label: '15%)' },
            { value: '10', label: '25%' },
            { value: '11', label: '94%' },
        ]

        this.cols = [
            //{ field: 'intCustomerBillingCodesKey', header: 'ID' },
            { field: 'areanumber', header: 'Area' },
            { field: 'strJob', header: 'WO#' },
            { field: 'strJobName', header: 'Job Name' },
            { field: 'strContractNo', header: 'Contract #' },
            { field: 'intEstFootage', header: 'Estimated Ftg' },
            //{ field: 'curSub2UnitRate', header: 'Sub2 Price' },
            //{ field: 'intInfratechBillingCode', header: 'Infratech Code' },
            { field: 'ysnFinaled', header: 'Finaled' },
            //{ field: 'intCustomer', header: 'Customer ID' },
            //{ field: 'intContractKey', header: 'Contract Key' },

        ];

        this.items = [
            {
                label: 'JPD', icon: 'pi pi-refresh', command: () => {
                    this.displayReport = true;
                    this.selectedReportName = "DFRs/JPD.trdp";
                    //window.open(data, "popup", "width=1000,height=700,left=300,top=200");
                }
            },
            {
                label: 'Invoice Preview', icon: 'pi pi-money-bill', command: () => {
                    this.displayReport = true;
                    this.selectedReportName ="Infratrac/Invoice Preview.trdp"
                }
            },
            {
                label: 'DEC Timesheet', icon: 'pi pi-refresh', command: () => {
                    this.displayReport = true;
                    this.selectedReportName = "DEC Reports/DEC Timesheet.trdp"
                }
            },
            //{
            //    label: 'Invoice Summary Preview', icon: 'pi pi-money-bill', command: () => {
            //        //this.delete();
            //    }
            //},
            //{ label: 'Setup', icon: 'pi pi-cog', routerLink: ['/reportviewer'], target: 'blank' }
        ];
    }

    OnChangeJobType(e) {
        //console.log(e.value);
        if (e.value == 'Lateral') {
            //console.log(e.value);
            this.isFeeder = false;
            this.isLateral = true;
            this.userform.get('feeder').setValue(false);
        } else if (e.value == 'Feeder') {
            this.isFeeder = true;
            this.isLateral = false;
            this.userform.get('feeder').setValue(true);
        } else {
            this.isFeeder = false;
            this.isLateral = false;
            this.userform.get('feeder').setValue(false);
        }
    }

    transformAmount(element) {
        this.bidAmount = element.target.value.replace(/[$,]/g, "");
        this.bidFormattedAmount = this.currencyPipe.transform(this.bidAmount, '$')

        element.target.value = this.bidFormattedAmount;
    }

    onSelect(value) {
        //const value = event.target.value;
        this.dateReceived = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.dtmReceived = this._dateService.toString(this.dateReceived);
    }

    onSelectStartDate(value) {
        //const value = event.target.value;
        this.dateStart = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.dtmEstStartDate = this._dateService.toString(this.dateStart);
    }

    onSelectEndDate(value) {
        //const value = event.target.value;
        this.dateEnd = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.dtmEstTermDate = this._dateService.toString(this.dateEnd);
    }

    onSelectSRDDate(value) {
        //const value = event.target.value;
        this.dateSRD = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.srd = this._dateService.toString(this.dateSRD);
    }

    onSelectPartialDate(value) {
        //const value = event.target.value;
        this.datePartial = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.strPartialBilledPer = this._dateService.toString(this.datePartial);
    }

    onSelectAsBuiltDate(value) {
        //const value = event.target.value;
        this.dateCertifiedasBuilts = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.holdOtherDate = this._dateService.toString(this.dateCertifiedasBuilts);
    }

    onSelect699Date(value) {
        //const value = event.target.value;
        this.date699 = value;
        //console.log(element.getAttribute('formControlName'));
        this.wo.date818 = this._dateService.toString(this.date699);
    }

    onChangeAreaSuper(event) {
        this.selectedAreaSupervisor = this.supervisors.find(s => s.intSupervisorKey == event.value);

        this.intWoSupervisors = this.filterWoSupervisor(this.selectedAreaSupervisor.strSupervisorName, this.supervisors);

        this.selectedAreaSupervisorEmplId = this.selectedAreaSupervisor.strSupervisorName;

        this.intAreaSupervisor = this.supervisors.find(s => s.strSupervisorName == this.selectedAreaSupervisorEmplId);

        //console.log(this.intAreaSupervisor);

        this.intWoSupervisors.push(this.intAreaSupervisor);
    }

    getBillingCodesCustomerbyContract(id): void {
        this.billingCodeCustomerService.getBillingCodesbyContract(id)
            .subscribe(billingcodes => {
                this.billingCodesCustomer = billingcodes;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getOfficeLocationbyCustomerId(id): void {
        this.officeLocationService.getOfficeLocationsbyCustomer(id)
            .subscribe(locations => {
                this.officeLocations = locations;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getWobyContract(id): void {
        this.jobService.getJobsbyContract(id)
            .subscribe(wos => {
                this.wos = wos;
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    getWo(id): void {
        this.jobService.getWO(id)
            .subscribe(wos => {
                this.wos = wos;
                //this.filteredWo = wos;
                console.log(this.wos);
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    filterWoString(wo): void {
        //let query = event.query;
        this.jobService.searchWo(wo)
            .subscribe(wos => {
                //this.filteredWo = wos;
                this.wos = wos;
            });
    }

    getActiveSupervisorsList(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('name'));
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(crewtypes);
            });
    }

    addWo(wo): void {
        if (!wo) { return; }
        this.jobService.addJob(this.wo)
            .subscribe(
                data => {
                    //customers.push(this.customer);
                    console.log(data);
                    this.ngOnInit();
                    this.showSuccessViaToast();
                });
    }

    getEmployees(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')).filter(e => (e.ysnActive == 'Active') && ((e.intPosition == '6') || (e.intPosition == '24'))));
    }

    //getEmployees(intwosupervisor): void {
    //    this.employeeService.getEmployees()
    //        .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')).filter(e => (e.intReportingOfficer == intwosupervisor )));
    //}

    updateWo(): void {
        //let wos = [...this.wos];
        //wos[this.wos.indexOf(this.selectedWo)] = this.wo;
        this.unitName = this.wo.strJob;
        //console.log(this.billingunittype);
        this.jobService.updateJob(this.intUnitId, this.wo)

            .subscribe(data => {
                //console.log(data);
                this.showSuccessUpdateViaToast();
                this.filterWoString(this.strJob);
                //this.ngOnInit();
                //this.handleError("err:Test");
            });
        //this.filterWoString(this.selectedWo);
    }

    handleChange(e) {
        //console.log(e.checked);
        if (e.checked == true) {
            this.isFeeder = true;
            this.isLateral = false;
            this.userform.get('jobtype').setValue('Feeder');
        } else {
            this.isFeeder = false;
        }
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newWo = true;
        this.wo = {};
        this.displayDialog = true;
        this.isFeeder = false;
        this.isLateral = false;
    }

    clone(c: Job): Job {
        let bcode = {};
        for (let prop in c) {
            bcode[prop] = c[prop];
        }
        return bcode;
    }

    cancel() {
        this.displayDialog = false;
        this.wo = {};

        this.userform.reset();
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'WO Added', detail: this.unitName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'WO Updated', detail: this.unitName });
    }

    insertASupertoWoSuper(asupervisorid) {
        this.selectedAreaSupervisor = this.supervisors.find(s => s.intSupervisorKey == asupervisorid);

        this.intWoSupervisors = this.filterWoSupervisor(this.selectedAreaSupervisor.strSupervisorName, this.supervisors);

        this.selectedAreaSupervisorEmplId = this.selectedAreaSupervisor.strSupervisorName;

        this.intAreaSupervisor = this.supervisors.find(s => s.strSupervisorName == this.selectedAreaSupervisorEmplId);

        this.intWoSupervisors.push(this.intAreaSupervisor);
    }

    getASupertoWoSuper(asupervisorid) {
        this.selectedAreaSupervisor = this.supervisors.find(s => s.intSupervisorKey == asupervisorid);

        this.intWoSupervisors = this.filterWoSupervisor(this.selectedAreaSupervisor.strSupervisorName, this.supervisors);

        this.selectedAreaSupervisorEmplId = this.selectedAreaSupervisor.strSupervisorName;

        //console.log(this.selectedAreaSupervisorEmplId);
    }

    edit(rowdata) {
        if (rowdata.intCustomerKey == '365' || rowdata.intCustomerKey == '266') {
            this.isFPL = true;
        } else {
            this.isFPL = false;
        }

        this.selectedContractId = rowdata.intContractKey;
        this.selectedCustomerId = rowdata.intCustomerId;

        this.getBillingCodesCustomerbyContract(rowdata.intContractKey);
        ////console.log(this.selectedContractId);
        //this.getWobyContract(this.selectedContractId);
        ////this.employees.sort(this.dynamicSort("strLastname"));

        this.getOfficeLocationbyCustomerId(rowdata.intCustomerKey);

        this.intUnitId = rowdata.intJobId;
        this.strJob = rowdata.strJob;
        this.newWo = false;
        this.wo = this.clone(rowdata);

        this.woAreaSupervisor = this.wo.intSupervisor;

        if (!this.woAreaSupervisor == null) {
            this.insertASupertoWoSuper(this.wo.intSupervisor);
        }

        //this.insertASupertoWoSuper(this.wo.intSupervisor);

        //console.log(this.intUnitId);
        this.displayDialog = true;
        this.userform.get('wo').setValue(this.wo.strJob);
        this.userform.get('woname').setValue(this.wo.strJobName);
        this.userform.get('finaled').setValue(this.wo.ysnFinaled);
        this.userform.get('active').setValue(this.wo.ysnActive);
        this.userform.get('estftg').setValue(this.wo.intEstFootage);
        this.userform.get('billingcode').setValue(this.wo.strBillingCode);
        this.userform.get('location').setValue(this.wo.intOfficeLocation);
        this.userform.get('project').setValue(this.wo.ysnProjectJob);
        this.userform.get('bidamount').setValue(this.currencyPipe.transform(this.wo.bidAmount, '$'));
        this.userform.get('comments').setValue(this.wo.mmoComments);
        if (this.wo.dtmReceived !== null) {
            this.userform.get('datereceived').setValue(new Date(this.wo.dtmReceived));
        }
        if (this.wo.dtmEstStartDate !== null) {
            this.userform.get('datestart').setValue(new Date(this.wo.dtmEstStartDate));
        }
        if (this.wo.dtmEstTermDate !== null) {
            this.userform.get('dateend').setValue(new Date(this.wo.dtmEstTermDate));
        }
        this.userform.get('wosupervisor').setValue(this.wo.intSupervisorIdForWo);
        this.userform.get('asupervisor').setValue(this.wo.intSupervisor);

        if (this.wo.ysnProjectJob == -1) {
            this.isProject=true
        }

        //***FPL Fields
        if (this.wo.feeder == true) {
            this.isFeeder = true;
            this.isLateral = false;
        }

        this.userform.get('loop').setValue(this.wo.strLoop);
        this.userform.get('switch1').setValue(this.wo.strSwitch1);
        this.userform.get('switch2').setValue(this.wo.strSwitch2);
        this.userform.get('feeder').setValue(this.wo.feeder);
        this.userform.get('groundstrap').setValue(this.wo.ysnGroundStrap);
        this.userform.get('faultrepair').setValue(this.wo.ysnFaultRepair);
        if (this.wo.srd !== null) {
            this.userform.get('srd').setValue(new Date(this.wo.srd));
        }
        this.userform.get('childnum').setValue((this.wo.childNumber));
        this.userform.get('revisedftg').setValue(this.wo.revisedFtg);
        this.userform.get('contact').setValue(this.wo.strContact);
        this.userform.get('pl').setValue(this.wo.pl);
        this.userform.get('fplothercomments').setValue(this.wo.fplotherComments);
        this.userform.get('percentcomplete').setValue(this.wo.percentComplete);
        this.userform.get('issuedstatus').setValue(this.wo.issuedStatus);
        if (this.wo.jobType === null || this.wo.jobType === undefined) {
        } else {
            this.userform.get('jobtype').setValue(this.wo.jobType.trim());
            if (this.wo.jobType.trim() == 'Lateral') {
                this.isFeeder = false;
                this.isLateral = true;
            }
        }

        this.userform.get('area').setValue(this.wo.area);
        this.userform.get('fpljobcode').setValue(this.wo.fplJobCode);
        this.userform.get('notesstatus').setValue(this.wo.notesStatus);
        this.userform.get('feederstatus').setValue(this.wo.feederStatus);
        this.userform.get('lateralstatus').setValue(this.wo.lateralStatus);
        this.userform.get('wlstatus').setValue(this.wo.fedexTrackingNum);
        this.userform.get('status99').setValue(this.wo.status99);
        if (this.wo.strPartialBilledPer != null || this.wo.date818 == '1900-01-01T05:00:00') {
            this.userform.get('partialbilled').setValue(new Date(this.wo.strPartialBilledPer));
        }
        if (this.wo.holdOtherDate != null || this.wo.date818 == '1900-01-01T05:00:00') {
            this.userform.get('asbuilts').setValue(new Date(this.wo.holdOtherDate));
        }
        //console.log(this.wo.date818);
        if (this.wo.date818 != null || this.wo.date818 == '1900-01-01T05:00:00') {
            this.userform.get('date699').setValue(new Date(this.wo.date818));
        }
        this.userform.get('billingcomments').setValue(this.wo.billingComments);

        this.customerId = this.wo.intCustomerKey;

        //this.userform.get('unit').setValue(this.billingCodeCustomer.intInfratechBillingCode);
        //this.userform.get('unitprice').setValue(this.billingCodeCustomer.curUnitRate);
        //this.userform.get('subprice').setValue(this.billingCodeCustomer.curSubUnitRate);
        //this.userform.get('sub2price').setValue(this.billingCodeCustomer.curSub2UnitRate);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });

        this.wo.strJob = this.userform.get('wo').value;
        this.wo.strJobName = this.userform.get('woname').value;
        this.wo.ysnFinaled = this.userform.get('finaled').value;
        this.wo.intEstFootage = this.userform.get('estftg').value;
        this.wo.ysnActive = this.userform.get('active').value;
        this.wo.strBillingCode = this.userform.get('billingcode').value;
        this.wo.ysnProjectJob = this.userform.get('project').value;
        this.wo.mmoComments = this.userform.get('comments').value;
        this.wo.strLoop = this.userform.get('loop').value;
        this.wo.strSwitch1 = this.userform.get('switch1').value;
        this.wo.strSwitch2 = this.userform.get('switch2').value;
        this.wo.feeder = this.userform.get('feeder').value;
        this.wo.ysnFaultRepair = this.userform.get('faultrepair').value;
        this.wo.ysnGroundStrap = this.userform.get('groundstrap').value;
        this.wo.revisedFtg = this.userform.get('revisedftg').value;
        this.wo.childNumber = this.userform.get('childnum').value;
        this.wo.fplotherComments = this.userform.get('fplothercomments').value;
        this.wo.pl = this.userform.get('pl').value;
        this.wo.strContact = this.userform.get('contact').value;
        this.wo.percentComplete = this.userform.get('percentcomplete').value;
        this.wo.issuedStatus = this.userform.get('issuedstatus').value;
        this.wo.jobType = this.userform.get('jobtype').value;
        this.wo.area = this.userform.get('area').value;
        this.wo.fplJobCode = this.userform.get('fpljobcode').value;
        this.wo.notesStatus = this.userform.get('notesstatus').value;
        this.wo.lateralStatus = this.userform.get('lateralstatus').value;
        this.wo.feederStatus = this.userform.get('feederstatus').value;
        this.wo.fedexTrackingNum = this.userform.get('wlstatus').value;
        this.wo.status99 = this.userform.get('status99').value;
        this.wo.strPartialBilledPer = this.userform.get('partialbilled').value;
        this.wo.holdOtherDate = this.userform.get('asbuilts').value;
        this.wo.date818 = this.userform.get('date699').value;
        this.wo.billingComments = this.userform.get('billingcomments').value;
        this.wo.intOfficeLocation = this.userform.get('location').value;
        this.wo.intSupervisorIdForWo = this.userform.get('wosupervisor').value;
        this.wo.intSupervisor = this.userform.get('asupervisor').value;
        this.wo.bidAmount = this.userform.get('bidamount').value.replace(/[$,]/g, "");
        this.wo.intContractKey = this.selectedContractId;
        this.wo.intCustomerKey = this.customerId;
        //this.wo.intOfficeLocation = '2';

        //this.billingCodeCustomer.intInfratechBillingCode = this.userform.get('unit').value;
        //this.billingCodeCustomer.curUnitRate = this.userform.get('unitprice').value;
        //this.billingCodeCustomer.curSubUnitRate = this.userform.get('subprice').value;
        //this.billingCodeCustomer.curSub2UnitRate = this.userform.get('sub2price').value;

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newWo) {
            this.addWo(this.wo);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            //console.log('Update');
            this.updateWo();

            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        //this.wos.push(this.wo);
        this.displayDialog = false;
        this.submitted = false;
        this.userform.reset();
        this.wo = {};
        this.filterWoString(this.strJob);
    }

    filterWoSupervisor(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intReportingSupervisorId == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    filterSupervisorId(areasuper, intWoSupervisors: Supervisor[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        //console.log(intWoSupervisors.length);
        let filtered: Supervisor[] = [];
        for (let i = 0; i < intWoSupervisors.length; i++) {
            let supervisor = intWoSupervisors[i];
            if (supervisor.intSupervisorKey == areasuper) {
                filtered.push(supervisor);
                //console.log(supervisor);
            }
        }
        return filtered;
        ;
    }

    woChange(event) {
        let query = event.query;
        this.filterWoString(query);
    }

    projectChanged(event) {
        if (event.value == -1) {
            this.isProject = true;
        } else {
            this.isProject = false;
        }
    }

    captureId(event: any) {
        this.intWoId = event.intJobId;
        this.selectedWo = event.strJob;
        //console.log(this.selectedWo);
        //this.getWo(this.intWoId);
        this.filterWoString(this.selectedWo);
    }

    invoiceCreated(created: boolean) {
        //this.variableToUpdate = val;
        //console.log(created);
        this.newInvoice = created;
    }

    invoiceDeleted(invoicenum: number) {
        //console.log(invoicenum);
        this.deletedInvoiceNum = invoicenum;
    }

    tabChange(event) {
        //console.log(event.index)
        let currentindex = event.index;
        if (currentindex == 0) {
            //this.deletedInvoiceNum = 1;
        }
    }

    onRowSelect(event) {
        this.displayWoDetails = true;
        this.selectedWONum = event.data.strJob;
        //console.log(event.data.intCustomerKey);
        this.selectedCustomerId = event.data.intCustomerKey;
        //var cont = this.allcontracts;
        if (event.data.intJobId) {
            this.selectedWoId = event.data.intJobId;
        }
        if (event.data.intSupervisor) {
            this.getASupertoWoSuper(event.data.intSupervisor);
            //console.log(this.selectedAreaSupervisor);
        }

        //console.log(event.data.intContractId);
        //this.contracts = cont;
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.selectedCustomerId });
        //this.newCustomer = false;
        //this.customer = this.cloneCustomer(event.data);
        ////console.log(this.tbUsers.selection);
        //this.displayDialog = true;
    }

    onRowUnselect() {
        this.displayWoDetails = false;
        this.selectedWo = null;
    }

    onReject() {
        this.service.clear('c');
        this.service.clear('d');
    }

    onFocus() {
        this.displayWoDetails = false;
        this.selectedWo = null;
        this.wos = null;
        this.autocompleteCharge.inputEL.nativeElement.value = null;
        this.selectedRow = [];
    }

    //printDFR(rowdata) {
    //    //this.displayReport = true;
    //    this.selectedWONum = rowdata.strJob;
    //    //this.selectedReportName = "DFRs/JPD.trdp";
    //}
}
