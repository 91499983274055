import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, Renderer } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectItem, Dropdown } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { EquipmentUsage } from '../domain/equipmentusage'
import { EquipmentUsageService } from '../service/equipmentusageservice';
import { Equipment } from '../domain/equipment'
import { EquipmentService } from '../service/equipmentservice';

@Component({
    selector: 'dfrequipmentusage-table',
    templateUrl: './dfrequipmentusage.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class DfrEquipmentUsageComponent implements OnInit {
    cols: any[];

    equipmentUsages: EquipmentUsage[] = [];

    equipmentUsage: EquipmentUsage = {};

    selectedRow: EquipmentUsage;

    equipment: Equipment = {};

    equipments: Equipment[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    newEquipmentUsage: boolean;

    activeFilter: SelectItem[];

    filter: any = {};

    submitted: boolean;

    @Input() insertedDfrId: string = "";

    showDelete: boolean = false;

    systemDownTimeItems: SelectItem[];

    equipmentUsageId: string;

    @ViewChild('qty', { static: false }) qty: ElementRef;

    dfrId: string;

    constructor(private service: MessageService, public snackbar: MatSnackBar, private route: Router, private fb: FormBuilder,
        private _dateService: DateService, private renderer: Renderer, private equipmentusageservice: EquipmentUsageService,
        private equipmentservice: EquipmentService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'insertedDfrId') {
                this.insertedDfrId = curVal;
                if (this.insertedDfrId)
                this.dfrId = JSON.parse(this.insertedDfrId);
                this.insertedDfrId = this.dfrId
                //console.log(this.selectedContractId);
                if (this.insertedDfrId) {
                    this.getEquipmentUsageByDFR(this.insertedDfrId);
                }
            }
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.active = [

            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'newEquipNo', header: 'Equipment', width: '40%' },
            { field: 'strDescription', header: 'Description', width: '60%' },
            //{ field: 'equipment', header: 'Equip #', width: '10%' },
            //{ field: 'description', header: 'Description', width: '30%' },
            //{ field: 'comments', header: 'Comments', width: '40%' },

        ];

        this.systemDownTimeItems = [
            { label: 'Select Delay', value: null },
            { label: 'Truck', value: 1 },
            { label: 'Trailer', value: 2 },
            { label: 'Drill', value: 3 },
            { label: 'Downhole', value: 4 },
            { label: 'Mud System', value: 5 },
            { label: 'Other', value: 6 },
            { label: 'Line Truck', value: 7 },
            { label: 'Excavator', value: 8 },

        ];

        this.getActiveEquipment();
    }

    getActiveEquipment(): void {
        this.equipmentservice.getActiveEquipmentId()
            .subscribe(data => this.equipments = data);
    }

    getEquipmentUsageByDFR(id): void {
        this.equipmentusageservice.getEquipmentUsagebyId(id)
            .subscribe(data => this.equipmentUsages = data);
    }

    addEquipmentUsage(equipment, dfrid): void {
        if (!equipment) { return; }
        this.equipmentusageservice.addEquipmentUsage(equipment)
            .subscribe(
                data => {
                    //console.log(data);
                    let message = 'Added';
                    this.getEquipmentUsageByDFR(dfrid);
                    this.showSuccessViaToast(message);
                });
    }

    updateEquipmentUsage(id, equipmentusage, dfrid): void {
        if (!equipmentusage) { return; }
        this.equipmentusageservice.updateEquipmentUsage(id, equipmentusage)
            .subscribe(
                data => {
                    this.getEquipmentUsageByDFR(dfrid);
                    this.showSuccessUpdateViaToast();
                });
    }

    deleteEquipmentUsage(id): void {
        this.equipmentusageservice.deleteEquipmentUsage(id)
            .subscribe();
        this.showdeletedmessage('Deleted', 'Deleted');
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAdd() {
        this.newEquipmentUsage = true;
        this.equipmentUsage = {};
        this.showDelete = false;
        this.displayDialog = true;
    }

    cancel() {
        this.displayDialog = false;
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Equipment Updated', detail: '' });
    }

    showSuccessViaToast(message) {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Equipment Added', detail: message });
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    onChange(event) {
        setTimeout(() => this.qty.nativeElement.focus());
    }

    onConfirm() {
        this.service.clear('d');
        let index = this.equipmentUsages.indexOf(this.selectedRow);
        this.deleteEquipmentUsage(this.equipmentUsageId);
        this.equipmentUsages = this.equipmentUsages.filter((val, i) => i != index);
        this.equipmentUsage = null;
        this.displayDialog = false;
    }

    onReject() {
        this.service.clear('d');
    }

    save() {
        if (this.equipmentUsage.intEquipmentKey) {
            this.equipmentUsage.intReportID = this.insertedDfrId;
            //this.productionDelay.intProdDelayId = '1';
            //console.log(this.insertedDfrId);
            //console.log(this.systemDownTime);
            let equipmentusages = [...this.equipmentUsages];

            if (this.newEquipmentUsage) {
                equipmentusages.push(this.equipmentUsage);
                this.addEquipmentUsage(this.equipmentUsage, this.insertedDfrId);
            }
            else {
                equipmentusages[this.equipmentUsages.indexOf(this.selectedRow)] = this.equipmentUsage;
                this.updateEquipmentUsage(this.equipmentUsageId, this.equipmentUsage, this.insertedDfrId);
            }
            this.equipmentUsages = equipmentusages;
            this.equipmentUsage = {};
            this.displayDialog = false;
        } else {
            this.showdNeedEquipment();
        }
    }

    onRowSelect(event) {
        this.showDelete = true;
        this.newEquipmentUsage = false;
        this.equipmentUsage = this.cloneUsage(event.data);
        this.displayDialog = true;
        this.equipmentUsageId = this.equipmentUsage.intEquipUsageKey;
        //this.deletedBc = this.billableProduction.strCustomerBillingCode;
        //this.deletedBcDescription = this.billableProduction.strBcdescription;
        //this.deletedQty = this.billableProduction.intQty;
    }

    cloneUsage(p: EquipmentUsage): EquipmentUsage {
        let usage = {};
        for (let prop in p) {
            usage[prop] = p[prop];
        }
        return usage;
    }

    delete() {
        this.service.add({
            key: 'd',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Used Equipment# ?',
            detail: this.equipmentUsage.newEquipNo + ' ' + this.equipmentUsage.strDescription
        });
    }

    showdeletedmessage(billingcode, description) {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Equipment Deleted', detail: 'Deleted' });
    }

    showdNeedEquipment() {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Need Equipment #', detail: 'Select an Equipment #' });
    }
}
