import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output } from '@angular/core';
import { CustomerService } from '../service/customerservice';
import { Customer } from '../domain/customer'
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { TableModule } from 'primeng/table'
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Contract } from '../domain/contract'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';


@Component({
    templateUrl: './customer.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class CustomerComponent implements OnInit {
    customer: Customer = {};

    customers: Customer[];

    contracts: Contract[];

    contract: Contract = {};

    allcontracts: Contract[];

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    displayDialog: boolean;

    selectedCustomer: Customer;

    newCustomer: boolean;

    newContract: boolean;

    activeFilter: SelectItem[];

    contractActiveSel: SelectItem[];

    customerName = "Test";

    contractName = "";

    filter: any = {};

    displayContract: boolean = false;

    displayContractDialog: boolean = false;

    selectedActive: string = "-1";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    selectedCustomerId: string = "";

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter()

    value: any

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    constructor(private customerService: CustomerService, private service: MessageService, public snackbar: MatSnackBar, private contractService: ContractService,
        private route: Router, private employeeService: EmployeeService, private userpermissionservice: UserPermissionService, private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    ngOnInit() {
        if (this.selectedActive == '-1') {
            this.getCustomersFilter('-1');
        } else if (this.selectedActive == '0') {
            this.getCustomersFilter('0');
        } else {
            this.getCustomers();
        }
        //this.getCustomers();

        this.getContracts();

        //this.getEmployees();

        //this.customer.ysnActive = -1;

        this.contractActiveSel = [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ];

        this.active = [
            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intCustomerKey', header: 'Customer ID' },
            { field: 'strCustomerName', header: 'Customer' },
            { field: 'strContactLastname', header: 'Last Name' },
            { field: 'strContactFirstName', header: 'First Name' },
            { field: 'strAttn', header: 'Contact' },
            { field: 'strPaymentTerms', header: 'Pay Terms' },
            { field: 'ysnActive', header: 'Active' },
        ];

        this.contractcols = [
            { field: 'intContractId', header: 'Contract ID' },
            { field: 'strContractNo', header: 'Contract #' },
            { field: 'strContractName', header: 'Contract Name' },
            { field: 'dtmStartDate', header: 'Start Date' },
            { field: 'dtmEndDate', header: 'End Date' }

        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);
                //console.log(this.userpermission.crewEdit);
                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.customerEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.customerCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                //console.log(this.canEdit);
            });
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getCustomers(): void {
        this.customerService.getCustomers()
            .subscribe(customers => this.customers = customers);
    }

    getCustomersFilter(status): void {
        this.customerService.getCustomerFilter(status)
            .subscribe(customers => this.customers = customers);
    }

    addCustomer(customer): void {
        if (!customer) { return; }
        this.customerService.addCustomer(this.customer)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addContract(contract): void {
        if (!contract) { return; }
        this.contractService.addContract(this.contract)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    updateCustomer(): void {
        let customers = [...this.customers];
        customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
        this.customerName = this.customer.strCustomerName;
        this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            .subscribe(data => {
                console.log(data);
                this.showSuccessUpdateViaToast();
                this.ngOnInit();

                //this.handleError("err:Test");
            });
    }

    updateContract(): void {
        //let contracts = [...this.contracts];
        //contracts[this.contracts.indexOf(this.selectedContract)] = this.customer;
        this.contractName = this.customer.strCustomerName;
        this.contractService.updateContract(this.contract.intContractId, this.contract)

            .subscribe(data => {
                console.log(data);
                this.showSuccessUpdateViaToast();
                this.ngOnInit();
                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

    getContracts(): void {
        this.contractService.getContracts()
            .subscribe(contracts => {
                this.contracts = this.allcontracts = contracts;
                //console.log(contracts);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newCustomer = true;
        this.customer = {};
        this.displayDialog = true;
    }

    showDialogToAddContract() {
        this.newContract = true;
        this.contract = {};
        this.contract.intCustomerId = this.selectedCustomer.intCustomerKey;
        this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.displayContractDialog = true;
    }

    save() {
        this.customer.strPhone.replace(/\D/g, '');
        this.customer.strFax.replace(/\D/g, '');
        let customers = [...this.customers];
        this.customerName = this.customer.strCustomerName;

        //this.showSuccessUpdateViaToast();
        if (this.newCustomer) {
            this.addCustomer(customers);
            customers.push(this.customer);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateCustomer();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }
        this.customers = customers;
        this.customer = null;
        this.displayDialog = false;
    }

    onSaveContract() {
        let contracts = [...this.contracts];
        this.contractName = this.contract.strContractName;
        //this.showSuccessUpdateViaToast();
        if (this.newContract) {
            this.addContract(contracts);
            contracts.push(this.contract);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateContract();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.contracts = contracts;
        this.contract = null;
        this.displayContractDialog = false;
    }

    //onSubmit() {
    //    this.customerService.addCustomer(this.customer)
    //        .subscribe(data => {
    //            //this.router.navigate(['list-user']);
    //        });
    //}
    onRowUnselect() {
        this.displayContract = false;
    }

    onRowSelect(event) {
        //this.displayContract = !this.displayContract;
        this.displayContract = true;
        var cont = this.allcontracts;
        if (event.data.intCustomerKey) {
            cont = cont.filter(v => v.intCustomerId == event.data.intCustomerKey);
            this.selectedCustomerId = event.data.intCustomerKey;
        }
        this.contracts = cont;
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.selectedCustomerId });
        //this.newCustomer = false;
        //this.customer = this.cloneCustomer(event.data);
        ////console.log(this.tbUsers.selection);
        //this.displayDialog = true;
    }

    onContractChange() {
        var cont = this.allcontracts;
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
        cont = cont.filter(v => v.intCustomerId == this.selectedCustomerId);

        this.contracts = cont;
    }

    cloneCustomer(c: Customer): Customer {
        let customer = {};
        for (let prop in c) {
            customer[prop] = c[prop];
        }
        return customer;
    }

    cloneContract(c: Contract): Contract {
        let contract = {};
        for (let prop in c) {
            contract[prop] = c[prop];
        }
        return contract;
    }

    cancel() {
        this.displayDialog = false;
    }

    onCancelContract() {
        this.displayContractDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    }

    editCustomer(rowdata) {
        this.newCustomer = false;
        this.customer = this.cloneCustomer(rowdata);
        //console.log(this.tbUsers.selection);
        this.displayDialog = true;
    }

    editContract(event: Event, car: Contract) {
        this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        this.newContract = false;
        this.contract = this.cloneContract(car);
        //console.log(this.tbUsers.selection);
        this.displayContractDialog = true;
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getCustomers();
        } else {
            console.log(event.value);
            this.getCustomersFilter(event.value);
        }

        this.selectedActive = event.value;
    }

    //onInputChange($event) {
    //    this.value = $event.target.value.toUpperCase()
    //    this.ngModelChange.emit(this.value)
    //}

    //var str = "Apples are round, and apples are juicy.";
    //var splitted = str.split(" ", 3);
    //console.log(splitted);

    //handleError(err: any) {
    //    this.snackbar.open(err.message, 'close');
    //}
    //@ViewChild('dt') tbUsers: DataTable;

    //onRowSelect() {
    //    console.log(this.tbUsers.selection);
    //}
}
