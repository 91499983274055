import { Component, OnInit, ViewEncapsulation, ViewChild, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolVendor } from '../domain/linemantoolvendor';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from '../service/linemantoolvendorservice';
import { PayRollHour } from '../domain/payrollhour';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';


@Component({
    templateUrl: './vendor.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class VendorComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    employees: Employee[];

    employee: Employee = {};

    payrollHours: PayRollHour[];

    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolVendors: LinemanToolVendor[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    cols: any[];

    payrollcols: any[];

    linemantoolPurccols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedEmployee: Employee;

    activeFilter: SelectItem[];

    perdiem: SelectItem[];

    txType: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    displayEmployeeDetails: boolean = false;

    newEmployee: boolean;

    selectedActive: string = "Active";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    selectedEmployeeId: string = "";

    userform: FormGroup;

    submitted: boolean;

    genders: SelectItem[];

    description: string;

    hireDate: Date;

    termDate: Date;

    rehireDate: Date;

    orientationDate: Date;

    bootPurchaseDate: Date;

    glassesPurchaseDate: Date;

    benefits: SelectItem[];

    viewRate: boolean = false;

    employeeName: string;

    yearFilter: number;

    yearTimeout: any;

    visible: boolean = true;

    orientationDone: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    newAuth: boolean = false;

  

    private searchTerms = new Subject<string>();

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private payRollHourService: PayRollHourService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private linemanToolVendorService: LinemanToolVendorServices,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    ngOnInit() {
        this.userform = this.fb.group({
           
            'lastname': new FormControl('', Validators.required),
            'notes': new FormControl(''),
            'active': new FormControl('', Validators.required),
            'position': new FormControl(''),
            'sapvendor': new FormControl('', Validators.required),
            'hiredate': new FormControl(''),
        });

        if (this.selectedActive == 'Active') {
            this.getVendorsbyStatus('Active');
        } else if (this.selectedActive == 'InActive') {
            this.getVendorsbyStatus('InActive');
        } else {
            this.getEmployees();
        }

        this.getPositions();

        //this.getEmployees();

        //this.getEmployeesbyStatus('active');

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.txType = [
            { label: 'All', value: null },
            { label: 'Deposit', value: '1' },
            { label: 'Withdraw', value: '2' },
        ];

        this.benefits = [
            { label: 'A', value: '1' },
            { label: 'B', value: '2' },
            { label: 'C', value: '3' },
            { label: 'D', value: '4' },
            { label: 'Z', value: '5' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.perdiem = [
            { label: '$0', value: null },
            { label: '$25', value: '1' },
            { label: '$50', value: '7' },
            { label: '$65', value: '2' },
            { label: '$75', value: '5' },
            //{ label: '$35', value: '3' },
            { label: '$100', value: '4' },

            //{ label: '$90', value: '6' },
        ];

        this.cols = [
            { field: 'strEmployeeId', header: 'Vendor ID' },
            { field: 'strLastName', header: 'Vendor Name' },
            { field: 'strFirstName', header: 'Vendor' },
            { field: 'dtmHireDate', header: 'Hire Date' },
            { field: 'intPosition', header: 'Position' },
            { field: 'ysnActive', header: 'Active' },
        ];
        this.payrollcols = [
            { field: 'dtDate', header: 'Date' },
            { field: 'intReportId', header: 'DFR#' },
            { field: 'intCrew', header: 'Crew' },
            { field: 'intHoursWorked', header: 'Hours' },
            { field: 'intHoursVac', header: 'Vacation' },
            { field: 'intHoursPto', header: 'PTO' },
            { field: 'intHoursHol', header: 'Hol' },
            { field: 'ysnPerDiem', header: 'PerDiem' },
            { field: 'isTabletRow', header: 'Tablet Entry' },
            { field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];

        this.linemantoolPurccols = [
            { field: 'dtmDate', header: 'Date' },
            { field: 'intTxtype', header: 'TX Type' },
            { field: 'intVendor', header: 'Vendor' },
            { field: 'strPono', header: 'PO #' },
            { field: 'curPoamnt', header: 'PO Amount' },
            { field: 'strInvNo', header: 'Invoice #' },
            { field: 'curInvAmnt', header: 'Invoice Amount' },
            { field: 'curDeposit', header: 'TX Amount' },
            //{ field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    onRowUnselect() {
        //this.payrollHours = [];
        this.linemanToolPurchases = [];
        this.displayEmployeeDetails = false;
    }

    onRowSelect(event) {
        if (event.data.strEmployeeId) {
            this.selectedEmployeeId = event.data.strEmployeeId;
            //this.getEmployeeHours(this.selectedEmployeeId);
            this.getLinemanToolPurchases(this.selectedEmployeeId);
            this.displayEmployeeDetails = true;
        }
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }

    getEmployeeAuth(id): void {
        this.userauthservice.getAuthId(id)
            .subscribe(auths => {
                this.userauths = auths

                this.userauth = auths.find(e => e.fkEmpId == id);
                //console.log(this.userauth);
                //console.log(this.userauths);
                if (this.userauth) {
                    this.userform.get('username').setValue(this.userauth.userId);
                    this.userform.get('password').setValue(this.userauth.password);
                    this.newAuth = false;
                } else {
                    this.newAuth = true;
                }
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.vendorEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.vendorCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }



            });
    }

    onActiveChange(event) {
        console.log(event.value);
        if (event.value = 'active') {
            this.getVendorsbyStatus('active');
        } else if (event.value = 'inactive') {
            this.getVendorsbyStatus('inActive');
        } else {
            this.getEmployees();
        }

        this.updateVisibility();
    }

    onSelect(value) {
        //const value = event.target.value;
        this.hireDate = value;
        //console.log(element.getAttribute('formControlName'));
        this.employee.dtmHireDate = this._dateService.toString(this.hireDate);
    }

    onTermSelect(value) {
        //const value = event.target.value;
        this.termDate = value;
        this.employee.drmTermDate = this._dateService.toString(this.termDate);
    }

    onRehireSelect(value) {
        //const value = event.target.value;
        this.rehireDate = value;
        this.employee.dtmRehireDate = this._dateService.toString(this.rehireDate);
    }

    onOrientSelect(value) {
        //const value = event.target.value;
        this.orientationDate = value;
        this.employee.dtmOrientationCompl = this._dateService.toString(this.orientationDate);
    }

    onBootPurSelect(value) {
        //const value = event.target.value;
        this.bootPurchaseDate = value;
        this.employee.bootPurchase = this._dateService.toString(this.bootPurchaseDate);
    }

    onGlassPurSelect(value) {
        //const value = event.target.value;
        this.glassesPurchaseDate = value;
        this.employee.glassesPurchase = this._dateService.toString(this.glassesPurchaseDate);
    }

    cancel() {
        this.displayEmployeeDialog = false;
        this.employee = {};
        this.userform.reset();
        //this.userform.get('active').setValue(null);
        //this.userform.get('position').setValue(null);
        //this.userform.get('perdiem').setValue(null);
    }

    onSubmit(value: string) {
        this.submitted = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.employee.strLastName = this.userform.get('lastname').value;
        this.employee.strFirstName = "Vendor";
        this.employee.txtNotes = this.userform.get('notes').value;
        this.employee.ysnActive = this.userform.get('active').value;
        this.employee.intPosition = this.userform.get('position').value;
        this.employee.strPerDiemType = null;
        this.employee.intBenefits = "5";
        this.employee.sapvendorNum = this.userform.get('sapvendor').value;
        this.employee.dtmHireDate = this._dateService.toString(this.userform.get('hiredate').value);

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newEmployee) {
            this.addEmployee(this.employee);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        } else {
            this.updateEmployee();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.employee = {};
        this.submitted = false;
        this.userform.reset();
        this.displayEmployeeDialog = false;
    }

    onSaveEmployee() {
        let employees = [...this.employees];
        this.employeeName = this.employee.strLastName;
        //this.showSuccessUpdateViaToast();
        if (this.newEmployee) {
            this.addEmployee(employees);
            employees.push(this.employee);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        } else {
            this.updateEmployee();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }
        this.displayEmployeeDialog = false;
        this.employees = employees;
        this.employee = null;
    }

    //get diagnostic() { return JSON.stringify(this.userform.value); };
    getEmployeeHours(id): void {
        this.payRollHourService.getEmployeePayRollHours(id)
            .subscribe(employeehours => this.payrollHours = employeehours);
    }

    getLinemanToolPurchases(id): void {
        this.linemanToolPurchaseService.getToolPurchasesbyID(id)
            .subscribe(toolpurchases => this.linemanToolPurchases = toolpurchases);
    }

    getLinemanToolVendors(): void {
        this.linemanToolVendorService.getToolVendors()
            .subscribe(toolvendors => this.linemanToolVendors = toolvendors);
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    getEmployeesbyStatus(status): void {
        this.employeeService.getEmployeesbyStatus(status)
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    getVendorsbyStatus(status): void {
        this.employeeService.getVendorbyStatus(status)
            .subscribe(employees => this.employees = employees);
    }

    addEmployee(employee): void {
        if (!employee) {
            return;
        }
        this.employeeService.addEmployee(this.employee)
            .subscribe(data => {
                //customers.push(this.customer);
                console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    updateEmployee(): void {
        let employees = [...this.employees];
        employees[this.employees.indexOf(this.selectedEmployee)] = this.employee;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        //console.log(this.selectedEmployee.strEmployeeID);
        this.employeeName = this.employee.strFirstName + ' ' + this.employee.strLastName;
        this.employeeService.updateEmployee(this.selectedEmployeeId, this.employee)
            .subscribe(data => {
                console.log(data);
                this.ngOnInit();
                this.showSuccessUpdateViaToast();
                //this.onContractChange();
                //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
                //this.handleError("err:Test");
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Vendor Added', detail: this.employeeName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Vendor Updated', detail: this.employeeName });
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployees("Active")
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAddEmployee() {
        this.newEmployee = true;
        this.employee = {};
        this.userform.reset();
        this.userform.get('hiredate').setValue(new Date(1977, 1, 1));
        this.userform.get('position').setValue(73);
        this.userform.get('active').setValue('Active');
        this.userform.get('position').disable();
        this.userform.get('hiredate').disable();
        //this.employee.strEmployeeID = this.selectedEmployee.strEmployeeID;
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.displayEmployeeDialog = true;
        //console.log(this.displayEmployeeDialog);
    }

    editEmployee(rowdata) {
        this.selectedEmployeeId = rowdata.strEmployeeId;
        this.newEmployee = false;
        this.employee = this.cloneEmployee(rowdata);
        //console.log(this.employees);
        this.displayEmployeeDialog = true;
        this.userform.get('active').setValue(this.employee.ysnActive);
        this.userform.get('position').setValue(73);
        this.userform.get('lastname').setValue(this.employee.strLastName);

        if (this.employee.dtmHireDate !== null) {
            this.userform.get('hiredate').setValue(new Date(1977,1,1));
        }
        this.userform.get('position').disable();
        this.userform.get('hiredate').disable();
        this.userform.get('sapvendor').setValue(this.employee.sapvendorNum);

        this.userform.get('notes').setValue(this.employee.txtNotes);
    }
    //onRowUnselect() {
    //    this.displayContract = false;
    //}

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    //showSuccessViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    //}

    //showSuccessUpdateViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    //}

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        },
            250);
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getEmployees();
        } else {
            this.getEmployeesbyStatus(event.value);
        }

        this.selectedActive = event.value;
    }

    onOrientationCheck(isChecked: boolean) {
        //console.log(isChecked);
        if (isChecked == true) {
            this.orientationDone = true;
            this.userform.get('orientationdate').setValue(new Date());
            this.employee.dtmOrientationCompl = this._dateService.toString(new Date());
            //this.employee.ysnOrientation = 1;
        } else {
            this.orientationDone = false;
            //this.employee.ysnOrientation = 0;
        }
    }
}
