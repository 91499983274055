import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomerInvoiceService } from '../service/customerinvoiceservice';
import { CustomerInvoice } from '../domain/customerinvoice'
import { HttpClientModule } from '@angular/common/http';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Contract } from '../domain/contract'
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { DateService } from '../../_services/date.service';
import { BillableProduction } from '../domain/billableproduction'
import { DailyFieldReportService } from '../service/dailyfieldreportservice';
import { BillableProductionService } from '../service/billableproductionservice';
import { DialogService } from 'primeng/api';
import { InvoicePaymentListComponent } from '../invoicepaymentslist/invoicepaymentlist.component';
import { invoicepayments } from '../domain/invoicepayments'
import { InvoicePaymentsServices } from '../service/invoicepayments';
import { MessageService, Message } from 'primeng/api';


@Component({
    selector: 'invoice-table',
    templateUrl: './customerinvoice.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService, DialogService]
})
export class CustomerInvoiceComponent implements OnInit {
    customerInvoice: CustomerInvoice = {};

    customerInvoices: CustomerInvoice[];

    invoicePayment: invoicepayments = {};

    invoicePayments: invoicepayments[];

    employees: Employee[];

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    adjustmentReason: SelectItem[];

    displayDialog: boolean;

    activeFilter: SelectItem[];

    customerName = "";

    contractName = "";

    filter: any = {};

    newInvoice: boolean = false;

    displayContract: boolean = false;

    displayContractDialog: boolean = false;

    selectedActive: string = "-1";

    selectedCustomerId: string = "";

    @Input() selectedWoId: string = "";

    @Input() invoiceCreated: boolean = false;

    userform: FormGroup;

    paymentform: FormGroup;

    selectedInvoice: any;

    displayReport: boolean = false;
        
    selectedInvoiceId: string = null;

    selectedReportName: string = null;

    @Output() invoiceDeleted = new EventEmitter<number>();

    billableProduction: BillableProduction = {};

    billableProductions: BillableProduction[];

    disableAddPayment: boolean = true;

    showPaymentForm: boolean = false;

    intJobNum: boolean = false;

    jobNumber: string;

    customerId: number;

    invoiceNumber: number;

    invoiceAmount: number;

    amount: number;

    formattedAmount: string;

    value: number = 0;

    showbillableprogress: boolean = false;

    msgs2: Message[] = [];


    constructor(private customerInvoiceService: CustomerInvoiceService, private service: MessageService, public snackbar: MatSnackBar, private contractService: ContractService,
        private route: Router, private employeeService: EmployeeService, private fb: FormBuilder, private billableproductionService: BillableProductionService,
        private _dateService: DateService, public dialogService: DialogService, private paymentservice: InvoicePaymentsServices, private currencyPipe: CurrencyPipe ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedWoId') {
                //this.onRowSelect(curVal);
                this.selectedWoId = curVal;
                this.getInvoicesbyWoId(this.selectedWoId);
                //console.log(this.selectedWoId);
            }

            if (propName === 'invoiceCreated') {
                //console.log(this.invoiceCreated);
                if (curVal == 'true') {
                    this.getInvoicesbyWoId(this.selectedWoId);
                }
                //console.log(this.selectedWoId);
            }

            //if (propName === 'selectedASupervisor') {
            //    //this.onRowSelect(curVal);
            //    this.selectedASupervisor = curVal;
            //    //console.log(this.selectedASupervisor);
            //}
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.userform = this.fb.group({
            'wo': new FormControl(''),
            'invoicenumber': new FormControl(''),
            'invoicedate': new FormControl(''),
            'invoicedue': new FormControl(''),
            'invoiceamount': new FormControl(''),
            'adjustment': new FormControl(''),
            'adjustmentreason': new FormControl(''),
            'revised': new FormControl(''),
            'final': new FormControl(''),
            //'orientationdate': new FormControl(''),
        });

        this.paymentform = this.fb.group({
            'invoicenumber': new FormControl(''),
            'jobid': new FormControl(''),
            'datereceived': new FormControl(''),
            //'invoicedue': new FormControl(''),
            'paymenttotal': new FormControl(''),
            'paymentfull': new FormControl(''),
            'customerid': new FormControl(''),
            'notes': new FormControl(''),
            //'orientationdate': new FormControl(''),
        });

        this.active = [
            { label: 'Yes', value: -1 },
            { label: 'No', value: 0 },
        ];

        this.adjustmentReason = [
            { label: 'Qty Misreport by Crew', value: 'Qty Misreport by Crew' },
            { label: 'Crew Failed to Report Production', value: 'Crew Failed to Report Production' },
            { label: 'Billing Code Misreported by Crew', value: 'Billing Code Misreported by Crew' },
            { label: 'Job Number Misreported by Crew', value: 'Job Number Misreported by Crew' },
            { label: 'PO Placeholder', value: 'PO Placeholder' },
            { label: 'Third-Party Invoice', value: 'Third- Party Invoice' },
            { label: 'Payment Difference', value: 'Payment Difference' },
            { label: 'Lump Sum Adjustment for Billing', value: 'Lump Sum Adjustment for Billing' },
            { label: 'Admin Error', value: 'Admin Error' },
            { label: 'Rejected by Customer', value: 'Rejected by Customer' },
            { label: 'Other - State in DFR Comments', value: 'Other - State in DFR Comments' },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intWoid', header: 'WO#' },
            { field: 'strInvoiceNo', header: 'Invoice #' },
            { field: 'dtmInvoiceDate', header: 'Invoice Date' },
            { field: 'dtmInvoiceDue', header: 'Invoice Due' },
            { field: 'curInvoiceTotal', header: 'Invoice Total' },
            { field: 'curAdjustment', header: 'Adjustment' },
            { field: 'ynFinal', header: 'Finaled' },
            { field: 'revised', header: 'Revised' },
        ];
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getInvoicesbyWoId(id): void {
        this.customerInvoiceService.getCustomerInvoicebyWoId(id)
            .subscribe(invoices => this.customerInvoices = invoices);
    }

    addCustomerInvoice(invoice): void {
        if (!invoice) { return; }
        this.customerInvoiceService.addCustomerInvoice(this.customerInvoice)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    addInvoicePayment(payment): void {
        if (!payment) { return; }
        this.paymentservice.addInvoicePayment(payment)
            .subscribe(data => {
                //customers.push(this.customer);
                //connsole.log(data);
                //this.service.add({ severity: 'success', summary: 'Payment Added', detail: 'Amount:' });
                this.getInvoicesbyWoId(this.selectedWoId);
            },             
                error => {
                    //in case of error, add the callback to bring the item back and re-throw the error.
                    //error.callback = () => this.data.splice(index, 0, item);
                    throw error;
                },
                () => {
                    // 'onCompleted' callback.
                    this.service.add({ severity: 'success', summary: 'Payment Added', detail: 'Amount:' + payment.paymentAmount });
                }
            );
    }

    updateCustomerInvoice(): void {
        //let customerInvoices = [...this.customerInvoices];
        //customerInvoices[this.customerInvoices.indexOf(this.selectedCustomer)] = this.customer;
        //this.customerName = this.customer.strCustomerName;
        this.customerInvoiceService.updateCustomerInvoice(this.customerInvoice.intInvoiceId, this.customerInvoice)

            .subscribe(data => {
                console.log(data);
                this.showSuccessUpdateViaToast();
                this.getInvoicesbyWoId(this.selectedWoId);

                //this.handleError("err:Test");
            });
    }

    deleteCustomerInvoice(id): void {
        this.customerInvoiceService.deleteCustomerInvoice(id)
            .subscribe(data => {
                this.showDeleteViaToast();
                this.getInvoicesbyWoId(this.selectedWoId);
            });
        
    }

    updateUnbilled(id, billableproduction, showtoast: boolean): void {
        //let crews = [...this.crews];
        //crews[this.crews.indexOf(this.selectedCrew)] = this.crew;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        this.billableproductionService.updateBillableProduction(id, billableproduction)

            .subscribe(data => {
                //this.invoiceDeleted.emit(1);
                //console.log(data);
                //this.ngOnInit();
                if (showtoast) {
                    //this.showSuccessUpdateViaToast();
                    //this.invoiceDeleted.emit(1);
                }
                //this.handleError("err:Test");
            });
        //if (!showtoast) {
        //    this.showSuccessUpdateViaToast();
        //}
    }

    async updateUnbilledPromise(id, billableproduction, showtoast: boolean): Promise<any> {
        var result = await this.billableproductionService.updateBillableProduction(id, billableproduction).toPromise();
        //this.invoiceDeleted.emit(1);
        if (showtoast) { }
        return result;
    }

    getBillableProductionbyWoId(id, invoiceid): void {
        this.billableproductionService.getBillableProductionbyWoId(id)
            .subscribe(bp => {
                this.billableProductions = bp;
                this.billableProductions = this.billableProductions.filter(bp => bp.strInvoiceNum == invoiceid);
                this.showbillableprogress = true;
                this.unbillProduction();
                //this.selectCrewTypes = crewtypes;
                //this.sortOrder = -1;
                //console.log(this.billableProductions);
            });
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    onClick() {
        this.showPaymentForm = true;
    }

    onRowSelect(rowdata) {
        this.disableAddPayment = false;
        //console.log(rowdata.data.intCustomerId);
        this.paymentform.get('invoicenumber').setValue(rowdata.data.strInvoiceNo);
        this.paymentform.get('paymentfull').setValue(0);
        this.intJobNum = rowdata.data.intJobNum;
        this.jobNumber = rowdata.data.intWoid;
        this.customerId = rowdata.data.intCustomerId;
        this.paymentform.get('datereceived').setValue(new Date());
        this.invoiceNumber = rowdata.data.strInvoiceNo;
        this.invoiceAmount = rowdata.data.curInvoiceTotal;


    }

    showDialogToAdd() {
        this.newInvoice = true;
        this.customerInvoice = {};
        this.displayDialog = true;
    }

    save() {
        let customerinvoices = [...this.customerInvoices];
        this.customerName = this.customerInvoice.strInvoiceNo;
        this.showSuccessUpdateViaToast();
        if (this.newInvoice) {
            this.addCustomerInvoice(customerinvoices);
            customerinvoices.push(this.customerInvoice);

            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        }
        else {
            this.updateCustomerInvoice();
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        this.customerInvoice = null;
        this.displayDialog = false;
    }

    onRowUnselect() {
        this.disableAddPayment = true;

      
    }

    //onRowSelect(event) {
    //    //this.displayContract = !this.displayContract;
    //    this.displayContract = true;
    //    var cont = this.allcontracts;
    //    if (event.data.intCustomerKey) {
    //        cont = cont.filter(v => v.intCustomerId == event.data.intCustomerKey);
    //        this.selectedCustomerId = event.data.intCustomerKey;
    //    }
    //    this.contracts = cont;
    //    //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.selectedCustomerId });
    //    //this.newCustomer = false;
    //    //this.customer = this.cloneCustomer(event.data);
    //    ////console.log(this.tbUsers.selection);
    //    //this.displayDialog = true;
    //}

    cloneInvoice(c: CustomerInvoice): CustomerInvoice {
        let contract = {};
        for (let prop in c) {
            contract[prop] = c[prop];
        }
        return contract;
    }

    cancel() {
        this.displayDialog = false;
    }

    onPaymentcancel() {
        this.showPaymentForm=false;
    }

    onCancelContract() {
        this.displayContractDialog = false;
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Added', detail: 'Invoice Added'});
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: ' Updated', detail: 'Invoice Update'});
    }

    showDeleteViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Invoice Deleted', detail:''  });
    }

    editCustomer(rowdata) {
        this.newInvoice = false;
        this.customerInvoice = this.cloneInvoice(rowdata);
       
        this.displayDialog = true;
        this.userform.get('wo').setValue(this.customerInvoice.intWoid);
        this.userform.get('final').setValue(this.customerInvoice.ynFinal);
        this.userform.get('invoicenumber').setValue(this.customerInvoice.strInvoiceNo);
        this.userform.get('invoiceamount').setValue(this.customerInvoice.curInvoiceTotal.toLocaleString('en-US', { minimumFractionDigits: 2 }));
        this.userform.get('adjustment').setValue(this.customerInvoice.curAdjustment);
        this.userform.get('revised').setValue(this.customerInvoice.revised);
        this.userform.get('adjustmentreason').setValue(this.customerInvoice.strAdjustmentReason);
        if (this.customerInvoice.dtmInvoiceDate !== null) {
            this.userform.get('invoicedate').setValue(new Date(this.customerInvoice.dtmInvoiceDate));
        }
        if (this.customerInvoice.dtmInvoiceDue !== null) {
            this.userform.get('invoicedue').setValue(new Date(this.customerInvoice.dtmInvoiceDue));
        }
        //this.userform.controls['wo'].disable();
        //this.userform.controls['invoicenumber'].disable();
        //this.userform.controls['invoiceamount'].disable();
        //this.userform.controls['invoicedate'].disable();
        //this.userform.controls['invoicedue'].disable();
        //this.userform.controls['invoiceamount'].disable();
    }

    async deleteInvoice() {
        //this.invoiceDeleted.emit(0);
        this.newInvoice = false;
        //this.customerInvoice = this.cloneInvoice(rowdata);
        this.deleteCustomerInvoice(this.customerInvoice.intInvoiceId);
        //this.getInvoicesbyWoId(this.selectedWoId);
        //this.getBillableProductionbyWoId(this.selectedWoId, this.customerInvoice.strInvoiceNo);
       
       
       
    }

    async deleteBilledInvoice() {

        const deleteinvoice = await this.deleteInvoice();
       
        this.getInvoicesbyWoId(this.selectedWoId);
        this.getBillableProductionbyWoId(this.selectedWoId, this.customerInvoice.strInvoiceNo)
        //this.showbillableprogress = false;
        //this.msgs2.push({
        //    severity: 'success',
        //    summary: 'Invoice Deleted',
        //    detail: this.customerInvoice.intWoid + '-' + this.customerInvoice.strInvoiceNo + ' Invoice Amount: ' + this.formatter.format(this.customerInvoice.curInvoiceTotal)
        //});
        //this.showbillableprogress = false;
        //this.invoiceDeleted.emit(0);
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    printDFR(rowdata) {
        this.displayReport = true;
        this.selectedInvoiceId = rowdata.intInvoiceId;
        this.selectedReportName = "Infratrac/Invoice.trdp";
        //console.log(rowdata.intInvoiceId);
    }

    async unbillProduction() {
        this.value = 0;
        //this.invoiceCreated.emit(false);    

        //console.log('unbilled');
        //console.log(this.padLeft(newinvoicenum.toString(), '0', 3));  // '000123')
        //console.log((String(0).repeat(3 - parseInt(this.newInvoiceNo) + 1).length) + this.newInvoiceNo));
        //let invoicenum = this.newInvoiceNo

        if (Array.isArray(this.billableProductions) && (this.billableProductions.length)) {
            let billed = [...this.billableProductions];

            for (let i = 0; i < billed.length; i++) {
                //if (formatDate(payrollhours[i].dtDate, format, locale) != formatDate(date, format, locale))
                //console.log(formatDate(payrollhours[i].dtDate, format, locale));
                //console.log(formatDate(date, format, locale));
                let data = billed[i];
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).strStatus = 0;
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).dtmInvoiceDate = null;
                this.billableProductions.find(b => b.intProductionId == data.intProductionId).strInvoiceNum = null;
                let results = await this.updateUnbilledPromise(billed[i].intProductionId, data, true);
                //console.log(this.value);
                this.value = Math.round((i / billed.length) * 100) ;
                if (this.value >= 99) {
                    this.value = 100;
                }

                //console.log(unbilled[i].intProductionId);


                //this.updatePayrollHour(payrollhours[i].intWorkId, payrollhours[i], this.insertedDfrId, false);

            }

            this.msgs2.push({
                severity: 'success',
                summary: 'Invoice Deleted: '+ this.customerInvoice.intWoid + '-' + this.customerInvoice.strInvoiceNo,
                detail:  ' Invoice Amount: ' + this.formatter.format(this.customerInvoice.curInvoiceTotal)
            });

            this.showbillableprogress = false;
            this.invoiceDeleted.emit(1);

        }

        //this.invoiceDeleted.emit(1);
     
    }

    deleteInvoiceConfirm(rowdata) {
        this.customerInvoice = this.cloneInvoice(rowdata);
        this.service.add({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Invoice?',
            //detail: this.deletedQty + ' of ' + this.deletedBc + ' : ' + this.deletedBcDescription
        });

    }

    onConfirm() {
        this.service.clear('c');
        this.showbillableprogress = true;
        this.deleteBilledInvoice();
    }

    onReject() {
        this.service.clear('c');
        this.customerInvoice = {};
    }

    onSubmit(value: string) {
        //this.submitted = true;

        this.customerInvoice.ynFinal = this.userform.get('final').value;
        this.customerInvoice.curAdjustment = this.userform.get('adjustment').value;
        this.customerInvoice.revised = this.userform.get('revised').value;
        this.customerInvoice.strAdjustmentReason = this.userform.get('adjustmentreason').value;

        this.updateCustomerInvoice();
        
        this.displayDialog = false;
        this.customerInvoice = {};
        //this.userform.controls['task'].enable();
        //this.userform.controls['crew'].enable();
        //this.userform.controls['foreman'].enable();
        this.userform.reset();
    }

    show(rowdata) {
        const ref = this.dialogService.open(InvoicePaymentListComponent, {
            data: {
                id: rowdata.intJobNum,
                invoicenum: rowdata.strInvoiceNo,
                invoiceamount: rowdata.curInvoiceTotal
            },
            header: 'Invoice Payments' ,
            width: '70%',
            contentStyle: { "max-height": "350px", "overflow": "auto" }
        });

        ref.onClose.subscribe((payment: invoicepayments) => {
            if (payment) {
                this.service.add({ severity: 'info', summary: 'Invoice Payment Deleted', detail: 'Amount:' + payment.paymentAmount});
            }
        });
    }

    onPaymentSubmit() {

        this.invoicePayment.customerId = this.customerId;
        this.invoicePayment.invoiceNumber = this.paymentform.get('invoicenumber').value;
        this.invoicePayment.jobId = this.intJobNum;
        this.invoicePayment.notes = this.paymentform.get('notes').value;
        this.invoicePayment.paymentAmount = this.paymentform.get('paymenttotal').value;
        this.invoicePayment.paymentInFull = this.paymentform.get('paymentfull').value;
        this.invoicePayment.dateRec = this._dateService.toString(this.paymentform.get('datereceived').value);

        this.addInvoicePayment(this.invoicePayment);

        this.showPaymentForm = false;
    }

    transformAmount(element) {
        this.amount = element.target.value.replace(/[$,]/g, "");
        this.formattedAmount = this.currencyPipe.transform(this.amount, '$')

        element.target.value = this.formattedAmount;
    }



    onSave() {
        //let crews = [...this.crews];
        //this.crewName = this.crew.strCrewId;
        //let existItem = crews.filter(item => item.strCrewId === this.crew.strCrewId);
        //if (this.newCrew) {
        //    if (existItem.length >= 1) {
        //        this.service.add(
        //            { key: 'tst', severity: 'warn', summary: 'Duplicate Crew Name', detail: this.crewName });
        //    }
        //    else {
        //        this.addCrew(crews);
        //        crews.push(this.crew);
        //        this.crew = null;
        //        this.displayDialog = false;
        //        this.crews = crews;
        //    }
        //    //this.customerService.addCustomer(this.customer)
        //    //    .subscribe(data => {
        //    //        customers.push(this.customer);
        //    //        console.log(data);
        //    //        this.ngOnInit();
        //    //        this.showSuccessViaToast();
        //    //    });
        //}
        //else {
        //    this.updateCrew();
        //    this.crew = null;
        //    this.displayDialog = false;
        //    this.crews = crews;
        //    //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
        //    //this.customerName = this.customer.strCustomerName;
        //    //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

        //    //    .subscribe(data => {
        //    //        console.log(data);
        //    //        this.ngOnInit();
        //    //        this.showSuccessUpdateViaToast();
        //    //        //this.handleError("err:Test");
        //    //    });
        //}
        ////this.crews = crews;
        ////this.crew = null;
        ////this.displayDialog = false;
    }
}
