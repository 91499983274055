import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerService } from '../service/customerservice';
import { Customer } from '../domain/customer'
import { HttpClientModule } from '@angular/common/http';
import { SelectItem } from 'primeng/primeng';
import { NgForm } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Contract } from '../domain/contract'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { DateService } from '../../_services/date.service';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';
@Component({
    selector: 'app-contract-child',
    templateUrl: './contract.component.html',
    styles: [
        `
        //.ui-dataview .search-icon {
        //    margin-top: 3em;
        //}

        //.ui-dataview .filter-container {
        //    text-align: center;
        //}

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

        //    .ui-dataview .filter-container {
        //        text-align: left;
        //    }
        //}
        //.customer-item {
        //    padding-top: 5px;
        //}

        //.customer-item .ui-md-3 {
        //    text-align: center;
        //}

        //.customer-item .ui-g-10 {
        //    font-weight: bold;
        //}

        //.empty-customer-item-index {
        //    background-color: #f1f1f1;
        //    width: 60px;
        //    height: 60px;
        //    margin: 36px auto 0 auto;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-image {
        //    background-color: #f1f1f1;
        //    width: 120px;
        //    height: 120px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.empty-customer-item-text {
        //    background-color: #f1f1f1;
        //    height: 18px;
        //    animation: pulse 1s infinite ease-in-out;
        //}

        //.title-container {
        //    padding: 1em;
        //    text-align: right;
        //}

        //.sort-container {
        //    text-align: left;
        //}

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class ContractChildComponent implements OnInit {
    customer: Customer = {};

    customers: Customer[];

    employees1 = [
        { "strEmployeeId": 1, "strLastName": "kiran", "strFirstName": "Account" },
        { "strEmployeeId": 2, "strLastName": "Wilson", "strFirstName": "HR" },

    ];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    contract: Contract = {};

    allcontracts: Contract[];

    employees: Employee[];

    employeesAssigned: Employee[];

    employeeAssigned: Employee = {};

    cols: any[];

    contractcols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    addClick = true;

    active: SelectItem[];

    activeContract: SelectItem[];

    displayDialog: boolean;

    selectedCustomer: Customer;

    newCustomer: boolean;

    newContract: boolean;

    activeFilter: SelectItem[];

    contractActiveSel: SelectItem[];

    customerName = "Test";

    contractName = "";

    filter: any = {};

    displayContract: boolean = false;

    displayContractDialog: boolean = false;

    selectedActive: string = "-1";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];

    @Input() selectedCustomerId: string;

    contracts: Contract[];

    customerId: string;

    contractStartDate: Date;

    contractEndDate: Date;

    sContractStartDate: Date;

    sContractEndDate: Date;

    selectedEmployees: any[];

    employee: any;

    employeeID: string;

    employeesResult: Employee[];

    selectedContractMembers: any[];

    newContractMember: boolean;

    targetEmployees: Employee[];

    sourceEmployees: Employee[];

    clonedSourceEmployees: Employee[];

    contractMembers: Employee[];

    constructor(private customerService: CustomerService, private service: MessageService, public snackbar: MatSnackBar, private contractService: ContractService,
        private route: Router, private employeeService: EmployeeService, private _dateService: DateService, private supervisorService: SupervisorService, ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let change = changes[propName];
            let curVal = JSON.stringify(change.currentValue);
            let prevVal = JSON.stringify(change.previousValue);

            //console.log(curVal);
            //console.log(prevVal);

            if (propName === 'selectedCustomerId') {
                //this.onRowSelect(curVal);
                this.customerId = curVal;
                this.getContracts(this.customerId);
            }
            //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer ID', detail: curVal });
        }

        // changes.prop contains the old and the new value...
    }

    ngOnInit() {
        this.getContracts(this.customerId);

        this.getEmployees();

        this.getCustomers();

        this.getActiveSupervisors();

        this.getEmployeesbyStatus();

        //this.onRowSelect(this.customerId);

        this.contractActiveSel = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
        ];

        this.active = [
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.activeContract = [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: '-1' },
            { label: 'No', value: '0' },
        ];

        this.cols = [
            { field: 'intCustomerKey', header: 'Customer ID' },
            { field: 'strCustomerName', header: 'Customer' },
            { field: 'strContactLastname', header: 'Last Name' },
            { field: 'strContactFirstName', header: 'First Name' },
            { field: 'strAttn', header: 'Contact' },
            { field: 'strPaymentTerms', header: 'Pay Terms' },
            { field: 'ysnActive', header: 'Active' },
        ];

        this.contractcols = [
            { field: 'intContractId', header: 'Id' },
            { field: 'strContractNo', header: 'Contract#' },
            { field: 'strContractName', header: 'Contract Name' },
            { field: 'dtmStartDate', header: 'Start Date' },
            { field: 'dtmEndDate', header: 'End Date' }

        ];

        this.targetEmployees = [];
        this.contractMembers = [];
    }

    getEmployeesbyStatus(): void {
        this.employeeService.getEmployeesbyStatus('active')
            .subscribe(employees => this.sourceEmployees = employees.sort(this.dynamicSort('strLastName')));
    }

    getEmployeeById(id): void {
        if (id) {
            this.employeeService.getEmployeesbyID(id)
                .subscribe(employees => {
                    this.employeesAssigned = employees;
                    if (this.contracts.length > 0) {
                        this.employeeAssigned = employees.find(e => e.strEmployeeId == id);
                        this.contractMembers.push(this.employeeAssigned);
                    }
                });
        }
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getCustomers(): void {
        this.customerService.getCustomers()
            .subscribe(customers => this.customers = customers);
    }

    addContract(contract): void {
        if (!contract) { return; }
        this.contractService.addContract(this.contract)
            .subscribe(data => {
                //console.log(data);
                this.showSuccessViaToast();
                this.ngOnInit();
            });
    }

    getActiveSupervisors(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(supervisors => {
                this.supervisors = supervisors.sort(this.dynamicSort('name'));
            });
    }

    updateContract(): void {
        this.contractName = this.contract.strContractNo;
        this.contractService.updateContract(this.contract.intContractId, this.contract)

            .subscribe(data => {
                console.log(data);
                this.showSuccessUpdateViaToast();
                this.ngOnInit();
            });
    }

    getContracts(customerId): void {
        this.contractService.getContracts()
            .subscribe(contracts => {
                this.contracts = contracts.filter(v => v.intCustomerId == customerId);
            });
    }

    sDatetoString(event) {
        const value = event.target.value;
        this.sContractStartDate = value;

        this.contract.dtmStartDate = this._dateService.toString(this.sContractStartDate);
    }

    eDatetoString(value) {
        this.sContractEndDate = value;

        this.contract.dtmEndDate = this._dateService.toString(this.sContractEndDate);
    }

    onSelect(value) {
        this.sContractStartDate = value;

        this.contract.dtmStartDate = this._dateService.toString(this.sContractStartDate);
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onClickAdd() {
        this.addClick = false;
    }

    showDialogToAdd() {
        this.newCustomer = true;
        this.customer = {};
        this.displayDialog = true;
    }

    showDialogToAddContract() {
        this.newContract = true;
        this.contract = {};
        this.contract.intCustomerId = this.selectedCustomerId;
        this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.clonedSourceEmployees = this.sourceEmployees;
        this.displayContractDialog = true;
    }

    onSaveContract() {
        let contracts = [...this.contracts];
        this.contractName = this.contract.strContractName;
        let contractmembers = [...this.contractMembers];
        let newcontractmembers = "";
        contractmembers.forEach(employee => {
            newcontractmembers += employee.strEmployeeId + ",";
        })
        if (this.newContract) {
            this.contract.strAssignedTo = newcontractmembers;
            this.addContract(contracts);
            contracts.push(this.contract);
        }
        else {
            this.contract.strAssignedTo = newcontractmembers;
            this.updateContract();
        }

        this.contracts = contracts;
        this.contract = null;
        this.displayContractDialog = false;
        this.contractMembers = [];
    }

    onRowUnselect() {
        this.displayContract = false;
    }

    onRowSelect(customerId) {
        this.displayContract = !this.displayContract;
        this.displayContract = true;
        var cont = this.contracts;
        if (customerId) {
            cont = cont.filter(v => v.intCustomerId == customerId);
        }
        this.contracts = cont;
    }

    onContractChange() {
        var cont = this.allcontracts;
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.selectedCustomerId });
        cont = cont.filter(v => v.intCustomerId == this.selectedCustomerId);

        this.contracts = cont;
    }

    cloneCustomer(c: Customer): Customer {
        let customer = {};
        for (let prop in c) {
            customer[prop] = c[prop];
        }
        return customer;
    }

    cloneContract(c: Contract): Contract {
        let contract = {};
        for (let prop in c) {
            contract[prop] = c[prop];
        }
        return contract;
    }

    cancel() {
        this.displayDialog = false;
    }

    onCancelContract() {
        this.displayContractDialog = false;
        this.contractMembers = [];
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Contract Added', detail: this.contractName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Contract Updated', detail: this.contractName });
    }

    editCustomer(rowdata) {
        this.newCustomer = false;
        this.customer = this.cloneCustomer(rowdata);
        this.displayDialog = true;
    }

    editContract(rowdata) {
        this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        this.newContract = false;
        this.contract = this.cloneContract(rowdata);

        this.contractStartDate = new Date(this.contract.dtmStartDate);
        this.contractEndDate = new Date(this.contract.dtmEndDate);
        if (this.contract.strAssignedTo) {
            this.selectedEmployees = this.contract.strAssignedTo.split(',');
            this.selectedContractMembers = this.pushCrewMembers(this.selectedEmployees)
        }

        this.clonedSourceEmployees = this.sourceEmployees;
        this.displayContractDialog = true;
        console.log(this.contract);
    }

    pushCrewMembers(filteredemployees: any[]) {
        let filtered: any[] = [];

        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            this.getEmployeeById(employee);
        }
        return filtered;
    }

    addExistingCrewMemberstoPicklist() {
        if (this.contractMembers) {
            let updatedcrew = "";
            this.newContractMember = false;
            this.contractMembers.forEach(member => {
                this.targetEmployees.push(member);
            })
        }
        else {
            //this.showSuccessViaToast();
            this.newContractMember = true;
        }
    }

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    searchEmps(event): void {
        this.employeesResult = this.employees.filter(c => c.strLastName.toLowerCase().startsWith(event.query.toLowerCase()));
        //this.employeesResult = this.employees1.filter(c => c.strLastName.startsWith(event.query));
    }

    captureId(event: any) {
        if (this.employeeID) {
            //console.log(this.employeeID);
            this.employeeID = this.employeeID + ',' + event.strEmployeeId;
            //console.log(this.employeeID);
            //this.contract.strAssignedTo = this.employeeID.toString();
        } else {
            //this.employeeID = event.strEmployeeId;
            //console.log(this.employeeID);
            this.employeeID = event.strEmployeeId;
            //console.log(this.employeeID);
        }
    }
}
