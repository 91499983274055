import { Component, OnInit, ViewEncapsulation, ViewChild, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService, SelectItem, MenuItem } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractService } from '../service/contractservice';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../service/employeeservice';
import { Employee } from '../domain/employee';
import { LinemanToolPurchase } from '../domain/linemantoolpurchase';
import { LinemanToolVendor } from '../domain/linemantoolvendor';
import { PositionService } from '../service/positionservice';
import { Position } from '../domain/position';
import { Validators, FormControl, FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { DateService } from '../../_services/date.service';
import { Observable, Subject } from 'rxjs';
import { PayRollHourService } from '../service/payrollhourservice';
import { LinemanToolPurchasesServices } from '../service/linemantoolpurchaseservice';
import { LinemanToolVendorServices } from '../service/linemantoolvendorservice';
import { PayRollHour } from '../domain/payrollhour';
import { UserPermissionService } from '../service/userpermissionsservice';
import { UserPermission } from '../domain/userpermission';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { UserAuthentication } from '../domain/userauthentication';
import { UserAuthService } from '../service/userauthservice';
import { Supervisor } from '../domain/supervisor';
import { SupervisorService } from '../service/supervisorservice';

@Component({
    templateUrl: './employee.component.html',
    styles: [
        `
        .ui-dataview .search-icon {
            margin-top: 3em;
        }

        .ui-dataview .filter-container {
            text-align: center;
        }

        @media (max-width: 40em) {
            .ui-dataview .customer-details, .ui-dataview .search-icon{
                text-align: center;
                margin-top: 0;
            }

            .ui-dataview .filter-container {
                text-align: left;
            }
        }
        .customer-item {
            padding-top: 5px;
        }

        .customer-item .ui-md-3 {
            text-align: center;
        }

        .customer-item .ui-g-10 {
            font-weight: bold;
        }

        .empty-customer-item-index {
            background-color: #f1f1f1;
            width: 60px;
            height: 60px;
            margin: 36px auto 0 auto;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-image {
            background-color: #f1f1f1;
            width: 120px;
            height: 120px;
            animation: pulse 1s infinite ease-in-out;
        }

        .empty-customer-item-text {
            background-color: #f1f1f1;
            height: 18px;
            animation: pulse 1s infinite ease-in-out;
        }

        .title-container {
            padding: 1em;
            text-align: right;
        }

        .sort-container {
            text-align: left;
        }

        @media (max-width: 40em) {
            .customer-item {
                text-align: center;
            }
            .index-col {
                display: none;
            }
            .image-col {
                display: none;
            }
        }
        @keyframes pulse {
            0% {
                background-color: rgba(165, 165, 165, 0.1)
            }
            50% {
                background-color: rgba(165, 165, 165, 0.3)
            }
            100% {
                background-color: rgba(165, 165, 165, 0.1)
            }
        }
    `
    ],
    providers: [MessageService]
})
export class EmployeeComponent implements OnInit {
    position: Position = {};

    positions: Position[];

    filteredpositions: Position[];

    classBPositions: Position[];

    employees: Employee[];

    loggedInUser: Employee = {} = {};

    loggedInUsers: Employee[];

    employee: Employee = {};

    payrollHours: PayRollHour[];

    linemanToolPurchases: LinemanToolPurchase[];

    linemanToolVendors: LinemanToolVendor[];

    supervisor: Supervisor = {};

    supervisors: Supervisor[];

    cols: any[];

    payrollcols: any[];

    linemantoolPurccols: any[];

    events: any[];

    sortKey: string;

    sortField: string;

    sortOrder: number;

    timeout: any;

    values: any[];

    active: SelectItem[];

    selectedEmployee: Employee;

    activeFilter: SelectItem[];

    perdiem: SelectItem[];

    txType: SelectItem[];

    filter: any = {};

    displayEmployeeDialog: boolean = false;

    displayEmployeeDetails: boolean = false;

    newEmployee: boolean;

    selectedActive: string = "Active";

    filteredAreaSuper: Employee[];

    filteredActiveEmployees: Employee[];   

    selectedEmployeeId: string = "";

    userform: FormGroup;

    submitted: boolean;

    genders: SelectItem[];

    description: string;

    hireDate: Date;

    termDate: Date;

    rehireDate: Date;

    orientationDate: Date;

    bootPurchaseDate: Date;

    glassesPurchaseDate: Date;

    benefits: SelectItem[];

    workType: any[];

    viewRate: boolean = false;

    employeeName: string;

    yearFilter: number;

    yearTimeout: any;

    visible: boolean = true;

    orientationDone: boolean = false;

    userpermission: UserPermission = {} = {};

    userpermissions: UserPermission[];

    userauth: UserAuthentication = {} = {};

    userauths: UserPermission[];

    userEmail: string;

    userId: string;

    isAdmin: boolean;

    isAuthenticated: boolean;

    canEdit: boolean = false;

    canAdd: boolean = false;

    newAuth: boolean = false;

    canViewEmployeeCounseling: boolean = false;

    items: MenuItem[];

    displayReport: boolean = false;

    selectedDfrId: string = null;

    selectedReportName: string = null;

    private searchTerms = new Subject<string>();

    emitSave: boolean = false;

    emitNewEmployee: boolean = false;

    canViewTraining: boolean = false;

    isRP: boolean = false;

    constructor(private service: MessageService,
        public snackbar: MatSnackBar,
        private route: Router,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private payRollHourService: PayRollHourService,
        private supervisorService: SupervisorService,
        private fb: FormBuilder,
        private _dateService: DateService,
        private linemanToolVendorService: LinemanToolVendorServices,
        private linemanToolPurchaseService: LinemanToolPurchasesServices,
        private userpermissionservice: UserPermissionService,
        private userauthservice: UserAuthService,
        private adalSvc: MsAdalAngular6Service) {
        var token = this.adalSvc.acquireToken('http://adal.resource.com').subscribe((token: string) => {
            //console.log(token);
            this.userEmail = this.adalSvc.LoggedInUserEmail;
            this.isAuthenticated = this.adalSvc.isAuthenticated;

            //console.log('constructor')
            //if (this.userpermission.intAdmin == 1) {
            //    this.isAdmin = true;
            //} else {
            //    this.isAdmin = false;
            //}
        });

        this.getEmployeesbyEmail();
    }

    search(term: string): void {
        this.searchTerms.next(term);
    }

    //ngAfterViewChecked() {
    //    this.items = [

    //        {
    //            label: 'Tool Bank Qtr Balance', icon: 'pi pi-print', command: () => {
    //                this.printQtrToolBankBalance();
    //            }
    //        },
    //        {
    //            label: 'Tool Bank Balance', icon: 'pi pi-print', command: () => {
    //                this.printToolBankBalance();
    //            }
    //        },
    //        {
    //            label: 'Employee Counseling', icon: 'pi pi-print', visible: this.canViewEmployeeCounseling, command: () => {
    //                this.printEmployeeCounseling();
    //            }
    //        },
    //        {
    //            label: 'Employee Training', icon: 'pi pi-print', command: () => {
    //                this.printEmployeeTraining();
    //            }
    //        },
    //    ];
    //}

    ngOnInit() {
        this.userform = this.fb.group({
            'firstname': new FormControl('', Validators.required),
            'lastname': new FormControl('', Validators.required),
            'notes': new FormControl(''),
            'active': new FormControl('', Validators.required),
            'position': new FormControl(''),
            'perdiem': new FormControl(''),
            'hiredate': new FormControl(''),
            'termdate': new FormControl(''),
            'benefit': new FormControl(''),
            'rehiredate': new FormControl(''),
            'email': new FormControl(''),
            'crew': new FormControl(''),
            'payrate': new FormControl(''),
            'orientationdone': new FormControl(''),
            'orientationdate': new FormControl(''),
            'bootpurchasedate': new FormControl(''),
            'glassespurchasedate': new FormControl(''),
            'username': new FormControl(''),
            'password': new FormControl(''),
            'minitial': new FormControl(''),
            'area': new FormControl(''),
            'worktype': new FormControl('')
        });

        //this.items = [

        //    {
        //        label: 'Tool Bank Qtr Balance', icon: 'pi pi-print', command: () => {
        //            this.printQtrToolBankBalance();
        //        }
        //    },
        //    {
        //        label: 'Tool Bank Balance', icon: 'pi pi-print', command: () => {
        //            this.printToolBankBalance();
        //        }
        //    },
        //    {
        //        label: 'Employee Counseling', icon: 'pi pi-print', visible: this.canViewEmployeeCounseling, command: () => {
        //            this.printEmployeeCounseling();
        //        }
        //    },
        //    {
        //        label: 'Employee Training', icon: 'pi pi-print', command: () => {
        //            this.printEmployeeTraining();
        //        }
        //    },
        //];

       

        if (this.selectedActive == 'Active') {
            this.getEmployeesbyStatus('Active');
        } else if (this.selectedActive == 'InActive') {
            this.getEmployeesbyStatus('InActive');
        } else {
            this.getEmployees();
        }

        this.getPositions();
        this.getAreas();

        //this.getEmployees();

        //this.getEmployeesbyStatus('active');

        this.active = [
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'Inactive' },
        ];

        this.txType = [
            { label: 'All', value: null },
            { label: 'Deposit', value: '1' },
            { label: 'Withdraw', value: '2' },
        ];

        this.benefits = [
            { label: 'A', value: 1 },
            { label: 'B', value: 2 },
            { label: 'C', value: 3 },
            { label: 'D', value: 4 },
            { label: 'Z', value: 5 },
        ];

        this.activeFilter = [
            { label: 'All', value: null },
            { label: 'Yes', value: 'Active' },
            { label: 'No', value: 'InActive' },
        ];

        this.perdiem = [
            //{ label: '$0', value: null },
            //{ label: '$15', value: '9' },
            //{ label: '$25', value: '1' },
            //{ label: '$35', value: '3' },
            { label: '$40', value: '11' },
            //{ label: '$45', value: '8' },
            { label: '$50', value: '7' },
            //{ label: '$65', value: '2' },
            { label: '$75', value: '15' },
            //{ label: '$85', value: '5' },
            { label: '$90', value: '6' },
            { label: '$100', value: '4' },
            //{ label: '$120', value: '10' },                               
            { label: '$125', value: '12' },
            { label: '$150', value: '13' },
            { label: '$140', value: '14' },
            { label: '$200', value: '16' },
           
            
            
           
        ];

        this.workType = [           
            { label: 'URD', value: 'URD' },
            { label: 'OH', value: 'OH'},
            { label: 'Streetlight', value: 'Streetlight' },
            { label: 'Gas', value: 'Gas' },            
        ];

        this.cols = [
            { field: 'strEmployeeId', header: 'Employee ID' },
            { field: 'strLastName', header: 'Last Name' },
            { field: 'strFirstName', header: 'First Name' },
            { field: 'dtmHireDate', header: 'Hire Date' },
            { field: 'intPosition', header: 'Position' },
            { field: 'strCrew', header: 'Crew' },
            { field: 'ysnActive', header: 'Active' },
        ];

        this.payrollcols = [
            { field: 'dtDate', header: 'Date' },
            { field: 'intReportId', header: 'DFR#' },
            { field: 'intCrew', header: 'Crew' },
            { field: 'intHoursWorked', header: 'Hours' },
            { field: 'intHoursVac', header: 'Vacation' },
            { field: 'intHoursPto', header: 'PTO' },
            { field: 'intHoursHol', header: 'Hol' },
            { field: 'ysnPerDiem', header: 'PerDiem' },
            { field: 'isTabletRow', header: 'Tablet Entry' },
            { field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];

        this.linemantoolPurccols = [
            { field: 'dtmDate', header: 'Date' },
            { field: 'intTxtype', header: 'TX Type' },
            { field: 'intVendor', header: 'Vendor' },
            { field: 'strPono', header: 'PO #' },
            { field: 'curPoamnt', header: 'PO Amount' },
            { field: 'strInvNo', header: 'Invoice #' },
            { field: 'curInvAmnt', header: 'Invoice Amount' },
            { field: 'curDeposit', header: 'TX Amount' },
            //{ field: 'isTabletRow', header: 'Tablet Entry' },
            //{ field: 'strComments', header: 'Comments' },
            //{ field: 'strFirstName', header: 'First Name' },
            //{ field: 'dtmHireDate', header: 'Hire Date' },
            //{ field: 'intPosition', header: 'Position' },
            //{ field: 'strCrew', header: 'Crew' },
            //{ field: 'ysnActive', header: 'Active' },
        ];
    }

    getEmployeesbyEmail(): void {
        this.employeeService.getEmployeesbyEmail(this.userEmail)
            .subscribe(employees => {
                this.loggedInUsers = employees
                //console.log(this.userEmail);
                this.loggedInUser = employees.find(e => e.strEmail.toLowerCase() == this.userEmail.toLowerCase());
                this.userId = this.loggedInUser.strEmployeeId;
                this.getUserPermissions(this.userId);
            });
    }



    getEmployeeAuth(id): void {
        this.userauthservice.getAuthId(id)
            .subscribe(auths => {
                this.userauths = auths
                if (this.userauths.length > 0) {
                    this.userauth = auths.find(e => e.fkEmpId == id);
                    //console.log(this.userauth);
                    //console.log(this.userauths);
                    if (this.userauth) {
                        this.userform.get('username').setValue(this.userauth.userId);
                        this.userform.get('password').setValue(this.userauth.password);
                        this.newAuth = false;
                    } else {
                        //this.newAuth = true;
                    }

                } else {
                    this.newAuth = true;
                }
            });
    }

    getUserPermissions(id): void {
        this.userpermissionservice.getPermissionbyId(id)
            .subscribe(permissions => {
                this.userpermissions = permissions;

                this.userpermission = this.userpermissions.find(p => p.fkEmployeeId == id);

                if (this.userpermission.employeeCounselingView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewEmployeeCounseling = true;
                } else {
                    this.canViewEmployeeCounseling = false;
                }
                if (this.userpermission.trainingView == 1 || this.userpermission.intAdmin == 1) {
                    this.canViewTraining = true;
                } else {
                    this.canViewTraining = false;
                }

                if (this.userpermission.intAdmin == 1) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }

                if (this.userpermission.employeeEdit == 1) {
                    this.canEdit = true;
                } else {
                    this.canEdit = false;
                }

                if (this.userpermission.employeeCreate == 1) {
                    this.canAdd = true;
                } else {
                    this.canAdd = false;
                }

                if (this.userpermission.intPayRate == 1) {
                    this.viewRate = true;
                } else {
                    this.viewRate = false;
                }

               

            });

        this.items = [

            {
                label: 'Tool Bank Qtr Balance', icon: 'pi pi-print', command: () => {
                    this.printQtrToolBankBalance();
                }
            },
            {
                label: 'Tool Bank Balance', icon: 'pi pi-print', command: () => {
                    this.printToolBankBalance();
                }
            },
            //{
            //    label: 'Employee Counseling', icon: 'pi pi-print', visible: this.canViewEmployeeCounseling, command: () => {
            //        this.printEmployeeCounseling();
            //    }
            //},
            {
                label: 'Employee Training', icon: 'pi pi-print', command: () => {
                    this.printEmployeeTraining();
                }
            },
            {
                label: 'Absentee Report', icon: 'pi pi-print', command: () => {
                    this.displayReport = true;
                    this.selectedReportName = "Employee/Employee Absentee Report.trdp";
                }
            },
            {
                label: 'NP Report', icon: 'pi pi-print', command: () => {
                    this.displayReport = true;
                    this.selectedReportName = "Employee/Employee Hours NP Report.trdp";
                }
            },
        ];
    }

    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    onRowUnselect() {
        //this.payrollHours = [];
        this.linemanToolPurchases = [];
        this.displayEmployeeDetails = false;
    }

    onRowSelect(event) {
        if (event.data.strEmployeeId) {
            this.emitSave = false;
            this.emitNewEmployee = false;
            this.selectedEmployeeId = event.data.strEmployeeId;
            //this.getEmployeeHours(this.selectedEmployeeId);
            this.getLinemanToolPurchases(this.selectedEmployeeId);
            this.displayEmployeeDetails = true;
        }
    }

    onActiveChange(event) {
        console.log(event.value);
        if (event.value = 'active') {
            this.getEmployeesbyStatus('active');
        } else if (event.value = 'inactive') {
            this.getEmployeesbyStatus('inActive');
        } else {
            this.getEmployees();
        }

        this.updateVisibility();
    }

    onSelect(value) {
        //const value = event.target.value;
        this.hireDate = value;
        //console.log(element.getAttribute('formControlName'));
        this.employee.dtmHireDate = this._dateService.toString(this.hireDate);
    }

    onTermSelect(value) {
        //const value = event.target.value;
        this.termDate = value;
        this.employee.drmTermDate = this._dateService.toString(this.termDate);
    }

    onRehireSelect(value) {
        //const value = event.target.value;
        this.rehireDate = value;
        this.employee.dtmRehireDate = this._dateService.toString(this.rehireDate);
    }

    onOrientSelect(value) {
        //const value = event.target.value;
        this.orientationDate = value;
        this.employee.dtmOrientationCompl = this._dateService.toString(this.orientationDate);
    }

    onBootPurSelect(value) {
        //const value = event.target.value;
        this.bootPurchaseDate = value;
        this.employee.bootPurchase = this._dateService.toString(this.bootPurchaseDate);
    }

    onGlassPurSelect(value) {
        //const value = event.target.value;
        this.glassesPurchaseDate = value;
        this.employee.glassesPurchase = this._dateService.toString(this.glassesPurchaseDate);
    }

    cancel() {
        this.displayEmployeeDialog = false;
        this.employee = {};
        this.userform.reset();
        //this.userform.get('active').setValue(null);
        //this.userform.get('position').setValue(null);
        //this.userform.get('perdiem').setValue(null);
    }

    onSubmit(value: string) {
        this.submitted = true;
        this.emitSave = true;
        //this.service.add({ key: 'tst', severity: 'info', summary: 'Success', detail: 'Form Submitted' });
        this.employee.strLastName = this.userform.get('lastname').value;
        this.employee.strFirstName = this.userform.get('firstname').value;
        this.employee.txtNotes = this.userform.get('notes').value;
        this.employee.ysnActive = this.userform.get('active').value;
        this.employee.intPosition = this.userform.get('position').value;
        this.employee.strPerDiemType = this.userform.get('perdiem').value;
        this.employee.intBenefits = this.userform.get('benefit').value;
        this.employee.otherId = this.userform.get('worktype').value;
        this.employee.strEmail = this.userform.get('email').value;
        this.employee.strCrew = this.userform.get('crew').value;
        this.employee.intPayRate = this.userform.get('payrate').value;
        this.employee.intAreaCode = this.userform.get('area').value;
        //this.employee.mintial = this.userform.get('minitial').value.trim();
        if (this.userform.get('minitial').value !== null) {
            this.employee.mintial = this.userform.get('minitial').value.trim();
        }

        if (this.orientationDone === true) {
            this.employee.ysnOrientation = 1;
        } else {
            this.employee.ysnOrientation = 0;
        }

        //let username = this.userform.get('username').value;
        //let pass = this.userform.get('password').value;
        ////console.log(username);
        //if ((username) && (pass)) {
        //    this.userauth.userId = username;
        //    this.userauth.password = pass;
        //    //console.log('setUsername')
        //}

        //this.userform.reset();
        //this.onSaveEmployee();

        if (this.newEmployee) {
            this.addEmployee(this.employee);
            //this.emitNewEmployee = true;
            //this.customerService.addCustomer(this.customer)
            //    .subscribe(data => {
            //        customers.push(this.customer);
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessViaToast();
            //    });
        } else {
            this.updateEmployee();
            //this.emitNewEmployee = false;
            //customers[this.customers.indexOf(this.selectedCustomer)] = this.customer;
            //this.customerName = this.customer.strCustomerName;
            //this.customerService.updateCustomer(this.customer.intCustomerKey, this.customer)

            //    .subscribe(data => {
            //        console.log(data);
            //        this.ngOnInit();
            //        this.showSuccessUpdateViaToast();
            //        //this.handleError("err:Test");
            //    });
        }

        if (this.newAuth) {
            this.userauth = {};
            //console.log('Creete Auth');
            let username = this.userform.get('username').value;
            this.userauth.userId = this.userform.get('username').value
            this.userauth.password = this.userform.get('password').value;
            //console.log(this.selectedEmployeeId);
            this.addEmployeeAuth(this.userauth);
        } else {
            //console.log('Update AUth');
            this.userauth.userId = this.userform.get('username').value;
            this.userauth.password = this.userform.get('password').value;
            if (this.userauth.userId !== null) {
                this.updateEmployeeAuth();
            }
        }

        this.employee = {};
        this.userauth = {};
        this.submitted = false;
        this.userform.reset();
        this.displayEmployeeDialog = false;
        this.getPositions();
        //this.emitSave = false;
    }

    //get diagnostic() { return JSON.stringify(this.userform.value); };
    getEmployeeHours(id): void {
        this.payRollHourService.getEmployeePayRollHours(id)
            .subscribe(employeehours => this.payrollHours = employeehours);
    }

    getLinemanToolPurchases(id): void {
        this.linemanToolPurchaseService.getToolPurchasesbyID(id)
            .subscribe(toolpurchases => this.linemanToolPurchases = toolpurchases);
    }

    getLinemanToolVendors(): void {
        this.linemanToolVendorService.getToolVendors()
            .subscribe(toolvendors => this.linemanToolVendors = toolvendors);
    }

    getEmployees(): void {
        this.employeeService.getEmployees()
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    getEmployeesbyStatus(status): void {
        this.employeeService.getEmployeesbyStatus(status)
            .subscribe(employees => this.employees =
                employees.filter(e => e.intPosition != "25" && e.intPosition != "73"));
    }

    addEmployee(employee): void {
        if (!employee) {
            return;
        }
        this.employeeService.addEmployee(this.employee)
            .subscribe(data => {
                //customers.push(this.customer);
                //console.log(data);
                this.showSuccessViaToast();
                //this.newAuth = true;
                this.selectedEmployeeId = data.strEmployeeId;
                if (this.selectedActive == 'Active') {
                    this.getEmployeesbyStatus('Active');
                } else if (this.selectedActive == 'InActive') {
                    this.getEmployeesbyStatus('InActive');
                } else {
                    this.getEmployees();
                }
            });
    }

    updateEmployee(): void {
        let employees = [...this.employees];
        employees[this.employees.indexOf(this.selectedEmployee)] = this.employee;
        //this.crewName = this.crew.strCrewId;
        //console.log(this.crew);
        //console.log(this.selectedEmployee.strEmployeeID);
        this.employeeName = this.employee.strFirstName + ' ' + this.employee.strLastName;
        this.employeeService.updateEmployee(this.selectedEmployeeId, this.employee)
            .subscribe(data => {
                //console.log(data);
                //this.ngOnInit();
                this.showSuccessUpdateViaToast();
                if (this.selectedActive == 'Active') {
                    this.getEmployeesbyStatus('Active');
                } else if (this.selectedActive == 'InActive') {
                    this.getEmployeesbyStatus('InActive');
                } else {
                    this.getEmployees();
                }
            });
    }

    updateEmployeeAuth(): void {
        let id = this.userauth.id;
        this.userauthservice.updateUserAuth(id, this.userauth)
            .subscribe(data => {
            });
    }

    addEmployeeAuth(auth): void {
        if (!auth) {
            return;
        }
        //console.log('adding');
        this.userauth.createdByUserId = this.userId
        this.userauth.creationDate = this._dateService.toString(new Date());
       //if (!this.selectedEmployeeId===undefined || !this.selectedEmployeeId===null)           
        this.userauth.fkEmpId = this.selectedEmployeeId;
        this.userauthservice.addUserAuth(auth)
            .subscribe(data => {
            });
    }

    showSuccessViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Employee Added', detail: this.employeeName });
    }

    showSuccessUpdateViaToast() {
        this.service.add({ key: 'tst', severity: 'success', summary: 'Employee Updated', detail: this.employeeName });
    }

    getActiveEmployees(): void {
        this.employeeService.searchEmployees("Active")
            .subscribe(employees => this.employees = employees.sort(this.dynamicSort('strLastName')));
    }

    getPositions(): void {
        this.positionService.getPositions()
            .subscribe(positions => this.positions = positions.sort(this.dynamicSort('strPosition')));
    }

    getAreas(): void {
        this.supervisorService.getActiveSupervisors()
            .subscribe(areas => this.supervisors = areas.sort(this.dynamicSort('strAreaNumber')));
    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    showDialogToAddEmployee() {
        this.newEmployee = true;
        this.employee = {};
        this.userform.reset();
        this.emitNewEmployee = true;
        //this.employee.strEmployeeID = this.selectedEmployee.strEmployeeID;
        //this.filteredActiveEmployees = this.filterActiveEmployees(this.employees);
        //this.filteredAreaSuper = this.filterAreaSupervisors(this.employees);
        this.displayEmployeeDialog = true;
        //console.log(this.displayEmployeeDialog);
    }

    editEmployee(rowdata) {
        //this.userform.get('worktype').setValue(null);
        this.selectedEmployeeId = rowdata.strEmployeeId;
        this.getEmployeeAuth(this.selectedEmployeeId);
        this.newEmployee = false;
        this.emitNewEmployee = false;
        this.employee = this.cloneEmployee(rowdata);
        //console.log(this.employee);
        this.displayEmployeeDialog = true;
        this.userform.get('active').setValue(this.employee.ysnActive);
        this.userform.get('position').setValue(this.employee.intPosition);
        this.userform.get('perdiem').setValue(this.employee.strPerDiemType);
        this.userform.get('firstname').setValue(this.employee.strFirstName);
        this.userform.get('lastname').setValue(this.employee.strLastName);
        this.userform.get('hiredate').setValue(new Date(this.employee.dtmHireDate));
        if (this.employee.drmTermDate !== null) {
            this.userform.get('termdate').setValue(new Date(this.employee.drmTermDate));
        }
        if (this.employee.dtmHireDate !== null) {
            this.userform.get('hiredate').setValue(new Date(this.employee.dtmHireDate));
        }
        if (this.employee.dtmRehireDate !== null) {
            this.userform.get('rehiredate').setValue(new Date(this.employee.dtmRehireDate));
        }
        if (this.employee.dtmOrientationCompl !== null) {
            this.userform.get('orientationdate').setValue(new Date(this.employee.dtmOrientationCompl));
        }
        if (this.employee.bootPurchase !== null) {
            this.userform.get('bootpurchasedate').setValue(new Date(this.employee.bootPurchase));
        }
        if (this.employee.glassesPurchase !== null) {
            this.userform.get('glassespurchasedate').setValue(new Date(this.employee.glassesPurchase));
        }

        if (this.employee.intBenefits == 2) {
            this.isRP = false;
            //this.filteredpositions = this.filterClassBPositions(this.positions, 'B');
            //this.positions.filter(e => e.intPositionId == 5 || e.intPositionId == 72 || e.intPositionId == 74 || e.intPositionId == 157 || e.intPositionId == 104 || e.intPositionId == 158 || e.intPositionId == 1 || e.intPositionId == 100 || e.intPositionId == 57 || e.intPositionId == 100)

        } else if (this.employee.intBenefits == 1) {
            this.isRP = false;
            //this.filteredpositions = this.filterClassBPositions(this.positions, 'A');
        } else if (this.employee.intBenefits == 3) {
            this.isRP = false;
            //this.filteredpositions = this.filterClassBPositions(this.positions, 'C');
        } else if (this.employee.intBenefits == 4) {
            this.isRP = false;
            //this.filteredpositions = this.filterClassBPositions(this.positions, 'D');
        } else if (this.employee.intBenefits == 5) {
            //this.filteredpositions = this.filterClassBPositions(this.positions, 'Z');
            this.isRP = true;
        }

        else {
            this.isRP = false;
            //this.filteredpositions=this.positions;
            //console.log(event.value);
        }
        
        this.filteredpositions = this.positions;

       
        //this.userform.get('minitial').setValue(this.employee.mintial.trim());
        this.userform.get('benefit').setValue(this.employee.intBenefits);
        this.userform.get('worktype').setValue(this.employee.otherId);
        this.userform.get('notes').setValue(this.employee.txtNotes);
        this.userform.get('email').setValue(this.employee.strEmail);
        this.userform.get('crew').setValue(this.employee.strCrew);
        this.userform.get('area').setValue(this.employee.intAreaCode);
        this.userform.get('payrate').setValue(this.employee.intPayRate);

        if (this.employee.ysnOrientation == '1') {
            this.orientationDone = true;
        } else {
            this.orientationDone = false;
        }

        this.userform.get('orientationdone').setValue(this.orientationDone);

        if (this.employee.mintial !== null) {
            this.userform.get('minitial').setValue(this.employee.mintial.trim());
        }

        this.filteredpositions = this.positions;
    }
    //onRowUnselect() {
    //    this.displayContract = false;
    //}

    cloneEmployee(c: Employee): Employee {
        let employee = {};
        for (let prop in c) {
            employee[prop] = c[prop];
        }
        return employee;
    }

    //showSuccessViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Added', detail: this.customerName });
    //}

    //showSuccessUpdateViaToast() {
    //    this.service.add({ key: 'tst', severity: 'success', summary: 'Customer Updated', detail: this.customerName });
    //}

    filterAreaSupervisors(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if ((employee.intPosition == '6') && (employee.ysnActive == 'Active')) {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    filterActiveEmployees(filteredemployees: Employee[]) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Employee[] = [];
        for (let i = 0; i < filteredemployees.length; i++) {
            let employee = filteredemployees[i];
            if (employee.ysnActive == 'Active') {
                filtered.push(employee);
            }
        }
        return filtered;
    }

    filterClassBPositions(positions: Position[], category) {
        //console.log(category);
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        //this.service.add({ key: 'tst', severity: 'success', summary: 'Crew Updated', detail: 'fireed' });
        let filtered: Position[] = [];
        for (let i = 0; i < positions.length; i++) {
            let position = positions[i];
            //if (position.intPositionId == 5 || position.intPositionId == 74 || position.intPositionId == 157 || position.intPositionId == 104 || position.intPositionId == 158 || position.intPositionId == 1 || position.intPositionId == 100 || position.intPositionId == 57) {
            if (position.strCategory == category) {
                filtered.push(position);
            }
        }
        return filtered;
    }

    onYearChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'dtmDate', 'gt');
            //console.log(event.value);
        },
            250);
    }

    OnActiveChange(event) {
        //dt.filter($event.value, col.field, 'equals')
        if (event.value === null) {
            this.getEmployees();
        } else {
            this.getEmployeesbyStatus(event.value);
        }

        this.selectedActive = event.value;
    }

    onBenefitSelect(event) {
        //this.getPositions();
        let filteredpositions = this.positions;
         //console.log(event.value);
        let category = event.value;
        //console.log(category);
        if (category == 2) {
            this.filteredpositions = this.filterClassBPositions(this.positions, 'B');
            this.isRP = false;
        } else if (category == 1) {
            this.filteredpositions = this.filterClassBPositions(this.positions, 'A');
            this.isRP = false;
        } else if (category == 3) {
            this.filteredpositions = this.filterClassBPositions(this.positions, 'C');
            this.isRP = false;
        } else if (category == 4) {
            this.filteredpositions = this.filterClassBPositions(this.positions, 'D');
            this.isRP = false;
        } else if (category == 5) {
            this.filteredpositions = this.filterClassBPositions(this.positions, 'Z');
            this.isRP = true;
            this.filteredpositions = this.positions;
        }

        else {
            this.filteredpositions = this.positions;
            //console.log(event.value);
        }
    }

    onOrientationCheck(isChecked: boolean) {
        //console.log(isChecked);
        if (isChecked == true) {
            this.orientationDone = true;
            this.userform.get('orientationdate').setValue(new Date());
            this.employee.dtmOrientationCompl = this._dateService.toString(new Date());
            //this.employee.ysnOrientation = 1;
        } else {
            this.orientationDone = false;
            //this.employee.ysnOrientation = 0;
        }
    }

    onBlur(val) {
        if (val === "area") {
            this.userform.get('hiredate').setValue(new Date(1977, 1, 1));
            this.userform.get('position').setValue(24);
            //this.userform.get('perdiem').setValue('0');
            this.userform.get('benefit').setValue(0);
            this.employee.dtmHireDate = this._dateService.toString(this.userform.get('hiredate').value);
        }
    }

    onBlurLastName(val) {
        //this.userform.get('crew').setValue(val);
    }

    printToolBankBalance() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;       
        this.selectedReportName = "Employee/Toolbank Balance.trdp";
        //console.log(this.selectedDfrId);
    }

    printQtrToolBankBalance() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;
        this.selectedReportName = "Employee/Qtr Toolbank Balance.trdp";
        //console.log(this.selectedDfrId);
    }

    printEmployeeCounseling() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;
        this.selectedReportName = "Employee/Employee Counseling by Employee Id.trdp";
        //console.log(this.selectedDfrId);
    }
    printEmployeeTraining() {
        //console.log(this.selectedEmployeeId);
        this.displayReport = true;
        this.selectedReportName = "Employee/Employee Training Records by Id.trdp";
        //console.log(this.selectedDfrId);
    }

    
}
